import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
// import data from './DataInstansi';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import Option from '../../bootstrap/Option';
import GlobalServices from '../Services/GlobalServices';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
// import { useFormik } from 'formik';
// import moment from 'moment';
// import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
// import Label from '../../bootstrap/forms/Label';
// import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
// import PAYMENTS from '../../../common/data/enumPaymentMethod';

const ModalData = ({ id, isOpen, setIsOpen, statusRefresh, detailData, bannerLama, title }) => {
	const token = sessionStorage.getItem('access_token');
	const initSurat = {
		uuid: '',
		uuid_instansi: '',
		banner: '',
	};

	// const [selectedInstansi, setSelectedInstansi] = useState(null);
	const [dataBanner, setDataBanner] = useState(initSurat);
	const [dataBannerLama, setDataBannerLama] = useState(bannerLama);
	const [dokumenSelected, setDokumenSelected] = useState(false);
	const [isComplete, setIsComplete] = useState(true);

	useEffect(() => {
		if (detailData) {
			setDataBanner(detailData)
			setDataBannerLama(bannerLama)
		}
	}, [detailData]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setDataBanner({ ...dataBanner, [name]: value });
	};

	const handleSubmit = () => {
		let data = {}
		let gambar = []

		if(dataBanner.dokumen){
			if (title === 'Tambah') {
				gambar = JSON.stringify([dataBanner.dokumen])
				data = {
					uuid_instansi: id,
					banner: gambar,
				}
			} else {
				gambar = JSON.stringify([...dataBannerLama, dataBanner.dokumen])
				data = {
					uuid: dataBanner.uuid,
					uuid_instansi: id,
					banner: gambar,
				}
			}
		
			if (title === 'Tambah') {
				GlobalServices.create(data, `banner`, token)
					.then((response) => {
						console.log(response);
	
						if (response.data.code === 201) {
							setIsOpen(false);
							// setSelectedInstansi(null);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Tambah</span>
								</span>,
								`Berhasil Tambah Data Banner`,
							);
							tutupForm();
							statusRefresh();
						} else {
							// setIsOpen(false);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Tambah</span>
								</span>,
								`Gagal Tambah Data Banner`,
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				GlobalServices.update(data, `banner`, token)
					.then((response) => {
						console.log(response);
	
						if (response.data.code === 201) {
							setIsOpen(false);
							// setSelectedInstansi(null);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Tambah</span>
								</span>,
								`Berhasil Tambah Data Banner`,
							);
							tutupForm();
							statusRefresh();
						} else {
							// setIsOpen(false);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Tambah</span>
								</span>,
								`Gagal Tambah Data Banner`,
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			}

		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Tambah</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	const uploadFile = (file) => {
		if (file) {
			const type = file.type.split('/');
			if (
				type[1] === 'jpg' || 
				type[1] === 'jpeg' || 
				type[1] === 'png'
			) {
				const body = new FormData()
				body.append('file', file)
				console.log(file);
				console.log(body);
	
				GlobalServices.create(body, 'upload-file/banner-instansi', token)
					.then((response) => {
						console.log(response);
	
						if (response.data.code === 200) {
							setDokumenSelected(true)
							setIsComplete(true)
	
							setDataBanner({
								...dataBanner, dokumen : response.data.results
							})
							
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Upload Dokumen</span>
								</span>,
								'Berhasil Upload Dokumen',
							);
						} else {
							setDokumenSelected(false)
							setIsComplete(false)
							
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Upload Dokumen</span>
								</span>,
								'Gagal Upload Dokumen',
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
	
			}
		}
	}

	const handleChangeFile = (event) => {
		console.log(event.target.files[0]);
		let data = event.target.files[0]
		setIsComplete(false)
		uploadFile(data)
	}

	const tutupForm = () => {
		setDataBanner(initSurat);
	};
	
	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
					<ModalHeader setIsOpen={setIsOpen} className='p-4'>
						<ModalTitle id={id}>Tambah Banner</ModalTitle>
					</ModalHeader>
					<ModalBody className='px-4'>
						<div className='row g-4'>
							<FormGroup id='dokumen' label='Dokumen' className='col-md-12'>
								<Input
									id='dokumen'
									type='file'
									accept='application/image'
									onChange={handleChangeFile}
									required={title === 'Tambah'}
									// value={dataBanner.dokumen}
								/>
							</FormGroup>
						</div>
					</ModalBody>
					<ModalFooter className='px-4 pb-4'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => {
								setIsOpen(false);
								setDataBanner(initSurat);
								tutupForm();
								// setSelectedInstansi(null);
							}}>
							Tutup
						</Button>
						{/* <Button color='info' onClick={formik.handleSubmit}> */}
						<Button color='info' type='submit' onClick={handleSubmit} disabled={!isComplete}>
							Simpan
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalData.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};

export default ModalData;
