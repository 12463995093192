import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';
import Card, { CardBody } from '../bootstrap/Card';

// Modal
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import ModalData from './Banner/ModalData';

// Alert
import showNotification from '../extras/showNotification';
import GlobalServices from './Services/GlobalServices';

const BannerInstansi = ({ setDataInstansi }) => {
	const roleUser = localStorage.getItem('role_user');
	const token = sessionStorage.getItem('access_token');

	// let uuidInstansi = ""
	// if(roleUser==='Super Operator'){
	// 	uuidInstansi = setDataInstansi;
	// }
	// else{
		let uuidInstansi = setDataInstansi.uuid;
	// }

	const Merge_settings = {
		dots: true,
		infinite: true,
		speed: 100,
		slidesToShow: 1,
		slidesToScroll: 1,
		// margin: 0,
		// padding: 0
	};

	// Fetching Data
	// const [list, setList] = useState([]);
	const [list, setList] = useState([]);
	const [dataset, setDataset] = useState([]);
	const [statusList, setStatusList] = useState('');
	const [loading, setLoading] = useState(true);

	const getDataList = (page) => {
		let extra = ''

		// if (page) {
		// 	extra = `?page=${page}&q=${cariValue.nama}`
		// }

		GlobalServices.getAll(`banner/instansi/${uuidInstansi}`, token)
			.then((response) => {
				if(response.results.banner){
					let parsingGambar = JSON.parse(response.results.banner)
					setList(parsingGambar);
					setStatusList('Ada')
					setDataset(response.results)
					setLoading(false)
				}
				else{
					setStatusList('Kosong')
					setLoading(false)
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const refreshList = (page = '') => {
		getDataList(page);
	};

	const [listInstansi, setListInstansi] = useState([]);

	useEffect(() => {
		getDataList();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// End Fetching Data

	// console.log('List')
	// console.log(list)
	let gambarList = [];
	if (statusList==='Ada') {
		gambarList = list.map((dt) => {
			return (
				<div align='center' style={{ paddingLeft: '350px'}}>
					<div className="p-2" align="center" style={{ paddingLeft: '350px'}}>
						<img className="p-2" style={{width: '450px', height: '150px'}} src={dt} alt="" />
					</div>
					<div align="center" style={{ paddingLeft: '20px'}}>
						<Button size="md" color="danger" 
							onClick={() => {
								initialStatus();
								setCenteredStatus(true);
								setState(true);
								onDelete(dt)}
							}
						>
							Hapus
						</Button>
					</div>
				</div>
			);
		});
	}

	// Fitur Modal
	const [tambahModalStatus, setTambahModalStatus] = useState(false);
	const [editModalStatus, setEditModalStatus] = useState(false);

	const [state, setState] = useState(false);

	const [centeredStatus, setCenteredStatus] = useState(false);

	const initialStatus = () => {
		setCenteredStatus(false);
	};
	// End Fitur Modal

	// Fitur Detail Data
	const initialInstansiState = {
		banner: '',
		uuid: '',
		uuid_instansi: '',
	};

	const [instansi, setInstansi] = useState(initialInstansiState);
	const [bannerLama, setBannerLama] = useState(initialInstansiState);
	const [detailBanner, setDetailBanner] = useState({
		banner: '',
		uuid: '',
		uuid_instansi: '',
	});
	const [dataValues, setDataValues] = useState(initialInstansiState);

	const detailData = () => {
		if (dataset) {
			const updateDetail = dataset;
			setDetailBanner(updateDetail);
			setBannerLama(JSON.parse(updateDetail.banner))
		}
	};
	// End Fitur Detail Data

	// Fitur Hapus Data
	const onDelete = (link) => {
		setDataValues({ ...dataValues, banner: link, });
	};

	const hapusData = () => {
		let newArray = [ ...list, dataValues.banner ];
		if (list.includes(dataValues.banner)) {
			newArray = newArray.filter((day) => day !== dataValues.banner);
		}

		let linkStr = JSON.stringify(newArray);

		// console.log('hasilnya')
		// console.log(dataValues.banner)
		// console.log(linkStr)
		
		const data = {
			uuid: dataset.uuid,
			uuid_instansi: dataset.uuid_instansi,
			banner: linkStr,
		};

		// console.log('Hapus Bos')
		// console.log(data)
		GlobalServices.update(data, `banner`, token)
			.then((response) => {
				if (response.data.code === 201) {
					setState(false);
					// setSelectedInstansi(null);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Berhasil Hapus</span>
						</span>,
						`Berhasil Hapus Data Banner`,
					);
					refreshList();
				} else {
					// setIsOpen(false);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Warning' size='lg' className='me-1' />
							<span>Gagal Hapus</span>
						</span>,
						`Gagal Hapus Data Banner`,
					);
				}
			})
			.catch((e) => {
				console.log(e);
			});

	};
	// End Fitur Hapus Data

	return (
		<>
			<div className='row'>
				<div className='col-3' align='left'>
					{statusList === 'Ada'
					?
						<>
							<Button
								// icon='Add'
								color='success'
								// isLight
								onClick={() => {detailData(); setEditModalStatus(true)}}>
								Tambah Data
							</Button>
						</>
					:
						<>
							<Button
								// icon='Add'
								color='success'
								// isLight
								onClick={() => setTambahModalStatus(true)}>
								Tambah Data
							</Button>
						</>
					}
				</div>
				<div className='col-9'>
					&nbsp;
				</div>
			</div>
			<br />
			<div className='row'>
				{
					loading
					?
					<>
						<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
							<br/><br/><br/><br/>
							<div className='row g-3'>
							{Object.keys(COLORS).map((color) => (
								<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
									<Spinner color={COLORS[color]} isSmall isGrow />
								</div>
							))}
							</div>
						</div>
					</>
					:
					<>
						{ list.length!==0 ? (
							<Slider {...Merge_settings}>{gambarList}</Slider>
						) : (
							<div>
								<h3 align="center">Data Banner Belum Ada</h3>
								<br />
								<br />
							</div>
						)}
					</>
				}
			</div>
					
			<ModalData
				setIsOpen={setTambahModalStatus}
				isOpen={tambahModalStatus}
				id={uuidInstansi}
				// uuidInstansi={uuidInstansi}
				statusRefresh={refreshList}
				title='Tambah'
			/>
			<ModalData
				setIsOpen={setEditModalStatus}
				isOpen={editModalStatus}
				id={uuidInstansi}
				detailData={detailBanner}
				bannerLama={bannerLama}
				statusRefresh={refreshList}
				title='Edit'
			/>

			<Modal
				isOpen={state}
				setIsOpen={setState}
				titleId='exampleModalLabel'
				isCentered={centeredStatus}>
				<ModalHeader>&nbsp;</ModalHeader>
				<ModalBody>
					<p align='center'>
						<h2>Yakin Ingin Hapus Data?</h2> <br />
						Proses ini tidak dapat dibatalkan!
					</p>
					<br />
					<div align='center'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						&nbsp;&nbsp;
						<Button color='danger' icon='Delete' onClick={hapusData}>
							Hapus
						</Button>
					</div>
				</ModalBody>
				<ModalFooter>
					&nbsp;
					{/* <div align='center'>
						<Button
							color='success'
							// isOutline
							className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						<Button color='danger' icon='Delete'>
							Hapus
						</Button>
					</div> */}
				</ModalFooter>
			</Modal>
		</>
	);
};

export default BannerInstansi;
