import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMaster, menuOperator } from '../../menu';
import Card, { CardBody } from '../bootstrap/Card';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import Breadcrumb from '../bootstrap/Breadcrumb';
import GlobalServices from './Services/GlobalServices';
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';

// Modal
import ModalTambah from './RiwayatPenomoran/ModalTambah';

const RiwayatPenomoranSurat = () => {
	const roleUser = localStorage.getItem('role_user');
	const uuidInstansi = localStorage.getItem('uuid_instansi');
	const token = sessionStorage.getItem('access_token');
	const formData = {
		id: '',
		username: '',
		nip: '',
		password: '',
		no_hp: '',
		email: '',
		role: '',
		selectRole: {},
	};

	// Fitur Pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);

	const [pagination, setPagination] = useState({
		current_page: 1,
		from: 1,
		last_page: 1,
		first_page_url: '',
		last_page_url: '',
		next_page_url: '',
		prev_page_url: 10,
		path: '',
		per_page: 10,
		to: 10,
		total: 0,
	});
	// End Fitur Pagination

	// Fetching Data
	const [loading, setLoading] = useState(true);
	const [list, setList] = useState([]);

	const getDataList = (page) => {
		let extra = ''

		if (page) {
			extra = `?page=${page}`
		}

		let link=`penomoran/${uuidInstansi}${extra}`;

		GlobalServices.getAll(link, token)
			.then((response) => {
				console.log('response')
				console.log(response)
				setList(response.results.data);
				setPagination(response.results)
				setCurrentPage(response.results.current_page)
				setPerPage(response.results.per_page)
				setLoading(false)
			})
			.catch((e) => {
				setLoading(false)
				console.log(e);
			});
	};

	const refreshList = (page = '') => {
		getDataList(page);
	};

	useEffect(() => {
		getDataList();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// End Fetching Data

	// Fitur Modal
	const [tambahModalStatus, setTambahModalStatus] = useState(false);
	// End Fitur Modal

	return (
		<PageWrapper title={menuOperator.riwayatPenomoran.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{
								title: menuOperator.riwayatPenomoran.text,
								to: menuOperator.riwayatPenomoran.path,
							},
						]}
					/>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
						{
							loading
							?
							<>
							<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
								<br/><br/><br/><br/><br/><br/><br/><br/><br/>
								<div className='row g-3'>
								{Object.keys(COLORS).map((color) => (
									<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
										<Spinner color={COLORS[color]} isSmall isGrow />
									</div>
								))}
								</div>
								<br/><br/><br/><br/>
							</div>
							</>
						:
							<>
							<CardBody isScrollable className='table-responsive'>
								<div className='row'>
									<div className='col-3' align='left'>
										<Button
											// icon='Add'
											color='success'
											// isLight
											onClick={() => setTambahModalStatus(true)}>
											Tambah Data
										</Button>
									</div>
								</div>
								<br />
								<table className='table table-modern table-hover'>
									<thead>
										<tr>
											{/* <th>No.</th> */}
											<th style={{textAlign:'center'}}>Tahun</th>
											<th style={{textAlign:'center'}}>Nomor Surat</th>
										</tr>
									</thead>
									<tbody>
										{list.map(
											(i, key) => (
												<tr key={i.id}>
													{/* <td>{key + 1}</td> */}
													<td align='center'>{i.tahun}</td>
													<td align='center'>{i.nomor}</td>
												</tr>
											),
										)}
									</tbody>
								</table>
							</CardBody>
							<PaginationButtons
								data={list}
								label='Penomoran Surat'
								setCurrentPage={getDataList}
								setPerPage={setPerPage}
								currentPage={currentPage}
								perPage={perPage}
								paginationDetail={pagination}
								setpaginationDetail={setPagination}
							/>
							</>
						}
						</Card>
					</div>
				</div>
			</Page>
			<ModalTambah
				setIsOpen={setTambahModalStatus}
				isOpen={tambahModalStatus}
				id={0}
				statusRefresh={refreshList}
			/>
		</PageWrapper>
	);
};

export default RiwayatPenomoranSurat;