import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe'
import Select from "react-select";
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import GlobalServices from '../Services/GlobalServices';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import COLORS from '../Services/Spinner';
import Spinner from '../../bootstrap/Spinner';
import SelectData from '../Services/SelectData';

const ModalDetail = ({ id, isOpen, setIsOpen, detailSurat, title, syaratSurat, statusRefresh, statusDisposisi }) => {
	const jabatanStrukturalUser = localStorage.getItem('uuid_jabatan_struktural');
	const roleUser = localStorage.getItem('role_user');
	const uuidPengguna = localStorage.getItem('uuid_pengguna');
	const uuidInstansi = localStorage.getItem('uuid_instansi');
	const token = sessionStorage.getItem('access_token');
	const initSurat = {
		data_karyawan: {},
		data_pengajuan_surat_disposisi: [],
		data_surat_instansi: {},
		status: '',
		syarat_surat: '',
		tanggal_pengajuan: '',
		uuid: '',
		uuid_surat_instansi: '',
		uuid_karyawan: '',
	};

	// Fitur Modal
	const [state, setState] = useState(false);
	const [stateVerif, setStateVerif] = useState(false);
	const [stateVerifDokumen, setStateVerifDokumen] = useState(false);
	const [statePreview, setStatePreview] = useState(false);
	const [statusSet, setStatusSet] = useState('');
	const formDataVerif = {
		title: 'Verifikasi Dokumen Karyawan',
		uuid: '',
		uuid_dokumen: '',
		uuid_karyawan: '',
		link: '',
		status: '',
	};
	// End Fitur Modal
	
	const [jenisVerif, setJenisVerif] = useState('');
	const [dataEdit, setDataEdit] = useState(formDataVerif);
	const [ listTTD, setListTTD ] = useState([]);
	const [ selectedTTD, setSelectedTTD ] = useState(null);
	
	const [loading, setLoading] = useState(false);
	const [loadingTTD, setLoadingTTD] = useState(false);
	const [loadingPreview, setLoadingPreview] = useState(false);
	const [linkPreviewCetak, setlinkPreviewCetak] = useState('');
	const [statusCQ, setstatusCQ] = useState(false);
	const [statusTujuanSurat, setstatusTujuanSurat] = useState(false);
	const [statusSalamPenutup, setstatusSalamPenutup] = useState(false);
	const [ surat, setSurat ] = useState(initSurat);
	const [ listDokumen, setListDokumen ] = useState([]);
	
	const [nomorSuratNext, setNomorSuratNext] = useState([]);
	const [selectedNomorSuratNext, setSelectedNomorSuratNext] = useState([]);
	const [statusOpenButton, setStatusOpenButton] = useState(false);
	const [statusInput, setStatusInput] = useState(false);

	const [listStatusPenggantiTTD, setListStatusPenggantiTTD] = useState(SelectData.statusPenggantiTTD);
	const [selectedStatusPengganti, setSelectedStatusPengganti] = useState(null);
	const [dasar, setDasar] = useState(null);

	async function getDokumen() {
		let listDetailDokumen = await getDetailDokumen()
		setListDokumen(listDetailDokumen)
	}

	useEffect(
		() => {
			if (detailSurat && detailSurat.uuid !== '') {
				console.log('detailSurat');
				console.log(detailSurat);
				// console.log(statusDisposisi);
				getDokumen()
				
				setSurat(detailSurat);
				setDataListDok(syaratSurat);
				setDataPengajuanSuratDisposisi(detailSurat.data_pengajuan_surat_disposisi);

				GlobalServices.getListBy('configurasi-tanda-tangan', 'instansi', uuidInstansi, token).then((hasil) => {

					let frmSelect = [];
					hasil.data.results.forEach(dt => {
						let dataConfig = JSON.parse(dt.config_ttd)

						let pengganti = ''
						if(dataConfig.isi[1].value !== ''){
							let jb_pengganti = ''
							if(dataConfig.isi[3].value !== ''){
								jb_pengganti = ` - ${dataConfig.isi[3].value}`
							}
							pengganti = `(${dataConfig.isi[1].value} ${dataConfig.isi[6].value}${jb_pengganti})`
						}
						frmSelect.push({ value: dt.config_ttd, label: `${dataConfig.isi[4].value} - ${dataConfig.isi[2].value} ${pengganti}` });
					});

					setListTTD(frmSelect)
				});

				GlobalServices.get(detailSurat.uuid, 'administrasi/pengajuan-surat', token).then((hasil) => {
					// console.log('Find Pengajuan Surat')
					// console.log(hasil.data.results)
					// console.log('show komponen surat')
					// console.log(hasil.data.results.data_pengajuan_surat_detail)

					let dataKomponen = [];
					hasil.data.results.data_pengajuan_surat_detail.forEach((dt, key) => {
						let isiKomponen = JSON.parse(dt.isi)
						// console.log('Apa Hasilnya')
						// // console.log(dt)
						// console.log(key)
						// console.log(isiKomponen)
						// JIKA SURAT TUGAS, CEK KEY DASAR, AMBIL ID DAN ISINYA
						let subIsi = isiKomponen.isi
						if (Array.isArray(subIsi)) {
							subIsi.forEach((val) => {
								if (val.key === 'dasar') {
									setDasar(dt)
								}
							})
						}

						dataKomponen.push({ komponen: isiKomponen.komponen });
					});

					// console.log('show data komponennya')
					// console.log(dataKomponen)

					// Set Tujuan Surat Aktif Nonaktif
					let newArray = dataKomponen;	
					newArray = newArray.filter(day => day.komponen === 'tujuan_surat');

					// console.log('Hasil Filter komponen')
					// console.log(newArray)
					
					if(newArray.length !== 0){
						setstatusTujuanSurat(true)
					}
					// End Set Tujuan Surat Aktif Nonaktif

					// Set Salam Penutup Aktif Nonaktif
					let dataKomponenTTD = [];
					hasil.data.results.data_pengajuan_surat_detail.forEach((dt, key) => {
						let isiKomponenTTD = JSON.parse(dt.isi)
						dataKomponenTTD.push({ komponen: isiKomponenTTD });
					});
					
					let newArrayKomponenTTD = dataKomponenTTD;	
					newArrayKomponenTTD = newArrayKomponenTTD.filter(day => day.komponen.komponen === 'tanda_tangan');

					let newArraySalamPenutup = newArrayKomponenTTD[0].komponen.isi;	
					newArraySalamPenutup = newArraySalamPenutup.filter(day => day.key === 'salam_penutup');

					if(newArraySalamPenutup[0].value !== ''){
						setstatusSalamPenutup(true)
					}
					else{
						setstatusSalamPenutup(false)
					}

					// console.log('show data komponen ttd')
					// console.log(dataKomponenTTD)
					// console.log(newArrayKomponenTTD)
					// console.log(newArrayKomponenTTD[0].komponen.isi)
					// console.log(newArraySalamPenutup)
					// console.log(newArraySalamPenutup[0].value)
					// End Set Salam Penutup Aktif Nonaktif
				});
			}
		},
		[detailSurat] // eslint-disable-line react-hooks/exhaustive-deps
	);

	const getDetailDokumen = async () => {
		let listDetailDokumen = []

		await Promise.all(
			syaratSurat.map(async (val) => {
				const status = await GlobalServices.get(val.uuid, 'administrasi/dokumen-karyawan', token).then((hasil) => {
					return hasil.data.results.status
				});
				
				listDetailDokumen.push({
					...val,
					status
				})
	
				return ''
			})
		)

		return listDetailDokumen
	}

	const getDetailPerDokumen = (data) => {
		// console.log('Data Per DOkumen')
		// console.log(data)
		GlobalServices.get(data.uuid, 'administrasi/dokumen-karyawan', token)
			.then((response) => {
				setDataEdit({
					...dataEdit, 
					uuid: response.data.results.uuid,
					uuid_dokumen: response.data.results.uuid_dokumen,
					uuid_karyawan: response.data.results.uuid_karyawan,
					link: response.data.results.link,
				})
			})
			.catch((e) => {
				console.log(e);
			});
	}

	const handleSubmitVerifDokumen = (status) => {
		let statusVerif = '';
		if(status === 'Tolak'){
			statusVerif = 'Tidak Aktif';
		}
		else if(status === 'Setuju'){
			statusVerif = 'Aktif';
		}

		const data = {
			uuid: dataEdit.uuid,
			uuid_dokumen: dataEdit.uuid_dokumen,
			uuid_karyawan: dataEdit.uuid_karyawan,
			link: dataEdit.link,
			status: statusVerif,
		};

		// console.log('Data Simpan Verifk Dokumen')
		// console.log(data)
		GlobalServices.update(data, 'administrasi/dokumen-karyawan', token)
			.then((response) => {
				if (response.data.code === 201) {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Berhasil Ubah Status</span>
						</span>,
						'Berhasil Ubah Status Dokumen Karyawan',
						);
					statusRefresh();
					setDataEdit(formDataVerif);
					setStateVerifDokumen(false);
					setIsOpen(true);
					getDokumen();
				} else {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Warning' size='lg' className='me-1' />
							<span>Gagal Ubah Status</span>
						</span>,
						'Gagal Ubah Status Dokumen Karyawan',
					);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const formData = {
		uuid: detailSurat.uuid,
		status: 'Selesai',
		catatan: '',
		tujuan: '',
		cq: '',
		tempat: '',
		nomor_surat: '',
		dasar: '',
	};

	const [dataValues, setdataValues] = useState(formData);
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setdataValues({ ...dataValues, [name]: value });
	};

	const handleSubmit = () => {
		if(jenisVerif === 'Selesai')
		{
			setLoading(true)
			if(listDisposisiLanjutan.length!==0){
				if (selectedDisposisi){
					let data = {
						uuid: dataPengajuanSuratDisposisi.uuid,
						uuid_pengajuan_surat: dataPengajuanSuratDisposisi.uuid_pengajuan_surat,
						uuid_jabatan_struktural: dataPengajuanSuratDisposisi.uuid_jabatan_struktural,
						status: 'Selesai',
						uuid_pengguna: uuidPengguna,
						memo:  dataPengajuanSuratDisposisi.memo,
						uuid_jabatan_struktural_tujuan: selectedDisposisi.value,
					};

					GlobalServices.create(data, 'administrasi/pengajuan-surat/disposisi/create-with-tujuan', token)
						.then((response) => {
							if (response.data.code === 201) {
								setLoading(false)
								showNotification(
									<span className='d-flex align-items-center'>
										<Icon icon='Info' size='lg' className='me-1' />
										<span>Berhasil Setujui Pengajuan</span>
									</span>,
									'Berhasil Setujui Pengajuan Surat',
									);
									statusRefresh();
									setSelectedDisposisi(null)
									setState(false);
									setIsOpen(false);
							} else {
								setLoading(false)
								showNotification(
									<span className='d-flex align-items-center'>
										<Icon icon='Warning' size='lg' className='me-1' />
										<span>Gagal Setujui Pengajuan</span>
									</span>,
									'Gagal Setujui Pengajuan Surat',
								);
							}
						})
						.catch((e) => {
							setLoading(false)
							console.log(e);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Setujui Pengajuan</span>
								</span>,
								'Gagal Setujui Pengajuan Surat',
							);
						});

				} else {
					// setIsOpen(false);
					setLoading(false)
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Warning' size='lg' className='me-1' />
							<span>Gagal Setujui Pengajuan</span>
						</span>,
						'Data Tidak Lengkap!',
					);
				}
			}
			else{
				let data = {
					uuid: dataPengajuanSuratDisposisi.uuid,
					uuid_pengajuan_surat: dataPengajuanSuratDisposisi.uuid_pengajuan_surat,
					uuid_jabatan_struktural: dataPengajuanSuratDisposisi.uuid_jabatan_struktural,
					status: 'Selesai',
					uuid_pengguna: uuidPengguna,
					memo:  dataPengajuanSuratDisposisi.memo,
					uuid_jabatan_struktural_tujuan: '',
				};

				GlobalServices.create(data, 'administrasi/pengajuan-surat/disposisi/create-with-tujuan', token)
					.then((response) => {
						if (response.data.code === 201) {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Setujui Pengajuan</span>
								</span>,
								'Berhasil Setujui Pengajuan Surat',
								);
								statusRefresh();
								setSelectedDisposisi(null)
								setState(false);
								setIsOpen(false);
						} else {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Setujui Pengajuan</span>
								</span>,
								'Gagal Setujui Pengajuan Surat',
							);
						}
					})
					.catch((e) => {
						setLoading(false)
						console.log(e);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Setujui Pengajuan</span>
							</span>,
							'Gagal Setujui Pengajuan Surat',
						);
					});
			}
		}
		else if(jenisVerif === 'Tolak'){
			setLoading(true)
			if (dataValues.catatan !== '') {
				let data = {
					uuid: dataPengajuanSuratDisposisi.uuid,
					uuid_pengajuan_surat: dataPengajuanSuratDisposisi.uuid_pengajuan_surat,
					uuid_jabatan_struktural: dataPengajuanSuratDisposisi.uuid_jabatan_struktural,
					status: 'Tolak',
					uuid_pengguna: uuidPengguna,
					memo:  dataValues.catatan,
					uuid_jabatan_struktural_tujuan: '',
				};

				GlobalServices.create(data, 'administrasi/pengajuan-surat/disposisi/create-with-tujuan', token)
					.then((response) => {
						if (response.data.code === 201) {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Tolak Pengajuan</span>
								</span>,
								'Berhasil Tolak Pengajuan Surat',
								);
								statusRefresh();
								setState(false);
								setIsOpen(false);
						} else {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Tolak Pengajuan</span>
								</span>,
								'Gagal Tolak Pengajuan Surat',
							);
						}
					})
					.catch((e) => {
						setLoading(false)
						console.log(e);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Tolak Pengajuan</span>
							</span>,
							'Gagal Tolak Pengajuan Surat',
						);
					});
			} else {
				setLoading(false)
				// setIsOpen(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Tolak Pengajuan</span>
					</span>,
					'Data Tidak Lengkap!',
				);
			}
		}
	};

	const handleSubmitVerifSurat = () => {
		
		if(statusDisposisi === 'Persetujuan'){
			if (selectedDisposisi){
				setLoading(true)
				let data = {
					uuid: dataPengajuanSuratDisposisi.uuid,
					uuid_pengajuan_surat: dataPengajuanSuratDisposisi.uuid_pengajuan_surat,
					uuid_jabatan_struktural: dataPengajuanSuratDisposisi.uuid_jabatan_struktural,
					status: 'Selesai',
					uuid_pengguna: uuidPengguna,
					memo:  dataPengajuanSuratDisposisi.memo,
					uuid_jabatan_struktural_tujuan: selectedDisposisi.value,
				};

				GlobalServices.create(data, 'administrasi/pengajuan-surat/disposisi/create-with-tujuan', token)
					.then((response) => {
						if (response.data.code === 201) {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Setujui Pengajuan</span>
								</span>,
								'Berhasil Setujui Pengajuan Surat',
								);
								statusRefresh();
								setSelectedDisposisi(null)
								setStateVerif(false);
								setIsOpen(false);
						} else {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Setujui Pengajuan</span>
								</span>,
								'Gagal Setujui Pengajuan Surat',
							);
						}
					})
					.catch((e) => {
						setLoading(false)
						console.log(e);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Setujui Pengajuan</span>
							</span>,
							'Gagal Setujui Pengajuan Surat',
						);
					});

			} else {
				// setIsOpen(false);
				setLoading(false)
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Setujui Pengajuan</span>
					</span>,
					'Data Tidak Lengkap!',
				);
			}
		}
		else if(statusDisposisi === 'Menunggu'){
			let cekCQ = false;
			let cekTujuan = false;
			let cekNomorSurat = false;
			let cekDasar = true;
			let setNomorSuratNow = '';
			let nomorTunggal = ''

			if(statusCQ){
				if(dataValues.cq !== ''){
					cekCQ = true;
				}
			}
			else{
				cekCQ = true;
			}

			if(statusTujuanSurat){
				if(dataValues.tujuan !== '' && dataValues.tempat !== ''){
					cekTujuan = true;
				}
			}
			else{
				cekTujuan = true;
			}

			let updatedDasar = null
			if (detailSurat && detailSurat.data_surat_instansi && detailSurat.data_surat_instansi.nama_alias && detailSurat.data_surat_instansi.nama_alias.toLowerCase() === 'surat tugas') {
				if (dataValues.dasar !== '') {
					cekDasar = true

					if (dasar) {
						// console.log(dasar);
						let isiDasar = JSON.parse(dasar.isi)
						let newIsiDasar = isiDasar
						isiDasar.isi.forEach((dt, index) => {
							if (dt.key === 'dasar') {
								newIsiDasar.isi[index] = {
									...newIsiDasar.isi[index],
									value : dataValues.dasar
								}
							}
						})
						let newDasar = {
							...dasar,
							isi: JSON.stringify(newIsiDasar)
						}
						delete newDasar.created_at
						delete newDasar.data_surat_instansi_format_surat
						delete newDasar.updated_at
						delete newDasar.uuid_format_surat
						delete newDasar.uuid_pengajuan_surat

						setDasar(newDasar)
						updatedDasar = newDasar
						// console.log(newDasar);
						// console.log(updatedDasar);
					}

				} else {
					cekDasar = false
				}
			}

			if(statusInput && selectedTTD){
				
				let nomorNext = selectedNomorSuratNext.value;
				let splitFormatNomor = nomorNext.split('/');
				let splitNomorSugest = splitFormatNomor[0].split('.');
				let setNomorSugest = splitNomorSugest[1];

				let currentNomorSuggest = setNomorSugest
				if (!setNomorSugest) {
					currentNomorSuggest = splitFormatNomor[0]
				} 
				// console.log(dataValues.nomor_surat)

				// if(dataValues.nomor_surat >= setNomorSugest){
				if(dataValues.nomor_surat >= currentNomorSuggest){
					// setNomorSuratNow = nomorNext.replace(`B.${setNomorSugest}/F`, `B.${dataValues.nomor_surat}/F`);
					setNomorSuratNow = nomorNext.replace(`B.${currentNomorSuggest}/F`, `B.${dataValues.nomor_surat}/F`);
					
					// Jika Surat Tugas
					if (detailSurat && detailSurat.data_surat_instansi && detailSurat.data_surat_instansi.nama_alias && detailSurat.data_surat_instansi.nama_alias.toLowerCase() === 'surat tugas') {
						setNomorSuratNow = nomorNext.replace(`${currentNomorSuggest}/HM`, `${dataValues.nomor_surat}/HM`);
					}
					// End jika surat tugas

					cekNomorSurat = true
					nomorTunggal = dataValues.nomor_surat
				}
				else{
					showNotification(
						<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Verifikasi</span>
					</span>,
					'Nomor Surat Tidak Boleh Kurang Dari Yang Telah Dianjurkan',
					);
				}
			}
			else if(!statusInput && selectedTTD){
				if(selectedNomorSuratNext){
					let nomorNext2 = selectedNomorSuratNext.value;
					let splitFormatNomor2 = nomorNext2.split('/');
					let splitNomorSugest2 = splitFormatNomor2[0].split('.');
					let setNomorSugest2 = splitNomorSugest2[1];
					
					cekNomorSurat = true
					setNomorSuratNow = selectedNomorSuratNext.value;
					if (setNomorSugest2) {
						nomorTunggal = setNomorSugest2
					} else {
						nomorTunggal = splitFormatNomor2[0]
					}
				}
			}

			if (
					selectedTTD &&
					cekNomorSurat &&
					cekCQ &&
					cekTujuan &&
					cekDasar 
				) 
			{
				// isi field DASAR dimasukkan ke API yg mna?
				setLoading(true)
				let statusPengganti = ''
				if(selectedStatusPengganti){
					statusPengganti = selectedStatusPengganti.value
				}

				let isiTujuanSurat = [
					{key: '', value: 'Kepada Yth. :'},
					{key: statusPengganti, value: dataValues.tujuan},
					{key: '', value: dataValues.tempat},
				];
				if(statusCQ){
					isiTujuanSurat = [
						{key: '', value: 'Kepada Yth. :'},
						{key: statusPengganti, value: dataValues.tujuan},
						{key: 'c.q', value: dataValues.cq},
						{key: '', value: dataValues.tempat},
					]
				}
				
				let dataTujuanSurat = {
					komponen: 'tujuan_surat',
					isi: isiTujuanSurat,
				}

				let data = {
					uuid: detailSurat.uuid,
					status: 'Selesai',
					catatan: dataValues.catatan,
					tanda_tangan: selectedTTD.value,
					nomor_surat: setNomorSuratNow,
				};

				if(statusTujuanSurat){
					data = {
						uuid: detailSurat.uuid,
						status: 'Selesai',
						catatan: dataValues.catatan,
						tanda_tangan: selectedTTD.value,
						tujuan: JSON.stringify(dataTujuanSurat),
						nomor_surat: setNomorSuratNow,
					};
				}

				if (detailSurat && detailSurat.data_surat_instansi && detailSurat.data_surat_instansi.nama_alias && detailSurat.data_surat_instansi.nama_alias.toLowerCase() === 'surat tugas') {
					data = {
						...data,
						dasar: updatedDasar
					}
				}
				
				// console.log('Hasil SImpan TTD')
				// console.log(data)
				// console.log(JSON.parse(data.tanda_tangan))
				// console.log('setNomorSuratNow')
				// console.log(setNomorSuratNow)
				// console.log('cekNomorSurat')
				// console.log(cekNomorSurat)
				GlobalServices.create(data, 'administrasi/pengajuan-surat/karyawan/verifikasi', token)
					.then((response) => {
						// console.log('Response Simpan')
						// console.log(response)
						if (response.data.code === 201) {

							// Create Nomor Surat
							let dataNomor = {
								uuid_instansi: uuidInstansi,
								nomor: nomorTunggal,
							}

							GlobalServices.create(dataNomor, 'penomoran/create', token)
							.then((hasil) => {
								// console.log('Hasil Create Penomoran')
								// console.log(hasil)
								if (hasil.data.code === 200) {
									setLoading(false)
									showNotification(
										<span className='d-flex align-items-center'>
										<Icon icon='Info' size='lg' className='me-1' />
										<span>Berhasil Verifikasi</span>
									</span>,
									'Berhasil Verifikasi Surat Keluar',
									);
									tutupVerifikasi();
									setIsOpen(false);
									setstatusTujuanSurat(false)
									statusRefresh();
								} else {
									setLoading(false)
									showNotification(
										<span className='d-flex align-items-center'>
											<Icon icon='Warning' size='lg' className='me-1' />
											<span>Gagal Verifikasi</span>
										</span>,
										'Gagal Verifikasi Surat Keluar',
									);
								}
							})
							.catch((e) => {
								setLoading(false)
								console.log(e);
							});

							// End Create Nomor Surat
						} else {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Verifikasi</span>
								</span>,
								'Gagal Verifikasi Surat Keluar',
							);
						}
					})
					.catch((e) => {
						setLoading(false)
						console.log(e);
					});
			} else {
				setLoading(false)
				// setIsOpen(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Verifikasi</span>
					</span>,
					'Data Tidak Lengkap!',
				);
			}
		}
	};

	const tutupVerifikasi = () => {
		setState(false);
		setStateVerif(false);
		setStateVerifDokumen(false);
		setJenisVerif('');
		setDataPengajuanSuratDisposisi(detailSurat.data_pengajuan_surat_disposisi);
		setdataValues(formData);
		setSelectedTTD(null);
		setSelectedDisposisi(null);
		setSelectedStatusPengganti(null);
		setstatusCQ(false);
	};

	const tombolVerif = (stat) => {
		switch (stat) {
			case 'Menunggu':
				return (
					<div align='right'>
						<Button
							icon='Check'
							color='success'
							style={{width:'130px'}}
							// isLight
							onClick={() => {
								setStateVerif(true);
								setIsOpen(false)
							}}

						>
							Verifikasi
						</Button>
					</div>
				)
				// break;
			
			case 'Persetujuan':
				return (
					<div align='right'>
						<Button
							icon='Close'
							color='danger'
							// isLight
							onClick={() => {
								getDetailDisposisi(detailSurat.uuid)
								setState(true);
								setIsOpen(false);
								setJenisVerif('Tolak');
							}}
							style={{width:'100px'}}
						>
							Tolak
						</Button>&nbsp;&nbsp;
						<Button
							icon='Check'
							color='success'
							// isLight
							onClick={() => {
								getDetailDisposisi(detailSurat.uuid)
								setState(true);
								setIsOpen(false);
								setJenisVerif('Selesai');
							}}
							style={{width:'100px'}}
						>
							Setujui
						</Button>
					</div>
				)
				// break;
			case 'Proses':
				let tombolAksi = ''
				if(roleUser === 'Super Operator'){
					tombolAksi = (
						<div align='right'>
							<Button
								icon='Close'
								color='danger'
								// isLight
								onClick={() => {
									getDetailDisposisi(detailSurat.uuid)
									setState(true);
									setIsOpen(false);
									setJenisVerif('Tolak');
								}}
								style={{width:'100px'}}
							>
								Tolak
							</Button>&nbsp;&nbsp;
							<Button
								icon='Check'
								color='success'
								// isLight
								onClick={() => {
									getDetailDisposisi(detailSurat.uuid)
									setState(true);
									setIsOpen(false);
									setJenisVerif('Selesai');
								}}
								style={{width:'100px'}}
							>
								Setujui
							</Button>
						</div>
					)
				}
				else{
					tombolAksi = ''
				}
				return tombolAksi;
				// break;
			default:
				return ''
				// break;
		}
	}

	// Fitur Persetujuan Disposisi Selanjutnya
	const [dataDetailDisposisi, setDataDetailDisposisi] = useState([]);
	const [getDisposisi, setGetDisposisi] = useState([]);

		// set data pengajuan surat by jabatan struktural user
		const [dataPengajuanSuratDisposisi, setDataPengajuanSuratDisposisi] = useState(detailSurat.data_pengajuan_surat_disposisi);

		// set data select disposisi lanjutan
		const [listDisposisiLanjutan, setListDisposisiLanjutan] = useState([]);
		const [selectedDisposisi, setSelectedDisposisi] = useState(null);
	
	const getDetailDisposisi = (uuid) => {
		setLoading(true)
		let newArray = dataPengajuanSuratDisposisi;	
		if(roleUser === 'Super Operator'){
			newArray = newArray.filter(day => day.status === 'Proses');
			setDataPengajuanSuratDisposisi(newArray[0])
		}
		else{
			// Filter By Jabatan Struktural User
			newArray = newArray.filter(day => day.uuid_jabatan_struktural === jabatanStrukturalUser);
			setDataPengajuanSuratDisposisi(newArray[0])
			// Filter By Status Proses
			newArray = newArray.filter(day => day.status === 'Proses');
			setDataPengajuanSuratDisposisi(newArray[0])
		}

		GlobalServices.getAll(`administrasi/pengajuan-surat/list-disposisi/${uuid}`, token)
			.then((response) => {
				let frmSelect = [];
				if(response.results){
					setLoading(false)
					response.results.forEach(dt => {
						frmSelect.push({ value: dt.uuid_jabatan_struktural, label: dt.data_jabatan_struktural.nama });
					});
				}
				setListDisposisiLanjutan(frmSelect)
			})
			.catch((e) => {
				setLoading(false)
				console.log(e);
			});
	}

	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "disposisi") {
				let newArray = listDisposisiLanjutan;	
				newArray = newArray.filter(day => day !== e);
				setListDisposisiLanjutan(newArray)
			}
			else if (jenis === "ttd") {
				let newArray = listTTD;	
				newArray = newArray.filter(day => day !== e);
				setListTTD(newArray)
			}
			else if (jenis === "nomor_surat_next") {
				let newArray = nomorSuratNext;	
				newArray = newArray.filter(day => day !== e);
				setNomorSuratNext(newArray)
			}
			else if (jenis === "jabatan_pengganti_tujuan") {
				let newArray = listStatusPenggantiTTD;	
				newArray = newArray.filter(day => day !== e);
				setListStatusPenggantiTTD(newArray)
			}
		}
	};

	const handleSelectChange = (event, param) => {
		if (event) {
			switch (param) {
				case 'disposisi':
					setSelectedDisposisi(event);
					break;
				case 'ttd':
					setNomorSuratNext([])
					setSelectedNomorSuratNext(null)
					setLoadingTTD(true)
					setStatusInput(false)
					setStatusOpenButton(false)
					setdataValues({...dataValues, nomor_surat: ''})

					// Set Selected TTD
					if(statusSalamPenutup){
						setSelectedTTD(event)
					}
					else{
						let setUlang = event.value.replace(`"salam_penutup","value":"Wassalam"`, `"salam_penutup","value":""`);

						setSelectedTTD({...event, value: setUlang});
					}
					// End Set Selected TTD
					
					let dataTTD = JSON.parse(event.value)
					let jabatanStrukturalTTD = ''
					if(dataTTD.isi[1].value!==''){
						jabatanStrukturalTTD = dataTTD.isi[3].value
					}
					else{
						jabatanStrukturalTTD = dataTTD.isi[2].value
					}

					let data={
						uuid_surat: detailSurat.uuid_surat_instansi,
						uuid_instansi: uuidInstansi,
						penanda_tangan: jabatanStrukturalTTD,
					}
					
					GlobalServices.create(data, 'penomoran', token)
						.then((response) => {
							if (response.data.code === 200) {
								let frmSelect = [];
								response.data.results.forEach(dt => {
									frmSelect.push({ value: dt, label: dt });
								});
								setNomorSuratNext(frmSelect)
								setLoadingTTD(false)
							}
							else{
								setLoadingTTD(false)
							}
						})
						.catch((e) => {
							setLoadingTTD(false)
							console.log(e);
						});
					break;
				case 'nomor_surat_next':
					setSelectedNomorSuratNext(event);
					setStatusOpenButton(true);
					setStatusInput(false)
					setdataValues({...dataValues, nomor_surat: event.value})

					break;
				case 'jabatan_pengganti_tujuan':
					setSelectedStatusPengganti(event);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'disposisi':
					setSelectedDisposisi(null);
					break;
				case 'ttd':
					setSelectedTTD(null);
					break;
				case 'nomor_surat_next':
					setSelectedNomorSuratNext(null);
					setStatusOpenButton(false);
					setStatusInput(false)
					setdataValues({...dataValues, nomor_surat: ''})

					break;
				case 'jabatan_pengganti_tujuan':
					setSelectedStatusPengganti(null);
					break;
				default:
					// no default
					break;
			}
		}
	};


	// End Fitur Persetujuan Disposisi Selanjutnya

	const tombolStatusDokumen = async (uuid) => {

		let uuidValue = uuid

		let res = ''

		res = await GlobalServices.get(uuidValue, 'administrasi/dokumen-karyawan', token).then((hasil) => {
			// console.log('Apa statnya?')
			// console.log(hasil.data.results.data_dokumen.nama, hasil.data.results.status)
			return hasil.data.results.status
		});
		console.log(res);

		return (res)
	}

	const [dataListDok, setDataListDok] = useState([]);

	let listDokumenSyarat = (
		listDokumen.map((i, key) =>{
			return (
				<>
				<div className='col-12'>
					<div className='row'>
						<div className='col-10' align='left'>
							<Button
								color= {i.status === 'Aktif'? 'success' : 'danger' }
								size='sm'
								isOutline
								style={{width:'150px'}}
							>
								{i.status}
							</Button>
							<font size='3'> - </font>
							<font size='3'>{i.label}</font>
						</div>
						<div className='col-2' align='right'>
							{
								i.status === 'Diajukan' && statusDisposisi === 'Persetujuan'
								?
								<>
									<Button
										icon='Close'
										color='danger'
										size='sm'
										title='Tolak Verifikasi Dokumen'
										onClick={() => {
											setStateVerifDokumen(true);
											setIsOpen(false);
											setStatusSet('Tolak');
											getDetailPerDokumen(i);
										}}
									/>
									{'  '}
									<Button
										icon='Check'
										color='success'
										size='sm'
										title='Setujui Verifikasi Dokumen'
										onClick={() => {
											setStateVerifDokumen(true);
											setIsOpen(false);
											setStatusSet('Setuju');
											getDetailPerDokumen(i);
										}}
									/>{'  '}
								</>
								:
								<>
								</>
							}
							<a href={i.link} style={{ marginTop: '5px' }} target="_blank" rel="noreferrer">
								<Button
									icon='Eye'
									color='info'
									size='sm'
									title='Lihat Dokumen'
								/>
							</a> 
						</div>
					</div>
				</div>
				<div className='col-12'>&nbsp;</div>
				</>
			)
		})
	)
	const dokumenSyarat = (
		<table style={{width: '100%'}}>
			<tr><td>&nbsp;</td></tr>
			{listDokumenSyarat}
		</table>
	)

	const statusDok = (item) => {
		switch (item) {
			case 'Aktif':
				return (
					<small><font color='green'><Button color='success' isOutline size='sm'>{item}</Button></font></small> 
				)
				// break;
			
			case 'Diajukan':
				return (
					<small><font color='yellow'><Button color='warning' isOutline size='sm'>{item}</Button></font></small> 
				)
				// break;
			case 'Tidak Aktif':
				return (
					<small><font color='red'><Button color='danger' isOutline size='sm'>{item}</Button></font></small>
				)
				// break;
			default:
				return ''
				// break;
		}
	}
	
	const statusColor = (stat) => {
		switch (stat) {
			case 'Proses':
				return ('tl-dot b-primary')
				// break;
			case 'Selesai':
				return ('tl-dot b-success')
				// break;
			case 'Tolak':
				return ('tl-dot b-danger')
				// break;
			default:
				return 'tl-dot b-primary'
				// break;
		}
	}

	// Sorting Status Disposisi
	detailSurat.data_pengajuan_surat_disposisi.sort(function (x, y) {
		let a = new Date(x.created_at);
		let	b = new Date(y.created_at);
		return a - b;
	});

	const formatNomorSurat = (data, jenis) => {
		switch (jenis) {
			case 'awal':
				let nomorNextAwal = data;
				let splitNomorAwal = nomorNextAwal.split('/')
				let splitNomorEditAwal = splitNomorAwal[0].split('.');
				let setNomorEditAwal = splitNomorEditAwal[0];
				return `${setNomorEditAwal}.`;
				// break;
			case 'akhir':
				let nomorNextAkhir = data;
				let setNomorEditAkhir = nomorNextAkhir.split('F.')

				// Jika surat tugas
				if (detailSurat && detailSurat.data_surat_instansi && detailSurat.data_surat_instansi.nama_alias && detailSurat.data_surat_instansi.nama_alias.toLowerCase() === 'surat tugas') {
					setNomorEditAkhir = nomorNextAkhir.split('HM.')
					return `/HM.${setNomorEditAkhir[1]}`;
				}
				// End jika surat tugas

				return `/F.${setNomorEditAkhir[1]}`;
				// break;
			default:
				return 'tl-dot b-primary'
				// break;
		}
	}

	const cetakLaporan = () => {
		if(detailSurat && detailSurat.uuid){
			setLoadingPreview(true)
			setStatePreview(true)
			setIsOpen(false)
			// setlinkPreviewCetak(`${window.location.origin}/api/v1/web/administrasi/pengajuan-surat-mahasiswa/cetak/${detailSurat.uuid}?show=Ya&preview=true`)
			setlinkPreviewCetak(`${process.env.REACT_APP_API_URL}api/v1/web/administrasi/pengajuan-surat/karyawan/cetak/${detailSurat.uuid}?show=Ya&preview=true`)

			GlobalServices.cetakLaporan(`administrasi/pengajuan-surat/karyawan/cetak/${detailSurat.uuid}?show=Ya&preview=true`, token)
				.then((response) => {
					setLoadingPreview(false);
				})
				.catch((e) => {
					setLoadingPreview(false);
					console.log(e);
				});
		}
		else{
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Cetak Laporan</span>
				</span>,
				'',
			);
		}
	};

	if (id || id === 0) {
		return (
		<>
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size="xl" titleId={id}>
				<ModalHeader setIsOpen={setIsOpen} className="p-4">
					<ModalTitle id={id}>{title} Surat Keluar<hr /></ModalTitle>
				</ModalHeader>
				<ModalBody className="px-4">
					<div className="row g-4">
						<div className='row'>
							<div className='col-12'>
								<div className='row'>
									<div className='col-4'>
										<div className='col-12' align='center'>
											<h5>Status Disposisi</h5>
										</div>
										{/* <div className='col-12' style={{ overflowX: 'auto'}}> */}
										<div className="col-lg-12" style={{ height: '300px',overflowY: 'scroll'}}>
											<div className="timeline p-4 block mb-4">
												{detailSurat.data_pengajuan_surat_disposisi.map((i, key) => (
													<div className="tl-item">
														<div className={statusColor(i.status)} />
														<div className="tl-content">
															<div className="">
																<b>{i.data_jabatan_struktural.nama} ({i.status})</b>
															</div>
															<div className=""><i>{i.memo}</i></div>
															<div className="tl-date text-muted mt-1">
																{`${new Date(i.created_at).toLocaleDateString('id-ID')} ${new Date(
																	i.created_at
																).toLocaleTimeString('id-ID')}`}
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
									<div className='col-8'>
										<div className='row'>
											<div className='col-12' align='center'>
												<h5>Data Pengajuan</h5>
											</div>
											<br/>
											{statusDisposisi!=='Selesai'?
												<>
													<div className='col-12' align='right'>
													<Button style={{width:'150px'}} color='success' onClick={cetakLaporan}>
														Preview Surat
													</Button>
													</div>
													<br/>
												</>
											:
												<></>
											}
											<FormGroup id='nama' label='Nama Pengaju' className='col-md-6'>
												<Input
													type='text'
													name='nama'
													value={detailSurat.data_karyawan.nama}
													isRequired
													disabled
												/>
											</FormGroup>
											<br/>
											<FormGroup id='nip' label='NIP' className='col-md-6'>
												<Input
													type='nip'
													name='nip'
													value={detailSurat.data_karyawan.nip}
													isRequired
													disabled
												/>
											</FormGroup>
											<div className='col-12'>&nbsp;</div>
											<FormGroup id='tanggal_pengajuan' label='Tanggal Pengajuan' className='col-md-6'>
												<Input
													type='date'
													name='tanggal_pengajuan'
													value={detailSurat.tanggal_pengajuan}
													isRequired
													disabled
												/>
											</FormGroup>
											<br/>
											<FormGroup id='jenis_surat' label='Jenis Surat Pengajuan' className='col-md-6'>
												<Input
													type='text'
													name='jenis_surat'
													value={detailSurat.data_surat_instansi.nama}
													isRequired
													disabled
												/>
											</FormGroup>
											<div className='col-12'>&nbsp;</div>
											{
												detailSurat.nip_dosen_pengampu
												?
												<>
													<FormGroup id='nip_dosen_pengampu' label='NIP Dosen Pengampu' className='col-md-6'>
														<Input
															type='text'
															name='nip_dosen_pengampu'
															value={detailSurat.nip_dosen_pengampu}
															isRequired
															disabled
														/>
													</FormGroup>
												</>
												:
												<>
												</>
											}
											{
												detailSurat.prodi
												?
												<>
													<br/>
													<FormGroup id='prodi' label='Prodi' className='col-md-6'>
														<Input
															type='text'
															name='prodi'
															value={detailSurat.prodi}
															isRequired
															disabled
														/>
													</FormGroup>
												</>
												:
												<>
												</>
											}
										</div>
									</div>
									
									{/* Dokumen */}

									{
										syaratSurat && syaratSurat.length === 0?
										<>
										</>
										:
										<>
											<div className='row'>
												<div className='col-12' align='left' style={{ overflowX: 'auto'}}>
													<hr />
												</div>
												<div className='col-12' align='left' style={{ overflowX: 'auto'}}>
													<h5>Dokumen Syarat Pengajuan</h5>
												</div>
												<div className='col-12'>&nbsp;</div>
												<div className='col-12' style={{ overflowX: 'auto'}}>
													{listDokumenSyarat}
												</div>
												<div className='col-12'>&nbsp;</div>

											</div>
										</>
									}
									

									{/* <div className='col-12'>
											<div className='col-12'><hr/></div>
											<div className='col-12'>&nbsp;</div>
											<div className='col-12' align='center'>
												<h5>Dokumen Syarat Pengajuan</h5>
											</div>
												{dokumenSyarat}
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className="px-4 pb-4">
					<Button
						color="primary"
						style={{width:'100px'}}
						// isOutline
						// className='border-0'
						onClick={() => {
							setIsOpen(false);
							setstatusTujuanSurat(false)
							// setSurat(initSurat);
							// setSelectedInstansi(null);
						}}
					>
						Tutup
					</Button>
					{/* {roleUser !== 'Super Operator' ? tombolVerif(statusDisposisi) : '' } */}
					{ tombolVerif(statusDisposisi) }
				</ModalFooter>
			</Modal>

			<Modal
				isOpen={state}
				setIsOpen={setState}
				size='md'
				isStaticBackdrop
				titleId='exampleModalLabel'
				>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader className='p-4'>
					<ModalTitle id={id}>{jenisVerif === 'Selesai'? 'Persetujuan Pengajuan Surat':''} 
						{/* {statusDisposisi === 'Persetujuan' ? 'Persetujuan Pengajuan Surat' : 'Verifikasi'} */}
					</ModalTitle>
				</ModalHeader>
				{
					loading
					?
					<>
					<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
						<br/><br/><br/><br/>
						<div className='row g-3'>
						{Object.keys(COLORS).map((color) => (
							<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
								<Spinner color={COLORS[color]} isSmall isGrow />
							</div>
						))}
						</div>
						<br/><br/><br/><br/>
					</div>
					</>
				:
					<>
						<ModalBody className='px-4'>
							{
								jenisVerif === 'Selesai' 
								?
								<>
									{
										listDisposisiLanjutan.length!==0
										?
										<>
										<div className='row g-4'>
											<FormGroup id='jenis' label='Disposisi Lanjutan' className='col-md-12'>
												<Select
													inputId="disposisi"
													classNamePrefix="select"
													name="disposisi"
													placeholder="Pilih Tujuan Disposisi"
													isClearable
													required
													defaultValue={selectedDisposisi}
													value={selectedDisposisi}
													options={listDisposisiLanjutan}
													onInputChange={e => handleInputSelect(e, "disposisi")}
													onChange={e => {
														handleSelectChange(e, "disposisi");
													}}
												/>
											</FormGroup>
										</div>
										</>
										:
										<>
										<p align='center'>
											<h3>Yakin Ingin Setujui Pengajuan Surat?</h3> <br />
											Proses ini tidak dapat dibatalkan!
										</p>
										</>
									}
								</>
								:
								<>
									<p align='center'>
										<h3>Yakin Ingin Tolak Pengajuan Surat?</h3> <br />
										Proses ini tidak dapat dibatalkan!
										<br/><br/>
										<FormGroup id='catatan' label='Catatan' className='col-md-12'>
											<Input
												type='text'
												name='catatan'
												value={dataValues.catatan}
												onChange={handleInputChange}
												required
											/>
										</FormGroup>
									</p>
									<br/>
									<div align='center'>
										<Button
											color='danger'
											// isOutline
											// className='border-0'
											onClick={() => {
												tutupVerifikasi();
												setdataValues(formData);
												setIsOpen(true);
											}}
											style={{width:'100px'}}
										>
											Batal
										</Button>
										&nbsp;&nbsp;
										<Button color='success' type='submit' style={{width:'100px'}} onClick={handleSubmit}>
											Ya
										</Button>
									</div>
								</>
							}
						</ModalBody>
						<ModalFooter className='px-4 pb-4'>
							{
								jenisVerif === 'Selesai'
								?
								<>
									<Button
										color='danger'
										// isOutline
										// className='border-0'
										onClick={() => {
											tutupVerifikasi()
											setdataValues(formData);
											setIsOpen(true);
										}}
										style={{width:'100px'}} 
									>
										Batal
									</Button>
									{/* <Button color='info' onClick={formik.handleSubmit}> */}
									<Button color='success' type='submit' style={{width:'100px'}} onClick={handleSubmit}>
										Simpan
									</Button>
								</>
								:
								<>
								</>
							}
						</ModalFooter>
					</>
				}
				</form>
			</Modal>

			<Modal
				isOpen={stateVerif}
				setIsOpen={setStateVerif}
				size='md'
				isStaticBackdrop
				titleId='exampleModalLabel'
				>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader className='p-4'>
					<ModalTitle id={id}>{statusDisposisi === 'Persetujuan' ? 'Persetujuan Pengajuan Surat' : 'Verifikasi'}</ModalTitle>
				</ModalHeader>
				{
					loading
					?
					<>
					<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
						<br/><br/><br/><br/>
						<div className='row g-3'>
						{Object.keys(COLORS).map((color) => (
							<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
								<Spinner color={COLORS[color]} isSmall isGrow />
							</div>
						))}
						</div>
						<br/><br/><br/><br/>
					</div>
					</>
				:
					<>
						<ModalBody className='px-4'>
							{
								statusDisposisi === 'Persetujuan' 
								?
								<div className='row g-4'>
									<FormGroup id='jenis' label='Disposisi Lanjutan' className='col-md-12'>
										<Select
											inputId="disposisi"
											classNamePrefix="select"
											name="disposisi"
											placeholder="Pilih Tujuan Disposisi"
											isClearable
											required
											defaultValue={selectedDisposisi}
											value={selectedDisposisi}
											options={listDisposisiLanjutan}
											onInputChange={e => handleInputSelect(e, "disposisi")}
											onChange={e => {
												handleSelectChange(e, "disposisi");
											}}
										/>
									</FormGroup>
								</div>
								:
								<div className='row g-4'>
									<FormGroup id='nip_ttd' label='Tanda Tangan' className='col-md-12'>
										<Select
											inputId="nip_ttd"
											classNamePrefix="select"
											name="nip_ttd"
											placeholder="Pilih Penandatangan"
											isClearable
											required
											defaultValue={selectedTTD}
											value={selectedTTD}
											options={listTTD}
											onInputChange={e => handleInputSelect(e, "ttd")}
											onChange={e => {
												handleSelectChange(e, "ttd");
											}}
										/>
									</FormGroup>
									{
										loadingTTD
										?
										<>
										<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
											<div className='row g-3'>
											{Object.keys(COLORS).map((color) => (
												<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
													<Spinner color={COLORS[color]} isSmall isGrow />
												</div>
											))}
											</div>
										</div>
										</>
									:
										<>
										{
											selectedTTD
											?
											<>
												<FormGroup id='nomor_surat' label='Nomor Surat *' className='col-md-12'>
													<Select
														inputId="nomor_surat_next"
														classNamePrefix="select"
														name="nomor_surat_next"
														placeholder="Pilih Nomor Surat Yg Disarankan"
														isClearable
														required
														defaultValue={selectedNomorSuratNext}
														value={selectedNomorSuratNext}
														options={nomorSuratNext}
														onInputChange={e => handleInputSelect(e, "nomor_surat_next")}
														onChange={e => {
															handleSelectChange(e, "nomor_surat_next");
														}}
													/>
												</FormGroup>
												{
													statusOpenButton?
													<>
													<div className='col-12'>
														<Button
															color={statusInput?'danger':'primary'}
															// isOutline
															className='btn btn-sm'
															style={{width:'250px'}}
															onClick={() => {
																setStatusInput(!statusInput);
																
																let nomorNext = selectedNomorSuratNext.value;

																let splitNomor = nomorNext.split('/')

																let splitNomorEdit = splitNomor[0].split('.');

																let setNomorEdit = splitNomorEdit[1];

																let nomor_surat = setNomorEdit
																
																// Jika surat tugas
																if (!setNomorEdit) {
																	nomor_surat = splitNomor[0]
																}
																// End jika surat tugas
																console.log(nomor_surat);

																setdataValues({...dataValues, nomor_surat})
															}}>
															Input Nomor Surat {statusInput ? "Otomatis" : "Manual"}
														</Button>
													</div>
													</>
													:
													<>
													</>
												}
												
												{statusInput?
													<>
													<FormGroup id='tujuan' label='Nomor Surat Manual*' className='col-md-12'>
														<div className='col-12'>
															<div className='row'>
																<div className='col-3'>
																	<Input
																		type='text'
																		name='nomor_surat2'
																		value={formatNomorSurat(selectedNomorSuratNext.value, 'awal')}
																		readOnly
																	/>
																</div>
																<div className='col-3'>
																	<Input
																		type='number'
																		name='nomor_surat'
																		value={dataValues.nomor_surat}
																		onChange={handleInputChange}
																		required
																	/>
																</div>
																<div className='col-6'>
																	<Input
																		type='text'
																		name='nomor_surat2'
																		value={formatNomorSurat(selectedNomorSuratNext.value, 'akhir')}
																		readOnly
																	/>
																</div>
															</div>
														</div>
													</FormGroup>
													</>
													:
													<>
													</>
												}
											</>
											:
											<>
											</>
										}
										</>
									}

									{
										statusTujuanSurat
										?
										<>
										<div className='col-12' align='center'>
											<b>Tujuan Surat</b>
											<hr/>
										</div>
										<FormGroup id='tujuan' label='Kepada Yth. *' className='col-md-12'>
											<div className='col-12'>
												<div className='row'>
													<div className='col-4'>
														<Select
															inputId="jabatan_pengganti_tujuan"
															classNamePrefix="select"
															name="jabatan_pengganti_tujuan"
															placeholder="Jabatan"
															isClearable
															required
															defaultValue={selectedStatusPengganti}
															value={selectedStatusPengganti}
															options={listStatusPenggantiTTD}
															onInputChange={e => handleInputSelect(e, "jabatan_pengganti_tujuan")}
															onChange={e => {
																handleSelectChange(e, "jabatan_pengganti_tujuan");
															}}
														/>
													</div>
													<div className='col-8'>
														<Input
															type='text'
															name='tujuan'
															value={dataValues.tujuan}
															onChange={handleInputChange}
															required
														/>
													</div>
												</div>
											</div>
										</FormGroup>
										{/* <FormGroup id='tujuan' label='Kepada Yth. *' className='col-md-12'>
											<Input
												type='text'
												name='tujuan'
												value={dataValues.tujuan}
												onChange={handleInputChange}
												required
											/>
										</FormGroup> */}
										<FormGroup id='tempat' label='Tempat *' className='col-md-12'>
											<Input
												type='text'
												name='tempat'
												value={dataValues.tempat}
												onChange={handleInputChange}
												required
											/>
										</FormGroup>
										<div className='col-12'>
											<Button
												color={statusCQ?'danger':'primary'}
												// isOutline
												// className='border-0'
												style={{width:'130px'}}
												onClick={() => {
													setstatusCQ(!statusCQ);
													setdataValues({...dataValues, cq:''});
												}}>
												{statusCQ?'Hapus':'Tambah'} c.q
											</Button>
										</div>
										{
											statusCQ
											?
											<>
												<FormGroup id='cq' label='c.q' className='col-md-12'>
													<Input
														type='text'
														name='cq'
														value={dataValues.cq}
														onChange={handleInputChange}
													/>
												</FormGroup>
											</>
											:
											<></>
										}
										</>
									:
										<></>
									}
									{detailSurat && detailSurat.data_surat_instansi && detailSurat.data_surat_instansi.nama_alias && detailSurat.data_surat_instansi.nama_alias.toLowerCase() === 'surat tugas' ? (
										<FormGroup id='dasar' label='Dasar *' className='col-md-12'>
											<Input
												type='text'
												name='dasar'
												value={dataValues.dasar}
												onChange={handleInputChange}
												required
											/>
										</FormGroup>
									) : ''}
									<div className='col-12' align='center'>
										<b>Catatan</b>
										<hr/>
									</div>
									<FormGroup id='catatan' label='' className='col-md-12'>
										<Input
											type='text'
											name='catatan'
											value={dataValues.catatan}
											onChange={handleInputChange}
											style={{height:'50px'}}
										/>
									</FormGroup>
								</div>
							}
						</ModalBody>
						<ModalFooter className='px-4 pb-4'>
							<Button
								color='danger'
								style={{width:'100px'}}
								// isOutline
								// className='border-0'
								onClick={() => {
									tutupVerifikasi()
									setIsOpen(true);
									setdataValues(formData);
								}}
							>
								Batal
							</Button>
							{/* <Button color='info' onClick={formik.handleSubmit}> */}
							<Button color='success' style={{width:'100px'}} type='submit' onClick={handleSubmitVerifSurat}>
								Simpan
							</Button>
						</ModalFooter>
					</>
				}
				</form>
			</Modal>


			<Modal
				isOpen={stateVerifDokumen}
				setIsOpen={setStateVerifDokumen}
				size='md'
				isStaticBackdrop
				titleId='exampleModalLabel'
			>
				<ModalHeader>&nbsp;</ModalHeader>
				<ModalBody>
					<p align='center'>
						<h2>Yakin Ingin {statusSet} Dokumen?</h2> <br />
						Proses ini tidak dapat dibatalkan!
					</p>
					<br />
					<div align='center'>
						<Button
							color='danger'
							// isOutline
							// className='border-0'
							onClick={() => {
								tutupVerifikasi()
								setDataEdit(formDataVerif);
							}}
							// onClick={() => setState(false)}
						>
							Batal
						</Button>
						&nbsp;&nbsp;
						<Button 
							color='success' 
							onClick={() => {
								handleSubmitVerifDokumen(statusSet)
							}}
						>
							Yakin
						</Button>
					</div>
				</ModalBody>
				<ModalFooter>
					&nbsp;
				</ModalFooter>
			</Modal>

			<Modal
				isOpen={statePreview}
				setIsOpen={setStatePreview}
				size='xl'
				isStaticBackdrop
				titleId='exampleModalLabel'
				>
				<ModalHeader className='p-4'>
					<ModalTitle id={id}>Preview Surat</ModalTitle>
				</ModalHeader>
				{
					loadingPreview
					?
					<>
					<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
						<br/><br/><br/><br/>
						
						<div className='row g-3'>
						{Object.keys(COLORS).map((color) => (
							<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
								<Spinner color={COLORS[color]} isSmall isGrow />
							</div>
						))}
						</div>
						<br/><br/><br/><br/>
					</div>
					</>
				:
					<>
						<ModalBody className='px-4'>
							<Iframe 
								id="myFrame" 
								src={linkPreviewCetak}
								width="100%"
								height="450px"
								className="myClassname"
								display="initial"
								position="relative"
								controls="0"
							/>
						</ModalBody>
						<ModalFooter className='px-4 pb-4'>
							<Button
								color='danger'
								style={{width:'100px'}}
								onClick={() => {
									setStatePreview(false);
									setlinkPreviewCetak('');
									setIsOpen(true);
								}}
							>
								Tutup
							</Button>
						</ModalFooter>
					</>
				}
			</Modal>
		</>
		);
	}
	return null;
};
ModalDetail.propTypes = {
	id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	syaratSurat: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
	statusDisposisi: PropTypes.func.isRequired,
	// listTujuanSurat: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ModalDetail;
