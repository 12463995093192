import React, { useEffect, useState } from 'react';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Breadcrumb from '../bootstrap/Breadcrumb';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Card, {
	CardActions,
	CardBody,
	CardCodeView,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle
} from '../bootstrap/Card';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Button from '../bootstrap/Button';
import GlobalServices from './Services/GlobalServices';
import Option from '../bootstrap/Option';
import Icon from '../icon/Icon';
import Alert from '../bootstrap/Alert';
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';

const TrackingSuratMahasiswa = (props) => {
	// useMinimizeAside();

	const [loading, setLoading] = useState(false);
	const [ reset, setReset ] = useState(true);
	const [ detailSurat, setDetailSurat ] = useState(null)
	const [ dataForm, setDataForm ] = useState({});
	const [ dataFormExtra, setDataFormExtra ] = useState({});

	const [ dataFormDokumen, setDataFormDokumen ] = useState({});
	const [ uploading, setUploading ] = useState(false);

	const initAlertData = {
		color: 'success',
		icon: 'Info',
		text: 'Masukkan kode surat anda pada kolom input dibawah',
		isLight: true
	};
	const [ alertData, setAlertData ] = useState(initAlertData);

	const handleSubmit = () => {
		setLoading(true)
		// let bodyData = {
		// 	kode_pengajuan: document.getElementById('kode_pengajuan').value
		// };
		let kode_pengajuan = document.getElementById('kode_pengajuan').value
		GlobalServices.getAll(
			`administrasi/pengajuan-surat-mahasiswa/kode/${kode_pengajuan}`
		)
			.then((response) => {
				console.log(response);

				// Jika berhasil
				if (response.code === 200) {
					setLoading(false)
					setDetailSurat(response.results)
					setAlertData({
						color: 'success',
						icon: 'VerifiedUser',
						text: 'Tracking berhasil',
						isLight: false
					});
					// Untuk reset data dri useEffect
					setReset(!reset);
					// document.getElementById('formSuratMahasiswa').reset();
				} else {
					setLoading(false)
					setAlertData({
						color: 'danger',
						icon: 'Report',
						text: 'Tracking gagal',
						isLight: false
					});
				}
			})
			.catch((e) => {
				setLoading(false)
				console.log(e);
				setAlertData({
					color: 'danger',
					icon: 'Report',
					// text: e.data.message,
					text: 'Surat tidak ditemukan',
					isLight: false
				});
			});
	};

	useEffect(
		() => {
			// getDataList();
			console.log(props);
			let query = new URLSearchParams(props.location.search)
			let kode_pengajuan = query.get('kode_pengajuan')
			
			if (kode_pengajuan) {
				document.getElementById('kode_pengajuan').value = kode_pengajuan
				handleSubmit()
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const statusColor = (stat) => {
		switch (stat) {
			case 'Proses':
				return ('tl-dot b-primary')
				// break;
			case 'Selesai':
				return ('tl-dot b-success')
				// break;
			case 'Tolak':
				return ('tl-dot b-danger')
				// break;
			default:
				return 'tl-dot b-primary'
				// break;
		}
	}


	return (
		<PageWrapper title="Tracking Surat Mahasiswa">
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						isToHome={false}
						list={[
							{ title: 'Dashboard', to: '/' },
							{ title: `Tracking Surat Mahasiswa`, to: '#' }
						]}
					/>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div className="row" style={{ justifyContent: 'center' }}>
					{/* tpye */}
					<div className="col-lg-6">
						<Card>
							<form id="formSuratMahasiswa" onSubmit={(e) => e.preventDefault()}>
								<CardHeader style={{ paddingBottom: 0, display: 'flex', flexDirection: 'column' }}>
									<div className="row" style={{ width: '100%' }}>
									<div className="col-lg-12" style={{ marginBottom: '17px' }}>
										<CardLabel>
											<CardTitle style={{ textAlign: 'center' }}>Tracking Surat Mahasiswa</CardTitle>
										</CardLabel>
									</div>
									<div className="col-lg-12">
										<Alert
											color={alertData.color}
											icon={alertData.icon}
											isLight
											style={{ width: '100%', margin: 0 }}
										>
											{alertData.text}
										</Alert>
									</div>
									</div>
								</CardHeader>

								<CardBody>
									<FormGroup
										label="Kode Pengajuan"
										isColForLabel
										labelClassName="col-sm-3 text-capitalize"
										childWrapperClassName="col-sm-9"
									>
										<Input
											id="kode_pengajuan"
											type="text"
											name="kode_pengajuan"
											// onChange={(e) => handleInputChange(e, true)}
											placeholder="Masukkan kode pengajuan"
											aria-label=".form-control-lg example"
											required
										/>
									</FormGroup>
								</CardBody>
								<CardFooter style={{ paddingTop: 0 }}>
									{/* <div className="row" style={{ width: '100%' }}> */}
									<div className="col-sm-12" style={{ display: 'flex', justifyContent: 'end' }}>
										<Button color="info" type="submit" onClick={() => {
											setDetailSurat(null)
											handleSubmit()
										}} disabled={uploading}>
											Cari Surat
										</Button>
									</div>
									{/* </div> */}
								</CardFooter>
							</form>
						</Card>
					</div>
				</div>
				{/* Hasil Tracking */}
				{loading ? (
					<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
						<br/><br/><br/><br/>
						<div className='row g-3'>
						{Object.keys(COLORS).map((color) => (
							<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
								<Spinner color={COLORS[color]} isSmall isGrow />
							</div>
						))}
						</div>
						<br/><br/><br/><br/>
					</div>
				) : (
					<>
						{detailSurat ? (
						<div className="row" style={{ justifyContent: 'center' }}>
							<div className="col-lg-6">
								<Card stretch>
									<CardHeader style={{ paddingBottom: 0, display: 'flex', flexDirection: 'column' }}>
									<div className="row" style={{ width: '100%' }}>
										<div className="col-lg-12" style={{ marginBottom: '17px' }}>
											<CardLabel style={{ justifyContent: 'center' }}>
												<CardTitle style={{ fontSize: '1.5 rem', textAlign: 'center' }}>Status Pengajuan Surat</CardTitle>
											</CardLabel>
										</div>
									</div>
									</CardHeader>

									<CardBody style={{ paddingTop: 0, paddingBottom: 0 }}>
									<div className="col-lg-12">
											<div className="timeline p-4 block mb-4">
												{detailSurat.data_pengajuan_surat_status.map((i, key) => (
													<div className="tl-item">
														<div className={statusColor(i.status)} />
														<div className="tl-content">
															<div className="">
																<b>{i.status}</b>
															</div>
															<div className=""><i>{i.memo}</i></div>
															<div className="tl-date text-muted mt-1">
																{`${new Date(i.created_at).toLocaleDateString('id-ID')} ${new Date(
																	i.created_at
																).toLocaleTimeString('id-ID')}`}
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
										<div className='col-12' align='center' style={{paddingLeft:'12px'}}>
											<h5>Detail Surat</h5>
										</div>
										<br/>
										<div className="row">
											<div className="col-sm-4">
												<p style={{ fontSize: '15px' }}>
													<b>Kode Pengajuan</b>
												</p>
											</div>
											<div className="col-sm-8">
												<p style={{ fontSize: '15px' }}>
													{detailSurat.kode_pengajuan}
												</p>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-4">
												<p style={{ fontSize: '15px' }}>
													<b>Jenis Surat</b>
												</p>
											</div>
											<div className="col-sm-8">
												<p style={{ fontSize: '15px' }}>
												{detailSurat.data_surat_instansi.nama}
												</p>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-4">
												<p style={{ fontSize: '15px' }}>
													<b>Nama</b>
												</p>
											</div>
											<div className="col-sm-8">
												<p style={{ fontSize: '15px' }}>
												{detailSurat.nama}
												</p>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-4">
												<p style={{ fontSize: '15px' }}>
													<b>NIM</b>
												</p>
											</div>
											<div className="col-sm-8">
												<p style={{ fontSize: '15px' }}>
												{detailSurat.nim}
												</p>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-4">
												<p style={{ fontSize: '15px' }}>
													<b>Tanggal Pengajuan</b>
												</p>
											</div>
											<div className="col-sm-8">
												<p style={{ fontSize: '15px' }}>
												{detailSurat.tanggal_pengajuan}
												</p>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-4">
												<p style={{ fontSize: '15px' }}>
													<b>Status</b>
												</p>
											</div>
											<div className="col-sm-8">
												<p style={{ fontSize: '15px' }}>
												{detailSurat.status}
												</p>
											</div>
										</div>
									</CardBody>
									<CardFooter style={{ paddingTop: 0 }}>
										{/* <div className="row" style={{ width: '100%' }}> */}
										<div className="col-sm-12" style={{ display: 'flex', justifyContent: 'end' }}>
											<Button color="primary" isLight type="submit" onClick={() => props.history.goBack()}>
												Kembali
											</Button>
										</div>
										{/* </div> */}
									</CardFooter>
								</Card>
							</div>
						</div>
						) : ''}
					</>
				)}
				{/* Hasil Tracking */}
				{/* {detailSurat ? (
				<div className="row" style={{ justifyContent: 'center' }}>
					<div className="col-lg-6">
						<div className='row'>
							<div className='col-12'>
								<Card stretch>
									<CardHeader style={{ paddingBottom: 0, display: 'flex', flexDirection: 'column' }}>
									<div className="row" style={{ width: '100%' }}>
										<div className="col-lg-12" style={{ marginBottom: '17px' }}>
											<CardLabel style={{ justifyContent: 'center' }}>
												<CardTitle style={{ fontSize: '1.5 rem', textAlign: 'center' }}>Detail Surat</CardTitle>
											</CardLabel>
										</div>
									</div>
									</CardHeader>

									<CardBody style={{ paddingTop: 0, paddingBottom: 0 }}>
										<div className='row'>
											<div className='col-8'>
												<div className="row">
													<div className="col-sm-6">
														<p style={{ fontSize: '15px' }}>
															<b>Kode Pengajuan</b>
														</p>
													</div>
													<div className="col-sm-6">
														<p style={{ fontSize: '15px' }}>
															{detailSurat.kode_pengajuan}
														</p>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-6">
														<p style={{ fontSize: '15px' }}>
															<b>Jenis Surat</b>
														</p>
													</div>
													<div className="col-sm-6">
														<p style={{ fontSize: '15px' }}>
														{detailSurat.data_surat_instansi.nama}
														</p>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-6">
														<p style={{ fontSize: '15px' }}>
															<b>Nama</b>
														</p>
													</div>
													<div className="col-sm-6">
														<p style={{ fontSize: '15px' }}>
														{detailSurat.nama}
														</p>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-6">
														<p style={{ fontSize: '15px' }}>
															<b>NIM</b>
														</p>
													</div>
													<div className="col-sm-6">
														<p style={{ fontSize: '15px' }}>
														{detailSurat.nim}
														</p>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-6">
														<p style={{ fontSize: '15px' }}>
															<b>Tanggal Pengajuan</b>
														</p>
													</div>
													<div className="col-sm-6">
														<p style={{ fontSize: '15px' }}>
														{detailSurat.tanggal_pengajuan}
														</p>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-6">
														<p style={{ fontSize: '15px' }}>
															<b>Status</b>
														</p>
													</div>
													<div className="col-sm-6">
														<p style={{ fontSize: '15px' }}>
														{detailSurat.status}
														</p>
													</div>
												</div>
											</div>
											<div className="col-lg-4" style={{ height: '220px',overflowY: 'scroll'}}>
												<div className="timeline p-4 block mb-4" style={{paddingTop:'120px', borderLeft: '2px solid black', height: '250px'}}>
													{detailSurat.data_pengajuan_surat_disposisi.map((i, key) => (
														<div className="tl-item">
															<div className={statusColor(i.status)} />
															<div className="tl-content">
																<div className="">
																	<b>{i.data_jabatan_struktural? i.data_jabatan_struktural.nama: ''} ({i.status})</b>
																</div>
																<div className=""><i>{i.memo}</i></div>
																<div className="tl-date text-muted mt-1">
																	{`${new Date(i.created_at).toLocaleDateString('id-ID')} ${new Date(
																		i.created_at
																	).toLocaleTimeString('id-ID')}`}
																</div>
															</div>
														</div>
													))}
												</div>
											</div>
										</div>
									</CardBody>
									<CardFooter style={{ paddingTop: 0 }}>
									<br/><br/><br/>
										<div className="col-sm-12" style={{ display: 'flex', justifyContent: 'end' }}>
											<Button color="primary" isLight type="submit" onClick={() => props.history.goBack()}>
												Kembali
											</Button>
										</div>
									</CardFooter>
								</Card>
							</div>
						</div>
					</div>		
				</div>
				) : ''} */}
			</Page>
		</PageWrapper>
	);
};
// Login.propTypes = {
// 	isSignUp: PropTypes.bool,
// };
// Login.defaultProps = {
// 	isSignUp: false,
// };

export default TrackingSuratMahasiswa;
