import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import Select from '../../bootstrap/forms/Select';
import Option from '../../bootstrap/Option';
import GlobalServices from '../Services/GlobalServices';

const ModalEdit = ({ id, isOpen, setIsOpen, statusRefresh, listInstansi, setDetail }) => {
	const token = sessionStorage.getItem('access_token');
	const formData = {
		title: 'Edit Data Jam',
		id: null,
		jam_absen: '',
		keterlambatan: '',
		active: '',
		urutan: '',
	};

	const [dataEdit, setDataEdit] = useState(formData);

	useEffect(() => {
		if (setDetail) {
			setDataEdit({
				title: 'Edit Data Jam',
				active: setDetail.active,
				jam_absen: setDetail.jam_absen,
				jenis: setDetail.jenis,
				keterlambatan: setDetail.keterlambatan,
				tipe: setDetail.tipe,
				urutan: setDetail.urutan,
				uuid_instansi: setDetail.uuid_instansi,
				uuid: setDetail.uuid,
			});

			const getSelectInstansi = setDetail.uuid_instansi;
			const getSelectJenis = setDetail.jenis;
			const getSelectTipe = setDetail.tipe;
			const getSelectActive = setDetail.active;
			setSelectedInstansi(getSelectInstansi);
			setSelectedJenis(getSelectJenis);
			setSelectedTipe(getSelectTipe);
			setSelectedStatusAktif(getSelectActive);
		}
	}, [setDetail]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setDataEdit({ ...dataEdit, [name]: value });
	};

	const [selectedInstansi, setSelectedInstansi] = useState(dataEdit.uuid_instansi);	
	const [selectedJenis, setSelectedJenis] = useState(dataEdit.jenis);
	const [selectedTipe, setSelectedTipe] = useState(dataEdit.tipe);
	const [selectedStatusAktif, setSelectedStatusAktif] = useState(dataEdit.active);

	const handleSelectChange = (event, param) => {
		console.log("APA")
		console.log(event.target.value)
		if (event) {
			switch (param) {
				case 'instansi':
					console.log('instansi')
					setSelectedInstansi(event.target.value);
					break;
				case 'jenis':
					console.log('jenis');
					setSelectedJenis(event.target.value);
					break;
				case 'tipe':
					console.log('tipe');
					setSelectedTipe(event.target.value);
					break;
				case 'status':
					console.log('status');
					setSelectedStatusAktif(event.target.value);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'instansi':
					console.log('non insntasi');
					setSelectedInstansi(null);
					break;
				case 'jenis':
					console.log('non jenis');
					setSelectedJenis(null);
					break;
				case 'tipe':
					console.log('non tipe');
					setSelectedTipe(null);
					break;
				case 'status':
					console.log('non status');
					setSelectedStatusAktif(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const handleSubmit = () => {
		if (
			dataEdit.jam_absen !== '' &&
			dataEdit.keterlambatan !== '' &&
			dataEdit.urutan !== '' &&
			selectedInstansi &&
			selectedJenis &&
			selectedTipe
		) {
			const time = dataEdit.jam_absen; 
			const split = time.split(":");
			const setJadwal = `${split[0]}:${split[1]}`;

			const data = {
				uuid: dataEdit.uuid,
				uuid_instansi: selectedInstansi,
				jenis: selectedJenis,
				tipe: selectedTipe,
				jam_absen: setJadwal,
				keterlambatan: dataEdit.keterlambatan,
				active: selectedStatusAktif,
				urutan: dataEdit.urutan,
			};
			GlobalServices.update(data, 'absensi/jam', token)
				.then((response) => {
					if (response.data.code === 201) {
						setIsOpen(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Berhasil Ubah Data</span>
							</span>,
							'Berhasil Ubah Data Jadwal',
						);
						setDataEdit(formData);
						statusRefresh();
						tutupForm();
					} else {
						// setIsOpen(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Ubah Data</span>
							</span>,
							'Gagal Ubah Data Jadwal',
						);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Tambah Data</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	const tutupForm = () => {
		setSelectedJenis(null);
		setSelectedInstansi(null);
		setSelectedTipe(null);
		setSelectedStatusAktif(null);
	};

	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={id}>Edit Jam</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row g-4'>
						<FormGroup id='uuid_instansi' label='Nama Instansi' className='col-md-6'>
							<Select
								id='largeSelect'
								ariaLabel='Default select example'
								placeholder='Pilih Instansi'
								required
								onChange={(e) => {
									handleSelectChange(e, 'instansi');
								}}
								value={selectedInstansi}
								size='md'>
								{listInstansi.map((item) => (
									<Option value={item.uuid}>{item.nama}</Option>
								))}
							</Select>
						</FormGroup>
						<FormGroup id='jenis' label='Jenis' className='col-md-6'>
							<Select
								id='mediumSelect'
								ariaLabel='Default select example'
								placeholder='Pilih Jenis Jadwal'
								required
								onChange={(e) => {
									handleSelectChange(e, 'jenis');
								}}
								value={selectedJenis}
								size='md'>
								<Option value='Pagi'>Pagi</Option>
								<Option value='Sore'>Sore</Option>
								<Option value='Istirahat'>Istirahat</Option>
							</Select>
						</FormGroup>
						<FormGroup id='tipe' label='Tipe Absen' className='col-md-6'>
							<Select
								id='mediumSelect'
								ariaLabel='Default select example'
								placeholder='Pilih Tipe Absen'
								required
								onChange={(e) => {
									handleSelectChange(e, 'tipe');
								}}
								value={selectedTipe}
								size='md'>
								<Option value='Masuk'>Masuk</Option>
								<Option value='Keluar'>Keluar</Option>
							</Select>
						</FormGroup>
						<FormGroup id='urutan' label='Urutan' className='col-md-6'>
							<Input
								type='number'
								maxLength='1'
								name='urutan'
								value={dataEdit.urutan}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='jam_absen' label='Jam Absen' className='col-md-6'>
							<Input
								placeholder='Jam Absen'
								onChange={handleInputChange}
								value={dataEdit.jam_absen}
								type='time'
								required
							/>
						</FormGroup>
						<FormGroup id='keterlambatan' label='Keterlambatan' className='col-md-6'>
							<Input
								type='number'
								name='keterlambatan'
								value={dataEdit.keterlambatan}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='jenis' label='Status Aktif' className='col-md-6'>
							<Select
								id='mediumSelect'
								ariaLabel='Default select example'
								placeholder='Pilih Status Aktif'
								required
								onChange={(e) => {
									handleSelectChange(e, 'status');
								}}
								value={selectedStatusAktif}
								size='md'>
								<Option value='1'>Aktif</Option>
								<Option value='0'>Nonaktif</Option>
							</Select>
						</FormGroup>
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button
						color='success'
						// isOutline
						// className='border-0'
						onClick={() => {
							setIsOpen(false);
							setDataEdit(formData);
							tutupForm();
						}}>
						Batal
					</Button>
					<Button color='info' type='submit' onClick={handleSubmit}>
						Simpan
					</Button>
				</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalEdit.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
	setDetail: PropTypes.func.isRequired,
	listInstansi: PropTypes.func.isRequired,
};

export default ModalEdit;
