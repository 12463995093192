import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import Select from '../../bootstrap/forms/Select';
import Option from '../../bootstrap/Option';
import GlobalServices from '../Services/GlobalServices';

const ModalEdit = ({ id, isOpen, setIsOpen, statusRefresh, listInstansi, detailInstansi, uuidInstansi }) => {
	const token = sessionStorage.getItem('access_token');
	const initialInstansiState = {
		title: 'Edit Data Jabatan Fungsional',
		id: null,
		nama: '',
		alamat: '',
		no_telp: '',
		lat: '',
		long: '',
	};

	const [dataEdit, setDataEdit] = useState(initialInstansiState);

	useEffect(() => {
		if (detailInstansi) {
			setDataEdit({
				title: 'Edit Data Jabatan Fungsional',
				id: detailInstansi.uuid,
				nama: detailInstansi.nama,
			});

			const getSelectInstansi = detailInstansi.uuid_instansi;
			setSelectedInstansi(getSelectInstansi);
		}
	}, [detailInstansi]);

	const [selectedInstansi, setSelectedInstansi] = useState();
	const handleSelectChangeInstansi = (event) => {
		setSelectedInstansi(event.target.value);
	};

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setDataEdit({ ...dataEdit, [name]: value });
	};

	const handleSubmit = () => {
		const data = {
			uuid: dataEdit.id,
			nama: dataEdit.nama,
			uuid_instansi: uuidInstansi,
		};

		if (dataEdit.nama !== '' && uuidInstansi) {
			GlobalServices.update(data, 'jabatan-fungsional', token)
				.then((response) => {
					if (response.data.code === 201) {
						setIsOpen(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Berhasil Ubah Data</span>
							</span>,
							'Berhasil Ubah Data Jabatan Fungsional',
						);
						statusRefresh();
						setDataEdit(initialInstansiState);
					} else {
						// setIsOpen(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Ubah Data</span>
							</span>,
							'Gagal Ubah Data Jabatan Fungsional',
						);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Tambah Data</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	if (id || id === 0) {
		return (
			<Modal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				detailInstansi={detailInstansi}
				isStaticBackdrop
				size='md'
				titleId={id}
				key='modal-edit-jabatanfungsional'>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={id}>
						{dataEdit.title}
					</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row g-4'>
						<FormGroup id='nama' label='Jabatan Fungsional' className='col-md-12'>
							<Input
								type='text'
								name='nama'
								value={dataEdit.nama}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button
						color='danger'
						// isOutline
						// className='border-0'
						onClick={() => {
							setIsOpen(false);
							setDataEdit(initialInstansiState);
							setSelectedInstansi(null);
						}}>
						Batal
					</Button>
					<Button color='success' type='submit' onClick={handleSubmit}>
						Simpan
					</Button>
				</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalEdit.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
	listInstansi: PropTypes.func.isRequired,
	detailInstansi: PropTypes.func.isRequired,
	uuidInstansi: PropTypes.func.isRequired,
};

export default ModalEdit;
