import React, { useEffect, useState } from 'react';
import Select from "react-select";
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
// import data from './DataInstansi';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import GlobalServices from '../Services/GlobalServices';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import COLORS from '../Services/Spinner';
import Spinner from '../../bootstrap/Spinner';

const ModalDetail = ({ id, isOpen, setIsOpen, detailSurat, title, statusDisposisi, statusRefresh }) => {
	const token = sessionStorage.getItem('access_token');
	const jabatanStrukturalUser = localStorage.getItem('uuid_jabatan_struktural');
	const uuidPengguna = localStorage.getItem('uuid_pengguna');

	const initSurat = {
		nomor_surat: '',
		pengirim: '',
		perihal: '',
		status: '',
		tanggal_surat: '',
		dokumen: '',
		dokumen_output: '',
		uuid_instansi: '',
		uuid_pengguna: '',
		data_surat_masuk_diposisi: []
	};
	// console.log(listTujuanSurat);

	// Fitur Modal
	const [loading, setLoading] = useState(false);
	const [state, setState] = useState(false);
	const [stateVerif, setStateVerif] = useState(false);
	const [statusLampiran, setstatusLampiran] = useState(false);
	const [isComplete, setIsComplete] = useState(true);
	const [dokumenSelected, setDokumenSelected] = useState(false);

	// const [selectedInstansi, setSelectedInstansi] = useState(null);
	const [ surat, setSurat ] = useState(initSurat);
	const [ gambarSelected, setGambarSelected ] = useState(false);
	
	const [ cekDis, setCekDis ] = useState(detailSurat.data_surat_masuk_diposisi);

	const roleUser = localStorage.getItem('role_user');

	useEffect(
		() => {
			if (detailSurat) {
				console.log('detailSurat');
				console.log(detailSurat);
				console.log(detailSurat.dokumen_output);
				setSurat(detailSurat);
				setDataPengajuanSuratDisposisi(detailSurat.data_surat_masuk_diposisi);

				let newArray = detailSurat.data_surat_masuk_diposisi

				if(roleUser === 'Super Operator'){
					newArray = newArray.filter(day => day.status === 'Proses');
					setCekDis(newArray)
				}
				else{
					// Filter By Jabatan Struktural User
					newArray = newArray.filter(day => day.uuid_jabatan_struktural === jabatanStrukturalUser);
					// Filter By Status Proses
					newArray = newArray.filter(day => day.status === 'Proses');
					setCekDis(newArray)
				}
			}
		},
		[ detailSurat ] // eslint-disable-line react-hooks/exhaustive-deps
	);

	const formData = {
		uuid: detailSurat.uuid,
		status: 'Selesai',
		catatan: '',
	};

	const [dataValues, setdataValues] = useState(formData);
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setdataValues({ ...dataValues, [name]: value });
	};

	const handleSubmit = () => {
		if(jenisVerif === 'Selesai')
		{
			setLoading(true)
			if(listDisposisiLanjutan.length!==0){
				if (selectedDisposisi){
					let data = {
						uuid: dataPengajuanSuratDisposisi.uuid,
						uuid_surat_masuk: dataPengajuanSuratDisposisi.uuid_surat_masuk,
						uuid_jabatan_struktural: dataPengajuanSuratDisposisi.uuid_jabatan_struktural,
						status: 'Selesai',
						uuid_pengguna: uuidPengguna,
						memo:  dataPengajuanSuratDisposisi.memo,
						uuid_jabatan_struktural_tujuan: selectedDisposisi.value,
					};

					GlobalServices.create(data, 'administrasi/surat-masuk/disposisi/create-with-tujuan', token)
						.then((response) => {
							if (response.data.code === 201) {
								setLoading(false)
								showNotification(
									<span className='d-flex align-items-center'>
										<Icon icon='Info' size='lg' className='me-1' />
										<span>Berhasil Setujui Pengajuan</span>
									</span>,
									'Berhasil Setujui Pengajuan Surat',
									);
									setSelectedDisposisi(null)
									setState(false);
									setIsOpen(false);
									statusRefresh();
							} else {
								setLoading(false)
								showNotification(
									<span className='d-flex align-items-center'>
										<Icon icon='Warning' size='lg' className='me-1' />
										<span>Gagal Setujui Pengajuan</span>
									</span>,
									'Gagal Setujui Pengajuan Surat',
								);
							}
						})
						.catch((e) => {
							setLoading(false)
							console.log(e);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Setujui Pengajuan</span>
								</span>,
								'Gagal Setujui Pengajuan Surat',
							);
						});

				} else {
					setLoading(false)
					// setIsOpen(false);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Warning' size='lg' className='me-1' />
							<span>Gagal Setujui Pengajuan</span>
						</span>,
						'Data Tidak Lengkap!',
					);
				}
			}
			else{
				let data = {
					uuid: dataPengajuanSuratDisposisi.uuid,
					uuid_surat_masuk: dataPengajuanSuratDisposisi.uuid_surat_masuk,
					uuid_jabatan_struktural: dataPengajuanSuratDisposisi.uuid_jabatan_struktural,
					status: 'Selesai',
					uuid_pengguna: uuidPengguna,
					memo:  dataPengajuanSuratDisposisi.memo,
					uuid_jabatan_struktural_tujuan: '',
				};

				GlobalServices.create(data, 'administrasi/surat-masuk/disposisi/create-with-tujuan', token)
					.then((response) => {
						if (response.data.code === 201) {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Setujui Pengajuan</span>
								</span>,
								'Berhasil Setujui Pengajuan Surat',
								);
								setSelectedDisposisi(null)
								setState(false);
								setIsOpen(false);
								statusRefresh();
						} else {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Setujui Pengajuan</span>
								</span>,
								'Gagal Setujui Pengajuan Surat',
							);
						}
					})
					.catch((e) => {
						setLoading(false)
						console.log(e);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Setujui Pengajuan</span>
							</span>,
							'Gagal Setujui Pengajuan Surat',
						);
					});
			}
		}
		else if(jenisVerif === 'Tolak'){
			setLoading(true)
			if (dataValues.catatan !== '') {
				let data = {
					uuid: dataPengajuanSuratDisposisi.uuid,
					uuid_surat_masuk: dataPengajuanSuratDisposisi.uuid_surat_masuk,
					uuid_jabatan_struktural: dataPengajuanSuratDisposisi.uuid_jabatan_struktural,
					status: 'Tolak',
					uuid_pengguna: uuidPengguna,
					memo:  dataValues.catatan,
					uuid_jabatan_struktural_tujuan: '',
				};

				GlobalServices.create(data, 'administrasi/surat-masuk/disposisi/create-with-tujuan', token)
					.then((response) => {
						if (response.data.code === 201) {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Tolak Pengajuan</span>
								</span>,
								'Berhasil Tolak Pengajuan Surat',
								);
								setState(false);
								setIsOpen(false);
								statusRefresh();
						} else {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Tolak Pengajuan</span>
								</span>,
								'Gagal Tolak Pengajuan Surat',
							);
						}
					})
					.catch((e) => {
						setLoading(false)
						console.log(e);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Tolak Pengajuan</span>
							</span>,
							'Gagal Tolak Pengajuan Surat',
						);
					});
			} else {
				setLoading(false)
				// setIsOpen(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Tolak Pengajuan</span>
					</span>,
					'Data Tidak Lengkap!',
				);
			}
		}
	};

	const handleSubmitVerifSurat = () => {
		if (
				dataValues.catatan !== ''
			) 
		{
			setLoading(true)
			let data = {
				uuid: detailSurat.uuid,
				status: 'Selesai',
				catatan: dataValues.catatan,
				dokumen_output: surat.dokumen_output
			};
			
			GlobalServices.create(data, 'administrasi/surat-masuk/verifikasi', token)
				.then((response) => {
					if (response.data.code === 201) {
						setLoading(false)
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Berhasil Verifikasi</span>
							</span>,
							'Berhasil Verifikasi Surat Masuk',
							);
							tutupVerifikasi();
							statusRefresh();
					} else {
						setLoading(false)
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Verifikasi</span>
							</span>,
							'Gagal Verifikasi Surat Masuk',
						);
					}
				})
				.catch((e) => {
					setLoading(false)
					let pesan = ''
					if(e.response.status === 404){
						pesan = 'Pengajuan Surat Tidak Ditemukan'
					}
					else if(e.response.status === 422){
						pesan = 'Data Tidak Boleh Kosong'
					}
					else{
						pesan = 'Gagal Verifikasi Surat Masuk'
					}
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Warning' size='lg' className='me-1' />
							<span>Gagal Verifikasi</span>
						</span>,
						pesan,
					);
				});
		} else {
			setLoading(false)
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Verifikasi Surat</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	const tombolVerif = (stat) => {
		switch (stat) {
			case 'Verifikasi':
				return (
					<div align='right'>
						<Button
							icon='Check'
							color='success'
							// isLight
							onClick={() => {
								setStateVerif(true);
								setIsOpen(false)
							}}
							style={{width:'120px'}}

						>
							Verifikasi
						</Button>
					</div>
				)
				// break;
			case 'Proses':
				let struktur = cekDis && cekDis.length !== 0?
				<>
					<div align='right'>
						{/* <Button
							icon='Close'
							color='danger'
							// isLight
							onClick={() => {
								getDetailDisposisi(detailSurat.uuid)
								setState(true);
								setIsOpen(false);
								setJenisVerif('Tolak');
							}}
							style={{width:'100px'}}
						>
							Tolak
						</Button>&nbsp;&nbsp; */}
						<Button
							icon='Check'
							color='success'
							// isLight
							onClick={() => {
								getDetailDisposisi(detailSurat.uuid)
								setState(true);
								setIsOpen(false);
								setJenisVerif('Selesai');
							}}
							style={{width:'100px'}}
						>
							Setujui
						</Button>
					</div>
				</>
				:
				<>
				</>
				return struktur;
				
				// break;
			default:
				return ''
				// break;
		}
	}

	// Fitur Persetujuan Disposisi Selanjutnya
	const [jenisVerif, setJenisVerif] = useState('');
	const [dataDetailDisposisi, setDataDetailDisposisi] = useState([]);
	const [getDisposisi, setGetDisposisi] = useState([]);

		// set data pengajuan surat by jabatan struktural user
		const [dataPengajuanSuratDisposisi, setDataPengajuanSuratDisposisi] = useState(detailSurat.data_surat_masuk_diposisi);

		// set data select disposisi lanjutan
		const [listDisposisiLanjutan, setListDisposisiLanjutan] = useState([]);
		const [selectedDisposisi, setSelectedDisposisi] = useState(null);
	
	const getDetailDisposisi = (uuid) => {
		setLoading(true)
		let newArray = dataPengajuanSuratDisposisi;	

		if(roleUser === 'Super Operator'){
			newArray = newArray.filter(day => day.status === 'Proses');
			setDataPengajuanSuratDisposisi(newArray[0])
		}
		else{
			// Filter By Jabatan Struktural User
			newArray = newArray.filter(day => day.uuid_jabatan_struktural === jabatanStrukturalUser);
			setDataPengajuanSuratDisposisi(newArray[0])
			// Filter By Status Proses
			newArray = newArray.filter(day => day.status === 'Proses');
			setDataPengajuanSuratDisposisi(newArray[0])
		}

		GlobalServices.getAll(`administrasi/surat-masuk/list-disposisi/${uuid}`, token)
			.then((response) => {
				let frmSelect = [];
				if(response.results){
					setLoading(false)
					response.results.forEach(dt => {
						frmSelect.push({ value: dt.uuid_jabatan_struktural, label: dt.data_jabatan_struktural.nama });
					});
				}
				setListDisposisiLanjutan(frmSelect)
			})
			.catch((e) => {
				setLoading(false)
				console.log(e);
			});
	}
	
	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "disposisi") {
				let newArray = listDisposisiLanjutan;	
				newArray = newArray.filter(day => day !== e);
				setListDisposisiLanjutan(newArray)
			}
		}
	};

	const handleSelectChange = (event, param) => {
		if (event) {
			switch (param) {
				case 'disposisi':
					setSelectedDisposisi(event);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'disposisi':
					setSelectedDisposisi(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const statusColor = (stat) => {
		switch (stat) {
			case 'Proses':
				return ('tl-dot b-primary')
				// break;
			case 'Selesai':
				return ('tl-dot b-success')
				// break;
			case 'Tolak':
				return ('tl-dot b-danger')
				// break;
			default:
				return 'tl-dot b-primary'
				// break;
		}
	}

	const uploadFile = (file) => {
		if (file) {
			const type = file.type.split('/');
			if (
				type[1] === 'jpg' || 
				type[1] === 'jpeg' || 
				type[1] === 'png' || 
				type[1] === 'bmp' || 
				type[1] === 'tiff' || 
				type[1] === 'webp' ||
				type[1] === 'pdf'  
			) {
				const body = new FormData()
				body.append('file', file)
				// console.log(file);
				// console.log(body);
	
				GlobalServices.create(body, 'upload-file/surat-masuk')
					.then((response) => {
						// console.log(response);
	
						if (response.data.code === 200) {
							setDokumenSelected(true)
							setIsComplete(true)
	
							setSurat({
								...surat, dokumen_output : response.data.results
							})
							
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Upload Dokumen</span>
								</span>,
								'Berhasil Upload Dokumen',
							);
						} else {
							setDokumenSelected(false)
							setIsComplete(false)
							
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Upload Dokumen</span>
								</span>,
								'Gagal Upload Dokumen',
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
	
			}
		}
	}
	
	const handleChangeFile = (event) => {
		console.log(event.target.files[0]);
		let data = event.target.files[0]
		setIsComplete(false)
		uploadFile(data)
	}

	const tutupVerifikasi = () => {
		setState(false);
		setStateVerif(false);
		setDataPengajuanSuratDisposisi(detailSurat.data_surat_masuk_diposisi);
		setdataValues(formData);
		setSelectedDisposisi(null);
		setstatusLampiran(false);
	};

	// Sorting Status Disposisi
	surat.data_surat_masuk_diposisi.sort(function (x, y) {
		let a = new Date(x.created_at);
		let	b = new Date(y.created_at);
		return a - b;
	});
	// End Sorting Status Disposisi

	if (id || id === 0) {
		return (
			<>
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size="xl" titleId={id}>
				<ModalHeader setIsOpen={setIsOpen} className="p-4">
					<ModalTitle id={id}>{title} Surat Masuk <hr/></ModalTitle>
				</ModalHeader>
				<ModalBody className="px-4">
					<div className="row g-4">
						<div className='row'>
							<div className='col-12'>
								<div className='row'>
									<div className='col-4'>
										<div className='col-12' align='center'>
											<h5>Status Disposisi</h5>
										</div>
										{/* <div className='col-12' style={{ overflowX: 'auto'}}> */}
										<div className="col-lg-12" style={{ height: '250px',overflowY: 'scroll'}}>
											<div className="timeline p-4 block mb-4">
												{surat.data_surat_masuk_diposisi.map((i, key) => (
													<div className="tl-item">
														<div className={statusColor(i.status)} />
														<div className="tl-content">
															<div className="">
																<b>{i.data_jabatan_struktural.nama} ({i.status})</b>
															</div>
															<div className=""><i>{i.memo}</i></div>
															<div className="tl-date text-muted mt-1">
																{`${new Date(i.created_at).toLocaleDateString('id-ID')} ${new Date(
																	i.created_at
																).toLocaleTimeString('id-ID')}`}
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
									<div className='col-8'>
										<div className='row'>
											<div className='col-12' align='center'>
												<h5>Data Pengajuan</h5>
											</div>
											<FormGroup id='nomor_surat' label='Nomor Surat' className='col-md-6'>
												<Input
													type='text'
													name='nomor_surat'
													value={detailSurat.nomor_surat}
													isRequired
													disabled
												/>
											</FormGroup>
									
											<FormGroup id='tanggal_surat' label='Tanggal Catat' className='col-md-6'>
												<Input
													type='date'
													name='tanggal_surat'
													value={detailSurat.tanggal_surat}
													isRequired
													disabled
												/>
											</FormGroup>
											<div className='col-12'>&nbsp;</div>
									
											<FormGroup id='pengirim' label='Nama Pengirim' className='col-md-6'>
												<Input
													type='text'
													name='pengirim'
													value={detailSurat.pengirim}
													isRequired
													disabled
												/>
											</FormGroup>
										
											<FormGroup id='perihal' label='Perihal' className='col-md-6'>
												<Input
													type='text'
													name='perihal'
													value={detailSurat.perihal}
													isRequired
													disabled
												/>
											</FormGroup>


											{/* Dokumen */}
											<div className='col-12'>&nbsp;</div>
											<div className='col-12' align='center'>
												<h5>Dokumen Syarat</h5>
											</div>
											<div className='col-12' style={{ overflowX: 'auto'}}>
												<a href={surat.dokumen} style={{ marginTop: '5px' }} target="_blank" rel="noreferrer">
													<Button
														icon='Eye'
														color='info'
													>
														Lihat Dokumen
													</Button>
												</a>
											</div>
											
											{
												statusDisposisi === 'Selesai' && detailSurat.dokumen_output
												?
												<>
													<div className='col-12'>&nbsp;</div>
													<div className='col-12' align='center'>
														<h5>Dokumen Lampiran</h5>
													</div>
													<div className='col-12' style={{ overflowX: 'auto'}}>
														<a href={detailSurat.dokumen_output} style={{ marginTop: '5px' }} target="_blank" rel="noreferrer">
															<Button
																icon='Eye'
																color='info'
															>
																Lihat Dokumen
															</Button>
														</a>
													</div>
												</>
												:
												<>
												</>
											}

										</div>
									</div>
								</div>
							</div>
						</div>
						
						{/* <div className='row'>
							<div className='col-12' align='left' style={{ overflowX: 'auto'}}>
								<hr />
							</div>
							<div className='col-12' align='left' style={{ overflowX: 'auto'}}>
								<h5>Dokumen Syarat</h5>
							</div>
						</div>
						<div className='col-12' style={{ overflowX: 'auto'}}>
							<a href={surat.dokumen} style={{ marginTop: '5px' }} target="_blank" rel="noreferrer">
								<Button
									icon='Eye'
									color='info'
								>
									Lihat Dokumen
								</Button>
							</a>
						</div> */}
					</div>
				</ModalBody>
				<ModalFooter className="px-4 pb-4">
					<Button
						color="primary"
						// isOutline
						// className='border-0'
						style={{width:'100px'}}
						onClick={() => {
							setIsOpen(false);
							// setSurat(initSurat);
							// setSelectedInstansi(null);
						}}
					>
						Tutup
					</Button>
					{/* {roleUser !== 'Super Operator' ? tombolVerif(statusDisposisi) : '' } */}
					{ tombolVerif(statusDisposisi) }
				</ModalFooter>
			</Modal>

			<Modal
				isOpen={state}
				setIsOpen={setState}
				size='md'
				isStaticBackdrop
				titleId='exampleModalLabel'
				>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader className='p-4'>
					<ModalTitle id={id}>{jenisVerif === 'Selesai'? 'Persetujuan Pengajuan Surat':''} 
						{/* {statusDisposisi === 'Persetujuan' ? 'Persetujuan Pengajuan Surat' : 'Verifikasi'} */}
					</ModalTitle>
				</ModalHeader>
				{
					loading
					?
					<>
					<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
						<br/><br/><br/><br/>
						<div className='row g-3'>
						{Object.keys(COLORS).map((color) => (
							<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
								<Spinner color={COLORS[color]} isSmall isGrow />
							</div>
						))}
						</div>
						<br/><br/><br/><br/>
					</div>
					</>
				:
					<>
						<ModalBody className='px-4'>
							{
								jenisVerif === 'Selesai' 
								?
								<>
									{
										listDisposisiLanjutan.length!==0
										?
										<>
										<div className='row g-4'>
											<FormGroup id='jenis' label='Disposisi Lanjutan' className='col-md-12'>
												<Select
													inputId="disposisi"
													classNamePrefix="select"
													name="disposisi"
													placeholder="Pilih Tujuan Disposisi"
													isClearable
													required
													defaultValue={selectedDisposisi}
													value={selectedDisposisi}
													options={listDisposisiLanjutan}
													onInputChange={e => handleInputSelect(e, "disposisi")}
													onChange={e => {
														handleSelectChange(e, "disposisi");
													}}
												/>
											</FormGroup>
										</div>
										</>
										:
										<>
										<p align='center'>
											<h3>Yakin Ingin Setujui Pengajuan Surat?</h3> <br />
											Proses ini tidak dapat dibatalkan!
										</p>
										</>
									}
								</>
								:
								<>
									<p align='center'>
										<h3>Yakin Ingin Tolak Pengajuan Surat?</h3> <br />
										Proses ini tidak dapat dibatalkan!
										<br/><br/>
										<FormGroup id='catatan' label='Catatan' className='col-md-12'>
											<Input
												type='text'
												name='catatan'
												value={dataValues.catatan}
												onChange={handleInputChange}
												required
											/>
										</FormGroup>
									</p>
									<br/>
									<div align='center'>
										<Button
											color='danger'
											// isOutline
											// className='border-0'
											onClick={() => {
												setIsOpen(true);
												tutupVerifikasi();
												setdataValues(formData);
											}}
											style={{width:'100px'}}
										>
											Batal
										</Button>
										&nbsp;&nbsp;
										<Button color='success' type='submit' style={{width:'100px'}} onClick={handleSubmit}>
											Ya
										</Button>
									</div>
								</>
							}
						</ModalBody>
						<ModalFooter className='px-4 pb-4'>
							{
								jenisVerif === 'Selesai'
								?
								<>
									<Button
										color='danger'
										// isOutline
										// className='border-0'
										onClick={() => {
											setIsOpen(true);
											tutupVerifikasi()
											setdataValues(formData);
										}}
										style={{width:'100px'}} 
									>
										Batal
									</Button>
									{/* <Button color='info' onClick={formik.handleSubmit}> */}
									<Button color='success' type='submit' style={{width:'100px'}} onClick={handleSubmit}>
										Simpan
									</Button>
								</>
								:
								<>
								</>
							}
						</ModalFooter>
					</>
				}
				</form>
			</Modal>

			<Modal
				isOpen={stateVerif}
				setIsOpen={setStateVerif}
				size='md'
				isStaticBackdrop
				titleId='exampleModalLabel'
				>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader className='p-4'>
					<ModalTitle id={id}>Verifikasi</ModalTitle>
				</ModalHeader>
				{
					loading
					?
					<>
					<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
						<br/><br/><br/><br/>
						<div className='row g-3'>
						{Object.keys(COLORS).map((color) => (
							<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
								<Spinner color={COLORS[color]} isSmall isGrow />
							</div>
						))}
						</div>
						<br/><br/><br/><br/>
					</div>
					</>
				:
					<>
						<ModalBody className='px-4'>
							<div className='row g-4'>
								<FormGroup id='catatan' label='Catatan' className='col-md-12'>
									<Input
										type='text'
										name='catatan'
										value={dataValues.catatan}
										onChange={handleInputChange}
										required
									/>
								</FormGroup>
								<div className='col-12'>
									<Button
										color={statusLampiran?'danger':'primary'}
										// isOutline
										// className='border-0'
										style={{width:'210px'}}
										onClick={() => {
											setstatusLampiran(!statusLampiran);
											setSurat({...surat, dokumen_output:''});
										}}>
										{statusLampiran?'Hapus':'Upload'} Dokumen Lampiran
									</Button>
								</div>
								{
									statusLampiran
									?
									<>
										<FormGroup id='dokumen' label='Dokumen' className='col-md-12'>
											<Input
												id='dokumen'
												type='file'
												accept='application/pdf'
												onChange={handleChangeFile}
												required={title === 'Tambah'}
												// value={surat.dokumen}
											/>
										</FormGroup>
									</>
									:
									<></>
								}
							</div>
						</ModalBody>
						<ModalFooter className='px-4 pb-4'>
							<Button
								color='danger'
								style={{width:'100px'}}
								// isOutline
								// className='border-0'
								onClick={() => {
									setIsOpen(true);
									tutupVerifikasi()
									setdataValues(formData);
									setSurat({...surat, dokumen_output:''});
								}}
							>
								Batal
							</Button>
							{/* <Button color='info' onClick={formik.handleSubmit}> */}
							<Button color='success' type='submit' style={{width:'100px'}} onClick={handleSubmitVerifSurat} disabled={!isComplete}>
								Simpan
							</Button>
						</ModalFooter>
					</>
				}
				</form>
				</Modal>
		</>
		);
	}
	return null;
};
ModalDetail.propTypes = {
	id: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	statusDisposisi: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
	// listTujuanSurat: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ModalDetail;
