import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-use';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import Breadcrumb from '../bootstrap/Breadcrumb';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Card, {
	CardActions,
	CardBody,
	CardCodeView,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle
} from '../bootstrap/Card';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Button from '../bootstrap/Button';
import { componentsMenu } from '../../menu';
import GlobalServices from './Services/GlobalServices';
import Select from '../bootstrap/forms/Select';
import Option from '../bootstrap/Option';
import Icon from '../icon/Icon';
import Alert from '../bootstrap/Alert';

const DetailSuratMahasiswa = (props) => {
	// useMinimizeAside();
	const location = useLocation();
	const history = useHistory();

	const initAlertData = {
		color: 'success',
		icon: 'Info',
		text: 'Lengkapi seluruh data dengan benar',
		isLight: true
	}
	const [alertData, setAlertData] = useState(initAlertData);

	useEffect(
		() => {
			if (location.state === null) {
				history.push('/')
			} else {
				console.log(location.state);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	if (location.state === null) {
		history.push('/')
		return ''
	} 

	return (
		<PageWrapper title={`Detail Surat ${location.state.state.dataProp.nama}`}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						isToHome={false}
						list={[
							{ title: 'Dashboard', to: '/' },
							// { title: `Surat ${location.state.state.dataProp.nama_alias}`, to: '#' }
							{ title: `Detail Pengajuan`, to: '#' }
						]}
					/>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div className="row" style={{ justifyContent: 'center' }}>
					{/* tpye */}
					<div className="col-lg-6 detail-pengajuan-surat">
						<Card stretch className="cursor-pointer shadow-3d-primary shadow-3d-hover">
							<CardHeader style={{ paddingBottom: 0, display: 'flex', flexDirection: 'column' }}>
								<CardLabel style={{ width: '100%' }}>
									<CardTitle style={{ textAlign: 'center', fontSize: '1.5rem' }}>Detail Pengajuan</CardTitle>
								</CardLabel>
								<div className="row" style={{ paddingTop: '15px', width: '100%' }}>
									<div className="col-lg-12">
										<Alert
											color="success"
											icon="Verified"
											isLight
											style={{ width: '100%', margin: 0, fontSize: '1.1rem' }}
										>
											Selamat, pengajuan surat anda telah berhasil dilakukan. <br/>
											Silahkan Cek Email Untuk Melihat Detail Pengajuan.
										</Alert>
									</div>
								</div>
								{/* </div> */}
							</CardHeader>

							<CardBody style={{ paddingBottom: 0 }}>
								<div className="row" style={{ marginBottom: '10px' }}>
									<div className="col-sm-6">
										<h5 style={{ margin: 0, fontWeight: 600, fontSize: '1.1rem' }}>Kode Pengajuan</h5>
									</div>
									<div className="col-sm-6">
										<h5 style={{ margin: 0, fontWeight: 500, fontSize: '1.1rem' }}>{location.state.state.detailPengajuan.kode_pengajuan}</h5>
									</div>
								</div>
								<div className="row" style={{ marginBottom: '10px' }}>
									<div className="col-sm-6">
										<h5 style={{ margin: 0, fontWeight: 600, fontSize: '1.1rem' }}>Jenis Surat</h5>
									</div>
									<div className="col-sm-6">
										<h5 style={{ margin: 0, fontWeight: 500, fontSize: '1.1rem' }}>{`Surat ${location.state.state.dataProp.nama_alias}`}</h5>
									</div>
								</div>
								<div className="row" style={{ marginBottom: '10px' }}>
									<div className="col-sm-6">
										<h5 style={{ margin: 0, fontWeight: 600, fontSize: '1.1rem' }}>Nama</h5>
									</div>
									<div className="col-sm-6">
										<h5 style={{ margin: 0, fontWeight: 500, fontSize: '1.1rem' }}>{location.state.state.detailPengajuan.nama}</h5>
									</div>
								</div>
								<div className="row" style={{ marginBottom: '10px' }}>
									<div className="col-sm-6">
										<h5 style={{ margin: 0, fontWeight: 600, fontSize: '1.1rem' }}>NIM</h5>
									</div>
									<div className="col-sm-6">
										<h5 style={{ margin: 0, fontWeight: 500, fontSize: '1.1rem' }}>{location.state.state.detailPengajuan.nim}</h5>
									</div>
								</div>
								{Object.keys(location.state.state.detailSurat).map((i, key) => {
									if (i !== 'nama' && i !== 'nim' && i !== 'no') {
										return (
											<div className="row" style={{ marginBottom: '10px' }}>
												<div className="col-sm-6">
													<h5 style={{ margin: 0, fontWeight: 600, fontSize: '1.1rem' }}>{location.state.state.detailSurat[i].label}</h5>
												</div>
												<div className="col-sm-6">
													<h5 style={{ margin: 0, fontWeight: 500, fontSize: '1.1rem' }}>{location.state.state.detailSurat[i].value}</h5>
												</div>
											</div>
										)
									}
									return ''
								})}
								{location.state.state.nama_dosen !== '' ? (
									<div className="row" style={{ marginBottom: '10px' }}>
										<div className="col-sm-6">
											<h5 style={{ margin: 0, fontWeight: 600, fontSize: '1.1rem' }}>Dosen Pengampu</h5>
										</div>
										<div className="col-sm-6">
											<h5 style={{ margin: 0, fontWeight: 500, fontSize: '1.1rem' }}>{location.state.state.nama_dosen}</h5>
										</div>
									</div>
								) : ''}
							</CardBody>
							<CardFooter style={{ justifyContent: 'center', paddingTop: 0 }}>
								<Button
									color="info"
									// icon="Save"
									tag="a"
									to={{
										pathname: '/'
									}}
								>
									Kembali ke dashboard
								</Button>
							</CardFooter>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
	

};
// Login.propTypes = {
// 	isSignUp: PropTypes.bool,
// };
// Login.defaultProps = {
// 	isSignUp: false,
// };

export default DetailSuratMahasiswa;
