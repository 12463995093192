import React, { lazy } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import DetailInstansi from '../../components/feature/InstansiDetail';
import DokumenInstansi from '../../components/feature/DokumenInstansi';
import DokumenSyarat from '../../components/feature/DokumenSyarat';
import DokumenKaryawan from '../../components/feature/DokumenKaryawan';
import VerifikasiDataKaryawan from '../../components/feature/KaryawanVerifikasi';
import DisposisiInstansi from '../../components/feature/DisposisiInstansi';
import DetailKaryawan from '../../components/feature/KaryawanDetail';
import DetailInfo from '../../components/feature/InfoDetail';
import DetailRiwayat from '../../components/feature/RiwayatDetail';
import DetailDisposisiInstansi from '../../components/feature/DetailDisposisiInstansi';
import JenisSurat from '../../components/feature/JenisSurat';
import { componentsMenu, dashboardMenu, menuMaster } from '../../menu';
import SuratMasukByInstansi from '../../components/feature/SuratMasukByInstansi';
import SuratKeluarByInstansi from '../../components/feature/SuratKeluar';
import SuratMahasiswa from '../../components/feature/SuratMahasiswa';
import Info from '../../components/feature/Info';
import InfoInstansi from '../../components/feature/InfoInstansi';
import Banner from '../../components/feature/Banner';

import SuperOperatorTempatTugas from '../../components/feature/SuperOperatorTempatTugas';
import SuperOperatorJabStruktural from '../../components/feature/SuperOperatorJabStruktural';
import SuperOperatorJabFungsional from '../../components/feature/SuperOperatorJabFungsional';
import SuperOperatorTandaTangan from '../../components/feature/SuperOperatorTandaTangan';
import SuperOperatorRiwayatPenomoran from '../../components/feature/SuperOperatorRiwayatPenomoran';
import SuperOperatorDokumen from '../../components/feature/SuperOperatorDokumen';
import SuperOperatorDokumenSyarat from '../../components/feature/SuperOperatorDokumenSyarat';
import HakAksesPengguna from '../../components/feature/HakAksesPengguna';
// import GlobalServices from '../../components/feature/Services/GlobalServices';
// import Login from '../../pages/presentation/auth/Login';


// const token = sessionStorage.getItem('access_token');
// const dataToken = GlobalServices.DataToken(token)
// console.log('dcode');
// console.log(dataToken);
// console.log(dataToken.sub.role);

const MASTER = {
	PENGGUNA: lazy(() => import('../../components/feature/Pengguna')),
	INSTANSI: lazy(() => import('../../components/feature/Instansi')),
	KARYAWAN: lazy(() => import('../../components/feature/Karyawan')),
	TEMPATTUGAS: lazy(() => import('../../components/feature/TempatTugas')),
	JABATANSTRUKTURAL: lazy(() => import('../../components/feature/JabatanStruktural')),
	JABATANFUNGSIONAL: lazy(() => import('../../components/feature/JabatanFungsional')),
	RIWAYAT: lazy(() => import('../../components/feature/Riwayat')),
	JAM: lazy(() => import('../../components/feature/Jam')),
	// DISPOSISIINSTANSI: lazy(() => import('../../components/feature/DisposisiInstansiLama')),
	SURATMASUK: lazy(() => import('../../components/feature/SuratMasuk')),
	JENISSURAT: lazy(() => import('../../components/feature/JenisSurat')),
};

const LANDING = {
	DASHBOARD: lazy(() => import('../../pages/dashboard/DashboardPage')),
	// DASHBOARD_BOOKING: lazy(() => import('../../pages/dashboard/DashboardBookingPage')),
	SUMMARY: lazy(() => import('../../pages/SummaryPage')),
};

const AUTH = {
	PAGE_404: lazy(() => import('../../pages/presentation/auth/Page404')),
};

// Content Page
const CONTENT = {
	TYPOGRAPHY: lazy(() => import('../../pages/documentation/content/TypographyPage')),
	IMAGES: lazy(() => import('../../pages/documentation/content/ImagesPage')),
	TABLES: lazy(() => import('../../pages/documentation/content/TablesPage')),
	FIGURES: lazy(() => import('../../pages/documentation/content/FiguresPage')),
};

// Components Page
const COMPONENTS_PAGE = {
	ACCORDION: lazy(() => import('../../pages/documentation/components/AccordionPage')),
	ALERT: lazy(() => import('../../pages/documentation/components/AlertPage')),
	BADGE: lazy(() => import('../../pages/documentation/components/BadgePage')),
	BREADCRUMB: lazy(() => import('../../pages/documentation/components/BreadcrumbPage')),
	BUTTON: lazy(() => import('../../pages/documentation/components/ButtonPage')),
	BUTTON_GROUP: lazy(() => import('../../pages/documentation/components/ButtonGroupPage')),
	CARD: lazy(() => import('../../pages/documentation/components/CardPage')),
	CAROUSEL: lazy(() => import('../../pages/documentation/components/CarouselPage')),
	COLLAPSE: lazy(() => import('../../pages/documentation/components/CollapsePage')),
	DROPDOWN: lazy(() => import('../../pages/documentation/components/DropdownsPage')),
	LIST_GROUP: lazy(() => import('../../pages/documentation/components/ListGroupPage')),
	MODAL: lazy(() => import('../../pages/documentation/components/ModalPage')),
	NAVS_TABS: lazy(() => import('../../pages/documentation/components/NavsTabsPage')),
	// OFF_CANVAS: lazy(() => import('../../pages/documentation/components/OffCanvasPage')),
	PAGINATION: lazy(() => import('../../pages/documentation/components/PaginationPage')),
	POPOVERS: lazy(() => import('../../pages/documentation/components/PopoversPage')),
	PROGRESS: lazy(() => import('../../pages/documentation/components/ProgressPage')),
	SCROLLSPY: lazy(() => import('../../pages/documentation/components/ScrollspyPage')),
	SPINNER: lazy(() => import('../../pages/documentation/components/SpinnersPage')),
	TABLE: lazy(() => import('../../pages/documentation/components/TablePage')),
	TOASTS: lazy(() => import('../../pages/documentation/components/ToastsPage')),
	TOOLTIP: lazy(() => import('../../pages/documentation/components/TooltipPage')),
};

// Forms Page
const FORMS_PAGE = {
	FORM_GROUP: lazy(() => import('../../pages/documentation/forms/FormGroupPage')),
	FORM_CONTROLS: lazy(() => import('../../pages/documentation/forms/FormControlsPage')),
	SELECT: lazy(() => import('../../pages/documentation/forms/SelectPage')),
	CHECKS_AND_RADIO: lazy(() => import('../../pages/documentation/forms/ChecksAndRadioPage')),
	RANGE: lazy(() => import('../../pages/documentation/forms/RangePage')),
	INPUT_GROUP: lazy(() => import('../../pages/documentation/forms/InputGroupPage')),
	VALIDATION: lazy(() => import('../../pages/documentation/forms/ValidationPage')),
	WIZARD: lazy(() => import('../../pages/documentation/forms/WizardPage')),
};

// Icons Page
const ICONS = {
	ICON: lazy(() => import('../../pages/documentation/icons/IconPage')),
	BOOTSTRAP: lazy(() => import('../../pages/documentation/icons/BootstrapIconPage')),
	MATERIAL: lazy(() => import('../../pages/documentation/icons/MaterialPage')),
};

// Extras Page
const EXTRA = {
	NOTIFICATION: lazy(() => import('../../pages/documentation/extras/NotificationPage')),
	HOOKS: lazy(() => import('../../pages/documentation/extras/HooksPage')),
};

// Charts Page
const CHARTS_PAGE = {
	GENERAL_USAGE: lazy(() => import('../../pages/documentation/charts/ChartGeneralUsagePage')),
	SPARKLINE: lazy(() => import('../../pages/documentation/charts/ChartSparklinePage')),
	LINE: lazy(() => import('../../pages/documentation/charts/ChartLinePage')),
	AREA: lazy(() => import('../../pages/documentation/charts/ChartAreaPage')),
	COLUMN: lazy(() => import('../../pages/documentation/charts/ChartColumnPage')),
	BAR: lazy(() => import('../../pages/documentation/charts/ChartBarPage')),
	MIXED: lazy(() => import('../../pages/documentation/charts/ChartMixedPage')),
	TIMELINE: lazy(() => import('../../pages/documentation/charts/ChartTimelinePage')),
	CANDLESTICK: lazy(() => import('../../pages/documentation/charts/ChartCandlestickPage')),
	BOX_WHISKER: lazy(() => import('../../pages/documentation/charts/ChartBoxWhiskerPage')),
	PIE_DONUT: lazy(() => import('../../pages/documentation/charts/ChartPieDonutPage')),
	RADAR: lazy(() => import('../../pages/documentation/charts/ChartRadarPage')),
	POLAR: lazy(() => import('../../pages/documentation/charts/ChartPolarPage')),
	RADIAL_BAR: lazy(() => import('../../pages/documentation/charts/ChartRadialBarPage')),
	BUBBLE: lazy(() => import('../../pages/documentation/charts/ChartBubblePage')),
	SCATTER: lazy(() => import('../../pages/documentation/charts/ChartScatterPage')),
	HEAT_MAP: lazy(() => import('../../pages/documentation/charts/ChartHeatMapPage')),
	TREE_MAP: lazy(() => import('../../pages/documentation/charts/ChartTreeMapPage')),
};

// Utilities Pages
const UTILITIES = {
	API: lazy(() => import('../../pages/documentation/utilities/ApiPage')),
	BACKGROUND: lazy(() => import('../../pages/documentation/utilities/BackgroundPage')),
	BORDERS: lazy(() => import('../../pages/documentation/utilities/BordersPage')),
	COLORS: lazy(() => import('../../pages/documentation/utilities/ColorsPage')),
	DISPLAY: lazy(() => import('../../pages/documentation/utilities/DisplayPage')),
	FLEX: lazy(() => import('../../pages/documentation/utilities/FlexPage')),
	FLOAT: lazy(() => import('../../pages/documentation/utilities/FloatPage')),
	INTERACTIONS: lazy(() => import('../../pages/documentation/utilities/InteractionsPage')),
	OVERFLOW: lazy(() => import('../../pages/documentation/utilities/OverflowPage')),
	POSITION: lazy(() => import('../../pages/documentation/utilities/PositionPage')),
	SHADOWS: lazy(() => import('../../pages/documentation/utilities/ShadowsPage')),
	SIZING: lazy(() => import('../../pages/documentation/utilities/SizingPage')),
	SPACING: lazy(() => import('../../pages/documentation/utilities/SpacingPage')),
	TEXT: lazy(() => import('../../pages/documentation/utilities/TextPage')),
	VERTICAL_ALIGN: lazy(() => import('../../pages/documentation/utilities/VerticalAlignPage')),
	VISIBILITY: lazy(() => import('../../pages/documentation/utilities/VisibilityPage')),
};

const PublicRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			return <Component {...props} {...rest} />;
		}}
	/>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			if (sessionStorage.getItem('access_token')) {
				return <Component {...props} {...rest} />;
			}
			return (
				<Redirect
					to={{
						pathname: '/login',
						state: { from: PropTypes.location },
					}}
				/>
			);
		}}
	/>
);

const ContentRoutes = () => {
	const location = useLocation();
	return (
		<Switch location={location}>
			{/* App */}
			{/*	exact */}
			{/*	path={`${demoPages.crm.subMenu.invoiceID.path}/:id`} */}
			{/*	component={APP.CRM.INVOICE} */}
			{/* /> */}

			{/* Landing */}
			<PrivateRoute exact path={dashboardMenu.dashboard.path} component={LANDING.DASHBOARD} />
			{/* <Route
				exact
				path={dashboardMenu.dashboardBooking.path}
				component={LANDING.DASHBOARD_BOOKING}
			/> */}
			{/* <Route exact path={dashboardMenu.summary.path} component={LANDING.SUMMARY} /> */}

			{/* DATA MASTER */}
			<PrivateRoute exact path={menuMaster.pengguna.path} component={MASTER.PENGGUNA} />
			{/* <PrivateRoute exact path={menuMaster.instansi.path} component={MASTER.INSTANSI} /> */}
			<PrivateRoute exact path={menuMaster.instansi.path} component={MASTER.INSTANSI} title='Instansi' />
			<PrivateRoute exact path='/admin/detail-instansi' component={DetailInstansi} title='Detail Instansi'/>
			<PrivateRoute exact path={menuMaster.karyawan.path} component={MASTER.KARYAWAN} />
			<PrivateRoute exact path='/admin/detail-karyawan' component={DetailKaryawan} title='Detail Karyawan'/>
			<PrivateRoute exact path='/admin/dokumen-karyawan' component={DokumenKaryawan} title='Dokumen Karyawan'/>
			<PrivateRoute exact path='/admin/verifikasi-data-pegawai' component={VerifikasiDataKaryawan} title='Verifikasi Data Pegawai'/>
			<PrivateRoute exact path={menuMaster.tempatTugas.path} component={MASTER.TEMPATTUGAS} />
			<PrivateRoute
				exact
				path={menuMaster.jabatanStruktural.path}
				component={MASTER.JABATANSTRUKTURAL}
			/>
			<PrivateRoute
				exact
				path={menuMaster.jabatanFungsional.path}
				component={MASTER.JABATANFUNGSIONAL}
			/>
			<PrivateRoute exact path={menuMaster.riwayat.path} component={MASTER.RIWAYAT} />
			<PrivateRoute exact path='/admin/detail-riwayat-absen' component={DetailRiwayat} title='Detail Riwayat Absen'/>
			<PrivateRoute exact path={menuMaster.konfigurasi.subMenu.jam.path} component={MASTER.JAM} />
			{/* <PrivateRoute exact path={menuMaster.konfigurasi.subMenu.disposisiInstansi.path} component={MASTER.DISPOSISIINSTANSI} /> */}

			<PrivateRoute exact path={menuMaster.suratMasuk.path} component={MASTER.INSTANSI} linkTo='SuratMasukByInstansi' title='Surat Masuk' />
			<PrivateRoute exact path={menuMaster.jenisSurat.path} component={MASTER.INSTANSI} linkTo='JenisSurat' title='Jenis Surat' />

			<PrivateRoute path='/admin/jenis-surat-instansi' component={JenisSurat} />
			<PrivateRoute path='/admin/surat-masuk-instansi' component={SuratMasukByInstansi} />
			<PrivateRoute path='/admin/surat-keluar-instansi' component={SuratKeluarByInstansi} />
			<PrivateRoute path='/admin/surat-mahasiswa' component={SuratMahasiswa} />
			<PrivateRoute path='/admin/info' component={Info} />
			<PrivateRoute path='/admin/info-instansi' component={InfoInstansi} />
			<PrivateRoute exact path='/admin/detail-info' component={DetailInfo} title='Detail Info'/>
			<PrivateRoute path='/admin/banner' component={Banner} />
			
			<PrivateRoute exact path={menuMaster.dokumen.path} component={MASTER.INSTANSI} linkTo='Dokumen' title='Dokumen' />
			<PrivateRoute exact path='/admin/dokumen-instansi' component={DokumenInstansi} />

			<PrivateRoute exact path={menuMaster.konfigurasi.subMenu.disposisiInstansi.path} component={MASTER.INSTANSI} linkTo="DisposisiInstansi" />
			<PrivateRoute exact path='/admin/disposisi' component={DisposisiInstansi} title='Disposisi Instansi'/>
			<PrivateRoute path='/admin/detail-disposisi-instansi' component={DetailDisposisiInstansi} title='Detail Disposisi Instansi' />

			<PrivateRoute exact path={menuMaster.dokumenSyarat.path} component={MASTER.INSTANSI} linkTo='DokumenSyaratByInstansi' title='Dokumen Syarat' />
			<PrivateRoute path='/admin/syarat-dokumen-instansi' component={JenisSurat} linkTo='Dokumen Syarat' title='Dokumen Syarat' />
			<PrivateRoute path='/admin/detail-syarat-dokumen-instansi' component={DokumenSyarat} title='Dokumen Syarat' />

			{/* Role Super Operator */}
			<PrivateRoute path='/superoperator/karyawan' component={MASTER.KARYAWAN} />
			<PrivateRoute exact path='/superoperator/verifikasi-data-pegawai' component={VerifikasiDataKaryawan} title='Verifikasi Data Pegawai'/>
			<PrivateRoute exact path='/superoperator/dokumen-karyawan' component={DokumenKaryawan} title='Dokumen Karyawan'/>
			<PrivateRoute exact path='/superoperator/tempat-tugas' component={SuperOperatorTempatTugas} title='Tempat Tugas'/>
			<PrivateRoute exact path='/superoperator/jabatan-struktural' component={SuperOperatorJabStruktural} title='Jabatan Struktural'/>
			<PrivateRoute exact path='/superoperator/jabatan-fungsional' component={SuperOperatorJabFungsional} title='Jabatan Fungsional'/>
			<PrivateRoute exact path='/superoperator/tanda-tangan' component={SuperOperatorTandaTangan} title='Tanda Tangan'/>
			<PrivateRoute exact path='/superoperator/riwayat-penomoran' component={SuperOperatorRiwayatPenomoran} title='Riwayat Penomoran Surat'/>
			<PrivateRoute exact path='/superoperator/dokumen' component={SuperOperatorDokumen} title='Dokumen'/>
			<PrivateRoute exact path='/superoperator/syarat-dokumen' component={SuperOperatorDokumenSyarat} title='Dokumen Syarat'/>
			<PrivateRoute path='/superoperator/pengguna' component={MASTER.PENGGUNA} />
			<PrivateRoute path='/superoperator/hak-akses-pengguna' component={HakAksesPengguna} />
			<PrivateRoute path='/superoperator/info' component={Info} />
			<PrivateRoute path='/superoperator/banner' component={Banner} />
			{/* End Role Super Operator */}

			{/* DOCUMENTATION */}
			{/* Content */}
			<Route
				path={componentsMenu.content.subMenu.typography.path}
				component={CONTENT.TYPOGRAPHY}
			/>
			<Route path={componentsMenu.content.subMenu.images.path} component={CONTENT.IMAGES} />
			<Route path={componentsMenu.content.subMenu.tables.path} component={CONTENT.TABLES} />
			<Route path={componentsMenu.content.subMenu.figures.path} component={CONTENT.FIGURES} />

			{/* Components  */}
			<Route
				path={componentsMenu.components.subMenu.navsTabs.path}
				component={COMPONENTS_PAGE.NAVS_TABS}
			/>
			<Route
				path={componentsMenu.components.subMenu.tooltip.path}
				component={COMPONENTS_PAGE.TOOLTIP}
			/>
			<Route
				path={componentsMenu.components.subMenu.toasts.path}
				component={COMPONENTS_PAGE.TOASTS}
			/>
			<Route
				path={componentsMenu.components.subMenu.scrollspy.path}
				component={COMPONENTS_PAGE.SCROLLSPY}
			/>
			<Route
				path={componentsMenu.components.subMenu.carousel.path}
				component={COMPONENTS_PAGE.CAROUSEL}
			/>
			<Route
				path={componentsMenu.components.subMenu.spinners.path}
				component={COMPONENTS_PAGE.SPINNER}
			/>
			<Route
				path={componentsMenu.components.subMenu.listGroup.path}
				component={COMPONENTS_PAGE.LIST_GROUP}
			/>
			<Route
				path={componentsMenu.components.subMenu.breadcrumb.path}
				component={COMPONENTS_PAGE.BREADCRUMB}
			/>
			<Route
				path={componentsMenu.components.subMenu.collapse.path}
				component={COMPONENTS_PAGE.COLLAPSE}
			/>
			<Route
				path={componentsMenu.components.subMenu.pagination.path}
				component={COMPONENTS_PAGE.PAGINATION}
			/>
			<Route
				path={componentsMenu.components.subMenu.progress.path}
				component={COMPONENTS_PAGE.PROGRESS}
			/>
			<Route
				path={componentsMenu.components.subMenu.card.path}
				component={COMPONENTS_PAGE.CARD}
			/>
			<Route
				path={componentsMenu.components.subMenu.button.path}
				component={COMPONENTS_PAGE.BUTTON}
			/>
			<Route
				path={componentsMenu.components.subMenu.buttonGroup.path}
				component={COMPONENTS_PAGE.BUTTON_GROUP}
			/>
			<Route
				path={componentsMenu.components.subMenu.alert.path}
				component={COMPONENTS_PAGE.ALERT}
			/>
			<Route
				path={componentsMenu.components.subMenu.badge.path}
				component={COMPONENTS_PAGE.BADGE}
			/>
			<Route
				path={componentsMenu.components.subMenu.popovers.path}
				component={COMPONENTS_PAGE.POPOVERS}
			/>
			<Route
				path={componentsMenu.components.subMenu.dropdowns.path}
				component={COMPONENTS_PAGE.DROPDOWN}
			/>
			<Route
				path={componentsMenu.components.subMenu.accordion.path}
				component={COMPONENTS_PAGE.ACCORDION}
			/>
			<Route
				path={componentsMenu.components.subMenu.modal.path}
				component={COMPONENTS_PAGE.MODAL}
			/>
			{/* <Route
				path={componentsMenu.components.subMenu.offcanvas.path}
				component={COMPONENTS_PAGE.OFF_CANVAS}
			/> */}
			<Route
				path={componentsMenu.components.subMenu.table.path}
				component={COMPONENTS_PAGE.TABLE}
			/>

			{/* Icons */}
			<Route path='/icons/icon' component={ICONS.ICON} />
			<Route path='/icons/material' component={ICONS.MATERIAL} />
			<Route path='/icons/bootstrap-icon' component={ICONS.BOOTSTRAP} />

			{/* Extras */}
			<Route path='/notifications' component={EXTRA.NOTIFICATION} />
			<Route path='/hooks' component={EXTRA.HOOKS} />

			{/* Forms */}
			<Route path='/forms/form-group' component={FORMS_PAGE.FORM_GROUP} />
			<Route path='/forms/form-controls' component={FORMS_PAGE.FORM_CONTROLS} />
			<Route path='/forms/select' component={FORMS_PAGE.SELECT} />
			<Route path='/forms/checks-and-radio' component={FORMS_PAGE.CHECKS_AND_RADIO} />
			<Route path='/forms/range' component={FORMS_PAGE.RANGE} />
			<Route path='/forms/input-group' component={FORMS_PAGE.INPUT_GROUP} />
			<Route path='/forms/validation' component={FORMS_PAGE.VALIDATION} />
			<Route path='/forms/wizard' component={FORMS_PAGE.WIZARD} />

			{/* Charts */}
			<Route path='/charts/general-usage' component={CHARTS_PAGE.GENERAL_USAGE} />
			<Route path='/charts/sparkline' component={CHARTS_PAGE.SPARKLINE} />
			<Route path='/charts/line' component={CHARTS_PAGE.LINE} />
			<Route path='/charts/area' component={CHARTS_PAGE.AREA} />
			<Route path='/charts/column' component={CHARTS_PAGE.COLUMN} />
			<Route path='/charts/bar' component={CHARTS_PAGE.BAR} />
			<Route path='/charts/mixed' component={CHARTS_PAGE.MIXED} />
			<Route path='/charts/timeline' component={CHARTS_PAGE.TIMELINE} />
			<Route path='/charts/candlestick' component={CHARTS_PAGE.CANDLESTICK} />
			<Route path='/charts/box-whisker' component={CHARTS_PAGE.BOX_WHISKER} />
			<Route path='/charts/pie-donut' component={CHARTS_PAGE.PIE_DONUT} />
			<Route path='/charts/radar' component={CHARTS_PAGE.RADAR} />
			<Route path='/charts/polar' component={CHARTS_PAGE.POLAR} />
			<Route path='/charts/radial-bar' component={CHARTS_PAGE.RADIAL_BAR} />
			<Route path='/charts/bubble' component={CHARTS_PAGE.BUBBLE} />
			<Route path='/charts/scatter' component={CHARTS_PAGE.SCATTER} />
			<Route path='/charts/heat-map' component={CHARTS_PAGE.HEAT_MAP} />
			<Route path='/charts/tree-map' component={CHARTS_PAGE.TREE_MAP} />

			{/* Utilities */}
			<Route path='/utilities/api' component={UTILITIES.API} />
			<Route path='/utilities/background' component={UTILITIES.BACKGROUND} />
			<Route path='/utilities/borders' component={UTILITIES.BORDERS} />
			<Route path='/utilities/colors' component={UTILITIES.COLORS} />
			<Route path='/utilities/display' component={UTILITIES.DISPLAY} />
			<Route path='/utilities/flex' component={UTILITIES.FLEX} />
			<Route path='/utilities/float' component={UTILITIES.FLOAT} />
			<Route path='/utilities/interactions' component={UTILITIES.INTERACTIONS} />
			<Route path='/utilities/overflow' component={UTILITIES.OVERFLOW} />
			<Route path='/utilities/position' component={UTILITIES.POSITION} />
			<Route path='/utilities/shadows' component={UTILITIES.SHADOWS} />
			<Route path='/utilities/sizing' component={UTILITIES.SIZING} />
			<Route path='/utilities/spacing' component={UTILITIES.SPACING} />
			<Route path='/utilities/text' component={UTILITIES.TEXT} />
			<Route path='/utilities/vertical-align' component={UTILITIES.VERTICAL_ALIGN} />
			<Route path='/utilities/visibility' component={UTILITIES.VISIBILITY} />

			<PrivateRoute component={AUTH.PAGE_404} />
		</Switch>
	);
};

export default ContentRoutes;
