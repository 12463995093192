import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-use';
import { useHistory } from 'react-router-dom';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import Breadcrumb from '../bootstrap/Breadcrumb';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Card, {
	CardActions,
	CardBody,
	CardCodeView,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../bootstrap/Card';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Button from '../bootstrap/Button';
import { componentsMenu } from '../../menu';
import GlobalServices from './Services/GlobalServices';
import Select from '../bootstrap/forms/Select';
import Option from '../bootstrap/Option';
import Icon from '../icon/Icon';
import Alert from '../bootstrap/Alert';
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';

const FormSuratMahasiswa = (props) => {
	// useMinimizeAside();
	const location = useLocation()
	const history = useHistory()

	const [modalState, setModalState] = useState(false);
	const [modalData, setModalData] = useState({
		title: `Pengajuan ${location.state.state.dataProp.nama}`,
		color: 'success',
		icon: 'Info',
		text: '',
		continueText: 'Ajukan Surat Baru',
	});

	const [loading, setLoading] = useState(false);
	const [reset, setReset] = useState(true);
	const [list, setList] = useState([]);
	
	const [listIsiFormat, setListIsiFormat] = useState([]);
	const [listIsiFormatStatic, setListIsiFormatStatic] = useState([]);
	const [listInput, setListInput] = useState([]);
	const [listInputExtra, setListInputExtra] = useState([]);
	const [dataForm, setDataForm] = useState({});
	const [staticDataForm, setStaticDataForm] = useState({});
	const [dataFormExtra, setDataFormExtra] = useState({});

	const [dokumenSyarat, setDokumenSyarat] = useState(location.state.state.dataProp.data_dokumen_syarat_surat);
	const [dataFormDokumen, setDataFormDokumen] = useState({});
	const [uploading, setUploading] = useState(false)

	const [alertStatus, setAlertStatus] = useState(false);
	const initAlertData = {
		color: 'success',
		icon: 'Info',
		text: 'Lengkapi seluruh data dengan benar',
		isLight: true
	}
	const [alertData, setAlertData] = useState(initAlertData);

	const getDataList = () => {
		GlobalServices.getAll(`administrasi/surat-instansi/format/find/${location.state.state.dataProp.uuid}`)
			.then((response) => {
				console.log(response);
				// console.log(JSON.parse(response.results[0].isi));

				if (response.results) {
					let dataIsiFormat = []
					let dataInput = []
					let initDataForm = {}
					response.results.map((i, key) => {
						const parseIsi = JSON.parse(i.isi)
						// console.log(parseIsi);
						let listIsi = parseIsi.isi.value ? parseIsi.isi.value : parseIsi.isi
						let isValueType = !Array.isArray(parseIsi.isi)
						// console.log(isValueType);
						
						dataIsiFormat = {
							...dataIsiFormat,
							[i.uuid]: {
								...i, 
								isi: parseIsi
							}
						}

						// console.log(listIsi);
						
						// let dataInput = []
						listIsi.map((j, key2) => {
							// console.log(j);
							
							if (typeof initDataForm[j.key] === 'undefined') {
								dataInput.push(j)

								// Bukan array
								if (!Array.isArray(j)) {
									let isiJ = {
										...j,
										isValueType
									}
									initDataForm = {
										...initDataForm,
										[j.key] : isiJ
									}
								} 
								// else { // array (ex: tabel)
								// 	console.log(initDataForm);

								// 	let tempIsi = [j]
								// 	if (initDataForm.multiple) {
								// 		tempIsi = [
								// 			...initDataForm.multiple,
								// 			j
								// 		]
								// 	}
									
								// 	initDataForm = {
								// 		...initDataForm,
								// 		'multiple' : tempIsi
								// 	}
								// }
							}

							// console.log(dataInput);
							return ''
						})

						return ''
					})
					

					// console.log(dataInput);
					// console.log(dataIsiFormat);
					// console.log(initDataForm);
					// setListInput(dataInput)
					setStaticDataForm(initDataForm)
					setDataForm(initDataForm)
					setListIsiFormat(dataIsiFormat)
					setListIsiFormatStatic(dataIsiFormat)
					setList(response.results);
					setLoading(false)
					setAlertData(initAlertData)
				}

			})
			.catch((e) => {
				setLoading(false)
				console.log(e);
			});
	};

	const handleInputChange = (event, isValueType = false, isArray = false, extra = false) => {
		const { name, value } = event.target;
		let names = name.split('|')
		let newListIsiFormat = {}

		// NEW
		if (extra) {
			let containerText = value
			let index = event.target.selectedIndex;
			if (typeof index !== 'undefined') {
				let innerText = event.target[index].text
				containerText = `${value}|${innerText}` 
			}
			let tmp = {
				...dataForm[name],
				value: containerText
			}
			setDataFormExtra({ ...dataFormExtra, [name]: tmp })
			console.log(dataFormExtra);
		} else {
			let uuidFormatSurat = names[1]
			let dataIndex = names[2]

			if (!isArray) {
				let newDataForm = {
					...dataForm,
					[names[0]] : {
						...dataForm[names[0]],
						value
					}
				}
				setDataForm(newDataForm)

				newListIsiFormat = listIsiFormat
				// if (isValueType) {
					
				// 	let getIsi = [...listIsiFormat[uuidFormatSurat].isi.isi.value]
				// 	getIsi[dataIndex] = {
				// 		...listIsiFormat[uuidFormatSurat].isi.isi.value[dataIndex],
				// 		value
				// 	}
				
				// 	newListIsiFormat = {
				// 		...listIsiFormat,
				// 		[uuidFormatSurat]: {
				// 			...listIsiFormat[uuidFormatSurat],
				// 			isi : {
				// 				...listIsiFormat[uuidFormatSurat].isi,
				// 				isi : {
				// 					...listIsiFormat[uuidFormatSurat].isi.isi,
				// 					value: getIsi
				// 				}
				// 			}
				// 		}
				// 	}
				// } else {
				// 	let getIsi = [...listIsiFormat[uuidFormatSurat].isi.isi]
				// 	getIsi[dataIndex] = {
				// 		...listIsiFormat[uuidFormatSurat].isi.isi[dataIndex],
				// 		value
				// 	}
				// 	// console.log(getIsi);

				// 	newListIsiFormat = {
				// 		...listIsiFormat,
				// 		[uuidFormatSurat]: {
				// 			...listIsiFormat[uuidFormatSurat],
				// 			isi : {
				// 				...listIsiFormat[uuidFormatSurat].isi,
				// 				isi : getIsi
				// 			}
				// 		}
				// 	}
				// }
			} else {
				let dataIndex2 = names[3]
	
				let getIsi = [...listIsiFormat[uuidFormatSurat].isi.isi]
				getIsi[dataIndex] = [...listIsiFormat[uuidFormatSurat].isi.isi[dataIndex]]
				
				// Set nomor otomatis
				getIsi[dataIndex].map((val, index2) => {
					if (val.key === 'no') {
						getIsi[dataIndex][index2] = {
							...listIsiFormat[uuidFormatSurat].isi.isi[dataIndex][index2],
							value: `${index2}`
						}
					}
					return ''
				})

				getIsi[dataIndex][dataIndex2] = {
					...listIsiFormat[uuidFormatSurat].isi.isi[dataIndex][dataIndex2],
					value
				}
				
				newListIsiFormat = {
					...listIsiFormat,
					[uuidFormatSurat]: {
						...listIsiFormat[uuidFormatSurat],
						isi : {
							...listIsiFormat[uuidFormatSurat].isi,
							isi : getIsi
						}
					}
				}
			}
			
			setListIsiFormat(newListIsiFormat)
			// console.log(newListIsiFormat);
		}
		// console.log();
		
	};

	const uploadFile = (file, name) => {
		if (file) {
			const type = file.type.split('/');
			if (
				// type[1] === 'jpg' || 
				// type[1] === 'jpeg' || 
				// type[1] === 'png' || 
				// type[1] === 'bmp' || 
				// type[1] === 'tiff' || 
				// type[1] === 'webp' ||
				type[1] === 'pdf'  
			) {
				const body = new FormData()
				body.append('file', file)
				console.log(file);
				console.log(body);

				setUploading(true)
	
				GlobalServices.create(body, 'upload-file/dokumen-surat-mahasiswa')
					.then((response) => {
						console.log(response);
	
						if (response.data.code === 200) {
							// setDokumenSelected(true)
							// setIsComplete(true)
	
							setDataFormDokumen({
								...dataFormDokumen, [name] : {
									label: name,
									link: response.data.results,
								}
							})

							setAlertData(initAlertData)
							
							// showNotification(
							// 	<span className='d-flex align-items-center'>
							// 		<Icon icon='Info' size='lg' className='me-1' />
							// 		<span>Berhasil Upload Dokumen</span>
							// 	</span>,
							// 	'Berhasil Upload Dokumen',
							// );
						} else {
							setAlertData({
								color: 'warning',
								icon: 'Info',
								text: 'Dokumen tidak berhasil diupload, silahkan pilih file dokumen kembali',
								isLight: false
							})
							// setDokumenSelected(false)
							// setIsComplete(false)
							
							// showNotification(
							// 	<span className='d-flex align-items-center'>
							// 		<Icon icon='Warning' size='lg' className='me-1' />
							// 		<span>Gagal Upload Dokumen</span>
							// 	</span>,
							// 	'Gagal Upload Dokumen',
							// );
						}
						setUploading(false)
					})
					.catch((e) => {
						setUploading(false)
						console.log(e);
					});
	
			} else {
				setAlertData({
					color: 'warning',
					icon: 'Info',
					text: 'Silahkan upload file dengan format .pdf',
					isLight: false
				})
			}
		}
	}

	const handleChangeFile = (event) => {
		console.log(event);
		console.log(event.target.files[0]);
		const { name, files } = event.target;
		let file = files[0]
		// setIsComplete(false)
		uploadFile(file, name)
		setAlertData({
			color: 'warning',
			icon: 'Info',
			text: 'Sedang mengupload file dokumen',
			isLight: false
		})
	}

	const _types = [
		'text',
		'email',
		'password',
		'file',
		'color',
		'date',
		'datetime-local',
		'hidden',
		'month',
		'number',
		'range',
		'search',
		'tel',
		'time',
		'url',
		'week',
	];

	const handleSubmit = () => {
		let newDataFormatSurat = {}
		let newDataFormExtra = {}

		let validated = true
		// Check validated for data non array (ex: tabel)
		Object.keys(dataForm).map((key) => {
			let stat = false

			let dataInput = dataForm[key]
			newDataFormatSurat = {
				...newDataFormatSurat,
				[dataInput.key]: {
					label: dataInput.label,
					value: dataInput.value
				}
			}

			// Jika ada key di dataFormExtra, maka set valuenya
			console.log(dataFormExtra);
			if (typeof dataFormExtra[key] !== 'undefined') {
				newDataFormExtra = {
					...dataFormExtra,
					[key] : dataInput.value
				}
				console.log(newDataFormExtra);
				setDataFormExtra(newDataFormExtra)
			}
			
			if (dataInput.value !== '' && dataInput.value !== null) {
				stat = true
			}
			validated = validated && stat
			return ''
		})
		console.log(validated);

		// NEW CHECK VALIDATED
		// for array types (ex : table)
		Object.keys(listIsiFormat).map((uuidFormatSurat) => {
			const parseIsi = listIsiFormat[uuidFormatSurat].isi
			const listIsi = parseIsi.isi.value ? parseIsi.isi.value : parseIsi.isi
			
			listIsi.map((dataInput, key2) => {
				let stat = true

				// Jika bukan array (tabel)
				if (!Array.isArray(dataInput)) {
					// No action
					// stat = false
					// newDataFormatSurat = {
					// 	...newDataFormatSurat,
					// 	[dataInput.key]: {
					// 		label: dataInput.label,
					// 		value: dataInput.value
					// 	}
					// }
					// if (dataInput.value !== '' && dataInput.value !== null) {
					// 	stat = true
					// }
				} else {
					stat = false
					let miniValidated = true

					dataInput.map((isiDataInput, key3) => {
						if (key2 === 0) {
							newDataFormatSurat = {
								...newDataFormatSurat,
								[isiDataInput.key]: {
									label: isiDataInput.label,
									value: isiDataInput.value
								}
							}
						}

						let stat2 = false
						if (isiDataInput.key === 'no') {
							stat2 = true
						} else if (isiDataInput.value !== '' && isiDataInput.value !== null) {
							stat2 = true
						}

						miniValidated = miniValidated && stat2
						return ''
					})

					stat = miniValidated
				}
				
				validated = validated && stat
				return ''
			})
			return ''
		})
		console.log(validated);
		console.log(newDataFormatSurat);
		// END NEW CHECK VALIDATED

		let validatedExtra = true

		// cek listInputExtra
		Object.keys(newDataFormExtra).map((i, key) => {
			let stat = false
			
			if (typeof newDataFormExtra[i] === 'object') {
				if (Object.keys(newDataFormExtra[i]).length > 0) {
					const val = newDataFormExtra[i].value
					const newVal = val.split("")
					if (newVal[0] !== '' && newVal[0] !== null) {
						stat = true
					}
				}
			} else if (newDataFormExtra[i] !== '' && newDataFormExtra[i] !== null) {
				stat = true
			}
			console.log(stat);
			console.log(validatedExtra);

			validatedExtra = validatedExtra && stat
			console.log(validatedExtra);

			return ''
		})

		console.log(validatedExtra);


		let validatedDokumen = true
		// cek list dokumen 
		Object.keys(dataFormDokumen).map((i, key) => {
			let stat = false
			if (dataFormDokumen[i].link !== '') {
				stat = true
			}
			validatedDokumen = validatedDokumen && stat

			return ''
		})
		console.log(dataFormDokumen);
		console.log(validatedDokumen);

		if (validated && validatedExtra && validatedDokumen) {
			setLoading(true)
			setAlertData({
				color: 'primary',
				icon: 'Info',
				text: 'Pengajuan Surat Sedang Diproses',
				isLight: true
			})
			let nim = ''
			let nama = ''
			let prodi = ''

			let pengajuan_detail = []

			// set value
			Object.keys(listIsiFormat).map((uuid_surat_instansi_format_surat) => {
				const parseIsi = listIsiFormat[uuid_surat_instansi_format_surat].isi
				let listIsi = parseIsi.isi.value ? parseIsi.isi.value : parseIsi.isi
				let isValueType = !Array.isArray(parseIsi.isi)

				let tmpIsiFormat = null
				listIsi.map((dataInput, key2) => {
					if (!Array.isArray(dataInput)) {
						if (isValueType) {
							let getIsi = [...listIsiFormat[uuid_surat_instansi_format_surat].isi.isi.value]
							getIsi[key2] = {
								...listIsiFormat[uuid_surat_instansi_format_surat].isi.isi.value[key2],
								value : newDataFormatSurat[dataInput.key].value
							}

							if (tmpIsiFormat !== null) {
								getIsi = [...tmpIsiFormat[uuid_surat_instansi_format_surat].isi.isi.value]
								getIsi[key2] = {
									...tmpIsiFormat[uuid_surat_instansi_format_surat].isi.isi.value[key2],
									value : newDataFormatSurat[dataInput.key].value
								}
							}
	
							tmpIsiFormat = {
								...listIsiFormat,
								[uuid_surat_instansi_format_surat]: {
									...listIsiFormat[uuid_surat_instansi_format_surat],
									isi : {
										...listIsiFormat[uuid_surat_instansi_format_surat].isi,
										isi : {
											...listIsiFormat[uuid_surat_instansi_format_surat].isi.isi,
											value: getIsi
										}
									}
								}
							}
						} else {
							// LANJUTKAN YANG BUKAN VALUETYPE  

							let getIsi = [...listIsiFormat[uuid_surat_instansi_format_surat].isi.isi]
							getIsi[key2] = {
								...listIsiFormat[uuid_surat_instansi_format_surat].isi.isi[key2],
								value : newDataFormatSurat[dataInput.key].value
							}

							if (tmpIsiFormat !== null) {
								getIsi = [...tmpIsiFormat[uuid_surat_instansi_format_surat].isi.isi]
								getIsi[key2] = {
									...tmpIsiFormat[uuid_surat_instansi_format_surat].isi.isi[key2],
									value : newDataFormatSurat[dataInput.key].value
								}
							}

							tmpIsiFormat = {
								...listIsiFormat,
								[uuid_surat_instansi_format_surat]: {
									...listIsiFormat[uuid_surat_instansi_format_surat],
									isi : {
										...listIsiFormat[uuid_surat_instansi_format_surat].isi,
										isi : getIsi
									}
								}
							}
						}
					}
					return ''
				})

				if (tmpIsiFormat) {
					let isiFormat = tmpIsiFormat[uuid_surat_instansi_format_surat].isi
					pengajuan_detail.push({
						uuid_surat_instansi_format_surat,
						isi: JSON.stringify(isiFormat)
					})
				}
				return ''

			})

			// set pengajuan_detail
			// -------------- NEW ADD PENGAJUAN DETAIL
			Object.keys(listIsiFormat).map((uuid_surat_instansi_format_surat) => {
				// hanya yang tipe array yang diset disini (PENTING)
				const parseIsi = listIsiFormat[uuid_surat_instansi_format_surat].isi
				let listIsi = parseIsi.isi.value ? parseIsi.isi.value : parseIsi.isi

				let isTabel = false

				listIsi.map((dataInput) => {
					if (Array.isArray(dataInput)) {
						isTabel = true
					}
					return ''
				})

				if (isTabel) {
					const isiFormat = listIsiFormat[uuid_surat_instansi_format_surat].isi
					pengajuan_detail.push({
						uuid_surat_instansi_format_surat,
						isi: JSON.stringify(isiFormat)
					})
				}

				return ''
			})
			// --------------- END NEW ADD PENGAJUAN DETAIL

			console.log(pengajuan_detail);

			// set syarat surat
			let syarat_surat = []
			Object.keys(dataFormDokumen).map((i, key) => {
				syarat_surat.push(dataFormDokumen[i])
				return ''
			})
			console.log(syarat_surat);
	
			// const prodi = dataForm.prodi ? dataForm.prodi.value : ''
			let nip_dosen_pengampu = ''
			let nomor_hp = ''
			let fakultas = ''
			let email = ''
			let nama_dosen = ''
			if (dataFormExtra.nip_dosen_pengampu) {
				let valNip = dataFormExtra.nip_dosen_pengampu.value.split("|")
				nip_dosen_pengampu = `${valNip[0]}`
				nama_dosen = `${valNip[1]}`
			}
			if (typeof newDataFormatSurat.email !== 'undefined') {
				email = newDataFormatSurat.email.value
			} else if (dataFormExtra.email) {
				email = dataFormExtra.email.value
			}
			if (typeof newDataFormatSurat.no_hp !== 'undefined') {
				nomor_hp = newDataFormatSurat.no_hp.value
			} else if (dataFormExtra.no_hp) {
				nomor_hp = dataFormExtra.no_hp.value
			}
			if (typeof newDataFormatSurat.fakultas !== 'undefined') {
				fakultas = newDataFormatSurat.fakultas.value
			} else if (dataFormExtra.fakultas) {
				fakultas = dataFormExtra.fakultas.value
			}
			if (typeof newDataFormatSurat.prodi !== 'undefined') {
				prodi = newDataFormatSurat.prodi.value
			} else if (dataFormExtra.prodi) {
				prodi = dataFormExtra.prodi.value
				let valNip = dataFormExtra.prodi.value.split("|")
				prodi = `${valNip[0]}`
			}
			if (typeof newDataFormatSurat.nim !== 'undefined') {
				nim = newDataFormatSurat.nim.value
			}
			if (typeof newDataFormatSurat.nama !== 'undefined') {
				nama = newDataFormatSurat.nama.value
			}
	
			let bodyData = {
				uuid_surat_instansi: location.state.state.dataProp.uuid,
				nim,
				nama,
				prodi,
				email,
				nip_dosen_pengampu,
				nomor_hp,
				fakultas,
				pengajuan_detail,
				syarat_surat: JSON.stringify(syarat_surat),
			}
	
			console.log(bodyData);

			// Post to WS
			GlobalServices.create(bodyData, `administrasi/pengajuan-surat-mahasiswa/create`)
				.then((response) => {
					console.log(response);

					// Jika berhasil
					if (response.data.code === 201) {
						setLoading(false)
						// setAlertData({
						// 	color: 'success',
						// 	icon: 'VerifiedUser',
						// 	text: 'Surat berhasil diajukan',
						// 	isLight: false
						// })
						// Untuk reset data dri useEffect
						setReset(!reset)
						document.getElementById('formSuratMahasiswa').reset()
						history.push({
							pathname: "/detail-pengajuan-mahasiswa",
							state: { 
								dataProp: location.state.state.dataProp,
								detailSurat: newDataFormatSurat,
								detailPengajuan: response.data.results,
								detailIsiFormatSurat: listIsiFormat,
								nip_dosen_pengampu,
								nama_dosen
							},
						})
						setModalData({
							...modalData,
							color: 'success',
							icon: 'VerifiedUser',
							text: 'Surat berhasil diajukan',
							continueText: 'Ajukan surat baru'
						})
						setModalState(true)
					} else {
						setLoading(false)
						// setAlertData({
						// 	color: 'danger',
						// 	icon: 'Report',
						// 	text: 'Surat gagal diajukan',
						// 	isLight: false
						// })
						setModalData({
							...modalData,
							color: 'danger',
							icon: 'Report',
							text: 'Surat gagal diajukan',
							continueText: 'Ajukan surat kembali'
						})
						setModalState(true)
					}
				})
				.catch((e) => {
					setLoading(false)
					setAlertData({
						color: 'warning',
						icon: 'Info',
						text: 'Terjadi kesalahan',
						isLight: true
					})
					console.log(e);
				});
		} else {
			setAlertData({
				color: 'warning',
				icon: 'Info',
				text: 'Isi seluruh data yang diperlukan dengan benar',
				isLight: false
			})
		}

	};

	useEffect(() => {
		setLoading(true)
		setAlertData({
			color: 'success',
			icon: 'Info',
			text: 'Sedang memuat data',
			isLight: false
		})
		console.log(location.state.state);

		async function setListKaryawan() {
			let dataInputExtra = [
				...listInputExtra
			]
			let formExtra = {
				...dataFormExtra
			}

			let listKaryawan = await GlobalServices.getAll(`karyawan/instansi/1450e8a0-6887-45fe-a0da-f56e6e496e0f/list`).then((response)=>{
				console.log(response.results);
				let karyawan = []
				response.results.map((val) => {
					karyawan.push({
						label: val.nama,
						value: val.uuid
					})
					return''
				})
				return karyawan
			}).catch((e) => {
				console.log(e);
				return []
			})

			dataInputExtra.push({
				key: 'email',
				label: 'Email',
				uuid_komponen: 'nothing',
				type: 'email',
				value: ''
			})
			dataInputExtra.push({
				key: 'no_hp',
				label: 'No.HP',
				uuid_komponen: 'nothing',
				type: 'number',
				max_length: 13,
				value: ''
			})
			// Jika surat izin riset
			if (location.state.state.dataProp && location.state.state.dataProp.uuid === '38418933-8f0f-446c-b699-be54261b348f') {
				dataInputExtra.push({
					key: 'prodi',
					label: 'Prodi',
					uuid_komponen: 'nothing',
					type: 'text',
					value: ''
				})
				// dataInputExtra.push({
				// 	key: 'prodi',
				// 	label: 'Prodi',
				// 	uuid_komponen: 'nothing',
				// 	type: 'select',
				// 	option: [
				// 		{
				// 			label: 'Teknik Informatika',
				// 			value: 'Teknik Informatika',
				// 		},
				// 		{
				// 			label: 'Teknik Industri',
				// 			value: 'Teknik Industri',
				// 		},
				// 		{
				// 			label: 'Teknik Elektro',
				// 			value: 'Teknik Elektro',
				// 		},
				// 		{
				// 			label: 'Matematika',
				// 			value: 'Matematika',
				// 		},
				// 		{
				// 			label: 'Sistem Informasi',
				// 			value: 'Sistem Informasi',
				// 		}
				// 	],
				// 	value: ''
				// })
				dataInputExtra.push({
					key: 'fakultas',
					label: 'Fakultas',
					uuid_komponen: 'nothing',
					type: 'text',
					value: ''
				})
			}
			dataInputExtra.push({
				key: 'nip_dosen_pengampu',
				label: 'Dosen Pengampu',
				uuid_komponen: 'nothing',
				type: 'select',
				option: listKaryawan,
				value: ''
			})
			formExtra = {
				...formExtra,
				nip_dosen_pengampu: '',
				email: '',
			}

			setListInputExtra(dataInputExtra)
			setDataFormExtra(formExtra)
		}
		
		getDataList()

		if (location.state.state.dataProp) {
			// Set listInputExtra
			const jenisDisposisi = location.state.state.dataProp.data_surat_instansi_disposisi.data_disposisi_instansi.nama_disposisi

			if (jenisDisposisi.toLowerCase() === 'disposisi dosen pengampu') {
				setListKaryawan()
			} else {
				let dataInputExtra = [
					...listInputExtra
				]
				dataInputExtra.push({
					key: 'email',
					label: 'Email',
					uuid_komponen: 'nothing',
					type: 'email',
					value: ''
				})
				dataInputExtra.push({
					key: 'no_hp',
					label: 'No.HP',
					uuid_komponen: 'nothing',
					type: 'number',
					max_length: 13,
					value: ''
				})
				// Jika surat izin riset
				if (location.state.state.dataProp && location.state.state.dataProp.uuid === '38418933-8f0f-446c-b699-be54261b348f') {
					dataInputExtra.push({
						key: 'prodi',
						label: 'Prodi',
						uuid_komponen: 'nothing',
						type: 'text',
						value: ''
					})
					// dataInputExtra.push({
					// 	key: 'prodi',
					// 	label: 'Prodi',
					// 	uuid_komponen: 'nothing',
					// 	type: 'select',
					// 	option: [
					// 		{
					// 			label: 'Teknik Informatika',
					// 			value: 'Teknik Informatika',
					// 		},
					// 		{
					// 			label: 'Teknik Industri',
					// 			value: 'Teknik Industri',
					// 		},
					// 		{
					// 			label: 'Teknik Elektro',
					// 			value: 'Teknik Elektro',
					// 		},
					// 		{
					// 			label: 'Matematika',
					// 			value: 'Matematika',
					// 		},
					// 		{
					// 			label: 'Sistem Informasi',
					// 			value: 'Sistem Informasi',
					// 		}
					// 	],
					// 	value: ''
					// })
					dataInputExtra.push({
						key: 'fakultas',
						label: 'Fakultas',
						uuid_komponen: 'nothing',
						type: 'text',
						value: ''
					})
				}
				let formExtra = {
					...formExtra,
					email: '',
				}
				setListInputExtra(dataInputExtra)
				setDataFormExtra(formExtra)
			} 

			// set dataFormDokumen
			let formDokumen = {}
			dokumenSyarat.map((i, key) => {
				formDokumen = {
					...formDokumen,
					[i.data_dokumen.nama] : {
						label: i.data_dokumen.nama,
						link: '',
					}
				}
				return ''
			})
			setDataFormDokumen(formDokumen)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ reset ]);

	return (
		<PageWrapper title={`Detail Surat ${location.state.state.dataProp.nama}`}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						isToHome={false}
						list={[
							{ title: 'Dashboard', to: '/' },
							{ title: `Surat ${location.state.state.dataProp.nama_alias}`, to: '#' },
						]}
					/>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div className='row' style={{ justifyContent: 'center' }}>
					{/* tpye */}
					<div className='col-lg-6'>
						<Card stretch>
							<form id="formSuratMahasiswa" onSubmit={(e) => e.preventDefault()}>
								<CardHeader style={{ paddingBottom: 0, display: 'flex', flexDirection: 'column' }}>
									<div className="row" style={{ width: '100%', marginBottom: '0px' }}>
										<div className="col-lg-12" style={{ marginBottom: '17px' }}>
											<CardLabel>
												<CardTitle style={{ textAlign: 'center' }}>
													Surat {location.state.state.dataProp.nama_alias}
												</CardTitle>
											</CardLabel>
										</div>
									</div>
									<div className="row" style={{ width: '100%' }}>
											<div className="col-lg-12">
												<Alert color={alertData.color} icon={alertData.icon} isLight style={{ width: '100%', margin: 0 }}>
													{alertData.text}
												</Alert>
											</div>
										</div>
								</CardHeader>

								{loading ? (
									<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
										<br/><br/><br/><br/>
										<div className='row g-3'>
										{Object.keys(COLORS).map((color) => (
											<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
												<Spinner color={COLORS[color]} isSmall isGrow />
											</div>
										))}
										</div>
										<br/><br/><br/><br/>
									</div>
								) : (
									<>
										<CardBody>
											{/* NEW */}
											{listInputExtra.map((dataInput, key2) => {
												let show = true
												const currentKey = dataInput.key
												if (typeof dataForm[currentKey] !== 'undefined') {
													show = false
												}
												if (show) {
													return (
														<div key={`${key2+ 1}`} className="col-12" >
															<FormGroup
																id={`exampleTypes--${key2+ 1}`}
																label={dataInput.label}
																isColForLabel
																labelClassName='col-sm-3 text-capitalize'
																style={{ marginBottom: '15px' }}
																childWrapperClassName='col-sm-9'>
																{dataInput.type !== 'select' ? (
																	<Input
																		type={dataInput.type ? dataInput.type : 'text'}
																		name={`${dataInput.key}`}
																		defaultValue={dataInput.value}
																		maxLength={dataInput.max_length ? dataInput.max_length : ''}
																		readOnly={dataInput.value !== '' && dataInput.value !== null}
																		onChange={(e) => handleInputChange(e, false, false, true)}
																		placeholder={`Masukkan data ${dataInput.label}`}
																		aria-label='.form-control-lg example'
																		required
																	/>
																) : (
																	<Select
																		id={`largeSelect--${key2+ 1}`}
																		ariaLabel='Default select example'
																		placeholder={`Pilih ${dataInput.label}`}
																		// name={`${dataInput.key}|${dataInput.komponen}|${dataInput.uuid_surat_instansi_format_surat}`}
																		name={`${dataInput.key}`}
																		// onChange={handleSelectChangeInstansi}
																		onChange={(e) => handleInputChange(e, false, false, true)}
																		// value={dataForm[`${dataInput.key}`] ? dataForm[`${dataInput.key}`].value : ''}
																		required
																		// size='md'
																		>
																		{dataInput.option.map((item, key) => (
																			<Option value={item.value}>{item.label}</Option>
																		))}
																	</Select>
																)}
															</FormGroup>
															
														</div>
													)
												}
												return ''
											})}
											{Object.keys(dataForm).map((key, key2) => {
												let dataInput = dataForm[key]
												let dataInputStatic = staticDataForm[key]
												return (
													<div key={`${key2+ 1}`} className="col-12" >
														<FormGroup
															id={`exampleTypes--${key2+ 1}`}
															label={dataInput.label}
															isColForLabel
															labelClassName='col-sm-3 text-capitalize'
															style={{ marginBottom: '15px' }}
															childWrapperClassName='col-sm-9'>
															{dataInput.type !== 'select' ? (
																<Input
																	type={dataInput.type ? dataInput.type : 'text'}
																	// name={`${dataInput.key}|${uuidFormatSurat}|${key2}`}
																	name={`${dataInput.key}|${key2}`}
																	defaultValue={dataInput.value}
																	// readOnly={dataInput.value !== '' && dataInput.value !== null}
																	// readOnly={dataInputStatic.value !== '' && dataInputStatic.value !== null}
																	readOnly={dataInputStatic.value !== '' && dataInputStatic.value !== null}
																	onChange={(e) => handleInputChange(e, dataInput.isValueType)}
																	placeholder={`Masukkan data ${dataInput.label}`}
																	aria-label='.form-control-lg example'
																	required
																/>
															) : (
																<Select
																	id={`largeSelect--${key2+ 1}`}
																	ariaLabel='Default select example'
																	placeholder={`Pilih ${dataInput.label}`}
																	// name={`${dataInput.key}|${dataInput.komponen}|${dataInput.uuid_surat_instansi_format_surat}`}
																	// name={`${dataInput.key}|${uuidFormatSurat}|${key2}`}
																	name={`${dataInput.key}|${key2}`}
																	// onChange={handleSelectChangeInstansi}
																	onChange={(e) => handleInputChange(e, dataInput.isValueType)}
																	// value={dataForm[`${dataInput.key}`] ? dataForm[`${dataInput.key}`].value : ''}
																	required
																	// size='md'
																	>
																	{dataInput.option.map((item) => (
																		<Option value={item}>{item}</Option>
																	))}
																</Select>
															)}
														</FormGroup>
														
													</div>
												)
											})}
											{Object.keys(listIsiFormatStatic).map((uuidFormatSurat) => {
												const parseIsi = listIsiFormat[uuidFormatSurat].isi
												const parseIsiStatic = listIsiFormatStatic[uuidFormatSurat].isi
												let listIsi = parseIsi.isi.value ? parseIsi.isi.value : parseIsi.isi
												let listIsiStatic = parseIsiStatic.isi.value ? parseIsiStatic.isi.value : parseIsiStatic.isi
												let isValueType = !Array.isArray(parseIsi.isi)



												// Looping hanya untuk dataInput array (ex: tabel)
												return listIsi.map((dataInput, key2) => {
													// let dataInputStatic = listIsiStatic[key2]
													// if (!Array.isArray(dataInput)) {
													// 	return (
													// 		<div key={`${key2+ 1}`} className="col-12" >
													// 			<FormGroup
													// 				id={`exampleTypes--${key2+ 1}`}
													// 				label={dataInput.label}
													// 				isColForLabel
													// 				labelClassName='col-sm-3 text-capitalize'
													// 				style={{ marginBottom: '15px' }}
													// 				childWrapperClassName='col-sm-9'>
													// 				{dataInput.type !== 'select' ? (
													// 					<Input
													// 						type={dataInput.type ? dataInput.type : 'text'}
													// 						name={`${dataInput.key}|${uuidFormatSurat}|${key2}`}
													// 						defaultValue={dataInput.value}
													// 						// readOnly={dataInput.value !== '' && dataInput.value !== null}
													// 						readOnly={dataInputStatic.value !== '' && dataInputStatic.value !== null}
													// 						onChange={(e) => handleInputChange(e, isValueType)}
													// 						placeholder={`Masukkan data ${dataInput.label}`}
													// 						aria-label='.form-control-lg example'
													// 						required
													// 					/>
													// 				) : (
													// 					<Select
													// 						id={`largeSelect--${key2+ 1}`}
													// 						ariaLabel='Default select example'
													// 						placeholder={`Pilih ${dataInput.label}`}
													// 						// name={`${dataInput.key}|${dataInput.komponen}|${dataInput.uuid_surat_instansi_format_surat}`}
													// 						name={`${dataInput.key}|${uuidFormatSurat}|${key2}`}
													// 						// onChange={handleSelectChangeInstansi}
													// 						onChange={(e) => handleInputChange(e, isValueType)}
													// 						// value={dataForm[`${dataInput.key}`] ? dataForm[`${dataInput.key}`].value : ''}
													// 						required
													// 						// size='md'
													// 						>
													// 						{dataInput.option.map((item) => (
													// 							<Option value={item}>{item}</Option>
													// 						))}
													// 					</Select>
													// 				)}
													// 			</FormGroup>
																
													// 		</div>
													// 	)
													// }
													if (Array.isArray(dataInput)) {
													const labelRow = key2 === 0 ? '(Pemohon)' : '';
													// if array (ex: table)
													return (
														<>
															<hr/>
															<div className="row" style={{ marginBottom: '15px' }}>
																<label className="form-label col-form-label col-sm-12 text-capitalize" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
																	{`Data ${key2+1} ${labelRow}`}
																	{key2 !== 0 ? (
																		<Button
																			color='danger'
																			icon='Delete'
																			shadow='none'
																			hoverShadow='lg'
																			onClick={() => {
																				// For Static
																				let getIsiStatic = [...listIsiFormatStatic[uuidFormatSurat].isi.isi]
																				// getIsiStatic.push(getIsiStatic[0])
																				delete getIsiStatic[key2]

																				let mappingIsiStatic = []
																				getIsiStatic.map((dt) => {
																					mappingIsiStatic.push(dt)
																					return ''
																				})

																				const newListIsiFormatStatic = {
																					...listIsiFormatStatic,
																					[uuidFormatSurat]: {
																						...listIsiFormatStatic[uuidFormatSurat],
																						isi : {
																							...listIsiFormatStatic[uuidFormatSurat].isi,
																							isi : mappingIsiStatic
																						}
																					}
																				}

																				// For Dynamic
																				let getIsi = [...listIsiFormat[uuidFormatSurat].isi.isi]
																				// getIsi.push(getIsi[0])
																				delete getIsi[key2]

																				let mappingIsi = []
																				getIsi.map((dt) => {
																					mappingIsi.push(dt)
																					return ''
																				})

																				const newListIsiFormat = {
																					...listIsiFormat,
																					[uuidFormatSurat]: {
																						...listIsiFormat[uuidFormatSurat],
																						isi : {
																							...listIsiFormat[uuidFormatSurat].isi,
																							isi : mappingIsi
																						}
																					}
																				}

																				setListIsiFormatStatic(newListIsiFormatStatic)
																				setListIsiFormat(newListIsiFormat)
																			}}
																		/>
																	) : ''}
																</label>
															</div>
															{dataInput.map((isiDataInput, key3) => {
																if (isiDataInput.key !== 'no') {
																	return (
																		<div key={`isiDataInput-${key2+ 1}-${key3}`} className="col-12" >
																			<FormGroup
																				label={isiDataInput.label}
																				isColForLabel
																				labelClassName='col-sm-3 text-capitalize'
																				style={{ marginBottom: '15px' }}
																				childWrapperClassName='col-sm-9'>
																				{isiDataInput.type !== 'select' ? (
																					<Input
																						id={`exampleTypes--${key2+ 1}-${key3}`}
																						type={isiDataInput.type ? isiDataInput.type : 'text'}
																						// name={`${isiDataInput.key}|${key3}`}
																						name={`${isiDataInput.key}|${uuidFormatSurat}|${key2}|${key3}`}
																						defaultValue={isiDataInput.value}
																						value={isiDataInput.value}
																						// khusus tabel readonly dimatikan
																						// readOnly={isiDataInput.value !== '' && isiDataInput.value !== null}
																						onChange={(e) => handleInputChange(e, false, true)}
																						placeholder={`Masukkan data ${isiDataInput.label}`}
																						aria-label='.form-control-lg example'
																						required
																					/>
																				) : (
																					<Select
																						id={`largeSelect--${key2+ 1}-${key3}`}
																						ariaLabel='Default select example'
																						placeholder={`Pilih ${isiDataInput.label}`}
																						// name={`${isiDataInput.key}|${key3}`}
																						name={`${isiDataInput.key}|${uuidFormatSurat}|${key2}|${key3}`}
																						onChange={(e) => handleInputChange(e, false, true)}
																						// value={dataForm[`${isiDataInput.key}`] ? dataForm[`${isiDataInput.key}`].value : ''}
																						required
																						// size='md'
																						>
																						{isiDataInput.option.map((item) => (
																							<Option value={item}>{item}</Option>
																						))}
																					</Select>
																				)}
																			</FormGroup>
																			
																		</div>
																	)
																}
																return ''
															})}
															{key2 === listIsi.length-1 ? (
																<div className="row">
																	<div className="col-sm-12">
																		<Button
																			color='success'
																			// icon='VerifiedUser'
																			shadow='none'
																			hoverShadow='lg'
																			onClick={() => {
																				// For Static
																				let getIsiStatic = [...listIsiFormatStatic[uuidFormatSurat].isi.isi]
																				getIsiStatic.push(getIsiStatic[0])

																				const newListIsiFormatStatic = {
																					...listIsiFormatStatic,
																					[uuidFormatSurat]: {
																						...listIsiFormatStatic[uuidFormatSurat],
																						isi : {
																							...listIsiFormatStatic[uuidFormatSurat].isi,
																							isi : getIsiStatic
																						}
																					}
																				}

																				// For Dynamic
																				let getIsi = [...listIsiFormat[uuidFormatSurat].isi.isi]
																				getIsi.push(getIsi[0])

																				const newListIsiFormat = {
																					...listIsiFormat,
																					[uuidFormatSurat]: {
																						...listIsiFormat[uuidFormatSurat],
																						isi : {
																							...listIsiFormat[uuidFormatSurat].isi,
																							isi : getIsi
																						}
																					}
																				}

																				setListIsiFormatStatic(newListIsiFormatStatic)
																				setListIsiFormat(newListIsiFormat)
																				console.log(newListIsiFormatStatic);
																				console.log(newListIsiFormat);
																			}}>
																			Tambah Data
																		</Button>
																	</div>
																</div>
															) : ''}
														</>
													)
													}
													return ''
												})
											})}
											{/* END NEW */}
											{dokumenSyarat.map((i, key) => (
												<div key={i} className="col-12" >
													<FormGroup 
														label={`Dokumen ${i.data_dokumen.nama}`}
														isColForLabel
														labelClassName='col-sm-3 text-capitalize'
														childWrapperClassName='col-sm-9' >
														<Input
															id={`dokumen${i}`}
															name={i.data_dokumen.nama}
															type='file'
															// accept='image/*,.pdf'
															accept='.pdf'
															onChange={handleChangeFile}
															required
															// value={surat.dokumen}
														/>
													</FormGroup>
												</div>
											))}
										</CardBody>
										<CardFooter style={{ justifyContent: 'center' }}>
											<div className="row" style={{ width:'100%' }}>
												{/* <div className="col-sm-9">{' '}</div> */}
												<div className="col-sm-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
													<Button color="primary" isLight onClick={() => props.history.goBack()} >
														Kembali
													</Button>
													<Button color='info' type='submit' onClick={handleSubmit} disabled={uploading} >
														Ajukan
													</Button>
												</div>
											</div>
										</CardFooter>
									</>
								)}
							
							</form>
						</Card>
					</div>
				</div>
				
				{/* MODAL */}
				<Modal
					isOpen={modalState}
					setIsOpen={setModalState}
					titleId='exampleModalLabel'
					isCentered
					color="info"
					// size="lg"
					isAnimation>
					<ModalHeader>
						<ModalTitle id='exampleModalLabel'>{modalData.title}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<Alert color={modalData.color} icon={modalData.icon} isLight style={{ width: '100%', margin: 0, fontSize: '14px' }}>
							{modalData.text}
						</Alert>
						{/* {modalData.body} */}
					</ModalBody>
					<ModalFooter>
						<Button
							color='info'
							isOutline
							className='border-0'
							onClick={() => setModalState(false)}>
							{modalData.continueText}
						</Button>
						<Button 
							color='info' 
							icon='Save' 
							tag='a'
							to={{
								pathname: "/dashboard",
							}}>
							Kembali ke dashboard
						</Button>
					</ModalFooter>
				</Modal>
			</Page>
		</PageWrapper>
	);
};
// Login.propTypes = {
// 	isSignUp: PropTypes.bool,
// };
// Login.defaultProps = {
// 	isSignUp: false,
// };

export default FormSuratMahasiswa;