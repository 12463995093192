import React, { useEffect, useState } from 'react';
import Select from "react-select";
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import GlobalServices from '../Services/GlobalServices';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import SelectData from '../Services/SelectData';

const ModalData = ({ id, isOpen, setIsOpen, statusRefresh, setDetail, listTujuanSurat, title, action }) => {
	const token = sessionStorage.getItem('access_token');
	const formData = {
		uuid: '',
		uuid_instansi: '',
		nama_disposisi: '',
		status: {},
	};
	
	const [dataValues, setDataValues] = useState(formData);
	const [listStatus, setListStatus] = useState(SelectData.statusDisposisi);
	const [selectedStatus, setSelectedStatus] = useState(null);

	useEffect(() => {
		if (setDetail) {
			setDataValues(setDetail)

			const getSelectedStatus = setDetail.status;
			setSelectedStatus(getSelectedStatus)
		}
	}, [setDetail])

	const handleSelectChange = (event, param) => {
		if (event) {
			switch (param) {
				case 'status':
					setSelectedStatus(event);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'status':
					setSelectedStatus(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "status") {
				let newArray = listStatus;	
				newArray = newArray.filter(day => day !== e);
				setListStatus(newArray)
			}
		}
	};

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setDataValues({ ...dataValues, [name]: value });
	};

	const handleSubmit = () => {
		const data = {
			...dataValues, 
			status: selectedStatus.value,
			uuid_instansi: id
		}

		if (action === 'Tambah') {
			delete data.uuid
		}

		let validated = true

		Object.keys(data).map((i, key) => {
			let stat = false
			if (typeof data[i] === 'string') {
				if (data[i] !== '') {
					stat = true
				}
			} else if (data[i].length > 0) {
				stat = true
			}
			
			validated = validated && stat

			return ''
		})

		if (validated) {
			if (action === 'Tambah') {
				GlobalServices.create(data, `disposisi-instansi`, token)
					.then((response) => {
						if (response.data.code === 201) {
							setIsOpen(false);
							// setSelectedInstansi(null);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil {title}</span>
								</span>,
								`Berhasil ${title}`,
							);
							tutupForm();
							statusRefresh();
						} else {
							// setIsOpen(false);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal {title}</span>
								</span>,
								`Gagal ${title}`,
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				GlobalServices.update(data, `disposisi-instansi`, token)
					.then((response) => {
	
						if (response.data.code === 201) {
							setIsOpen(false);
							// setSelectedInstansi(null);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil {title}</span>
								</span>,
								`Berhasil ${title}`,
							);
							tutupForm();
							statusRefresh();
						} else {
							// setIsOpen(false);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal {title}</span>
								</span>,
								`Gagal ${title}`,
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			}
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal {title}</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	const tutupForm = () => {
		setSelectedStatus(null);
		setDataValues(formData);
	};

	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
					<ModalHeader setIsOpen={setIsOpen} className='p-4'>
						<ModalTitle id={id}>{title}</ModalTitle>
					</ModalHeader>
					<ModalBody className='px-4'>
						<div className='row g-4'>
							<FormGroup id='nama_disposisi' label='Nama Disposisi' className='col-md-12'>
								<Input
									type='text'
									name='nama_disposisi'
									// onChange={formik.handleChange}
									value={dataValues.nama_disposisi}
									onChange={handleInputChange}
									required
								/>
							</FormGroup>
							<FormGroup id='status' label='Status Disposisi' className='col-md-12'>
								<Select
									inputId="status"
									classNamePrefix="select"
									name="status"
									placeholder="Pilih Status"
									isClearable
									required
									defaultValue={selectedStatus}
									value={selectedStatus}
									options={listStatus}
									onInputChange={e => handleInputSelect(e, "status")}
									onChange={e => {
										handleSelectChange(e, "status");
									}}
								/>
							</FormGroup>
						</div>
					</ModalBody>
					<ModalFooter className='px-4 pb-4'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => {
								setIsOpen(false);
								tutupForm();
								// setSelectedInstansi(null);
							}}>
							Batal
						</Button>
						{/* <Button color='info' onClick={formik.handleSubmit}> */}
						<Button color='info' type='submit' onClick={handleSubmit}>
							Simpan
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalData.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
	setDetail: PropTypes.func.isRequired,
	listTujuanSurat: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ModalData;
