import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMaster } from '../../menu';
import Card, { CardBody } from '../bootstrap/Card';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import Breadcrumb from '../bootstrap/Breadcrumb';
import SelectData from './Services/SelectData';
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';

// Modal
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import ModalEdit from './Instansi/ModalEditInstansi';

// Alert
import showNotification from '../extras/showNotification';
import GlobalServices from './Services/GlobalServices';
import ModalData from './SuratMasuk/ModalData';
import ModalDetail from './SuratMasuk/ModalDetail';

const SuratMasukByInstansiMenungguVerifikasi = ({ setReset }) => {
	const history = useHistory();
	const roleUser = localStorage.getItem('role_user');
	const uuidInstansiRole = localStorage.getItem('uuid_instansi');
	const token = sessionStorage.getItem('access_token');
	
	const location = useLocation()
  	const setDataProp = location.state?.dataProp
	// const history = useHistory();

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const [pagination, setPagination] = useState({
		current_page: 1,
		from: 1,
		last_page: 1,
		first_page_url: '',
		last_page_url: '',
		next_page_url: '',
		prev_page_url: 10,
		path: '',
		per_page: 10,
		to: 10,
		total: 0,
	});

	const [uuidInstansi, setUuidInstansi] = useState('');
	const [loading, setLoading] = useState(true);
	const [list, setList] = useState([]);
	const [listJabatanStruktural, setListJabatanStruktural] = useState([]);
	const [detailSurat, setDetailSurat] = useState({
		nomor_surat: '',
		pengirim: '',
		perihal: '',
		status: '',
		tanggal_surat: '',
		dokumen: '',
		memo: '',
		uuid_instansi: '',
		uuid_pengguna: '',
		uuid_jabatan_struktural_tujuan: '',
		data_surat_masuk_diposisi: []
	})

	const getDataList = (page) => {

		let extra = ''

		let sortData = '';
		if(selectedSorting && selectedSorting.value === 'Terlama'){
			sortData = '&order_type=asc';
		}
		else{
			sortData = '&order_type=desc';
		}
		
		let firstDate = '';
		let lastDate = '';
		if(paramValue.startDate){
			firstDate = `&first_date=${paramValue.startDate}`;
		}
		if(paramValue.endDate){
			lastDate = `&last_date=${paramValue.endDate}`;
		}

		if (page) {
			extra = `&page=${page}&order_by=created_at${sortData}${firstDate}${lastDate}`
		}

		let link = ''
		if(roleUser === 'Admin' || roleUser === 'Super Operator'){
			link=`administrasi/surat-masuk/instansi/${uuidInstansiRole}?status=Menunggu Verifikasi${extra}`;
		}
		else{
			link=`administrasi/surat-masuk/instansi/${setDataProp.uuid}?status=Menunggu Verifikasi${extra}`;
		}

		GlobalServices.getAll(link, token)
			.then((response) => {
				setLoading(false)
				// console.log('Data Surat Masuk Instansi')
				// console.log(response)
				setList(response.results.data);
				setPagination(response.results)
				setCurrentPage(response.results.current_page)
				setPerPage(response.results.per_page)
			})
			.catch((e) => {
				setLoading(false)
				console.log(e);
			});
	};
	
	const getJabatanStrukturalList = (uuidinstansi) => {
		GlobalServices.getAll(`jabatan-struktural/instansi/${uuidinstansi}/list`, token)
			.then((response) => {
				// console.log('Jabatan Struktural');
				// console.log(response.results);
				let frmSelect = [];
				response.results.forEach(dt => {
					frmSelect.push({ value: dt.uuid, label: dt.nama });
				});
				setListJabatanStruktural(frmSelect)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const refreshList = (page = '') => {
		setLoading(true)
		setTimeout(() => {
			getDataList(page);
			setLoading(false)
		}, 2000);
	};

	useEffect(() => {
		if(roleUser === 'Admin' || roleUser === 'Super Operator'){
			getDataList();
			getJabatanStrukturalList(uuidInstansiRole);
		}
		else if(roleUser !== 'Admin'){
			if (setDataProp.uuid) {
				setUuidInstansi(setDataProp.uuid)
				getDataList();
				getJabatanStrukturalList(setDataProp.uuid);
			}
		}
	}, [setReset]); // eslint-disable-line react-hooks/exhaustive-deps

	const [tambahModalStatus, setTambahModalStatus] = useState(false);
	const [editModalStatus, setEditModalStatus] = useState(false);
	const [detailModalStatus, setDetailModalStatus] = useState(false);

	const [state, setState] = useState(false);

	const [centeredStatus, setCenteredStatus] = useState(false);

	const initialStatus = () => {
		setCenteredStatus(false);
	};

	const initialSuratState = {
		id: '',
		nama: '',
		alamat: '',
		no_telp: '',
	};

	const [surat, setSurat] = useState(initialSuratState);
	const [detailInstansi, setDetailInstansi] = useState({});

	const detailData = (data) => {
		if (data) {
			// console.log(data);
			setDetailSurat(data);
		}
	};

	const onDelete = (getId) => {
		setSurat({ ...surat, id: getId });
	};

	const hapusData = () => {
		const data = {
			uuid: surat.id,
		};

		GlobalServices.remove(data, `administrasi/surat-masuk`, token).catch((e) => {
			setState(false);

			if (e === 204) {
				refreshList();
				setState(false);
				setSurat(initialSuratState);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Berhasil Hapus Data</span>
					</span>,
					'Berhasil Hapus Data Surat Masuk',
				);
			} else {
				setState(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Hapus Data</span>
					</span>,
					'Gagal Hapus Data Surat Masuk',
				);
			}
		});
	};
	
	// Fitur Pencarian
	const dataCari = {
		nama: '',
	};

	const [cariValue, setcariValue] = useState(dataCari);
	const handleInputSearch = (event) => {
		const { name, value } = event.target;
		setcariValue({ ...cariValue, [name]: value });
	};

	const handleSearch = () => {

		let sortData = '';
		if(selectedSorting && selectedSorting.value === 'Terlama'){
			sortData = '&order_type=asc';
		}
		else{
			sortData = '&order_type=desc';
		}
		
		let firstDate = '';
		let lastDate = '';
		if(paramValue.startDate){
			firstDate = `&first_date=${paramValue.startDate}`;
		}
		if(paramValue.endDate){
			lastDate = `&last_date=${paramValue.endDate}`;
		}

		
		let extra = `&page=${1}&order_by=created_at${sortData}${firstDate}${lastDate}`
		

		let link = ''
		if(roleUser === 'Admin' || roleUser === 'Super Operator'){
			link=`administrasi/surat-masuk/instansi/${uuidInstansiRole}?status=Menunggu Verifikasi${extra}`;
		}
		else{
			link=`administrasi/surat-masuk/instansi/${setDataProp.uuid}?status=Menunggu Verifikasi${extra}`;
		}

		GlobalServices.getSearch(link, cariValue.nama, token)
			.then((response) => {
				setList(response.data.results.data);
				setPagination(response.data.results)
				setCurrentPage(response.data.results.current_page)
				setPerPage(response.data.results.per_page)
				
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const handleSearchEnter = (event) => {
		if (event.key === 'Enter') {
			
			let sortData = '';
			if(selectedSorting && selectedSorting.value === 'Terlama'){
				sortData = '&order_type=asc';
			}
			else{
				sortData = '&order_type=desc';
			}
			
			let firstDate = '';
			let lastDate = '';
			if(paramValue.startDate){
				firstDate = `&first_date=${paramValue.startDate}`;
			}
			if(paramValue.endDate){
				lastDate = `&last_date=${paramValue.endDate}`;
			}

			
			let extra = `&page=${1}&order_by=created_at${sortData}${firstDate}${lastDate}`

			let link = ''
			if(roleUser === 'Admin' || roleUser === 'Super Operator'){
				link=`administrasi/surat-masuk/instansi/${uuidInstansiRole}?status=Menunggu Verifikasi${extra}`;
			}
			else{
				link=`administrasi/surat-masuk/instansi/${setDataProp.uuid}?status=Menunggu Verifikasi${extra}`;
			}

			GlobalServices.getSearch(link, cariValue.nama, token)
				.then((response) => {
					setList(response.data.results.data);
					setPagination(response.data.results)
					setCurrentPage(response.data.results.current_page)
					setPerPage(response.data.results.per_page)
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};
	// End Fitur Pencarian

	// Fitur Sorting
	const [listSorting, setListSorting] = useState(SelectData.sortData);
	const [selectedSorting, setSelectedSorting] = useState(null);

	const handleInputSorting = (e, jenis) => {
		if (e) {
			if (jenis === "sorting") {
				let newArray = listSorting;	
				newArray = newArray.filter(day => day !== e);
				setListSorting(newArray)
			}
		}
	};

	const handleSelectSorting = (event, param) => {
		if (event) {
			switch (param) {
				case 'sorting':
					setSelectedSorting(event);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'sorting':
					setSelectedSorting(null);
					break;
				default:
					// no default
					break;
			}
		}
	};
	// End Fitur Sorting

	// Fitur Filter Date
	const paramState = {
		startDate: '',
		endDate: '',
	};
	const [paramValue, setParam] = useState(paramState);
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setParam({ ...paramValue, [name]: value });
	};
	// End Fitur Filter Date

	// Proses Filter Data
	const filterData = () => {
		let sortData = '';
		if(selectedSorting && selectedSorting.value === 'Terlama'){
			sortData = '&order_type=asc';
		}
		else{
			sortData = '&order_type=desc';
		}
		let extra = `&page=${1}&order_by=created_at${sortData}&first_date=${paramValue.startDate}&last_date=${paramValue.endDate}`

		let link = ''
		if(roleUser === 'Admin' || roleUser === 'Super Operator'){
			link=`administrasi/surat-masuk/instansi/${uuidInstansiRole}?status=Menunggu Verifikasi${extra}`;
		}
		else{
			link=`administrasi/surat-masuk/instansi/${setDataProp.uuid}?status=Menunggu Verifikasi${extra}`;
		}

		GlobalServices.getAll(link, token)
			.then((response) => {
				// console.log('Data Surat Masuk Instansi Filter')
				// console.log(response)
				setList(response.results.data);
				setPagination(response.results)
				setCurrentPage(response.results.current_page)
				setPerPage(response.results.per_page)
				tutupFormFilter()
			})
			.catch((e) => {
				console.log(e);
			});
	};
	// End Proses Filter Data
	
	const tutupFormFilter = () => {
		setState(false);
		// setParam(paramState);
		// setSelectedSorting(null);
	};
	
	return (
		<>
			{
				loading
				?
				<>
				<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
					<br/><br/><br/><br/>
					<div className='row g-3'>
					{Object.keys(COLORS).map((color) => (
						<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
							<Spinner color={COLORS[color]} isSmall isGrow />
						</div>
					))}
					</div>
					<br/><br/><br/><br/>
				</div>
				</>
			:
				<>
					<div className='row'>
						<div className='col-6' align='left'>
							{/* {roleUser === 'Admin'
							?
							<>
							</>
							:
							<>
								<Button
									// icon='Add'
									color='success'
									// isLight
									onClick={() => history.goBack()}>
									Kembali
								</Button>
								&nbsp;
								&nbsp;
								&nbsp;
							</>
							} */}
					
							{/* <Button
								// icon='Add'
								color='primary'
								// isLight
								onClick={() => setTambahModalStatus(true)}>
								Tambah Data
							</Button> */}
						</div>
						<div className='col-6'>
							<div className='row'>
								<div className='col-10'>
									<Input
										type='text'
										name='nama'
										value={cariValue.nama}
										onChange={handleInputSearch}
										isRequired
										onKeyDown={handleSearchEnter}
									/>
								</div>
								<div className='col-1' align='right'>
									<Button
										icon='Search'
										color='primary'
										// isLight
										onClick={handleSearch}>
										{/* Cari Data */}
									</Button>
								</div>
								<div className='col-1' align='right'>
									<Button
										icon='FilterAlt'
										color='dark'
										isLight
										className='btn-only-icon position-relative'
										onClick={() => setState(true)}
									>
											&nbsp;
									</Button>
								</div>
							</div>
						</div>
					</div>
					<br />
					<table className='table table-modern table-hover'>
						<thead>
							<tr>
								<th>Tanggal</th>
								<th>Nomor Surat</th>
								<th>Pengirim</th>
								<th>Perihal</th>
								<th>Status</th>
								<th style={{ width: '150px', textAlign: 'center' }}>Aksi</th>
							</tr>
						</thead>
						<tbody>
							{list.map(
								(i, key) => (
									<tr key={i.id}>
										<td>
											{
												`${new Date(i.created_at).toLocaleDateString(
													"id-ID"
												)} ${new Date(i.created_at).toLocaleTimeString(
													"id-ID"
												)}`
											}
										</td>
										<td>{i.nomor_surat}</td>
										<td>{i.pengirim}</td>
										<td>{i.perihal}</td>
										<td>{i.status}</td>
										<td style={{ textAlign: 'center' }}>
											{/* {tombolAksi(i)} */}
											<Button
												icon='Eye'
												color='info'
												title='Lihat Detail Disposisi'
												onClick={() => {
													detailData(i);
													setDetailModalStatus(true)
												}} />
												&nbsp;&nbsp;
												{roleUser !== 'Super Operator' ? (
													<Button
														icon='Pencil'
														color='success'
														title='Edit'
														onClick={() => {
															detailData(i);
															setEditModalStatus(true);
														}}
													/>
												) : ''}
										</td>
									</tr>
								),
							)}
						</tbody>
					</table>
					<PaginationButtons
						data={list}
						label='Surat Masuk Menunggu Verifikasi'
						setCurrentPage={getDataList}
						setPerPage={setPerPage}
						currentPage={currentPage}
						perPage={perPage}
						paginationDetail={pagination}
						setpaginationDetail={setPagination}
					/>
				</>
			}
						
			<ModalData
				setIsOpen={setTambahModalStatus}
				isOpen={tambahModalStatus}
				id={uuidInstansiRole}
				statusRefresh={refreshList}
				listJabatanStruktural={listJabatanStruktural}
				title='Tambah'
			/>
			<ModalData
				setIsOpen={setEditModalStatus}
				isOpen={editModalStatus}
				id={uuidInstansiRole}
				detailSurat={detailSurat}
				listJabatanStruktural={listJabatanStruktural}
				statusRefresh={refreshList}
				title='Edit'
			/>
			<ModalDetail
				setIsOpen={setDetailModalStatus}
				isOpen={detailModalStatus}
				id={uuidInstansiRole}
				detailSurat={detailSurat}
				statusRefresh={refreshList}
				title='Detail'
				statusDisposisi='Verifikasi'
			/>
			<Modal
				isOpen={state}
				setIsOpen={setState}
				titleId='exampleModalLabel'
				isCentered={centeredStatus}>
				<ModalHeader>&nbsp;</ModalHeader>
				<ModalBody>
					<div className='row'>
						<div className='col-6' align='center'>
							<p align='left'><b>Dari Tanggal</b></p>
							<Input
								type='date'
								name='startDate'
								value={paramValue.startDate}
								onChange={handleInputChange}
								// isRequired
							/>
						</div>
						<div className='col-6' align='right'>
							<p align='left'><b>Sampai Tanggal</b></p>
							<Input
								type='date'
								name='endDate'
								value={paramValue.endDate}
								onChange={handleInputChange}
								// isRequired
							/>
						</div>
						<div className='col-12'>&nbsp;</div>
						<div className='col-6' align='right'>
							<p align='left'><b>Urut Dari</b>
							<Select
								inputId="sorting"
								classNamePrefix="select"
								name="sorting"
								placeholder="Pilih Urutan"
								isClearable
								required
								defaultValue={selectedSorting}
								value={selectedSorting}
								options={listSorting}
								onInputChange={e => handleInputSorting(e, "sorting")}
								onChange={e => {
									handleSelectSorting(e, "sorting");
								}}
							/>
							</p>
						</div>
					</div>
					<br />
					<div align='right'>
						<Button
							color='info'
							onClick={tutupFormFilter}>
							Tutup
						</Button>
						{'  '}
						<Button color='success' onClick={filterData}>
							Lihat Data
						</Button>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default SuratMasukByInstansiMenungguVerifikasi;
