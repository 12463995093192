import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Form, FormGroup, Label, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMaster } from '../../menu';
import Card, { CardBody } from '../bootstrap/Card';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import Breadcrumb from '../bootstrap/Breadcrumb';
import GlobalServices from './Services/GlobalServices';

// Modal
import ModalDetail from './Karyawan/ModalVerifikasi';

// Alert
import showNotification from '../extras/showNotification';

// Page Tab Pane
import KaryawanVerifikasiProses from './KaryawanVerifikasiProses';
import KaryawanVerifikasiTolak from './KaryawanVerifikasiTolak';
import KaryawanVerifikasiSelesai from './KaryawanVerifikasiSelesai';

const DokumenKaryawan = () => {
	const roleUser = localStorage.getItem('role_user');
	const uuidInstansi = localStorage.getItem('uuid_instansi');
	const token = sessionStorage.getItem('access_token');
	const formData = {
		id: '',
		nama: '',
		alamat: '',
		no_telp: '',
	};

	// Fitur Tab Pane
	const [resetList, setResetList] = useState(true);
	const [activeTabIcon, setActiveTabIcon] = useState('1');
	const [styleTab, setStyleTab] = useState({ backgroundColor: '#6a5cd0' });
	const [styleFontTab, setStyleFontTab] = useState('white');
	const toggleTab = (tab) => {
		if (activeTabIcon !== tab) {
			setActiveTabIcon(tab)
			setStyleTab({ backgroundColor: '#6a5cd0' })
			setStyleFontTab('white')
		}
	}
	// ENd Fitur Tab Pane

	const [dataValues, setDataValues] = useState(formData);

	// Fitur Pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);

	const [pagination, setPagination] = useState({
		current_page: 1,
		from: 1,
		last_page: 1,
		first_page_url: '',
		last_page_url: '',
		next_page_url: '',
		prev_page_url: 10,
		path: '',
		per_page: 10,
		to: 10,
		total: 0,
	});
	// End Fitur Pagination

	// Fetching Data
	const [list, setList] = useState([]);

	const getDataList = (page) => {
		let extra = ''

		if (page) {
			extra = `&page=${page}&q=${cariValue.nama}`
		}

		GlobalServices.getAll(`karyawan/perubahan/riwayat/${uuidInstansi}?status=Diajukan${extra}`, token)
			.then((response) => {
				setList(response.results.data);
				setPagination(response.results)
				setCurrentPage(response.results.current_page)
				setPerPage(response.results.per_page)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const refreshList = (page = '') => {
		getDataList(page);
	};

	useEffect(() => {
		getDataList();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// End Fetching Data

	// Fitur Modal
	const [detailModalStatus, setDetailModalStatus] = useState(false);

	const [state, setState] = useState(false);
	// End Fitur Modal

	// Fitur Detail Data
	const [detailData, setdetailData] = useState({
		uuid: '',
		uuid_dokumen: '',
		nama_dokumen: '',
		uuid_karyawan: '',
		link: '',
		status: '',
	});

	const detail = (data) => {
		if (data) {
			const datanya = data;
			console.log('Data Detail Parent')
			console.log(datanya)
			
			setdetailData(data);
		}
	};
	// End Fitur Detail Data

	// Fitur Pencarian
	const dataCari = {
		nama: '',
	};

	const [cariValue, setcariValue] = useState(dataCari);
	const handleInputSearch = (event) => {
		const { name, value } = event.target;
		setcariValue({ ...cariValue, [name]: value });
	};

	const handleSearch = () => {
		let extra = `?status=Diajukan&page=${1}`

		GlobalServices.getSearch(`administrasi/dokumen-karyawan/instansi/${uuidInstansi}${extra}`, cariValue.nama, token)
			.then((response) => {
				setList(response.data.results.data);
				setPagination(response.data.results)
				setCurrentPage(response.data.results.current_page)
				setPerPage(response.data.results.per_page)
				
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const handleSearchEnter = (event) => {
		if (event.key === 'Enter') {
			const extra = `?status=Diajukan&page=${1}`

			GlobalServices.getSearch(`administrasi/dokumen-karyawan/instansi/${uuidInstansi}${extra}`, cariValue.nama, token)
				.then((response) => {
					setList(response.data.results.data);
					setPagination(response.data.results)
					setCurrentPage(response.data.results.current_page)
					setPerPage(response.data.results.per_page)
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};
	// End Fitur Pencarian

	return (
		<PageWrapper title='Verifikasi Data Pegawai'>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{
								title: 'Verifikasi Data Pegawai',
								to: menuMaster.karyawan.path,
							},
						]}
					/>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
							<CardBody isScrollable className='table-responsive'>
								<Nav tabs className="tabs-color">
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '1' })}
											onClick={() => { setResetList(!resetList); toggleTab('1'); }} style={ activeTabIcon==='1'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-truck-alt" /><font color={activeTabIcon==='1'?styleFontTab:'#6a5cd0'}>Menunggu Verifikasi</font>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '2' })}
											onClick={() => { setResetList(!resetList); toggleTab('2'); }} style={ activeTabIcon==='2'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-truck-alt" /><font color={activeTabIcon==='2'?styleFontTab:'#6a5cd0'}>Ditolak</font>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '3' })}
											onClick={() => { setResetList(!resetList); toggleTab('3'); }} style={ activeTabIcon==='3'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-truck-alt" /><font color={activeTabIcon==='3'?styleFontTab:'#6a5cd0'}>Selesai</font>
										</NavLink>
									</NavItem>
								</Nav>
								<br />
								
								<TabContent activeTab={activeTabIcon}>	
									{/* =========================================================================== */}
									<TabPane tabId="1">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<KaryawanVerifikasiProses setReset={resetList}/>
												</Col>
											</Row>

										</div>
									</TabPane>

									{/* =========================================================================== */}
									<TabPane tabId="2">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<KaryawanVerifikasiTolak setReset={resetList}/>
												</Col>
											</Row>

										</div>
									</TabPane>
									{/* =========================================================================== */}
									<TabPane tabId="3">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<KaryawanVerifikasiSelesai setReset={resetList}/>
												</Col>
											</Row>

										</div>
									</TabPane>
									{/* =========================================================================== */}
								</TabContent>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
			<ModalDetail
				setIsOpen={setDetailModalStatus}
				isOpen={detailModalStatus}
				id={0}
				setDetail={detailData}
				statusRefresh={refreshList}
				jenisVerifikasi='Perubahan Data'
			/>
			
		</PageWrapper>
	);
};

export default DokumenKaryawan;
