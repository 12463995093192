import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
// import data from './DataInstansi';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import Option from '../../bootstrap/Option';
import GlobalServices from '../Services/GlobalServices';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import COLORS from '../Services/Spinner';
import Spinner from '../../bootstrap/Spinner';

const ModalData = ({ id, isOpen, setIsOpen, statusRefresh, listDokumenInstansi, title }) => {
	const token = sessionStorage.getItem('access_token');

	const initSurat = {
		uuid: '',
		uuid_dokumen: '',
		uuid_karyawan: '',
		link: '',
	};
	// console.log(listJabatanStruktural);

	// const [selectedInstansi, setSelectedInstansi] = useState(null);
	const [loading, setLoading] = useState(false);
	const [surat, setSurat] = useState(initSurat);
	const [dokumenSelected, setDokumenSelected] = useState(false);
	const [isComplete, setIsComplete] = useState(true);

	useEffect(() => {
		if (id) {
			console.log('Modal Open BOy')
			
			const setDokumenInstansiList = listDokumenInstansi;
			setDataListDokumen(setDokumenInstansiList);
		}
	}, [id, listDokumenInstansi]) // eslint-disable-line react-hooks/exhaustive-deps
	

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setSurat({ ...surat, [name]: value });
	};

	const [dataListDokumen, setDataListDokumen] = useState(listDokumenInstansi);
	const [selectedDokumen, setSelectedDokumen] = useState();

	const handleSelectChange = (e, param) => {
		if (e) {
			switch (param) {
				case 'dokumen':
					setSelectedDokumen(e);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'dokumen':
					setSelectedDokumen(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "dokumen") {
				let newArray = dataListDokumen;
				newArray = newArray.filter(day => day !== e);

				setDataListDokumen(newArray)
			} else {
			
		  	}
		}
	};

	const handleSubmit = () => {
		if (selectedDokumen) {
			setLoading(true)

			let data = {
				uuid_dokumen: selectedDokumen.value,
				uuid_karyawan: id,
				link: surat.dokumen,
			}

			console.log('Data Simpan Dokumen Karyawan')
			console.log(data)

			GlobalServices.create(data, `administrasi/dokumen-karyawan`, token)
				.then((response) => {
					if (response.data.code === 201) {
						console.log('===== Berhasil Simpan Dokumen Karyawan =====');
						console.log(response);

						setLoading(false)
						setIsOpen(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Berhasil {title}</span>
							</span>,
							`Berhasil ${title} Dokumen Pegawai`,
						);
						tutupForm();
						statusRefresh();
						// ==========================
						} else {
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal {title}</span>
								</span>,
								`Gagal ${title} Dokumen Pegawai`,
							);
						}
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal {title}</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	const uploadFile = (file) => {
		if (file) {
			const type = file.type.split('/');
			if (
				type[1] === 'jpg' || 
				type[1] === 'jpeg' || 
				type[1] === 'png' || 
				type[1] === 'bmp' || 
				type[1] === 'tiff' || 
				type[1] === 'webp' ||
				type[1] === 'pdf'  
			) {
				const body = new FormData()
				body.append('file', file)
				// console.log(file);
				// console.log(body);
	
				GlobalServices.create(body, 'upload-file/dokumen', token)
					.then((response) => {
						// console.log(response);
	
						if (response.data.code === 200) {
							setDokumenSelected(true)
							setIsComplete(true)
	
							setSurat({
								...surat, dokumen : response.data.results
							})
							
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Upload Dokumen</span>
								</span>,
								'Berhasil Upload Dokumen',
							);
						} else {
							setSurat({
								...surat, dokumen : ''
							})
							setDokumenSelected(false)
							setIsComplete(false)
							
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Upload Dokumen</span>
								</span>,
								'Gagal Upload Dokumen',
							);
						}
					})
					.catch((e) => {
						setSurat({
							...surat, dokumen : ''
						})
						setDokumenSelected(false)
						setIsComplete(false)
						console.log(e);
					});
	
			}
		}
	}

	const handleChangeFile = (event) => {
		console.log(event.target.files[0]);
		let data = event.target.files[0]
		setIsComplete(false)
		uploadFile(data)
	}

	const tutupForm = () => {
		setSurat(initSurat);
		setSelectedDokumen(null)
	};
	
	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
					<ModalHeader setIsOpen={setIsOpen} className='p-4'>
						<ModalTitle id={id}>{title} Dokumen Karyawan</ModalTitle>
					</ModalHeader>
					{
						loading
						?
						<>
						<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
							<br/><br/><br/><br/>
							<div className='row g-3'>
							{Object.keys(COLORS).map((color) => (
								<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
									<Spinner color={COLORS[color]} isSmall isGrow />
								</div>
							))}
							</div>
							<br/><br/><br/><br/>
						</div>
						</>
					:
						<>
						<ModalBody className='px-4'>
							<div className='row g-4'>
								<FormGroup id='uuid_dokumen' label='Nama Dokumen' className='col-md-12'>
									<Select
										inputId="uuid_dokumen"
										classNamePrefix="select"
										name="uuid_dokumen"
										placeholder="Pilih Dokumen"
										isClearable
										required
										defaultValue={selectedDokumen}
										value={selectedDokumen}
										options={dataListDokumen}
										onInputChange={e => handleInputSelect(e, "dokumen")}
										onChange={e => {
											handleSelectChange(e, "dokumen");
										}}
									/>
								</FormGroup>
								<FormGroup id='dokumen' label='Dokumen' className='col-md-12'>
									<Input
										id='dokumen'
										type='file'
										accept='application/pdf'
										onChange={handleChangeFile}
										required={title === 'Tambah'}
										// value={surat.dokumen}
									/>
								</FormGroup>
								{/* {title === 'Tambah' ? (
									<>
										<FormGroup id='uuid_jabatan_struktural_tujuan' label='Disposisi Tujuan' className='col-md-12'>
											<Select
												inputId="nik3"
												classNamePrefix="select"
												name="NIK3"
												placeholder="Pilih Disposisi Tujuan"
												isClearable
												defaultValue={selectedDisposisiTujuan}
												value={selectedDisposisiTujuan}
												options={frmJabatanStruktural}
												onInputChange={e => handleInputSelect(e, "disposisi-tujuan")}
												onChange={e => {
													handleSelectChange(e, "disposisi-tujuan");
												}}
											/>
										</FormGroup>
										<FormGroup id='memo' label='Memo' className='col-md-12'>
											<Input
												type='text'
												name='memo'
												// onChange={formik.handleChange}
												value={surat.memo}
												onChange={handleInputChange}
												required
											/>
										</FormGroup>
									</>
								) : ''} */}
								{/* <Select
											id='largeSelect'
											ariaLabel='Default select example'
											placeholder='Pilih Disposisi Tujuan'
											onChange={handleSelectChange}
											required
											value={surat.uuid_jabatan_struktural_tujuan}
											name='uuid_jabatan_struktural_tujuan'
											size='md'>
												{listJabatanStruktural.map((i, key) => (
													<Option value={i.uuid}>{i.nama}</Option>
												))}
										</Select> */}
							</div>
						</ModalBody>
						<ModalFooter className='px-4 pb-4'>
							<Button
								color='success'
								// isOutline
								// className='border-0'
								onClick={() => {
									setIsOpen(false);
									setSurat(initSurat);
									tutupForm();
									// setSelectedInstansi(null);
								}}>
								Tutup
							</Button>
							{/* <Button color='info' onClick={formik.handleSubmit}> */}
							<Button color='info' type='submit' onClick={handleSubmit} disabled={!isComplete}>
								Simpan
							</Button>
						</ModalFooter>
					</>
					}
				</form>
			</Modal>
		);
	}
	return null;
};
ModalData.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};

export default ModalData;