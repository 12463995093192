import React, { useState, useEffect } from 'react';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';

// Modal
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import ModalTambah from './TandaTangan/ModalTambah';
import ModalEdit from './TandaTangan/ModalEdit';

// Alert
import showNotification from '../extras/showNotification';
import GlobalServices from './Services/GlobalServices';

const TandaTangan = ({ setDataInstansi }) => {
	const roleUser = localStorage.getItem('role_user');
	const token = sessionStorage.getItem('access_token');

	let uuidInstansi = ""
	if(roleUser==='Super Operator'){
		uuidInstansi = setDataInstansi;
	}
	else{
		uuidInstansi = setDataInstansi.uuid;
	}

	// Fitur Pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	// End Fitur Pagination

	// Fetching Data
	const [loading, setLoading] = useState(true);
	const [list, setList] = useState([]);
	const [pagination, setPagination] = useState({
		current_page: 1,
		from: 1,
		last_page: 1,
		first_page_url: '',
		last_page_url: '',
		next_page_url: '',
		prev_page_url: 10,
		path: '',
		per_page: 10,
		to: 10,
		total: 0,
	});

	const getDataList = (page) => {
		let extra = ''

		if (page) {
			extra = `?page=${page}&q=${cariValue.nama}`
		}

		GlobalServices.getAll(`configurasi-tanda-tangan/instansi/${uuidInstansi}${extra}`, token)
			.then((response) => {
				setList(response.results.data);
				setPagination(response.results)
				setCurrentPage(response.results.current_page)
				setPerPage(response.results.per_page)
				setLoading(false)
				
			})
			.catch((e) => {
				setLoading(false)
				console.log(e);
			});
	};

	const refreshList = (page = '') => {
		getDataList(page);
	};

	const [listInstansi, setListInstansi] = useState([]);
	const [listKaryawan, setListKaryawan] = useState([]);

	useEffect(() => {
		getDataList();
		GlobalServices.getAll('instansi', token).then((hasil) => {
			// console.log(hasil);
			setListInstansi(hasil.results.data);
		});
		GlobalServices.getListBy('karyawan', `instansi`, uuidInstansi, token).then((hasil) => {
			let frmSelect = [];
			hasil.data.results.forEach(dt => {
				frmSelect.push({ 
					value: {
						jabatan_struktural: dt.data_jabatan_struktural, 
						nama: dt.nama, 
						nip: dt.nip
					}, 
					label: dt.nama 
				});
			});
			setListKaryawan(frmSelect)
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// End Fetching Data

	// Fitur Modal
	const [tambahModalStatus, setTambahModalStatus] = useState(false);
	const [editModalStatus, setEditModalStatus] = useState(false);

	const [state, setState] = useState(false);

	const [centeredStatus, setCenteredStatus] = useState(false);

	const initialStatus = () => {
		setCenteredStatus(false);
	};
	// End Fitur Modal

	// Fitur Detail Data
	const initialInstansiState = {
		id: '',
		nama: '',
		alamat: '',
		no_telp: '',
	};

	const [instansi, setInstansi] = useState(initialInstansiState);
	const [detailData, setDetailData] = useState({
		uuid: '',
		selectJabatanTTD: {},
		selectJabatanTTDPengganti: {},
		salam_penutup: '',
		nip_ttd: '',
		nama_ttd: '',
		nip_ttd_pengganti: '',
		nama_ttd_pengganti: '',
		status_ttd: '',
		dokumen: '',
	});

	const getDetailData = (data) => {
		if (data) {
			let isiConfig = JSON.parse(data.config_ttd)
			console.log('Data Detail Parent');
			console.log(isiConfig);
			const nip1 = isiConfig.isi[5].value.split("NIP. ");
			const nip2 = isiConfig.isi[7].value.split("NIP. ");

			const setselectJabatanTTD= {
				value: isiConfig.isi[2].value,
				label: isiConfig.isi[2].value
			}
			const setselectJabatanTTDPengganti= {
				value: isiConfig.isi[3].value, 
				label: isiConfig.isi[3].value
			}

			let selectStatusPenggantiTTD = { value:'', label:''};
			if(isiConfig.isi[1].value!==''){
				selectStatusPenggantiTTD = {value:isiConfig.isi[1].value, label:isiConfig.isi[1].value}
			}

			setDetailData({
				uuid: data.uuid,
				salam_penutup: isiConfig.isi[0].value,
				nip_ttd: nip1[1],
				nama_ttd: isiConfig.isi[4].value,
				selectJabatanTTD: setselectJabatanTTD,
				selectStatusTTD: selectStatusPenggantiTTD,
				status_ttd: isiConfig.isi[1].value,
				nip_ttd_pengganti: nip2[1],
				nama_ttd_pengganti: isiConfig.isi[6].value,
				selectJabatanTTDPengganti: setselectJabatanTTDPengganti,
				dokumen: isiConfig.isi[8].value,
			});
		}
	};
	// End Fitur Detail Data

	// Fitur Hapus Data
	const onDelete = (getId) => {
		setInstansi({ ...instansi, id: getId });
	};

	const hapusData = () => {
		const data = {
			uuid: instansi.id,
		};

		GlobalServices.remove(data, 'configurasi-tanda-tangan', token).catch((e) => {
			setState(false);

			if (e === 204) {
				refreshList();
				setState(false);
				setInstansi(initialInstansiState);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Berhasil Hapus Data</span>
					</span>,
					'Berhasil Hapus Data Jabatan Fungsional',
				);
			} else {
				setState(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Hapus Data</span>
					</span>,
					'Gagal Hapus Data Jabatan Fungsional',
				);
			}
		});
	};
	// End Fitur Hapus Data

	// Fitur Pencarian
	const dataCari = {
		nama: '',
	};

	const [cariValue, setcariValue] = useState(dataCari);
	const handleInputSearch = (event) => {
		const { name, value } = event.target;
		setcariValue({ ...cariValue, [name]: value });
	};

	const handleSearch = () => {
		let extra = `?page=${1}`
	
		GlobalServices.getSearch(`configurasi-tanda-tangan/instansi/${uuidInstansi}${extra}`, cariValue.nama, token)
			.then((response) => {
				setList(response.data.results.data);
				setPagination(response.data.results)
				setCurrentPage(response.data.results.current_page)
				setPerPage(response.data.results.per_page)
				
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const handleSearchEnter = (event) => {
		if (event.key === 'Enter') {
			const extra = `?page=${1}`

			GlobalServices.getSearch(`configurasi-tanda-tangan/instansi/${uuidInstansi}${extra}`, cariValue.nama, token)
				.then((response) => {
					// console.log(response);
					setList(response.data.results.data);
					setPagination(response.data.results)
					setCurrentPage(response.data.results.current_page)
					setPerPage(response.data.results.per_page)
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};
	// End Fitur Pencarian

	const ubahFormat = (data) => {
		let dataFormat = JSON.parse(data)
		let isiData = dataFormat.isi
		return isiData;
	}

	return (
		<>
			{
				loading
				?
				<>
				<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
					<br/><br/><br/><br/><br/><br/><br/><br/>
					<div className='row g-3'>
					{Object.keys(COLORS).map((color) => (
						<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
							<Spinner color={COLORS[color]} isSmall isGrow />
						</div>
					))}
					</div>
					<br/><br/><br/><br/>
				</div>
				</>
			:
				<>
					<div className='row'>
						<div className='col-3' align='left'>
							<Button
								// icon='Add'
								color='success'
								// isLight
								onClick={() => setTambahModalStatus(true)}>
								Tambah Data
							</Button>
						</div>
						<div className='col-9'>
							{/* <div className='row'>
								<div className='col-11'>
									<Input
										type='text'
										name='nama'
										value={cariValue.nama}
										onChange={handleInputSearch}
										isRequired
										onKeyDown={handleSearchEnter}
									/>
								</div>
								<div className='col-1' align='right'>
									<Button
										icon='Search'
										color='primary'
										// isLight
										onClick={handleSearch}>
									</Button>
								</div>
							</div> */}
						</div>
					</div>
					<br />
					<table className='table table-modern table-hover'>
						<thead>
							<tr>
								{/* <th>No.</th> */}
								<th>NIP TTD</th>
								<th>Nama TTD</th>
								<th>Jabatan</th>
								<th>NIP TTD Pengganti</th>
								<th>Nama TTD Pengganti</th>
								<th>Jabatan Pengganti</th>
								<th>Status</th>
								<th style={{ width: '150px', textAlign: 'center' }}>Aksi</th>
							</tr>
						</thead>
						<tbody>
							{list.map(
								(i, key) => (
									<tr key={i.id}>
										{/* <td>{key + 1}</td> */}
										<td>
											{ubahFormat(i.config_ttd)[5].value}
										</td>
										<td>
											{ubahFormat(i.config_ttd)[4].value}
										</td>
										<td>
											{ubahFormat(i.config_ttd)[2].value}
										</td>
										<td>
											{ubahFormat(i.config_ttd)[7].value}
										</td>
										<td>
											{ubahFormat(i.config_ttd)[6].value}
										</td>
										<td>
											{ubahFormat(i.config_ttd)[3].value}
										</td>
										<td>
											{ubahFormat(i.config_ttd)[1].value}
										</td>
										<td style={{ textAlign: 'center' }}>
											<Button
												icon='Pencil'
												color='primary'
												title='Ubah Data'
												onClick={() => {
													setEditModalStatus(true);
													getDetailData(i);
												}}>
												{/* Edit */}
											</Button>{' '}
											<Button
												icon='Delete'
												color='danger'
												title='Hapus Data'
												onClick={() => {
													initialStatus();
													setCenteredStatus(true);
													setState(true);
													onDelete(i.uuid);
												}}>
												{/* Hapus */}
											</Button>
										</td>
									</tr>
								),
							)}
						</tbody>
					</table>

					<PaginationButtons
						data={list}
						label='Tanda Tangan'
						setCurrentPage={getDataList}
						setPerPage={setPerPage}
						currentPage={currentPage}
						perPage={perPage}
						paginationDetail={pagination}
						setpaginationDetail={setPagination}
					/>
				</>
			}
					
			<ModalTambah
				setIsOpen={setTambahModalStatus}
				isOpen={tambahModalStatus}
				id={0}
				uuidInstansi={uuidInstansi}
				listInstansi={listInstansi}
				listKaryawan={listKaryawan}
				statusRefresh={refreshList}
			/>
			<ModalEdit
				setIsOpen={setEditModalStatus}
				isOpen={editModalStatus}
				id={0}
				uuidInstansi={uuidInstansi}
				detailData={detailData}
				listInstansi={listInstansi}
				listKaryawan={listKaryawan}
				statusRefresh={refreshList}
			/>
			<Modal
				isOpen={state}
				setIsOpen={setState}
				titleId='exampleModalLabel'
				isCentered={centeredStatus}>
				<ModalHeader>&nbsp;</ModalHeader>
				<ModalBody>
					<p align='center'>
						<h2>Yakin Ingin Hapus Data?</h2> <br />
						Proses ini tidak dapat dibatalkan!
					</p>
					<br />
					<div align='center'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						&nbsp;&nbsp;
						<Button color='danger' icon='Delete' onClick={hapusData}>
							Hapus
						</Button>
					</div>
				</ModalBody>
				<ModalFooter>
					&nbsp;
					{/* <div align='center'>
						<Button
							color='success'
							// isOutline
							className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						<Button color='danger' icon='Delete'>
							Hapus
						</Button>
					</div> */}
				</ModalFooter>
			</Modal>
		</>
	);
};

export default TandaTangan;
