import http from './http-common';

const getAll = () => {
	return http
		.get('/instansi')
		.then((response) => response.data)
		.catch((error) => {
			throw error;
		});
};

const getSearch = (param) => {
	return http
		.get(`/instansi?q=${param}`)
		.then((response) => response.data)
		.catch((error) => {
			throw error;
		});
};

const get = (id) => {
	return http
		.get(`/instansi/find/${id}`)
		.then((response) => response.data)
		.catch((error) => {
			throw error;
		});
};

const create = (data) => {
	return http.post('/instansi', data);
};

const update = (data) => {
	return http.patch('/instansi', data);
};

const remove = (data) => {
	return http
		.delete('/instansi', { data }, null)
		.then(function (response) {
			throw response.status;
		})
		.catch((error) => {
			throw error;
		});
};

const removeAll = () => {
	return http.delete(`/instansi`);
};

const findByTitle = (title) => {
	return http.get(`/instansi?title=${title}`);
};

export default {
	getAll,
	getSearch,
	get,
	create,
	update,
	remove,
	removeAll,
	findByTitle,
};
