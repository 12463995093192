import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMasterAdmin, menuMaster } from '../../menu';
import Card, { CardBody } from '../bootstrap/Card';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import Breadcrumb from '../bootstrap/Breadcrumb';
import GlobalServices from './Services/GlobalServices';
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';

// Modal
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import ModalData from './Info/ModalData';
import ModalDetail from './Info/ModalDetail';

// Alert
import showNotification from '../extras/showNotification';

const Info = ({ props }) => {
	const history = useHistory();
	const roleUser = localStorage.getItem('role_user');
	const location = useLocation()
	const setDataInstansi = location.state?.dataProp
	const token = sessionStorage.getItem('access_token');

	let uuidInstansi = ""
	if(roleUser==='Super Operator' || roleUser==='Admin'){
		uuidInstansi = localStorage.getItem('uuid_instansi');
	}
	else{
		uuidInstansi = setDataInstansi.uuid;
	}

	const formData = {
		id: '',
		nama: '',
		alamat: '',
		no_telp: '',
	};

	const [dataValues, setDataValues] = useState(formData);

	// Fitur Pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);

	const [pagination, setPagination] = useState({
		current_page: 1,
		from: 1,
		last_page: 1,
		first_page_url: '',
		last_page_url: '',
		next_page_url: '',
		prev_page_url: 10,
		path: '',
		per_page: 10,
		to: 10,
		total: 0,
	});
	// End Fitur Pagination

	// Fetching Data
	const [loading, setLoading] = useState(true);
	const [list, setList] = useState([]);

	const getDataList = (page) => {
		let extra = ''

		if (page) {
			extra = `?page=${page}&q=${cariValue.nama}`
		}

		// let link = ''
		// if(roleUser === 'Admin' || roleUser === 'Super Operator'){
			let	link=`info-instansi/instansi/${uuidInstansi}${extra}`;
		// }
		// else{
		// 	link=`info-instansi${extra}`;
		// }

		GlobalServices.getAll(link, token)
			.then((response) => {
				setList(response.results.data);
				setPagination(response.results)
				setCurrentPage(response.results.current_page)
				setPerPage(response.results.per_page)
				setLoading(false)
			})
			.catch((e) => {
				setLoading(false)
				console.log(e);
			});
	};

	const refreshList = (page = '') => {
		getDataList(page);
	};

	useEffect(() => {
		getDataList();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// End Fetching Data

	// Fitur Modal
	const [tambahModalStatus, setTambahModalStatus] = useState(false);
	const [editModalStatus, setEditModalStatus] = useState(false);
	const [detailModalStatus, setDetailModalStatus] = useState(false);

	const [state, setState] = useState(false);

	const [centeredStatus, setCenteredStatus] = useState(false);

	const initialStatus = () => {
		setCenteredStatus(false);
	};
	// End Fitur Modal

	// Fitur Detail Data
	const [detailData, setdetailData] = useState({
		gambar: '',
		isi: '',
		judul: '',
		keterangan: '',
		uuid: '',
		uuid_instansi: '',
	});

	const detail = (data) => {
		if (data) {
			const datanya = data;
			console.log('Data Detail Parent')
			console.log(datanya)
			setdetailData(datanya);
		}
	};
	// End Fitur Detail Data

		// Fitur Hapus Data
		const onDelete = (getId) => {
			setDataValues({ ...dataValues, id: getId });
		};
	
		const hapusData = () => {
			const data = {
				uuid: dataValues.id,
			};
	
			GlobalServices.remove(data, 'info-instansi', token).catch((e) => {
				setState(false);
	
				if (e === 204) {
					refreshList();
					setState(false);
					setDataValues(formData);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Berhasil Hapus Data</span>
						</span>,
						'Berhasil Hapus Data Karyawan',
					);
				} else {
					setState(false);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Warning' size='lg' className='me-1' />
							<span>Gagal Hapus Data</span>
						</span>,
						'Gagal Hapus Data Karyawan',
					);
				}
			});
		};
		// End Fitur Hapus Data

	// Fitur Pencarian
	const dataCari = {
		nama: '',
	};

	const [cariValue, setcariValue] = useState(dataCari);
	const handleInputSearch = (event) => {
		const { name, value } = event.target;
		setcariValue({ ...cariValue, [name]: value });
	};

	const handleSearch = () => {
		let extra = `?page=${1}`

		// let link = ''
		// if(roleUser === 'Admin' || roleUser === 'Super Operator'){
		let	link=`info-instansi/instansi/${uuidInstansi}${extra}`;
		// }
		// else{
		// 	link=`info-instansi${extra}`;
		// }

		GlobalServices.getSearch(link, cariValue.nama, token)
			.then((response) => {
				setList(response.data.results.data);
				setPagination(response.data.results)
				setCurrentPage(response.data.results.current_page)
				setPerPage(response.data.results.per_page)
				
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const handleSearchEnter = (event) => {
		if (event.key === 'Enter') {
			const extra = `?page=${1}`

			// let link = ''
			// if(roleUser === 'Admin' || roleUser === 'Super Operator'){
				let link=`info-instansi/instansi/${uuidInstansi}${extra}`;
			// }
			// else{
			// 	link=`info-instansi${extra}`;
			// }

			GlobalServices.getSearch(link, cariValue.nama, token)
				.then((response) => {
					setList(response.data.results.data);
					setPagination(response.data.results)
					setCurrentPage(response.data.results.current_page)
					setPerPage(response.data.results.per_page)
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};
	// End Fitur Pencarian

	return (
		<PageWrapper title={roleUser === 'Super Admin' ? menuMaster.info.text : menuMasterAdmin.info.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{
								title: roleUser === 'Super Admin' ? menuMaster.info.text :menuMasterAdmin.info.text,
								to: roleUser === 'Super Admin' ? menuMaster.info.path :menuMasterAdmin.info.path,
							},
						]}
					/>
				</SubHeaderLeft>
				{
					roleUser === 'Super Admin'
					?
						<SubHeaderRight>
							<Button
								icon='ArrowLeft'
								color='primary'
								// isLight
								onClick={() => history.goBack()}>
								Kembali
							</Button>
						</SubHeaderRight>
					:
					<>
					</>
				}
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
						{
							loading
							?
							<>
							<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
								<br/><br/><br/><br/><br/><br/><br/><br/><br/>
								<div className='row g-3'>
								{Object.keys(COLORS).map((color) => (
									<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
										<Spinner color={COLORS[color]} isSmall isGrow />
									</div>
								))}
								</div>
								<br/><br/><br/><br/>
							</div>
							</>
						:
							<>
							<CardBody isScrollable className='table-responsive'>
								<div className='row'>
									<div className='col-3' align='left'>
										<Button
											// icon='Add'
											color='success'
											// isLight
											onClick={() => setTambahModalStatus(true)}>
											Tambah Data
										</Button>
									</div>
									<div className='col-9'>
										<div className='row'>
											<div className='col-11'>
												<Input
													type='text'
													name='nama'
													value={cariValue.nama}
													onChange={handleInputSearch}
													isRequired
													onKeyDown={handleSearchEnter}
												/>
											</div>
											<div className='col-1' align='right'>
												<Button
													icon='Search'
													color='primary'
													// isLight
													onClick={handleSearch}>
													{/* Cari Data */}
												</Button>
											</div>
										</div>
									</div>
								</div>
								<br />
								<table className='table table-modern table-hover'>
									<thead>
										<tr>
											{/* <th>No.</th> */}
											<th>Tanggal Publish</th>
											<th>Judul</th>
											{/* <th>Isi</th> */}
											<th>Keterangan</th>
											<th style={{ width: '150px', textAlign: 'center' }}>Aksi</th>
										</tr>
									</thead>
									<tbody>
										{list.map(
											(i, key) => (
												<tr key={i.id}>
													{/* <td>{key + 1}</td> */}
													<td>
														{new Date(i.created_at).toLocaleDateString('id-ID')}
													</td>
													<td>{i.judul}</td>
													{/* <td>{i.isi}</td> */}
													<td>{i.keterangan}</td>
													<td style={{ textAlign: 'center' }}>
														<Link 
															to={{
																pathname: "/admin/detail-info",
																state: { dataProp: i },
															}}
															style={{ padding: 0 }}
														>
															<Button
																icon='Eye'
																color='secondary'
																title='Lihat Detail Info'
																/>
														</Link>{' '}
														{/* <Button
															icon='Eye'
															color='secondary'
															onClick={() => {
																setDetailModalStatus(true);
																detail(i);
															}}>
														</Button>{' '} */}
														<Button
															icon='Pencil'
															color='primary'
															onClick={() => {
																setEditModalStatus(true);
																detail(i);
															}}>
															{/* Edit */}
														</Button>{' '}
														<Button
															icon='Delete'
															color='danger'
															onClick={() => {
																initialStatus();
																setCenteredStatus(true);
																setState(true);
																onDelete(i.uuid);
															}}>
															{/* Hapus */}
														</Button>
													</td>
												</tr>
											),
										)}
									</tbody>
								</table>
							</CardBody>
							<PaginationButtons
								data={list}
								label='Verifikasi Dokumen Karyawan'
								setCurrentPage={getDataList}
								setPerPage={setPerPage}
								currentPage={currentPage}
								perPage={perPage}
								paginationDetail={pagination}
								setpaginationDetail={setPagination}
							/>
							</>
						}
						</Card>
					</div>
				</div>
			</Page>
			<ModalData
				setIsOpen={setTambahModalStatus}
				isOpen={tambahModalStatus}
				id={uuidInstansi}
				statusRefresh={refreshList}
				title='Tambah'
			/>
			
			<ModalData
				setIsOpen={setEditModalStatus}
				isOpen={editModalStatus}
				id={uuidInstansi}
				detailData={detailData}
				statusRefresh={refreshList}
				title='Edit'
			/>

			<ModalDetail
				setIsOpen={setDetailModalStatus}
				isOpen={detailModalStatus}
				id={uuidInstansi}
				detailData={detailData}
				title='Detail'
			/>

			<Modal
				isOpen={state}
				setIsOpen={setState}
				titleId='exampleModalLabel'
				isCentered={centeredStatus}>
				<ModalHeader>&nbsp;</ModalHeader>
				<ModalBody>
					<p align='center'>
						<h2>Yakin Ingin Hapus Data?</h2> <br />
						Proses ini tidak dapat dibatalkan!
					</p>
					<br />
					<div align='center'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						&nbsp;&nbsp;
						<Button color='danger' icon='Delete' onClick={hapusData}>
							Hapus
						</Button>
					</div>
				</ModalBody>
				<ModalFooter>
					&nbsp;
				</ModalFooter>
			</Modal>
			
		</PageWrapper>
	);
};

export default Info;
