import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
// import data from './DataInstansi';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import GlobalServices from '../Services/GlobalServices';
import COLORS from '../Services/Spinner';
import Spinner from '../../bootstrap/Spinner';

const ModalTambah = ({ id, isOpen, setIsOpen, statusRefresh}) => {
	const uuidInstansi = localStorage.getItem('uuid_instansi');
	const token = sessionStorage.getItem('access_token');

	const formData = {
		title: 'Tambah Data Penomoran Surat',
		id: null,
		nomor: '',
	};

	const [loading, setLoading] = useState(false);
	const [nomorAkhir, setNomorAkhir] = useState('');

	const getDataList = () => {	
		GlobalServices.getAll(`penomoran/${uuidInstansi}?page=1`, token)
		.then((response) => {
			console.log('hasil response')
			console.log(response)
			if(response.results.data && response.results.data.length !== 0){
				let nextNomor = response.results.data[0].nomor+1
				console.log('response useeffect nomor akhir')
				console.log(response.results.data[0].nomor)
				console.log(nextNomor)
				setNomorAkhir(response.results.data[0].nomor)
				setDataValues({...dataValues, nomor: nextNomor});
			}
			else{
				let nextNomor = 1;
				setNomorAkhir(0);
				setDataValues({...dataValues, nomor: nextNomor});
			}
		})
		.catch((e) => {
			console.log(e);
		});	
	};

	const refreshList = () => {
		getDataList();
	};

	useEffect(() => {
		getDataList();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const [dataValues, setDataValues] = useState(formData);
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setDataValues({ ...dataValues, [name]: value });
	};

	const handleSubmit = () => {
		let cekNomor = false;
		if(dataValues.nomor !== '' && dataValues.nomor > nomorAkhir){
			cekNomor = true;
		}
		else{
			showNotification(
				<span className='d-flex align-items-center'>
				<Icon icon='Warning' size='lg' className='me-1' />
				<span>Gagal Tambah Nomor</span>
			</span>,
			'Nomor Surat Tidak Boleh Kurang Dari Yang Telah Dianjurkan',
			);
		}

		if (dataValues.nomor !== '' && cekNomor) {
			setLoading(true)
			const data = {
				uuid_instansi: uuidInstansi,
				nomor: dataValues.nomor,
			};
			GlobalServices.create(data, `penomoran/create`, token)
				.then((response) => {
					// console.log('response Hasil Simpan')
					// console.log(response)
					if (response.data.code === 200) {
						setLoading(false)
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Berhasil Tambah Data</span>
							</span>,
							'Berhasil Tambah Data Nomor Surat',
						);
						statusRefresh();
						tutupForm();
					} else {
						setLoading(false)
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Tambah Data</span>
							</span>,
							'Gagal Tambah Data Nomor Surat',
						);
					}
				})
				.catch((e) => {
					setLoading(false)
					console.log(e);
				});
		} else {
			setLoading(false)
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Tambah Data</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	const tutupForm = () => {
		setIsOpen(false);
		setDataValues(formData);
		refreshList();
	};

	// console.log('dataValues')
	// console.log(dataValues)
	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={id}>{dataValues.title}</ModalTitle>
				</ModalHeader>
				{
					loading
					?
					<>
						<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
							<br/><br/><br/><br/>
							<div className='row g-3'>
							{Object.keys(COLORS).map((color) => (
								<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
									<Spinner color={COLORS[color]} isSmall isGrow />
								</div>
							))}
							</div>
							<br/><br/><br/><br/>
						</div>
						</>
					:
						<>
						<ModalBody className='px-4'>
							<div className='row g-4'>
								<FormGroup id='nomor' label='Nomor Surat' className='col-md-12'>
									<Input
										type='number'
										name='nomor'
										value={dataValues.nomor}
										onChange={handleInputChange}
										required
									/>
								</FormGroup>
							</div>
						</ModalBody>
						<ModalFooter className='px-4 pb-4'>
							<Button
								color='success'
								style={{width:'100px'}}
								// isOutline
								// className='border-0'
								onClick={() => {
									tutupForm();
								}}>
								Batal
							</Button>
							{/* <Button color='info' onClick={formik.handleSubmit}> */}
							<Button color='info' style={{width:'100px'}} type='submit' onClick={handleSubmit}>
								Simpan
							</Button>
						</ModalFooter>
					</>
				}
				</form>
			</Modal>
		);
	}
	return null;
};
ModalTambah.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
};

export default ModalTambah;
