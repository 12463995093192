import React, { useState, useEffect } from 'react';
import Iframe from 'react-iframe'
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Button from '../../bootstrap/Button';
import GlobalServices from '../Services/GlobalServices';

import Select from '../../bootstrap/forms/Select';
import Option from '../../bootstrap/Option';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';

const ModalDetail = ({ id, isOpen, setIsOpen, setDetail, statusRefresh, jenisVerifikasi, statusVerifikasi}) => {
	const token = sessionStorage.getItem('access_token');
	console.log('Apa bos');
	console.log(setDetail);
	console.log(jenisVerifikasi);
	const formData = {
		title: jenisVerifikasi === 'Perubahan Data'?'Verifikasi Data Karyawan':'Verifikasi Dokumen Karyawan',
		uuid: '',
		uuid_dokumen: '',
		uuid_karyawan: '',
		nama_dokumen:'',
		link: '',
		status: '',
	};

	// Fitur Modal
	const [state, setState] = useState(false);
	const [statusSet, setStatusSet] = useState('');
	const formDataVerif = {
		title: jenisVerifikasi === 'Perubahan Data'?'Verifikasi Data Karyawan':'Verifikasi Dokumen Karyawan',
		uuid: setDetail.uuid,
		nama_dokumen: setDetail.nama_dokumen,
		uuid_dokumen: setDetail.uuid_dokumen,
		uuid_karyawan: setDetail.uuid_karyawan,
		link: setDetail.link,
		status: setDetail.status,
	};
	// End Fitur Modal
	
	const [dataEdit, setDataEdit] = useState(formData);

	useEffect(() => {
		if (setDetail) {
			if(jenisVerifikasi === 'Dokumen'){
				setDataEdit({
					title: jenisVerifikasi === 'Perubahan Data'?'Verifikasi Data Karyawan':'Verifikasi Dokumen Karyawan',
					uuid: setDetail.uuid,
					nama_dokumen: setDetail.nama_dokumen,
					uuid_dokumen: setDetail.uuid_dokumen,
					uuid_karyawan: setDetail.uuid_karyawan,
					link: setDetail.link,
					status: setDetail.status,
				});
				
				const getSelectStatus = setDetail.status;
				setSelectedStatusDokumen(getSelectStatus);
			}
		}
	}, [setDetail]); // eslint-disable-line react-hooks/exhaustive-deps

	
	const [selectedStatusDokumen, setSelectedStatusDokumen] = useState(setDetail.status);
	const handleSelectChange = (event, param) => {
		if (event) {
			switch (param) {
				case 'status_dokumen':
					setSelectedStatusDokumen(event.target.value);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'status_dokumen':
					setSelectedStatusDokumen(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const tutupForm = () => {
		setSelectedStatusDokumen(null);
	};

	const handleSubmit = (status) => {
		let statusVerif = '';
		let linkSubmit = '';
		let data = {};
		if(jenisVerifikasi === 'Dokumen'){
			if(status === 'Tolak'){
				statusVerif = 'Tidak Aktif';
			}
			else if(status === 'Setuju'){
				statusVerif = 'Aktif';
			}

			linkSubmit = 'administrasi/dokumen-karyawan';
			data = {
				uuid: dataEdit.uuid,
				uuid_dokumen: dataEdit.uuid_dokumen,
				uuid_karyawan: dataEdit.uuid_karyawan,
				link: dataEdit.link,
				status: statusVerif,
			};
		}
		else if(jenisVerifikasi === 'Perubahan Data'){
			if(status === 'Tolak'){
				statusVerif = 'Tolak';
			}
			else if(status === 'Setuju'){
				statusVerif = 'Selesai';
			}

			linkSubmit = 'karyawan/perubahan/verifikasi';
			data = {
				uuid: setDetail.uuid,
				status: statusVerif,
			};
		}

		GlobalServices.update(data, linkSubmit, token)
			.then((response) => {
				if (response.data.code === 201) {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>{jenisVerifikasi === 'Dokumen'?'Berhasil Ubah Status':`Berhasil ${statusVerif} Perubahan Data`}</span>
						</span>,
							jenisVerifikasi === 'Dokumen'?'Berhasil Ubah Status Dokumen Pegawai':`Berhasil ${statusVerif} Perubahan Data Pegawai`,
						);
					statusRefresh();
					setDataEdit(formData);
					setState(false);
					setIsOpen(false);
					tutupForm();
				} else {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Warning' size='lg' className='me-1' />
							<span>{jenisVerifikasi === 'Dokumen'?'Gagal Ubah Status':`Gagal ${statusVerif} Perubahan Data`}</span>
						</span>,
						jenisVerifikasi === 'Dokumen'?'Gagal Ubah Status Dokumen Pegawai':`Gagal ${statusVerif} Perubahan Data Pegawai`,
					);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const tombolVerif = (stat) => {
		switch (stat) {
			case 'Dokumen':
				return <>
					<Button 
						color='danger' 
						type='submit' 
						style={{width:'100px'}}
						onClick={() => {
							setState(true);
							setIsOpen(false)
							setStatusSet('Tolak')
						}}
					>
						Tolak
					</Button>
					<Button 
						color='success' 
						type='submit' 
						style={{width:'100px'}}
						onClick={() => {
							setState(true);
							setIsOpen(false)
							setStatusSet('Setuju')
						}}
					>
						Setuju
					</Button>
				</>
				// break;
			
			case 'Perubahan Data':
				let tombolnya = ''

				if(statusVerifikasi==='Verifikasi'){
					tombolnya = <>
						<Button 
							color='danger' 
							type='submit' 
							style={{width:'100px'}}
							onClick={() => {
								setState(true);
								setIsOpen(false)
								setStatusSet('Tolak')
							}}
						>
							Tolak
						</Button>
						<Button 
							color='success' 
							type='submit' 
							style={{width:'100px'}}
							onClick={() => {
								setState(true);
								setIsOpen(false)
								setStatusSet('Setuju')
							}}
						>
							Setuju
						</Button>
					</>
				}
				else{
					tombolnya = ''
				}

				return tombolnya;
				// break;
			default:
				return ''
				// break;
		}
	}

	const tutupVerifikasi = () => {
		setState(false);
		setIsOpen(true)
	};

	if (id || id === 0) {
		return <>
			<Modal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				setDetail={setDetail}
				isStaticBackdrop
				size='md'
				titleId={id}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={id}>
						{dataEdit.title}
					</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					{jenisVerifikasi === 'Dokumen'
						?
						<div className='row g-4'>
							<FormGroup id='alamat' label='Nama Dokumen' className='col-md-12'>
								<Input
									type='text'
									name='nama_dokumen'
									value={dataEdit.nama_dokumen}
									// onChange={handleInputChange}
									isRequired
									disabled
								/>
							</FormGroup>
							{/* <FormGroup id='status_dokumen' label='Status Dokumen' className='col-md-12'>
								<Select
									id='status_dokumen'
									ariaLabel='Default select example'
									placeholder='Pilih Status Dokumen'
									onChange={(e) => {
										handleSelectChange(e, 'status_dokumen');
									}}
									value={selectedStatusDokumen}
									size='md'>
									<Option value='Aktif'>Aktif</Option>
									<Option value='Diajukan'>Diajukan</Option>
									<Option value='Tidak Aktif'>Tidak Aktif</Option>
								</Select>
							</FormGroup> */}
							<div align='right'>
								<a href={dataEdit.link} target="_blank" rel="noreferrer">
									<Button
										icon='Download'
										color='primary'
									>
										Download Dokumen
									</Button>
								</a>
							</div>
							<div>
								<Iframe 
									id="myFrame" 
									src={dataEdit.link}
									width="100%"
									height="450px"
									className="myClassname"
									display="initial"
									position="relative"
									controls="0"
								/>
							</div>
						</div>
						:
						
						<div className='row g-4'>
							{
								setDetail.data_jabatan_struktural?
									<FormGroup id='uuid_jabatan_struktural' label='Jabatan Struktural' className='col-md-12'>
										<Input
											type='text'
											name='uuid_jabatan_struktural'
											value={setDetail.data_jabatan_struktural.nama}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.data_jabatan_fungsional?
									<FormGroup id='uuid_jabatan_fungsional' label='Jabatan Fungsional' className='col-md-6'>
										<Input
											type='text'
											name='uuid_jabatan_fungsional'
											value={setDetail.data_jabatan_fungsional.nama}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.tmt_jabatan_fungsional?
									<FormGroup id='tmt_jabatan_fungsional' label='TMT Jabatan Fungsional' className='col-md-6'>
										<Input
											type='date'
											name='tmt_jabatan_fungsional'
											value={setDetail.tmt_jabatan_fungsional}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.status_pegawai?
									<FormGroup id='status_pegawai' label='Status Pegawai' className='col-md-6'>
										<Input
											type='text'
											name='status_pegawai'
											value={setDetail.status_pegawai}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.jenis_karyawan?
									<FormGroup id='jenis_karyawan' label='Jenis Pegawai' className='col-md-6'>
										<Input
											type='text'
											name='jenis_karyawan'
											value={setDetail.jenis_karyawan}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.data_pangkat_golongan?
									<FormGroup id='uuid_pangkat_golongan' label='Jabatan Golongan' className='col-md-12'>
										<Input
											type='text'
											name='uuid_pangkat_golongan'
											value={`${setDetail.data_pangkat_golongan.golongan}${setDetail.data_pangkat_golongan.ruang} ${setDetail.data_pangkat_golongan.pangkat}`}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.tmt_cpns?
									<FormGroup id='tmt_cpns' label='TMT CPNS' className='col-md-6'>
										<Input
											type='date'
											name='tmt_cpns'
											value={setDetail.tmt_cpns}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.tmt_pns?
									<FormGroup id='tmt_pns' label='TMT PNS' className='col-md-6'>
										<Input
											type='date'
											name='tmt_pns'
											value={setDetail.tmt_pns}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.tmt_golongan?
									<FormGroup id='tmt_golongan' label='TMT Golongan' className='col-md-6'>
										<Input
											type='date'
											name='tmt_golongan'
											value={setDetail.tmt_golongan}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.prodi?
									<FormGroup id='prodi' label='Prodi' className='col-md-6'>
										<Input
											type='text'
											name='prodi'
											value={setDetail.prodi}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.data_tempat_tugas?
									<FormGroup id='uuid_tempat_tugas' label='Tempat Tugas' className='col-md-6'>
										<Input
											type='text'
											name='uuid_tempat_tugas'
											value={setDetail.data_tempat_tugas.nama}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.nip?
									<FormGroup id='nip' label='NIP' className='col-md-6'>
										<Input
											type='text'
											name='nip'
											value={setDetail.nip}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.nama?
									<FormGroup id='nama' label='Nama Pegawai' className='col-md-6'>
										<Input
											type='text'
											name='nama'
											value={setDetail.nama}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.tempat_lahir?
									<FormGroup id='tempat_lahir' label='Tempat Lahir' className='col-md-6'>
										<Input
											type='text'
											name='tempat_lahir'
											value={setDetail.tempat_lahir}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.tanggal_lahir?
									<FormGroup id='tanggal_lahir' label='Tanggal Lahir' className='col-md-6'>
										<Input
											type='date'
											name='tanggal_lahir'
											value={setDetail.tanggal_lahir}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.jenis_kelamin?
									<FormGroup id='jenis_kelamin' label='Jenis Kelamin' className='col-md-6'>
										<Input
											type='text'
											name='jenis_kelamin'
											value={setDetail.jenis_kelamin}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.agama?
									<FormGroup id='agama' label='Agama' className='col-md-6'>
										<Input
											type='text'
											name='agama'
											value={setDetail.agama}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.golongan_darah?
									<FormGroup id='golongan_darah' label='Golongan Darah' className='col-md-6'>
										<Input
											type='text'
											name='golongan_darah'
											value={setDetail.golongan_darah}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.status_perkawinan?
									<FormGroup id='status_perkawinan' label='Status Perkawinan' className='col-md-6'>
										<Input
											type='text'
											name='status_perkawinan'
											value={setDetail.status_perkawinan}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.pendidikan_terakhir?
									<FormGroup id='status_perkawinan' label='Pendidikan Terakhir' className='col-md-6'>
										<Input
											type='text'
											name='pendidikan_terakhir'
											value={setDetail.pendidikan_terakhir}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
							{
								setDetail.alamat?
									<FormGroup id='alamat' label='Alamat' className='col-md-12'>
										<Input
											type='text'
											name='alamat'
											value={setDetail.alamat}
											readOnly
										/>
									</FormGroup>
								:
								''
							}
						</div>
					}
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button
						color='info'
						// isOutline
						// className='border-0'
						style={{width:'100px'}}
						onClick={() => {
							setIsOpen(false);
							setDataEdit(formData);
							tutupForm();
						}}>
						Tutup
					</Button>

					{ tombolVerif(jenisVerifikasi) }
				</ModalFooter>
			</Modal>

			<Modal
				isOpen={state}
				setIsOpen={setState}
				size='md'
				isStaticBackdrop
				titleId='exampleModalLabel'
			>
				<ModalHeader>&nbsp;</ModalHeader>
				<ModalBody>
					<p align='center'>
						<h2>Yakin Ingin {statusSet} {jenisVerifikasi === 'Perubahan Data'?'Perubahan Data Karyawan':'Dokumen'} ?</h2> <br />
						Proses ini tidak dapat dibatalkan!
					</p>
					<br />
					<div align='center'>
						<Button
							color='danger'
							style={{width:'100px'}}
							// isOutline
							// className='border-0'
							onClick={() => {
								tutupVerifikasi()
								setDataEdit(formDataVerif);
							}}
							// onClick={() => setState(false)}
						>
							Batal
						</Button>
						&nbsp;&nbsp;
						<Button 
							color='success'
							style={{width:'100px'}} 
							onClick={() => {
								handleSubmit(statusSet)
							}}
						>
							Yakin
						</Button>
					</div>
				</ModalBody>
				<ModalFooter>
					&nbsp;
				</ModalFooter>
				</Modal>
		</>
	}
	return null;
};
ModalDetail.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	setDetail: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
};

export default ModalDetail;
