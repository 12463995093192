import React, { useState, useEffect } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMaster } from '../../menu';
import Card, { CardBody } from '../bootstrap/Card';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import Breadcrumb from '../bootstrap/Breadcrumb';
import GlobalServices from './Services/GlobalServices';

// Modal
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import ModalTambah from './Jam/ModalTambahJam';
import ModalEdit from './Jam/ModalEditJam';
// import { getColorNameWithIndex } from '../../common/data/enumColors';
// Alert
import showNotification from '../extras/showNotification';

const Jam = () => {
	const token = sessionStorage.getItem('access_token');
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);

	const [list, setList] = useState([]);
	const [pagination, setPagination] = useState({
		current_page: 1,
		from: 1,
		last_page: 1,
		first_page_url: '',
		last_page_url: '',
		next_page_url: '',
		prev_page_url: 10,
		path: '',
		per_page: 10,
		to: 10,
		total: 0,
	});

	const getDataList = (page) => {
		let extra = ''

		if (page) {
			extra = `?page=${page}&q=${cariValue.nama}`
		}

		GlobalServices.getAll(`absensi/jam${extra}`, token)
			.then((response) => {
				setList(response.results.data);
				setPagination(response.results)
				setCurrentPage(response.results.current_page)
				setPerPage(response.results.per_page)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const refreshList = (page = '') => {
		getDataList(page);
	};
	
	const [listInstansi, setListInstansi] = useState([]);

	useEffect(() => {
		getDataList();
		GlobalServices.getAll('instansi', token).then((hasil) => {
			console.log(hasil);
			setListInstansi(hasil.results.data);
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const [tambahModalStatus, setTambahModalStatus] = useState(false);
	const [editModalStatus, setEditModalStatus] = useState(false);

	const [state, setState] = useState(false);

	const [centeredStatus, setCenteredStatus] = useState(false);

	const initialStatus = () => {
		setCenteredStatus(false);
	};

	const initialInstansiState = {
		id: '',
		nama: '',
		alamat: '',
		no_telp: '',
	};

	const [instansi, setInstansi] = useState(initialInstansiState);

	const [setDetail, setdetailData] = useState({});
	const detailData = (data) => {
		if (data) {
			const datanya = data;
			console.log('data Detail');
			console.log(datanya);
			const dataDetail = {
				active: datanya.active,
				jam_absen: datanya.jam_absen,
				jenis: datanya.jenis,
				keterlambatan: datanya.keterlambatan,
				tipe: datanya.tipe,
				urutan: datanya.urutan,
				uuid_instansi: datanya.uuid_instansi,
				uuid: datanya.uuid,
			};
			setdetailData(dataDetail);
		}
	};

	// Fitur Hapus Data
	const onDelete = (getId) => {
		setInstansi({ ...instansi, id: getId });
	};

	const hapusData = () => {
		const data = {
			uuid: instansi.id,
		};

		GlobalServices.remove(data, 'absensi/jam', token).catch((e) => {
			setState(false);

			if (e === 204) {
				refreshList();
				setState(false);
				setInstansi(initialInstansiState);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Berhasil Hapus Data</span>
					</span>,
					'Berhasil Hapus Data Jam',
				);
			} else {
				setState(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Hapus Data</span>
					</span>,
					'Gagal Hapus Data Jam',
				);
			}
		});
	};
	// End Fitur Hapus Data

	// Fitur Pencarian
	const dataCari = {
		nama: '',
	};

	const [cariValue, setcariValue] = useState(dataCari);
	const handleInputSearch = (event) => {
		const { name, value } = event.target;
		setcariValue({ ...cariValue, [name]: value });
	};

	const handleSearch = () => {
		let extra = `?page=${1}`
	
		GlobalServices.getSearch(`absensi/jam${extra}`, cariValue.nama, token)
			.then((response) => {
				setList(response.data.results.data);
				setPagination(response.data.results)
				setCurrentPage(response.data.results.current_page)
				setPerPage(response.data.results.per_page)
				
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const handleSearchEnter = (event) => {
		if (event.key === 'Enter') {
			const extra = `?page=${1}`

			GlobalServices.getSearch(`absensi/jam${extra}`, cariValue.nama, token)
				.then((response) => {
					console.log(response);
					setList(response.data.results.data);
					setPagination(response.data.results)
					setCurrentPage(response.data.results.current_page)
					setPerPage(response.data.results.per_page)
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};
	// End Fitur Pencarian

	return (
		<PageWrapper title={menuMaster.konfigurasi.subMenu.jam.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{
								title: menuMaster.konfigurasi.text,
								to: '#',
							},
							{
								title: menuMaster.konfigurasi.subMenu.jam.text,
								to: menuMaster.konfigurasi.subMenu.jam.path,
							},
						]}
					/>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
							<CardBody isScrollable className='table-responsive'>
								{/* <h1>{list}</h1> */}
								<div className='row'>
									<div className='col-3' align='left'>
										<Button
											// icon='Add'
											color='primary'
											// isLight
											onClick={() => setTambahModalStatus(true)}>
											Tambah Data
										</Button>
									</div>
									<div className='col-9'>
									<div className='row'>
											<div className='col-11'>
												<Input
													type='text'
													name='nama'
													value={cariValue.nama}
													onChange={handleInputSearch}
													isRequired
													onKeyDown={handleSearchEnter}
												/>
											</div>
											<div className='col-1' align='right'>
												<Button
													icon='Search'
													color='primary'
													// isLight
													onClick={handleSearch}>
													{/* Cari Data */}
												</Button>
											</div>
										</div>
									</div>
								</div>
								<br />
								<table className='table table-modern table-hover'>
									<thead>
										<tr>
											{/* <th>No.</th> */}
											<th>Jenis</th>
											<th>Tipe</th>
											<th>Jam Absen</th>
											<th>Keterlambatan</th>
											<th>Instansi</th>
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{list.map(
											(i, key) => (
												<tr key={i.id}>
													{/* <td>{key + 1}</td> */}
													<td>{i.jenis}</td>
													<td>{i.tipe}</td>
													<td>{i.jam_absen}</td>
													<td>{i.keterlambatan}</td>
													<td>{i.data_instansi.nama}</td>
													<td>
														<Button
															icon='Pencil'
															color='primary'
															onClick={() => {
																setEditModalStatus(true);
																detailData(i);
															}}>
															Edit
														</Button>{' '}
														<Button
															icon='Delete'
															color='danger'
															onClick={() => {
																initialStatus();
																setCenteredStatus(true);
																setState(true);
																onDelete(i.uuid);
															}}>
															Hapus
														</Button>
													</td>
												</tr>
											),
										)}
									</tbody>
								</table>
							</CardBody>
							<PaginationButtons
								data={list}
								label='Jam'
								setCurrentPage={getDataList}
								setPerPage={setPerPage}
								currentPage={currentPage}
								perPage={perPage}
								paginationDetail={pagination}
								setpaginationDetail={setPagination}
							/>
						</Card>
					</div>
				</div>
			</Page>
			<ModalTambah
				setIsOpen={setTambahModalStatus}
				isOpen={tambahModalStatus}
				id={0}
				listInstansi={listInstansi}
				statusRefresh={refreshList}
			/>
			<ModalEdit
				setIsOpen={setEditModalStatus}
				isOpen={editModalStatus}
				id={0}
				setDetail={setDetail}
				listInstansi={listInstansi}
				statusRefresh={refreshList}
			/>
			<Modal
				isOpen={state}
				setIsOpen={setState}
				titleId='exampleModalLabel'
				isCentered={centeredStatus}>
				<ModalHeader>&nbsp;</ModalHeader>
				<ModalBody>
					<p align='center'>
						<h2>Yakin Ingin Hapus Data?</h2> <br />
						Proses ini tidak dapat dibatalkan!
					</p>
					<br />
					<div align='center'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						&nbsp;&nbsp;
						<Button color='danger' icon='Delete' onClick={hapusData}>
							Hapus
						</Button>
					</div>
				</ModalBody>
				<ModalFooter>
					&nbsp;
					{/* <div align='center'>
						<Button
							color='success'
							// isOutline
							className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						<Button color='danger' icon='Delete'>
							Hapus
						</Button>
					</div> */}
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};

export default Jam;
