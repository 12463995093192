import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { TabContent, TabPane, Nav, NavItem, NavLink, Form, FormGroup, Label, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { Link, useLocation, useHistory } from 'react-router-dom';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMaster } from '../../menu';
import Card, { CardBody } from '../bootstrap/Card';
import Breadcrumb from '../bootstrap/Breadcrumb';
import Button from '../bootstrap/Button';

// Page Tab Pane
import SuratMasukStatusProses from './SuratMasukByInstansiProses';
import SuratMasukStatusMenungguVerifikasi from './SuratMasukByInstansiVerifikasi';
import SuratMasukStatusSelesai from './SuratMasukByInstansiSelesai';
import SuratMasukCetakLaporan from './SuratMasukCetakLaporan';

const SuratMasukByInstansi = (props) => {
	const roleUser = localStorage.getItem('role_user');
	const uuidInstansiRole = localStorage.getItem('uuid_instansi');
	
	const location = useLocation()
  	const setDataProp = location.state?.dataProp
	// const history = useHistory();

	// Fitur Tab Pane
	const [resetList, setResetList] = useState(true);
	const [activeTabIcon, setActiveTabIcon] = useState('1');
	const [styleTab, setStyleTab] = useState({ backgroundColor: '#6a5cd0' });
	const [styleFontTab, setStyleFontTab] = useState('white');
	const toggleTab = (tab) => {
		if (activeTabIcon !== tab) {
			setActiveTabIcon(tab)
			setStyleTab({ backgroundColor: '#6a5cd0' })
			setStyleFontTab('white')
		}
	}
	// ENd Fitur Tab Pane

	const { linkTo, title } = props

	let titlePage = menuMaster.suratMasuk.text
	if (title) {
		titlePage = title
	}
	
	return (
		<PageWrapper title={menuMaster.suratMasuk.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{ 
								title: titlePage, 
								to: menuMaster.suratMasuk.path 
							}
						]}
					/>
				</SubHeaderLeft>
				{
					roleUser === 'Super Admin'?
					<>
						<SubHeaderRight>
							<Button
								icon='ArrowLeft'
								color='primary'
								// isLight
								onClick={() => props.history.goBack()}>
								Kembali
							</Button>
						</SubHeaderRight>
					</>
					:
					<>
					</>
				}
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
							<CardBody isScrollable className='table-responsive'>
								<Nav tabs className="tabs-color">
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '1' })}
											onClick={() => { setResetList(!resetList); toggleTab('1'); }} style={ activeTabIcon==='1'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-truck-alt" /><font color={activeTabIcon==='1'?styleFontTab:'#6a5cd0'}>Proses</font>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '2' })}
											onClick={() => { setResetList(!resetList); toggleTab('2'); }} style={ activeTabIcon==='2'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-truck-alt" /><font color={activeTabIcon==='2'?styleFontTab:'#6a5cd0'}>Menunggu Verifikasi</font>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '3' })}
											onClick={() => { setResetList(!resetList); toggleTab('3'); }} style={ activeTabIcon==='3'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-truck-alt" /><font color={activeTabIcon==='3'?styleFontTab:'#6a5cd0'}>Selesai</font>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '4' })}
											onClick={() => { setResetList(!resetList); toggleTab('4'); }} style={ activeTabIcon==='4'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-truck-alt" /><font color={activeTabIcon==='4'?styleFontTab:'#6a5cd0'}>Cetak Laporan</font>
										</NavLink>
									</NavItem>
								</Nav>
								<br />
								<TabContent activeTab={activeTabIcon}>
									<TabPane tabId="1">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<SuratMasukStatusProses setReset={resetList}/>
												</Col>
											</Row>

										</div>
									</TabPane>

									{/* =========================================================================== */}
									<TabPane tabId="2">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<SuratMasukStatusMenungguVerifikasi setReset={resetList}/>
												</Col>
											</Row>

										</div>
									</TabPane>

									{/* =========================================================================== */}
									<TabPane tabId="3">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<SuratMasukStatusSelesai setReset={resetList}/>
												</Col>
											</Row>

										</div>
									</TabPane>

									{/* =========================================================================== */}
									<TabPane tabId="4">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<SuratMasukCetakLaporan setReset={resetList}/>
												</Col>
											</Row>

										</div>
									</TabPane>
								</TabContent>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default SuratMasukByInstansi;
