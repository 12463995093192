import React, { useEffect, useState } from 'react';
import Select from "react-select";
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import GlobalServices from '../Services/GlobalServices';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';

const ModalData = ({ id, isOpen, setIsOpen, statusRefresh, detailDokumen, listDokumen, title, action }) => {
	const token = sessionStorage.getItem('access_token');
	const initialDokumenState = {
		uuid: '',
		uuid_surat_instansi: '',
		uuid_dokumen: '',
	};
	
	const [dokumen, setDokumen] = useState(initialDokumenState);
	const [dataListDokumen, setDataListDokumen] = useState(listDokumen);
	const [selectedDokumen, setSelectedDokumen] = useState(null);

	useEffect(() => {
		setDataListDokumen(listDokumen)
		if (detailDokumen) {
			setDokumen(detailDokumen)
		}
	}, [detailDokumen, listDokumen])
	
	const handleSelectChange = (event, param) => {
		console.log('select value')
		console.log(event)
		if (event) {
			switch (param) {
				case 'dokumen':
					setSelectedDokumen(event);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'dokumen':
					setSelectedDokumen(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "dokumen") {
				let newArray = dataListDokumen;	
				newArray = newArray.filter(day => day !== e);
				setDataListDokumen(newArray)
			}
		}
	};

	const handleSubmit = () => {
		console.log('Select Dokumen')
		console.log(selectedDokumen)
		if (selectedDokumen) {
			const data = {
				...dokumen, 
				uuid_surat_instansi: id,
				uuid_dokumen: selectedDokumen.value,
			}

			if (action === 'Tambah') {
				delete data.uuid
			}

			console.log(data);

			// let validated = true
			// Object.keys(data).map((i, key) => {
			// 	let stat = false
			// 	if (typeof data[i] === 'string') {
			// 		if (data[i] !== '') {
			// 			stat = true
			// 		}
			// 	} else if (data[i].length > 0) {
			// 		stat = true
			// 	}
				
			// 	validated = validated && stat

			// 	return ''
			// })

			// console.log(validated);

			if (action === 'Tambah') {
				GlobalServices.create(data, `administrasi/dokumen-syarat-surat`, token)
					.then((response) => {
						console.log(response);
	
						if (response.data.code === 201) {
							setIsOpen(false);
							// setSelectedDokumen(null);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil {title}</span>
								</span>,
								`Berhasil ${title}`,
							);
							tutupForm();
							statusRefresh();
						} else {
							// setIsOpen(false);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal {title}</span>
								</span>,
								`Gagal ${title}`,
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				GlobalServices.update(data, `administrasi/dokumen-syarat-surat`, token)
					.then((response) => {
						console.log(response);
	
						if (response.data.code === 201) {
							setIsOpen(false);
							// setSelectedDokumen(null);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil {title}</span>
								</span>,
								`Berhasil ${title}`,
							);
							statusRefresh();
						} else {
							// setIsOpen(false);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal {title}</span>
								</span>,
								`Gagal ${title}`,
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			}
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal {title}</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	const tutupForm = () => {
		setSelectedDokumen(null);
	};
	
	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
					<ModalHeader setIsOpen={setIsOpen} className='p-4'>
						<ModalTitle id={id}>{title}</ModalTitle>
					</ModalHeader>
					<ModalBody className='px-4'>
						<div className='row g-4'>
							<Select
								inputId="dokumen"
								classNamePrefix="select"
								name="dokumen"
								placeholder="Pilih Dokumen"
								isClearable
								required
								defaultValue={selectedDokumen}
								value={selectedDokumen}
								options={dataListDokumen}
								onInputChange={e => handleInputSelect(e, "dokumen")}
								onChange={e => {
									handleSelectChange(e, "dokumen");
								}}
							/>
						</div>
					</ModalBody>
					<ModalFooter className='px-4 pb-4'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => {
								setIsOpen(false);
								setDokumen(initialDokumenState);
								// setSelectedDokumen(null);
							}}>
							Batal
						</Button>
						{/* <Button color='info' onClick={formik.handleSubmit}> */}
						<Button color='info' type='submit' onClick={handleSubmit}>
							Simpan
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalData.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
	listDokumen: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ModalData;
