import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMaster } from '../../menu';
import Card, { CardBody } from '../bootstrap/Card';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import Breadcrumb from '../bootstrap/Breadcrumb';
import GlobalServices from './Services/GlobalServices';
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';

// Modal
import ModalDetail from './Karyawan/ModalVerifikasi';

// Alert
import showNotification from '../extras/showNotification';

const VerifikasiDataKaryawan = ({ setReset }) => {
	const roleUser = localStorage.getItem('role_user');
	const uuidInstansi = localStorage.getItem('uuid_instansi');
	const token = sessionStorage.getItem('access_token');
	const formData = {
		id: '',
		nama: '',
		alamat: '',
		no_telp: '',
	};

	const [dataValues, setDataValues] = useState(formData);

	// Fitur Pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);

	const [pagination, setPagination] = useState({
		current_page: 1,
		from: 1,
		last_page: 1,
		first_page_url: '',
		last_page_url: '',
		next_page_url: '',
		prev_page_url: 10,
		path: '',
		per_page: 10,
		to: 10,
		total: 0,
	});
	// End Fitur Pagination

	// Fetching Data
	const [loading, setLoading] = useState(true);
	const [list, setList] = useState([]);

	const getDataList = (page) => {
		let extra = ''

		if (page) {
			extra = `&page=${page}&q=${cariValue.nama}`
		}

		GlobalServices.getAll(`karyawan/perubahan/riwayat/${uuidInstansi}?status=Selesai${extra}`, token)
			.then((response) => {
				setList(response.results.data);
				setPagination(response.results)
				setCurrentPage(response.results.current_page)
				setPerPage(response.results.per_page)
				setLoading(false)
			})
			.catch((e) => {
				setLoading(false)
				console.log(e);
			});
	};

	const refreshList = (page = '') => {
		getDataList(page);
	};

	useEffect(() => {
		getDataList();
	}, [setReset]); // eslint-disable-line react-hooks/exhaustive-deps

	// End Fetching Data

	// Fitur Modal
	const [detailModalStatus, setDetailModalStatus] = useState(false);

	const [state, setState] = useState(false);
	// End Fitur Modal

	// Fitur Detail Data
	const [detailData, setdetailData] = useState({
		uuid: '',
		uuid_dokumen: '',
		nama_dokumen: '',
		uuid_karyawan: '',
		link: '',
		status: '',
	});

	const detail = (data) => {
		if (data) {
			const datanya = data;
			console.log('Data Detail Parent')
			console.log(datanya)
			
			setdetailData(data);
		}
	};
	// End Fitur Detail Data

	// Fitur Pencarian
	const dataCari = {
		nama: '',
	};

	const [cariValue, setcariValue] = useState(dataCari);
	const handleInputSearch = (event) => {
		const { name, value } = event.target;
		setcariValue({ ...cariValue, [name]: value });
	};

	const handleSearch = () => {
		let extra = `&page=${1}`

		GlobalServices.getSearch(`karyawan/perubahan/riwayat/${uuidInstansi}?status=Selesai${extra}`, cariValue.nama, token)
			.then((response) => {
				setList(response.data.results.data);
				setPagination(response.data.results)
				setCurrentPage(response.data.results.current_page)
				setPerPage(response.data.results.per_page)
				
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const handleSearchEnter = (event) => {
		if (event.key === 'Enter') {
			const extra = `&page=${1}`

			GlobalServices.getSearch(`karyawan/perubahan/riwayat/${uuidInstansi}?status=Selesai${extra}`, cariValue.nama, token)
				.then((response) => {
					setList(response.data.results.data);
					setPagination(response.data.results)
					setCurrentPage(response.data.results.current_page)
					setPerPage(response.data.results.per_page)
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};
	// End Fitur Pencarian

	return (
		<>
			{
				loading
				?
				<>
				<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
					<br/><br/><br/><br/>
					<div className='row g-3'>
					{Object.keys(COLORS).map((color) => (
						<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
							<Spinner color={COLORS[color]} isSmall isGrow />
						</div>
					))}
					</div>
					<br/><br/><br/><br/>
				</div>
				</>
			:
				<>
				<div className='row'>
					<div className='col-3' align='left'>
						&nbsp;
					</div>
					<div className='col-9'>
						<div className='row'>
							<div className='col-11'>
								<Input
									type='text'
									name='nama'
									value={cariValue.nama}
									onChange={handleInputSearch}
									isRequired
									onKeyDown={handleSearchEnter}
								/>
							</div>
							<div className='col-1' align='right'>
								<Button
									icon='Search'
									color='primary'
									// isLight
									onClick={handleSearch}>
									{/* Cari Data */}
								</Button>
							</div>
						</div>
					</div>
				</div>
				<br />
				<table className='table table-modern table-hover'>
					<thead>
						<tr>
							{/* <th>No.</th> */}
							<th>NIP</th>
							<th>Nama Karyawan</th>
							<th style={{ width: '150px', textAlign: 'center' }}>Aksi</th>
						</tr>
					</thead>
					<tbody>
						{list.map(
							(i, key) => (
								<tr key={i.id}>
									{/* <td>{key + 1}</td> */}
									<td>{i.nip}</td>
									<td>{i.nama}</td>
									<td style={{ textAlign: 'center' }}>
										<Button
											icon='Eye'
											color='primary'
											onClick={() => {
												setDetailModalStatus(true);
												detail(i);
											}}>
											{/* Edit */}
										</Button>{' '}
									</td>
								</tr>
							),
						)}
					</tbody>
				</table>
				<PaginationButtons
					data={list}
					label='Verifikasi Data Pegawai Selesai'
					setCurrentPage={getDataList}
					setPerPage={setPerPage}
					currentPage={currentPage}
					perPage={perPage}
					paginationDetail={pagination}
					setpaginationDetail={setPagination}
				/>
				</>
			}

			<ModalDetail
				setIsOpen={setDetailModalStatus}
				isOpen={detailModalStatus}
				id={0}
				setDetail={detailData}
				statusRefresh={refreshList}
				jenisVerifikasi='Perubahan Data'
				statusVerifikasi='Selesai'
			/>
						
		</>
	);
};

export default VerifikasiDataKaryawan;
