import React from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuOperator } from '../../menu';
import Button from '../bootstrap/Button';
import Breadcrumb from '../bootstrap/Breadcrumb';

import Card, { CardBody } from '../bootstrap/Card';

// Page Tab Pane
import TempatTugas from './TempatTugas';

const SuperOperatorTempatTugas = (props) => {
	const roleUser = localStorage.getItem('role_user');
	const uuidInstansi = localStorage.getItem('uuid_instansi');

	const { linkTo, title } = props

	let titlePage = menuOperator.tempatTugas.text
	if (title) {
		titlePage = title
	}

	return (
		<PageWrapper title={menuOperator.tempatTugas.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{ 
								title: menuOperator.tempatTugas.text, 
								to: menuOperator.tempatTugas.path 
							}
						]}
					/>
				</SubHeaderLeft>
				<SubHeaderRight>
					<Button
						icon='ArrowLeft'
						color='primary'
						// isLight
						onClick={() => props.history.goBack()}>
						Kembali
					</Button>
				</SubHeaderRight>
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
							<CardBody isScrollable className='table-responsive'>
								<TempatTugas setDataInstansi={uuidInstansi}/>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default SuperOperatorTempatTugas;
