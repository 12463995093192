import React, { useState, useEffect } from 'react';
import Select from "react-select";
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../bootstrap/Modal';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';
import Button from '../../../bootstrap/Button';
// import Select from '../../../bootstrap/forms/Select';
import Option from '../../../bootstrap/Option';
import showNotification from '../../../extras/showNotification';
import Icon from '../../../icon/Icon';
import GlobalServices from '../../Services/GlobalServices';
import SelectData from '../../Services/SelectData';

const ModalTambahHakAksesPengguna = ({ id, isOpen, setIsOpen, statusRefresh }) => {
	const roleUser = localStorage.getItem('role_user');
	const uuidInstansi = localStorage.getItem('uuid_instansi');
	const jenisInstansi = localStorage.getItem('jenis_instansi');
	const token = sessionStorage.getItem('access_token');

	const formData = {
		title: 'Tambah Hak Akses Pengguna',
		id: null,
		username: '',
		nip: '',
		password: '',
		no_hp: '',
		email: '',
		role: ''
	};

	const [selectedRole, setSelectedRole] = useState(null);
	const [listInstansi, setListInstansi] = useState([]);
	const [listDetailSurat, setListDetailSurat] = useState([]);
	const [listSurat, setListSurat] = useState([]);
	const [listTujuanSurat, setListTujuanSurat] = useState([]);
	const [listRole, setListRole] = useState(SelectData.jenisPenggunaSuperadmin);

	useEffect(() => {
		GlobalServices.getAll(`select-entity/select-enum/karyawan_akses/jenis`, token).then((response) => {
			let frmSelect = [];
			response.forEach(dt => {
				frmSelect.push({ value: dt, label: dt });
			});
			setListTujuanSurat(frmSelect)
		})
		.catch((e) => {
			console.log(e);
		});

	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	
	const [selectedInstansi, setSelectedInstansi] = useState(null);
	const [selectedSurat, setSelectedSurat] = useState(null);
	const [selectedJenisSurat, setSelectedJenisSurat] = useState(null);
	const [dataInstansi, setDataInstansi] = useState({jenis_instansi: '', nama_instansi: ''});

	const handleSelectChange = (event, param) => {
		if (event) {
			switch (param) {
				case 'role':
					setSelectedRole(event);
					break;
				case 'surat':
					setSelectedSurat(event);

					// let indexSelectedSurat = listDetailSurat.map(function(e) { return e.uuid; }).indexOf(event.value);
					// if (indexSelectedSurat !== -1) {
					// 	setSelectedJenisSurat(listDetailSurat[indexSelectedSurat].tujuan_surat)
					// } else {
					// 	setSelectedJenisSurat('Semua Surat')
					// }
					
					console.log(selectedSurat);
					// console.log(selectedJenisSurat);

					break;
				case 'jenis': 
					setSelectedJenisSurat(event);

					let uuid_instansi = null
					if(roleUser === 'Super Operator'){
						uuid_instansi = uuidInstansi
					} else {
						uuid_instansi = id.data_karyawan.uuid_instansi
					}

					if (event.value.toLowerCase() === 'dosen') {

						GlobalServices.getAll(`administrasi/surat-instansi/instansi/${uuid_instansi}/list`, token).then((hasil) => {
							console.log(hasil);
							if (hasil.results) {
								let frmSelect = [{
									value: "Semua Surat",
									label: "Semua Surat",
								}];
								hasil.results.forEach(dt => {
									frmSelect.push({ value: dt.uuid, label: dt.nama_alias });
								});
								setListSurat(frmSelect)
							}
						})
					} else if (event.value.toLowerCase() === 'mahasiswa') {
						GlobalServices.getAll(`administrasi/surat-instansi/instansi/${uuid_instansi}/mahasiswa/list`, token)
						.then((response) => {
							console.log(response);
							// console.log(JSON.parse(response.results[0].isi));

							if (response.results) {
								let frmSelect = [{
									value: "Semua Surat",
									label: "Semua Surat",
								}];
								response.results.forEach(dt => {
									frmSelect.push({ value: dt.uuid, label: dt.nama_alias });
								});
								setListSurat(frmSelect)
							}
						})
					} else if (event.value.toLowerCase() === 'kepegawaian') {
						setListSurat([{
							value: "",
							label: "Kepegawaian",
						}])
					} else {
						setListSurat([{
							value: "",
							label: "Semua Surat",
						}])
					}

					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'role':
					setSelectedRole(null);
					setSelectedInstansi(null);
					setSelectedSurat(null);
					setListSurat([]);
					setDataInstansi({jenis_instansi: '', nama_instansi: ''});
					break;
				case 'instansi':
					setSelectedInstansi(null);
					setSelectedSurat(null);
					setListSurat([]);
					setDataInstansi({jenis_instansi: '', nama_instansi: ''});
					break;
				case 'karyawan':
					setSelectedSurat(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "instansi") {
				let newArray = listInstansi;	
				newArray = newArray.filter(day => day !== e);
				setListInstansi(newArray)
			}
			else if (jenis === "role") {
				let newArray = listRole;	
				newArray = newArray.filter(day => day !== e);
				setListRole(newArray)
			}
			else if (jenis === "karyawan") {
				let newArray = listSurat;	
				newArray = newArray.filter(day => day !== e);
				setListSurat(newArray)
			}
		}
	};


	const [dataValues, setdataValues] = useState(formData);
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setdataValues({ ...dataValues, [name]: value });
	};

	const handleSubmit = () => {
		if ( selectedSurat &&
				selectedJenisSurat ) {
			let data = {
				uuid_karyawan: id.data_karyawan.uuid,
				jenis: selectedJenisSurat.label,
				uuid_jenis_surat: selectedSurat.value,
			};
			GlobalServices.create(data, 'administrasi/karyawan-akses', token)
				.then((response) => {
					console.log(response);
					if (response.data.code === 201) {
						setIsOpen(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Berhasil Tambah Data</span>
							</span>,
							'Berhasil Tambah Hak Akses Pengguna',
							);
						statusRefresh();
						setdataValues(formData);
						tutupForm();
					} else {
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Tambah Data</span>
							</span>,
							'Gagal Tambah Hak Akses Pengguna',
						);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Tambah Data</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	
	const tutupForm = () => {
		setSelectedRole(null);
		setSelectedInstansi(null);
		setSelectedSurat(null);
		setDataInstansi({jenis_instansi: '', nama_instansi: ''})
	};

	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={id}>{dataValues.title}</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row g-4'>
						{/* {
							roleUser === 'Super Operator'
							?
							<>
							</>
							:
							<>
							<FormGroup id='uuid_instansi' label='Nama Instansi' className='col-md-12'>
								<Select
									inputId="uuid_instansi"
									classNamePrefix="select"
									name="uuid_instansi"
									placeholder="Pilih Instansi"
									isClearable
									required
									defaultValue={selectedInstansi}
									value={selectedInstansi}
									options={listInstansi}
									onInputChange={e => handleInputSelect(e, "instansi")}
									onChange={e => {
										handleSelectChange(e, "instansi");
									}}
								/>
							</FormGroup>
							</>
						} */}

						<FormGroup id='jenis' label="Jenis" className="col-md-12">
							<Select
								inputId="jenis"
								classNamePrefix="select"
								name="jenis"
								placeholder="Pilih Jenis"
								isClearable
								required
								options={listTujuanSurat}
								onChange={e => {
									handleSelectChange(e, "jenis");
								}}
							/>
						</FormGroup>

						<FormGroup id='surat' label="Hak Akses" className="col-md-12">
							<Select
								inputId="surat"
								classNamePrefix="select"
								// name="karyawan"
								placeholder="Pilih Hak Akses"
								isClearable
								required
								// defaultValue={selectedSurat}
								// value={selectedSurat}
								options={listSurat}
								// onInputChange={e => handleInputSelect(e, "karyawan")}
								onChange={e => {
									handleSelectChange(e, "surat");
								}}
							/>
						</FormGroup>
							
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button
						color='danger'
						// isOutline
						// className='border-0'
						onClick={() => {
							setIsOpen(false);
							setdataValues(formData);
							tutupForm();
						}}>
						Batal
					</Button>
					{/* <Button color='info' onClick={formik.handleSubmit}> */}
					<Button color='success' type='submit' onClick={handleSubmit}>
						Simpan
					</Button>
				</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalTambahHakAksesPengguna.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	// listInstansi: PropTypes.func.isRequired,
	// listGolonganJabatan: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
};

export default ModalTambahHakAksesPengguna;
