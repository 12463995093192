import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
// import data from './DataInstansi';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import Option from '../../bootstrap/Option';
import GlobalServices from '../Services/GlobalServices';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import COLORS from '../Services/Spinner';
import Spinner from '../../bootstrap/Spinner';
// import { useFormik } from 'formik';
// import moment from 'moment';
// import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
// import Label from '../../bootstrap/forms/Label';
// import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
// import PAYMENTS from '../../../common/data/enumPaymentMethod';

const ModalData = ({ id, isOpen, setIsOpen, statusRefresh, detailSurat, listJabatanStruktural, title }) => {
	const token = sessionStorage.getItem('access_token');
	const jabatanStrukturalUser = localStorage.getItem('uuid_jabatan_struktural');
	const initSurat = {
		uuid: '',
		nomor_surat: '',
		pengirim: '',
		perihal: '',
		status: '',
		tanggal_surat: '',
		dokumen: '',
		memo: '',
		uuid_instansi: '',
		uuid_pengguna: '',
		uuid_jabatan_struktural_tujuan: '',
		data_surat_masuk_disposisi: []
	};
	// console.log(listJabatanStruktural);

	// const [selectedInstansi, setSelectedInstansi] = useState(null);
	const [loading, setLoading] = useState(false);
	const [surat, setSurat] = useState(initSurat);
	const [dokumenSelected, setDokumenSelected] = useState(false);
	const [isComplete, setIsComplete] = useState(true);

	useEffect(() => {
		if (detailSurat) {
			setSurat(detailSurat)
		}
		setFrmJabatanStruktural(listJabatanStruktural)
	}, [detailSurat, listJabatanStruktural]) // eslint-disable-line react-hooks/exhaustive-deps
	
	// const handleSelectChange = (e, type) => {
	// 	if (type === 'multiple') {
	// 		let value = Array.from(e.target.selectedOptions, option => option.value);
	// 		setSurat({
	// 			...surat, [e.target.name]: value
	// 		})
	// 	} else { 
	// 		setSurat({
	// 			...surat, [e.target.name]: e.target.value
	// 		})
	// 	}
	// 	// setSelectedInstansi(e.target.value);
	// };

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setSurat({ ...surat, [name]: value });
	};

	const [selectedDisposisiTujuan, setSelectedDisposisiTujuan] = useState();
	const [frmJabatanStruktural, setFrmJabatanStruktural] = useState(listJabatanStruktural);

	const handleSelectChange = (e, param) => {
		if (e) {
			switch (param) {
				case 'disposisi-tujuan':
					setSelectedDisposisiTujuan(e);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'disposisi-tujuan':
					setSelectedDisposisiTujuan(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "disposisi-tujuan") {
				let newArray = frmJabatanStruktural;
	
				newArray = newArray.filter(day => day !== e);

				// setSelectedDisposisiTujuan(setSelect)
				setFrmJabatanStruktural(newArray)
			} else {
			
		  	}
		}
	};

	const handleSubmit = () => {
		let tanggalSurat = new Date()
		tanggalSurat = tanggalSurat.toISOString().slice(0, 10)

		let uuidPengguna = localStorage.getItem('uuid_user')

		let data = {}

		if (title === 'Tambah') {
			data = {
				uuid_instansi: id,
				tanggal_surat: tanggalSurat,
				pengirim: surat.pengirim,
				perihal: surat.perihal,
				uuid_pengguna: uuidPengguna,
				dokumen: surat.dokumen,
				// disposisi_tujuan: {
				// 	uuid_jabatan_struktural_tujuan: selectedDisposisiTujuan.value,
				// 	uuid_pengguna: uuidPengguna,
				// 	memo: surat.memo,
				// }
			}
		} else {
			data = {
				uuid: surat.uuid,
				uuid_instansi: id,
				tanggal_surat: tanggalSurat,
				pengirim: surat.pengirim,
				perihal: surat.perihal,
				uuid_pengguna: uuidPengguna,
				dokumen: surat.dokumen,
			}
		}
		
		// console.log(data);

		let validated = true

		// Validasi isi parameter root
		Object.keys(data).map((i, key) => {
			let stat = false
			// console.log(typeof data[i]);
			if (typeof data[i] === 'string') {
				if (data[i] !== '') {
					stat = true
				}
				// just receive string value
				validated = validated && stat
			} 
			// else if (data[i].length > 0) {
			// 	stat = true
			// }
			// validated = validated && stat
			return ''
		})

		// if (title === 'Tambah') {
		// 	// validasi isi parameter object disposisi_tujuan
		// 	Object.keys(data.disposisi_tujuan).map((i, key) => {
		// 		let stat = false
		// 		if (typeof data[i] === 'string') {
		// 			if (data[i] !== '') {
		// 				stat = true
		// 			}
		// 			// just receive string value
		// 			validated = validated && stat
		// 		} 
		// 		return ''
		// 	})
		// }
		
		// console.log(validated);

		if (validated) {
			setLoading(true)
			if (title === 'Tambah') {
				data = { ...data, nomor_surat: surat.nomor_surat}
				GlobalServices.create(data, `administrasi/surat-masuk`, token)
					.then((response) => {
						console.log('===== Berhasil Simpan Surat Masuk =====');
						console.log(response);
	
						if (response.data.code === 201) {
							setTimeout(() => {
								const dataPengajuanSuratDisposisi = response.data.results.data_surat_masuk_diposisi[0]

								GlobalServices.getAll(`administrasi/surat-masuk/list-disposisi/${response.data.results.uuid}`, token)
								.then((hasil) => {
									setTimeout(() => {
										console.log('Disposisi Selanjutnya Ke:')
										console.log(hasil)

										let nextDisposisi = hasil.results[0].uuid_jabatan_struktural;

										let dataDisposisiAnalis = {
											uuid: dataPengajuanSuratDisposisi.uuid,
											uuid_surat_masuk: dataPengajuanSuratDisposisi.uuid_surat_masuk,
											uuid_jabatan_struktural: dataPengajuanSuratDisposisi.uuid_jabatan_struktural,
											status: 'Selesai',
											uuid_pengguna: uuidPengguna,
											memo:  dataPengajuanSuratDisposisi.memo,
											uuid_jabatan_struktural_tujuan: nextDisposisi,
										};

										GlobalServices.create(dataDisposisiAnalis, 'administrasi/surat-masuk/disposisi/create-with-tujuan', token)
										.then((response2) => {
											console.log('===== Response Simpan Disposisi Setujui =====')
											console.log(response2)
											if (response2.data.code === 201) {
												setLoading(false)
												console.log('Berhasil Setujui Analis UMUM')
												// ==========================
												setIsOpen(false);
												// setSelectedInstansi(null);
												showNotification(
													<span className='d-flex align-items-center'>
														<Icon icon='Info' size='lg' className='me-1' />
														<span>Berhasil {title}</span>
													</span>,
													`Berhasil ${title} Data Surat Masuk`,
												);
												tutupForm();
												statusRefresh();
												// ==========================
											} else {
												setLoading(false)
												console.log('Gagal Setujui Analis UMUM')
												showNotification(
													<span className='d-flex align-items-center'>
														<Icon icon='Warning' size='lg' className='me-1' />
														<span>Gagal {title}</span>
													</span>,
													`Gagal ${title} Data Surat Masuk`,
												);
											}
										})
										.catch((e) => {
											setLoading(false)
											console.log('Gagal Setujui Analis UMUM CATCH')
											console.log(e);
											
											showNotification(
												<span className='d-flex align-items-center'>
													<Icon icon='Warning' size='lg' className='me-1' />
													<span>Gagal {title}</span>
												</span>,
												`Gagal ${title} Data Surat Masuk`,
											);
										});
			
										console.log("Persetujuan Disposisi Analis Umum")
										console.log(dataPengajuanSuratDisposisi)
										console.log(dataDisposisiAnalis)
									}, 1000);
								})
								.catch((e) => {
									setLoading(false)
									console.log(e);
								});
							}, 1000);
							
						} else {
							setLoading(false)
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal {title}</span>
								</span>,
								`Gagal ${title} Data Surat Masuk`,
							);
						}
					})
					.catch((e) => {
						setLoading(false)
						console.log(e);
					});
			} else {
				data = { ...data, nomor_surat: surat.nomor_surat}
				GlobalServices.update(data, `administrasi/surat-masuk`, token)
					.then((response) => {
						// console.log(response);
	
						if (response.data.code === 201) {
							setLoading(false)
							setIsOpen(false);
							// setSelectedInstansi(null);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil {title}</span>
								</span>,
								`Berhasil ${title} Data Surat Masuk`,
							);
							tutupForm();
							statusRefresh();
						} else {
							setLoading(false)
							// setIsOpen(false);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal {title}</span>
								</span>,
								`Gagal ${title} Data Surat Masuk`,
							);
						}
					})
					.catch((e) => {
						setLoading(false)
						console.log(e);
					});
			}
		} else {
			setLoading(false)
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal {title}</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	const uploadFile = (file) => {
		if (file) {
			const type = file.type.split('/');
			if (
				type[1] === 'jpg' || 
				type[1] === 'jpeg' || 
				type[1] === 'png' || 
				type[1] === 'bmp' || 
				type[1] === 'tiff' || 
				type[1] === 'webp' ||
				type[1] === 'pdf'  
			) {
				const body = new FormData()
				body.append('file', file)
				// console.log(file);
				// console.log(body);
	
				GlobalServices.create(body, 'upload-file/surat-masuk', token)
					.then((response) => {
						// console.log(response);
	
						if (response.data.code === 200) {
							setDokumenSelected(true)
							setIsComplete(true)
	
							setSurat({
								...surat, dokumen : response.data.results
							})
							
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Upload Dokumen</span>
								</span>,
								'Berhasil Upload Dokumen',
							);
						} else {
							setDokumenSelected(false)
							setIsComplete(false)
							
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Upload Dokumen</span>
								</span>,
								'Gagal Upload Dokumen',
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
	
			}
		}
	}

	const handleChangeFile = (event) => {
		console.log(event.target.files[0]);
		let data = event.target.files[0]
		setIsComplete(false)
		uploadFile(data)
	}

	const tutupForm = () => {
		setSurat(initSurat);
		setSelectedDisposisiTujuan(null)
	};
	
	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
					<ModalHeader setIsOpen={setIsOpen} className='p-4'>
						<ModalTitle id={id}>{title} Surat Masuk</ModalTitle>
					</ModalHeader>
					{
						loading
						?
						<>
						<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
							<br/><br/><br/><br/>
							<div className='row g-3'>
							{Object.keys(COLORS).map((color) => (
								<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
									<Spinner color={COLORS[color]} isSmall isGrow />
								</div>
							))}
							</div>
							<br/><br/><br/><br/>
						</div>
						</>
					:
						<>
						<ModalBody className='px-4'>
							<div className='row g-4'>
								<FormGroup id='nomor_surat' label='Nomor Surat' className='col-md-12'>
									<Input
										type='text'
										name='nomor_surat'
										value={surat.nomor_surat}
										onChange={handleInputChange}
										// required
									/>
								</FormGroup>
								<FormGroup id='pengirim' label='Pengirim' className='col-md-12'>
									<Input
										type='text'
										name='pengirim'
										// onChange={formik.handleChange}
										value={surat.pengirim}
										onChange={handleInputChange}
										required
									/>
								</FormGroup>
								<FormGroup id='perihal' label='Perihal' className='col-md-12'>
									<Input
										type='text'
										name='perihal'
										// onChange={formik.handleChange}
										value={surat.perihal}
										onChange={handleInputChange}
										required
									/>
								</FormGroup>
								<FormGroup id='dokumen' label='Dokumen' className='col-md-12'>
									<Input
										id='dokumen'
										type='file'
										accept='application/pdf'
										onChange={handleChangeFile}
										required={title === 'Tambah'}
										// value={surat.dokumen}
									/>
								</FormGroup>
								{/* {title === 'Tambah' ? (
									<>
										<FormGroup id='uuid_jabatan_struktural_tujuan' label='Disposisi Tujuan' className='col-md-12'>
											<Select
												inputId="nik3"
												classNamePrefix="select"
												name="NIK3"
												placeholder="Pilih Disposisi Tujuan"
												isClearable
												defaultValue={selectedDisposisiTujuan}
												value={selectedDisposisiTujuan}
												options={frmJabatanStruktural}
												onInputChange={e => handleInputSelect(e, "disposisi-tujuan")}
												onChange={e => {
													handleSelectChange(e, "disposisi-tujuan");
												}}
											/>
										</FormGroup>
										<FormGroup id='memo' label='Memo' className='col-md-12'>
											<Input
												type='text'
												name='memo'
												// onChange={formik.handleChange}
												value={surat.memo}
												onChange={handleInputChange}
												required
											/>
										</FormGroup>
									</>
								) : ''} */}
								{/* <Select
											id='largeSelect'
											ariaLabel='Default select example'
											placeholder='Pilih Disposisi Tujuan'
											onChange={handleSelectChange}
											required
											value={surat.uuid_jabatan_struktural_tujuan}
											name='uuid_jabatan_struktural_tujuan'
											size='md'>
												{listJabatanStruktural.map((i, key) => (
													<Option value={i.uuid}>{i.nama}</Option>
												))}
										</Select> */}
							</div>
						</ModalBody>
						<ModalFooter className='px-4 pb-4'>
							<Button
								color='success'
								// isOutline
								// className='border-0'
								onClick={() => {
									setIsOpen(false);
									setSurat(initSurat);
									tutupForm();
									// setSelectedInstansi(null);
								}}>
								Tutup
							</Button>
							{/* <Button color='info' onClick={formik.handleSubmit}> */}
							<Button color='info' type='submit' onClick={handleSubmit} disabled={!isComplete}>
								Simpan
							</Button>
						</ModalFooter>
					</>
					}
				</form>
			</Modal>
		);
	}
	return null;
};
ModalData.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	// statusRefresh: PropTypes.func.isRequired,
	// listJabatanStruktural: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ModalData;