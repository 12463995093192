import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import GlobalServices from './Services/GlobalServices';
import AppLogo from '../../assets/img/Pamungkas.png'
import CheckListIcon from '../../assets/img/checklist.png'
import InformationIcon from '../../assets/img/information-icon.png'
import AskIcon from '../../assets/img/ask.png'

const BarcodeResult = (props) => {
	// useMinimizeAside();

	const [ idSurat, setIdSurat ] = useState(null);
	const [ detailSurat, setDetailSurat ] = useState(null);
	const [ jenisSurat, setJenisSurat ] = useState(null);
	const [ loading, setLoading ] = useState(true);

	useEffect(
		() => {
			const token = sessionStorage.getItem('access_token');
			// getDataList();
			console.log(props);
			console.log(props.match.params);
			setJenisSurat(props.match.params.jenis)
			if (props.match.params.jenis === 'pegawai') {
				GlobalServices.get(props.match.params.id, 'administrasi/pengajuan-surat', token)
					.then((response) => {
						if (response.data.results) {
							console.log(response);
							setDetailSurat({
								jenis_surat : response.data.results.data_surat_instansi.nama,
								nama : response.data.results.data_karyawan.nama,
								tgl_pengajuan : response.data.results.tanggal_pengajuan,
								no_identitas : response.data.results.data_karyawan.nip,
								status : response.data.results.status,
							})
						}
						setLoading(false)
					})
					.catch((e) => {
						console.log(e);
						setLoading(false)
					});
			} else if (props.match.params.jenis === 'mahasiswa') {
				GlobalServices.get(props.match.params.id, 'administrasi/pengajuan-surat-mahasiswa', token)
					.then((response) => {
						console.log(response);
						if (response.data.results) {
							console.log(response);
							setDetailSurat({
								jenis_surat : response.data.results.data_surat_instansi.nama,
								nama : response.data.results.nama,
								tgl_pengajuan : response.data.results.tanggal_pengajuan,
								no_identitas : response.data.results.nim,
								status : response.data.results.status,
							})
						}
						setLoading(false)
					})
					.catch((e) => {
						console.log(e);
						setLoading(false)
					});
			}
			
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	let detailView = (
		<div className='col-12 d-flex align-items-baseline justify-content-center mt-4'>
			<img
				src={AskIcon}
				alt=""
				style={{ maxHeight: '150px', margin: '15px' }}
			/>
		</div>
	)

	if (detailSurat) {
		detailView = (
			<>
				<div className='col-12 d-flex align-items-baseline justify-content-center mt-4'>
					<img
						src={detailSurat.status.toLowerCase() === 'selesai' ? CheckListIcon : InformationIcon}
						alt=""
						style={{ maxHeight: '150px', margin: '15px' }}
					/>
				</div>
				<div className='col-4 mt-4 d-flex flex-column justify-content-center text-center'>
					<h5>Jenis Surat : {detailSurat.jenis_surat}</h5>
					<h5>Status : {detailSurat.status}</h5>
					<h5>Nama : {detailSurat.nama}</h5>
					<h5>Tanggal Pengajuan : {detailSurat.tgl_pengajuan}</h5>
					{jenisSurat && jenisSurat === 'pegawai' ? (
						<h5>NIP : {detailSurat.no_identitas}</h5>
					) : (
						<h5>NIM : {detailSurat.no_identitas}</h5>
					)}
				</div>
			</>
		)
	}

	return (
		// <PageWrapper title={demoPages.page404.text}>
		<PageWrapper title='Detail Surat'>
			<Page>
				<div className='row d-flex align-items-center' style={{ justifyContent: 'center' }}>
					<div className='col-6 d-flex flex-column justify-content-center align-items-center' style={{ height: 'fitContent' }}>
						<div
							className='text-primary fw-bold'
							style={{ fontSize: 'calc(3rem + 3vw)' }}>
								<img src={AppLogo} alt="logo-aplikasi" style={{ width: '100%' }} />
							{/* 404 */}
						</div>
						{!loading ? (
							<div
								className='text-dark text-center fw-bold mt-4'
								style={{ fontSize: 'calc(1rem + 1.5vw)' }}>
								{detailSurat ? 'Detail Surat' : 'Surat Tidak Ditemukan'}
							</div>
						) : ''}
					</div>
					{/* <div className='col-12 d-flex align-items-baseline justify-content-center mt-4'>
						<img
							srcSet={HumansWebp}
							src={Humans}
							alt='Humans'
							style={{ height: '50vh' }}
						/>
					</div> */}
					{!loading ? detailView : ''}
				</div>
			</Page>
		</PageWrapper>
	); 
	

};
// Login.propTypes = {
// 	isSignUp: PropTypes.bool,
// };
// Login.defaultProps = {
// 	isSignUp: false,
// };

export default BarcodeResult;
