import React from 'react';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../bootstrap/Card';
import Button from '../bootstrap/Button';
import Chart from '../extras/Chart';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../bootstrap/Dropdown';
import Badge from '../bootstrap/Badge';
import { priceFormat } from '../../helpers/helpers';
import showNotification from '../extras/showNotification';
import Icon from '../icon/Icon';
// import { demoPages } from '../../menu';

const CommonGridProductItem = ({
	// eslint-disable-next-line react/prop-types
	id,
	// eslint-disable-next-line react/prop-types
	name,
	// eslint-disable-next-line react/prop-types
	category,
	// eslint-disable-next-line react/prop-types
	img,
	// eslint-disable-next-line react/prop-types
	detail,
	// eslint-disable-next-line react/prop-types
	color,
	// eslint-disable-next-line react/prop-types
	editAction,
	// eslint-disable-next-line react/prop-types
	deleteAction,
}) => {
	return (
		<Card style={{ cursor: 'pointer', height: '100%' }} >
			{/* <CardHeader>
				<CardLabel>
					<CardTitle>
						{name}
					</CardTitle>
					<CardSubTitle>{category}</CardSubTitle>
				</CardLabel>
			</CardHeader> */}
			<CardBody>
				<div>
					<img
						src={img}
						alt=''
						width={201}
						height={201}
						className='mx-auto d-block img-fluid mb-3'
					/>
				</div>
				<CardLabel style={{ textAlign: 'center' }}>
					<CardTitle style={{ fontSize: '1.8rem' }}>
						{name}
					</CardTitle>
					<CardSubTitle style={{ fontSize: '1.1rem' }}>{category}</CardSubTitle>
				</CardLabel>
			</CardBody>
			{/* <CardFooter className='shadow-3d-container'>
				<Button
					color='dark'
					className='w-100 shadow-3d-dark mb-4 shadow-3d-up-hover'
					size='lg'
					tag='a'
					to={{
						pathname: "/pengajuan-mahasiswa",
						state: { 
							dataProp: detail,
						},
						}}
					>
					Ajukan Surat
				</Button>
			</CardFooter> */}
		</Card>
	);
};

export default CommonGridProductItem;
