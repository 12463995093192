import React, { useState, useEffect } from 'react';
import Select from "react-select";
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import GlobalServices from '../Services/GlobalServices';
import SelectData from '../Services/SelectData';

const ModalEdit = ({ id, isOpen, setIsOpen, statusRefresh, setDetail }) => {
	const token = sessionStorage.getItem('access_token');
	const formData = {
		title: 'Edit Data Instansi',
		id: null,
		nama: '',
		alamat: '',
		no_telp: '',
		lat: '',
		long: '',
		jam_istirahat:'',
		pengurangan_jam_kerja:'',
	};

	const [dataEdit, setDataEdit] = useState(formData);

	useEffect(() => {
		if (setDetail) {
			setDataEdit({
				title: 'Edit Data Instansi',
				id: setDetail.uuid,
				nama: setDetail.nama,
				alamat: setDetail.alamat,
				no_telp: setDetail.no_telp,
				lat: setDetail.lat,
				long: setDetail.long,
				jam_istirahat: setDetail.jam_istirahat,
				pengurangan_jam_kerja: setDetail.pengurangan_jam_kerja,
				selectJenis: setDetail.selectJenis,
			});

			const getSelectJenis = setDetail.selectJenis;
			setSelectedJenis(getSelectJenis);
		}
	}, [setDetail]);

	const [listJenisInstansi, setListJenisInstansi] = useState(SelectData.jenisInstansi);
	const [selectedJenis, setSelectedJenis] = useState(dataEdit.selectJenis);
	
	const handleSelectChange = (event, param) => {
		if (event) {
			switch (param) {
				case 'jenis_instansi':
					setSelectedJenis(event);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'jenis_instansi':
					setSelectedJenis(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "jenis_instansi") {
				let newArray = listJenisInstansi;	
				newArray = newArray.filter(day => day !== e);
				setListJenisInstansi(newArray)
			}
		}
	};

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setDataEdit({ ...dataEdit, [name]: value });
	};

	const handleSubmit = () => {
		if (
			dataEdit.nama !== '' &&
			dataEdit.alamat !== '' &&
			dataEdit.no_telp !== '' &&
			dataEdit.lat !== '' &&
			dataEdit.long !== '' &&
			dataEdit.jam_istirahat !== '' &&
			dataEdit.pengurangan_jam_kerja !== '' &&
			selectedJenis
		) {
			const data = {
				uuid: dataEdit.id,
				nama: dataEdit.nama,
				alamat: dataEdit.alamat,
				no_telp: dataEdit.no_telp,
				lat: dataEdit.lat,
				long: dataEdit.long,
				jam_istirahat: dataEdit.jam_istirahat,
				pengurangan_jam_kerja: dataEdit.pengurangan_jam_kerja,
				jenis: selectedJenis.value,
			};

			GlobalServices.update(data, 'instansi', token)
				.then((response) => {
					if (response.data.code === 201) {
						setIsOpen(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Berhasil Ubah Data</span>
							</span>,
							'Berhasil Ubah Data Instansi',
						);
						setDataEdit(formData);
						statusRefresh();
					} else {
						// setIsOpen(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Ubah Data</span>
							</span>,
							'Gagal Ubah Data Instansi',
						);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Tambah Data</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	if (id || id === 0) {
		return (
			<Modal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				setDetail={setDetail}
				isStaticBackdrop
				size='md'
				titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={id}>
						{dataEdit.title}
					</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row g-4'>
						<FormGroup id='nama' label='Nama Instansi' className='col-md-6'>
							<Input
								type='text'
								name='nama'
								// onChange={formik.handleChange}
								value={dataEdit.nama}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='jenis' label='Jenis Instansi' className='col-md-6'>
							<Select
								inputId="jenis"
								classNamePrefix="select"
								name="jenis"
								placeholder="Pilih Jenis Instansi"
								isClearable
								required
								defaultValue={selectedJenis}
								value={selectedJenis}
								options={listJenisInstansi}
								onInputChange={e => handleInputSelect(e, "jenis_instansi")}
								onChange={e => {
									handleSelectChange(e, "jenis_instansi");
								}}
							/>
						</FormGroup>
						<FormGroup id='no_telp' label='No Telp' className='col-md-6'>
							<Input
								type='text'
								name='no_telp'
								// onChange={formik.handleChange}
								value={dataEdit.no_telp}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='alamat' label='Alamat Instansi' className='col-md-6'>
							<Input
								type='text'
								name='alamat'
								// onChange={formik.handleChange}
								value={dataEdit.alamat}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='lat' label='Lat' className='col-md-6'>
							<Input
								type='text'
								name='lat'
								// onChange={formik.handleChange}
								value={dataEdit.lat}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='long' label='Long' className='col-md-6'>
							<Input
								type='text'
								name='long'
								// onChange={formik.handleChange}
								value={dataEdit.long}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='jam_istirahat' label='Jam Istirahat' className='col-md-6'>
							<Input
								type='time'
								name='jam_istirahat'
								value={dataEdit.jam_istirahat}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='pengurangan_jam_kerja' label='Pengurangan Jam Kerja' className='col-md-6'>
							<Input
								type='number'
								name='pengurangan_jam_kerja'
								value={dataEdit.pengurangan_jam_kerja}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button
						color='success'
						// isOutline
						// className='border-0'
						onClick={() => {
							setIsOpen(false);
							setDataEdit(formData);
							setSelectedJenis(null);
						}}>
						Batal
					</Button>
					<Button color='info' type='submit' onClick={handleSubmit}>
						Simpan
					</Button>
				</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalEdit.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
	setDetail: PropTypes.func.isRequired,
};

export default ModalEdit;
