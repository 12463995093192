import React, { useEffect, useState } from 'react';
import Select from "react-select";
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
// import Select from '../../bootstrap/forms/Select';
import Option from '../../bootstrap/Option';
import GlobalServices from '../Services/GlobalServices';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';

const ModalData = ({ id, isOpen, setIsOpen, statusRefresh, setDetail, listJabatanStruktural, dataDisposisi, title, action }) => {
	const token = sessionStorage.getItem('access_token');
	const formData = {
		uuid: '',
		uuid_disposisi_instansi: '',
		uuid_jabatan_struktural: '',
		urutan: '',
	};
	
	const [dataValues, setDataValues] = useState(formData);
	const [dataListJabatanStruktural, setDataListJabatanStruktural] = useState(listJabatanStruktural);

	console.log('asdasd');
	console.log(dataValues);
	useEffect(() => {
		if (setDetail) {
			console.log('data');
			console.log(setDetail);
			setDataValues(setDetail)
			
			const getJabatanStruktural = setDetail.selectJabatanStruktural;
			setSelectedJabatanStruktural(getJabatanStruktural);
		}
		else{
			setDataValues({ ...dataValues, uuid_disposisi_instansi: dataDisposisi.uuid });
		}
		setDataListJabatanStruktural(listJabatanStruktural)
	}, [setDetail, listJabatanStruktural]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setDataValues({ ...dataValues, [name]: value });
	};

	let valueStruktural = null;
	if(action === 'Edit'){
		valueStruktural = setDetail.selectJabatanStruktural
	}
	const [selectedJabatanStruktural, setSelectedJabatanStruktural] = useState(valueStruktural);

	const handleSelectChange = (event, param) => {
		if (event) {
			switch (param) {
				case 'jabatan_struktural':
					setSelectedJabatanStruktural(event);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'jabatan_struktural':
					setSelectedJabatanStruktural(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "jabatan_struktural") {
				let newArray = dataListJabatanStruktural;	
				newArray = newArray.filter(day => day !== e);
				setDataListJabatanStruktural(newArray)
			}
		}
	};

	const handleSubmit = () => {
		// let data = {
		// 	...dataValues,
		// 	urutan: dataValues.urutan,
		// 	uuid_disposisi_instansi: dataValues.uuid_disposisi_instansi,
		// 	uuid_jabatan_struktural: selectedJabatanStruktural,
		// }
		// console.log('Data Submit')
		// // console.log(dataDisposisi)
		// console.log(data)

		// if (action === 'Tambah') {
		// 	delete data.uuid
		// }

		// let validated = true

		// Object.keys(data).map((i, key) => {
		// 	let stat = false
		// 	console.log(`${typeof data[i]} - ${data[i]}`);
		// 	if (typeof data[i] === 'string') {
		// 		if (data[i] !== '') {
		// 			stat = true
		// 		}
		// 	} else if (typeof data[i] === 'number') {
		// 		if (data[i] !== '') {
		// 			stat = true
		// 		}
		// 	} else if (data[i].length > 0) {
		// 		stat = true
		// 	} 
			
		// 	validated = validated && stat

		// 	return ''
		// })

		let data = {}
		if (action === 'Tambah') {
			data = {
				uuid: null,
				urutan: dataValues.urutan,
				uuid_disposisi_instansi: dataDisposisi.uuid,
				uuid_jabatan_struktural: selectedJabatanStruktural.value,
			}
		}
		else{
			data = {
				uuid: dataValues.uuid,
				urutan: dataValues.urutan,
				uuid_disposisi_instansi: dataValues.uuid_disposisi_instansi,
				uuid_jabatan_struktural: selectedJabatanStruktural.value,
			}
		}

		// if (validated) {
			if (action === 'Tambah') {
				GlobalServices.create(data, `disposisi-instansi/detail`, token)
					.then((response) => {
						if (response.data.code === 201) {
							setIsOpen(false);
							// setSelectedInstansi(null);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil {title}</span>
								</span>,
								`Berhasil ${title}`,
							);
							setDataValues(formData);
							tutupForm();
							statusRefresh();
						} else {
							// setIsOpen(false);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal {title}</span>
								</span>,
								`Gagal ${title}`,
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				GlobalServices.update(data, `disposisi-instansi/detail`, token)
					.then((response) => {
	
						if (response.data.code === 201) {
							setIsOpen(false);
							// setSelectedInstansi(null);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil {title}</span>
								</span>,
								`Berhasil ${title}`,
							);
							setDataValues(formData);
							tutupForm();
							statusRefresh();
						} else {
							// setIsOpen(false);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal {title}</span>
								</span>,
								`Gagal ${title}`,
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			}
		// } else {
		// 	// setIsOpen(false);
		// 	showNotification(
		// 		<span className='d-flex align-items-center'>
		// 			<Icon icon='Warning' size='lg' className='me-1' />
		// 			<span>Gagal {title}</span>
		// 		</span>,
		// 		'Data Tidak Lengkap!',
		// 	);
		// }
	};

	const tutupForm = () => {
		setSelectedJabatanStruktural(null);
		setDataValues(formData)
	};

	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
					<ModalHeader setIsOpen={setIsOpen} className='p-4'>
						<ModalTitle id={id}>{title}</ModalTitle>
					</ModalHeader>
					<ModalBody className='px-4'>
						<div className='row g-4'>
							<FormGroup id='uuid_jabatan_struktural' label='Jabatan Struktural' className='col-md-6'>
								<Select
									inputId="uuid_jabatan_struktural"
									classNamePrefix="select"
									name="uuid_jabatan_struktural"
									placeholder="Pilih Jabatan Struktural"
									isClearable
									required
									defaultValue={selectedJabatanStruktural}
									value={selectedJabatanStruktural}
									options={dataListJabatanStruktural}
									onInputChange={e => handleInputSelect(e, "jabatan_struktural")}
									onChange={e => {
										handleSelectChange(e, "jabatan_struktural");
									}}
								/>
								{/* <Select
									id='uuid_jabatan_struktural'
									ariaLabel='Default select example'
									placeholder='Pilih Jabatan Struktural'
									required
									onChange={(e) => {
										handleSelectChange(e, 'jabatan_struktural');
									}}
									value={selectedJabatanStruktural}
									size='md'>
									{listJabatanStruktural.map((item) => (
										<Option value={item.uuid}>{item.nama}</Option>
									))}
								</Select> */}
							</FormGroup>
							<FormGroup id='urutan' label='Urutan' className='col-md-6'>
								<Input
									type='number'
									name='urutan'
									value={dataValues.urutan}
									onChange={handleInputChange}
									required
								/>
							</FormGroup>
						</div>
					</ModalBody>
					<ModalFooter className='px-4 pb-4'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => {
								setIsOpen(false);
								setDataValues(formData);
								tutupForm();
								// setSelectedInstansi(null);
							}}>
							Batal
						</Button>
						{/* <Button color='info' onClick={formik.handleSubmit}> */}
						<Button color='info' type='submit' onClick={handleSubmit}>
							Simpan
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalData.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
	listJabatanStruktural: PropTypes.arrayOf(PropTypes.object).isRequired,
	dataDisposisi: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ModalData;
