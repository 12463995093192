import React, { useState, useEffect } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMasterAdmin } from '../../menu';
import Card, { CardBody } from '../bootstrap/Card';
import Breadcrumb from '../bootstrap/Breadcrumb';
import BannerInstansi from './BannerInstansi';

const Instansi = (props) => {
	
	const uuidInstansi = localStorage.getItem('uuid_instansi');
	const setDataProp = {uuid: uuidInstansi};
	const [resetList, setResetList] = useState(true);
	const { linkTo, title } = props

	let titlePage = menuMasterAdmin.banner.text
	if (title) {
		titlePage = title
	}
	console.log('aa')
	console.log(setDataProp)

	return (
		<PageWrapper title={menuMasterAdmin.banner.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{ 
								title: titlePage, 
								to: menuMasterAdmin.banner.path 
							}
						]}
					/>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
							<CardBody isScrollable className='table-responsive'>
								<BannerInstansi setDataInstansi={setDataProp} setReset={resetList}/>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Instansi;
