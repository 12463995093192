import React, { useEffect, useState } from 'react';
import Select from "react-select";
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import GlobalServices from '../Services/GlobalServices';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import SelectData from '../Services/SelectData';

const ModalData = ({ id, isOpen, setIsOpen, statusRefresh, detailDokumen, listTujuanSurat, title, action, uuidInstansi }) => {
	const token = sessionStorage.getItem('access_token');
	const formData = {
		uuid: '',
		nama: '',
	};
	
	const [dokumen, setDokumen] = useState(formData);

	useEffect(() => {
		if (detailDokumen) {
			console.log(detailDokumen)
			setDokumen(detailDokumen)
			setSelectedJenisDokumen(detailDokumen.jenisSelect)
		}
	}, [detailDokumen])


	const [listJenisDokumen, setListJenisDokumen] = useState(SelectData.jenisDokumen);
	const [selectedJenisDokumen, setSelectedJenisDokumen] = useState(null);

		
	const handleSelectChange = (event, param) => {
		if (event) {
			switch (param) {
				case 'jenis':
					setSelectedJenisDokumen(event);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'jenis':
					setSelectedJenisDokumen(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "jenis") {
				let newArray = listJenisDokumen;	
				newArray = newArray.filter(day => day !== e);
				setListJenisDokumen(newArray)
			}
		}
	};

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setDokumen({ ...dokumen, [name]: value });
	};

	const handleSubmit = () => {
		if(selectedJenisDokumen){
			const data = {
				...dokumen, 
				uuid_instansi: uuidInstansi,
				jenis: selectedJenisDokumen.value,
			}

			if (action === 'Tambah') {
				delete data.uuid
			}

			console.log(data);

			// let validated = true

			// Object.keys(data).map((i, key) => {
			// 	let stat = false
			// 	if (typeof data[i] === 'string') {
			// 		if (data[i] !== '') {
			// 			stat = true
			// 		}
			// 	} else if (data[i].length > 0) {
			// 		stat = true
			// 	}
				
			// 	validated = validated && stat

			// 	return ''
			// })

			// console.log(validated);

			// if (validated) {
				if (action === 'Tambah') {
					GlobalServices.create(data, `administrasi/dokumen`, token)
						.then((response) => {
							console.log(response);
		
							if (response.data.code === 201) {
								setIsOpen(false);
								// setSelectedInstansi(null);
								showNotification(
									<span className='d-flex align-items-center'>
										<Icon icon='Info' size='lg' className='me-1' />
										<span>Berhasil {title}</span>
									</span>,
									`Berhasil ${title}`,
								);
								tutupForm();
								statusRefresh();
							} else {
								// setIsOpen(false);
								showNotification(
									<span className='d-flex align-items-center'>
										<Icon icon='Warning' size='lg' className='me-1' />
										<span>Gagal {title}</span>
									</span>,
									`Gagal ${title}`,
								);
							}
						})
						.catch((e) => {
							console.log(e);
						});
				} else {
					GlobalServices.update(data, `administrasi/dokumen`, token)
						.then((response) => {
							console.log(response);
		
							if (response.data.code === 201) {
								setIsOpen(false);
								// setSelectedInstansi(null);
								showNotification(
									<span className='d-flex align-items-center'>
										<Icon icon='Info' size='lg' className='me-1' />
										<span>Berhasil {title}</span>
									</span>,
									`Berhasil ${title}`,
								);
								setDokumen(formData);
								statusRefresh();
							} else {
								// setIsOpen(false);
								showNotification(
									<span className='d-flex align-items-center'>
										<Icon icon='Warning' size='lg' className='me-1' />
										<span>Gagal {title}</span>
									</span>,
									`Gagal ${title}`,
								);
							}
						})
						.catch((e) => {
							console.log(e);
						});
				}
			// } else {
			// 	// setIsOpen(false);
			// 	showNotification(
			// 		<span className='d-flex align-items-center'>
			// 			<Icon icon='Warning' size='lg' className='me-1' />
			// 			<span>Gagal {title}</span>
			// 		</span>,
			// 		'Data Tidak Lengkap!',
			// 	);
			// }
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal {title}</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	
	const tutupForm = () => {
		setDokumen(formData);
		setSelectedJenisDokumen(null);
	};
	

	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
					<ModalHeader setIsOpen={setIsOpen} className='p-4'>
						<ModalTitle id={id}>{title}</ModalTitle>
					</ModalHeader>
					<ModalBody className='px-4'>
						<div className='row g-4'>
							<FormGroup id='nama' label='Nama Dokumen' className='col-md-12'>
								<Input
									type='text'
									name='nama'
									// onChange={formik.handleChange}
									value={dokumen.nama}
									onChange={handleInputChange}
									required
								/>
							</FormGroup>
							<FormGroup id='jenis' label='Jenis Dokumen' className='col-md-12'>
								<Select
									inputId="jenis"
									classNamePrefix="select"
									name="jenis"
									placeholder="Pilih Jenis Dokumen"
									isClearable
									required
									defaultValue={selectedJenisDokumen}
									value={selectedJenisDokumen}
									options={listJenisDokumen}
									onInputChange={e => handleInputSelect(e, "jenis")}
									onChange={e => {
										handleSelectChange(e, "jenis");
									}}
								/>
							</FormGroup>
						</div>
					</ModalBody>
					<ModalFooter className='px-4 pb-4'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => {
								setIsOpen(false);
								tutupForm();
								// setSelectedInstansi(null);
							}}>
							Batal
						</Button>
						{/* <Button color='info' onClick={formik.handleSubmit}> */}
						<Button color='info' type='submit' onClick={handleSubmit}>
							Simpan
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalData.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
	listTujuanSurat: PropTypes.arrayOf(PropTypes.object).isRequired,
	uuidInstansi: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ModalData;
