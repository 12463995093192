import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMaster } from '../../menu';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Breadcrumb from '../bootstrap/Breadcrumb';

import Card, { CardBody } from '../bootstrap/Card';

// Modal
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import ModalTambah from './TempatTugas/ModalTambahTempatTugas';
import ModalEdit from './TempatTugas/ModalEditTempatTugas';

// Page Tab Pane
import TempatTugas from './TempatTugas';
import JabatanStruktural from './JabatanStruktural';
import JabatanFungsional from './JabatanFungsional';
import TandaTangan from './TandaTangan';
import DokumenInstansi from './DokumenInstansi';
import JenisSurat from './JenisSurat';
import BannerInstansi from './BannerInstansi';

// Alert
import showNotification from '../extras/showNotification';
import GlobalServices from './Services/GlobalServices';

const Instansi = (props) => {
	const location = useLocation()
	const setDataProp = location.state?.dataProp
	const token = sessionStorage.getItem('access_token');

	const { linkTo, title } = props

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);

	const [refreshTab, setRefreshTab] = useState(true)

	const [list, setList] = useState([]);
	const [pagination, setPagination] = useState({
		current_page: 1,
		from: 1,
		last_page: 1,
		first_page_url: '',
		last_page_url: '',
		next_page_url: '',
		prev_page_url: 10,
		path: '',
		per_page: 10,
		to: 10,
		total: 0,
	});

	const getDataList = (page) => {
		let extra = ''

		if (page) {
			extra = `?page=${page}&q=${cariValue.nama}`
		}

		GlobalServices.getAll(`tempat-tugas/instansi/${setDataProp.uuid}${extra}`, token)
			.then((response) => {
				setList(response.results.data);
				setPagination(response.results)
				setCurrentPage(response.results.current_page)
				setPerPage(response.results.per_page)
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const refreshList = (page = '') => {
		getDataList(page);
	};

	// useEffect(() => {
	// 	InstansiDataService.getAll().then((hasil) => {
	// 		console.log(hasil);
	// 		setList(hasil.results.data);
	// 	});
	// }, []);
	useEffect(() => {
		getDataList();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps


	// Fitur Tab Pane
	const [resetList, setResetList] = useState(true);
	const [activeTabIcon, setActiveTabIcon] = useState('1');
	const [styleTab, setStyleTab] = useState({ backgroundColor: '#6a5cd0' });
	const [styleFontTab, setStyleFontTab] = useState('white');
	const toggleTab = (tab) => {
		if (activeTabIcon !== tab) {
			setActiveTabIcon(tab)
			setStyleTab({ backgroundColor: '#6a5cd0' })
			setStyleFontTab('white')
		}
	}
	// End Fitur Tab Pane

	const [tambahModalStatus, setTambahModalStatus] = useState(false);
	const [editModalStatus, setEditModalStatus] = useState(false);

	const [state, setState] = useState(false);

	const [centeredStatus, setCenteredStatus] = useState(false);

	const initialStatus = () => {
		setCenteredStatus(false);
	};

	const initialInstansiState = {
		id: '',
		nama: '',
		alamat: '',
		no_telp: '',
	};

	const [instansi, setInstansi] = useState(initialInstansiState);
	const [setDetail, setdetailData] = useState({});

	const detailData = (data) => {
		if (data) {
			const datanya = data;
			const dataDetail = {
				alamat: datanya.alamat,
				jam_istirahat: datanya.jam_istirahat,
				jenis: datanya.jenis,
				lat: datanya.lat,
				long: datanya.long,
				nama: datanya.nama,
				no_telp: datanya.no_telp,
				pengurangan_jam_kerja: datanya.pengurangan_jam_kerja,
				uuid: datanya.uuid,
			};
			setdetailData(dataDetail);
		}
	};

	const onDelete = (getId) => {
		setInstansi({ ...instansi, id: getId });
	};

	const hapusData = () => {
		const data = {
			uuid: instansi.id,
		};

		GlobalServices.remove(data, 'instansi', token).catch((e) => {
			setState(false);

			if (e === 204) {
				refreshList();
				setState(false);
				setInstansi(initialInstansiState);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Berhasil Hapus Data</span>
					</span>,
					'Berhasil Hapus Data Instansi',
				);
			} else {
				setState(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Hapus Data</span>
					</span>,
					'Gagal Hapus Data Instansi',
				);
			}
		});
	};

	// Fitur Pencarian
	const dataCari = {
		nama: '',
	};

	const [cariValue, setcariValue] = useState(dataCari);
	const handleInputSearch = (event) => {
		const { name, value } = event.target;
		setcariValue({ ...cariValue, [name]: value });
	};

	const handleSearch = () => {
		let extra = `?page=${1}`
	
		GlobalServices.getSearch(`tempat-tugas${extra}`, cariValue.nama, token)
			.then((response) => {
				setList(response.data.results.data);
				setPagination(response.data.results)
				setCurrentPage(response.data.results.current_page)
				setPerPage(response.data.results.per_page)
				
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const handleSearchEnter = (event) => {
		if (event.key === 'Enter') {
			const extra = `?page=${1}`

			GlobalServices.getSearch(`tempat-tugas${extra}`, cariValue.nama, token)
				.then((response) => {
					setList(response.data.results.data);
					setPagination(response.data.results)
					setCurrentPage(response.data.results.current_page)
					setPerPage(response.data.results.per_page)
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};
	// End Fitur Pencarian

	let titlePage = menuMaster.instansi.text
	if (title) {
		titlePage = title
	}

	return (
		<PageWrapper title={menuMaster.instansi.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{ 
								title: `Konfigurasi Instansi ${setDataProp.nama}`, 
								to: menuMaster.instansi.path 
							}
						]}
					/>
				</SubHeaderLeft>
				<SubHeaderRight>
					<Button
						icon='ArrowLeft'
						color='primary'
						// isLight
						onClick={() => props.history.goBack()}>
						Kembali
					</Button>
				</SubHeaderRight>
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
							<CardBody isScrollable className='table-responsive'>
								<Nav tabs className="tabs-color">
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '1' })}
											onClick={() => { setResetList(!resetList); toggleTab('1'); }} style={ activeTabIcon==='1'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-truck-alt" /><font color={activeTabIcon==='1'?styleFontTab:'#6a5cd0'}>Tempat Tugas</font>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '2' })}
											onClick={() => { setResetList(!resetList); toggleTab('2'); }} style={ activeTabIcon==='2'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-truck-alt" /><font color={activeTabIcon==='2'?styleFontTab:'#6a5cd0'}>Jabatan Struktural</font>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '3' })}
											onClick={() => { setResetList(!resetList); toggleTab('3'); }} style={ activeTabIcon==='3'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-user" /><font color={activeTabIcon==='3'?styleFontTab:'#6a5cd0'}>Jabatan Fungsional</font>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '8' })}
											onClick={() => { setResetList(!resetList); toggleTab('8'); }} style={ activeTabIcon==='8'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-user" /><font color={activeTabIcon==='8'?styleFontTab:'#6a5cd0'}>Tanda Tangan</font>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '4' })}
											onClick={() => { setResetList(!resetList); toggleTab('4'); }} style={ activeTabIcon==='4'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-user" /><font color={activeTabIcon==='4'?styleFontTab:'#6a5cd0'}>Jenis Surat</font>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '5' })}
											onClick={() => { setResetList(!resetList); toggleTab('5'); }} style={ activeTabIcon==='5'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-user" /><font color={activeTabIcon==='5'?styleFontTab:'#6a5cd0'}>Dokumen</font>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '6' })}
											onClick={() => { setResetList(!resetList); toggleTab('6'); }} style={ activeTabIcon==='6'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-user" /><font color={activeTabIcon==='6'?styleFontTab:'#6a5cd0'}>Dokumen Syarat</font>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '7' })}
											onClick={() => { setResetList(!resetList); toggleTab('7'); }} style={ activeTabIcon==='7'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-user" /><font color={activeTabIcon==='7'?styleFontTab:'#6a5cd0'}>Banner</font>
										</NavLink>
									</NavItem>
								</Nav>
								<br />
								<TabContent activeTab={activeTabIcon}>
									<TabPane tabId="1">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<TempatTugas setReset={resetList} setDataInstansi={setDataProp}/>
												</Col>
											</Row>

										</div>
									</TabPane>

									{/* =========================================================================== */}
									<TabPane tabId="2">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<JabatanStruktural setReset={resetList} setDataInstansi={setDataProp}/>
												</Col>
											</Row>

										</div>
									</TabPane>
									{/* =========================================================================== */}
									<TabPane tabId="3">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<JabatanFungsional setReset={resetList} setDataInstansi={setDataProp}/>
												</Col>
											</Row>

										</div>
									</TabPane>
									{/* =========================================================================== */}
									<TabPane tabId="4">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<JenisSurat setReset={resetList} setDataInstansi={setDataProp} linkParam=''/>
												</Col>
											</Row>

										</div>
									</TabPane>
									{/* =========================================================================== */}
									<TabPane tabId="5">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<DokumenInstansi setReset={resetList} setDataInstansi={setDataProp}/>
												</Col>
											</Row>

										</div>
									</TabPane>
									{/* =========================================================================== */}
									<TabPane tabId="6">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<JenisSurat setDataInstansi={setDataProp} linkParam='Dokumen Syarat' setReset={resetList} activeTabIcon='6'/>
												</Col>
											</Row>

										</div>
									</TabPane>
									{/* =========================================================================== */}
									<TabPane tabId="7">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<BannerInstansi setDataInstansi={setDataProp} setReset={resetList}/>
												</Col>
											</Row>

										</div>
									</TabPane>
									{/* =========================================================================== */}
									<TabPane tabId="8">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<TandaTangan setReset={resetList} setDataInstansi={setDataProp}/>
												</Col>
											</Row>

										</div>
									</TabPane>
									{/* =========================================================================== */}
								</TabContent>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
			<ModalTambah
				setIsOpen={setTambahModalStatus}
				isOpen={tambahModalStatus}
				id={0}
				statusRefresh={refreshList}
			/>
			<ModalEdit
				setIsOpen={setEditModalStatus}
				isOpen={editModalStatus}
				id={0}
				setDetail={setDetail}
				statusRefresh={refreshList}
			/>
			<Modal
				isOpen={state}
				setIsOpen={setState}
				titleId='exampleModalLabel'
				isCentered={centeredStatus}>
				<ModalHeader>&nbsp;</ModalHeader>
				<ModalBody>
					<p align='center'>
						<h2>Yakin Ingin Hapus Data?</h2> <br />
						Proses ini tidak dapat dibatalkan!
					</p>
					<br />
					<div align='center'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						&nbsp;&nbsp;
						<Button color='danger' icon='Delete' onClick={hapusData}>
							Hapus
						</Button>
					</div>
				</ModalBody>
				<ModalFooter>
					&nbsp;
					{/* <div align='center'>
						<Button
							color='success'
							// isOutline
							className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						<Button color='danger' icon='Delete'>
							Hapus
						</Button>
					</div> */}
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};

export default Instansi;
