import React, { useState, useEffect } from 'react';
import Select from "react-select";
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
// import Select from '../../bootstrap/forms/Select';
import Option from '../../bootstrap/Option';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import GlobalServices from '../Services/GlobalServices';
import SelectData from '../Services/SelectData';

const ModalTambah = ({ id, isOpen, setIsOpen, statusRefresh }) => {
	const roleUser = localStorage.getItem('role_user');
	const uuidInstansi = localStorage.getItem('uuid_instansi');
	const jenisInstansi = localStorage.getItem('jenis_instansi');
	const token = sessionStorage.getItem('access_token');

	const formData = {
		title: 'Tambah Data Pengguna',
		id: null,
		username: '',
		nip: '',
		password: '',
		no_hp: '',
		email: '',
		role: ''
	};

	const [selectedRole, setSelectedRole] = useState(null);
	const [listInstansi, setListInstansi] = useState([]);
	const [listKaryawan, setListKaryawan] = useState([]);
	const [listRole, setListRole] = useState(SelectData.jenisPenggunaSuperadmin);

	useEffect(() => {
		if(roleUser === 'Super Operator'){
			GlobalServices.getListBy('karyawan', `instansi`, uuidInstansi, token).then((hasil) => {
				let frmSelect = [];
				hasil.data.results.forEach(dt => {
					frmSelect.push({ value: dt.nip, label: dt.nama });
				});
				setListKaryawan(frmSelect)
			});
		}
		else{
			GlobalServices.getList('instansi', token).then((hasil) => {
				let frmSelect = [];
				hasil.data.results.forEach(dt => {
					frmSelect.push({ value: dt.uuid, label: dt.nama });
				});
				setListInstansi(frmSelect)
			});
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	
	const [selectedInstansi, setSelectedInstansi] = useState(null);
	const [selectedKaryawan, setSelectedKaryawan] = useState(null);
	const [dataInstansi, setDataInstansi] = useState({jenis_instansi: '', nama_instansi: ''});

	const handleSelectChange = (event, param) => {
		
		if (event) {
			switch (param) {
				case 'role':
					setSelectedRole(event);
					break;
				case 'instansi':
					setSelectedKaryawan(null);

					GlobalServices.get(event.value, 'instansi', token).then((hasil) => {
						setDataInstansi({
							jenis_instansi: hasil.data.results.jenis,
							nama_instansi: hasil.data.results.nama
						});
					});

					GlobalServices.getListBy('karyawan', `instansi`, event.value, token).then((hasil) => {
						let frmSelect = [];
						hasil.data.results.forEach(dt => {
							frmSelect.push({ value: dt.nip, label: dt.nama });
						});
						setListKaryawan(frmSelect)
					});

					setSelectedInstansi(event);
					break;
				case 'karyawan':
					setSelectedKaryawan(event);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'role':
					setSelectedRole(null);
					setSelectedInstansi(null);
					setSelectedKaryawan(null);
					setListKaryawan([]);
					setDataInstansi({jenis_instansi: '', nama_instansi: ''});
					break;
				case 'instansi':
					setSelectedInstansi(null);
					setSelectedKaryawan(null);
					setListKaryawan([]);
					setDataInstansi({jenis_instansi: '', nama_instansi: ''});
					break;
				case 'karyawan':
					setSelectedKaryawan(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "instansi") {
				let newArray = listInstansi;	
				newArray = newArray.filter(day => day !== e);
				setListInstansi(newArray)
			}
			else if (jenis === "role") {
				let newArray = listRole;	
				newArray = newArray.filter(day => day !== e);
				setListRole(newArray)
			}
			else if (jenis === "karyawan") {
				let newArray = listKaryawan;	
				newArray = newArray.filter(day => day !== e);
				setListKaryawan(newArray)
			}
		}
	};


	const [dataValues, setdataValues] = useState(formData);
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setdataValues({ ...dataValues, [name]: value });
	};

	const handleSubmit = () => {
		let cekNIP = false;
		let setRole = '';
		let cekselectedInstansi = false;
		if(roleUser==='Super Operator'){
			if(selectedKaryawan){
				cekNIP = true;
				setRole = 'Admin';
				cekselectedInstansi = true;
			}
		}
		else if(roleUser!=='Super Operator'){
			if(selectedInstansi){
				cekselectedInstansi = true;
				if(selectedRole && selectedRole.value === 'Admin'){
					if(selectedKaryawan){
						cekNIP = true
						setRole = selectedRole.value
					}
				}
				else if(selectedRole && selectedRole.value !== 'Admin'){
					cekNIP = true
					setRole = selectedRole.value
				}
			}
		}

		console.log('data simpan')
		console.log(dataValues.username)
		console.log(dataValues.password)
		console.log(dataValues.no_hp)
		console.log(dataValues.email)
		console.log(setRole)
		console.log(cekNIP)
		console.log(cekselectedInstansi)
		if (
				dataValues.username !== '' && 
				dataValues.password !== '' && 
				dataValues.no_hp !== '' && 
				dataValues.email !== '' && 
				setRole &&
				cekNIP &&
				cekselectedInstansi
			) 
		{
			let data = {};
			let link = '';
			if(roleUser === 'Super Operator' || (selectedRole && selectedRole.value === 'Admin')){
				link = 'pengguna/karyawan'
				data = {
					username: dataValues.username,
					nip: selectedKaryawan.value,
					password: dataValues.password,
					no_hp: dataValues.no_hp,
					email: dataValues.email,
					role: setRole,
				};
			}
			else{
				link = 'pengguna'
				data = {
					username: dataValues.username,
					password: dataValues.password,
					no_hp: dataValues.no_hp,
					email: dataValues.email,
					role: setRole,
					uuid_instansi: selectedInstansi.value,
				};
			}
			GlobalServices.create(data, link, token)
				.then((response) => {
					if (response.data.code === 201) {
						setIsOpen(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Berhasil Tambah Data</span>
							</span>,
							'Berhasil Tambah Data Pengguna',
							);
						statusRefresh();
						setdataValues(formData);
						tutupForm();
					} else {
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Tambah Data</span>
							</span>,
							'Gagal Tambah Data Pengguna',
						);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Tambah Data</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	
	const tutupForm = () => {
		setSelectedRole(null);
		setSelectedInstansi(null);
		setSelectedKaryawan(null);
		setDataInstansi({jenis_instansi: '', nama_instansi: ''})
	};

	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={id}>{dataValues.title}</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row g-4'>
					{
							roleUser === 'Super Operator'
							?<></>
							: 
							<FormGroup id='role' label='Role' className='col-md-12'>
								<Select
									inputId="role"
									classNamePrefix="select"
									name="role"
									placeholder="Pilih Role Pengguna"
									isClearable
									required
									defaultValue={selectedRole}
									value={selectedRole}
									options={listRole}
									onInputChange={e => handleInputSelect(e, "role")}
									onChange={e => {
										handleSelectChange(e, "role");
									}}
								/>
							</FormGroup>
						}

						{
							roleUser === 'Super Operator'
							?
							<>
							</>
							:
							<>
							<FormGroup id='uuid_instansi' label='Nama Instansi' className='col-md-12'>
								<Select
									inputId="uuid_instansi"
									classNamePrefix="select"
									name="uuid_instansi"
									placeholder="Pilih Instansi"
									isClearable
									required
									defaultValue={selectedInstansi}
									value={selectedInstansi}
									options={listInstansi}
									onInputChange={e => handleInputSelect(e, "instansi")}
									onChange={e => {
										handleSelectChange(e, "instansi");
									}}
								/>
							</FormGroup>
							</>
						}

						{
							roleUser === 'Super Operator' || selectedRole && selectedRole.value === 'Admin'
							?
							<FormGroup id='nip' label={dataInstansi.jenis_instansi === 'Pemerintahan' || jenisInstansi === 'Pemerintahan'?'NIP':'No. Karyawan'} className={dataInstansi.jenis_instansi === 'Pemerintahan' || jenisInstansi === 'Pemerintahan'?'col-md-12':'col-md-6'}>
								<Select
									inputId="karyawan"
									classNamePrefix="select"
									name="karyawan"
									placeholder="Pilih Karyawan"
									isClearable
									required
									defaultValue={selectedKaryawan}
									value={selectedKaryawan}
									options={listKaryawan}
									onInputChange={e => handleInputSelect(e, "karyawan")}
									onChange={e => {
										handleSelectChange(e, "karyawan");
									}}
								/>
							</FormGroup>
							:<></>
						}

						<FormGroup id='username' label='Username' className='col-md-6'>
							<Input
								type='text'
								name='username'
								value={dataValues.username}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						
						<FormGroup id='password' label='Password' className='col-md-6'>
							<Input
								type='password'
								name='password'
								value={dataValues.password}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						
						<FormGroup id='no_hp' label='No. HP' className='col-md-6'>
							<Input
								type='text'
								name='no_hp'
								value={dataValues.no_hp}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						
						<FormGroup id='email' label='Email' className='col-md-6'>
							<Input
								type='email'
								name='email'
								value={dataValues.email}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>

					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button
						color='danger'
						// isOutline
						// className='border-0'
						onClick={() => {
							setIsOpen(false);
							setdataValues(formData);
							tutupForm();
						}}>
						Batal
					</Button>
					{/* <Button color='info' onClick={formik.handleSubmit}> */}
					<Button color='success' type='submit' onClick={handleSubmit}>
						Simpan
					</Button>
				</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalTambah.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	// listInstansi: PropTypes.func.isRequired,
	// listGolonganJabatan: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
};

export default ModalTambah;
