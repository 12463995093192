import React from 'react';
import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import PropTypes from 'prop-types';

import './styles/styles.scss';
import App from './App/App';
// import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import './i18n';

import Login from './components/feature/Login';
// import Dashboard from './components/feature/Dashboard';
// import Pegawai from './components/feature/Pegawai';
// import Pengguna from './components/feature/Pengguna';
import Pengguna from './components/feature/Pengguna';
import Instansi from './components/feature/Instansi';
import Jam from './components/feature/Jam';
import { dashboardMenu } from './menu';
import DashboardUmum from './components/feature/DashboardUmum';
import FormSuratMahasiswa from './components/feature/FormSuratMahasiswa';
import TrackingSuratMahasiswa from './components/feature/TrackingSuratMahasiswa';
import DetailSuratMahasiswa from './components/feature/DetailSuratMahasiswa';
import DownloadSurat from './components/feature/DownloadSurat';
import BarcodeResult from './components/feature/BarcodeResult';

// ReactDOM.render(
// 	<Router>
// 		<React.StrictMode>
// 			<ThemeContextProvider>
// 				<App />
// 			</ThemeContextProvider>
// 		</React.StrictMode>
// 	</Router>,
// 	document.getElementById('root'),
// );

// private route n public route
// const PrivateRoute = ({ component: Component, ...rest }) => (
// 	<Route
// 		{...rest}
// 		render={(props) => {
// 			if (sessionStorage.getItem('access_token')) {
// 				return <Component {...props} />;
// 			}
// 			return (
// 				<Redirect
// 					to={{
// 						pathname: '/login',
// 						state: { from: props.location },
// 					}}
// 				/>
// 			);
// 		}}
// 	/>
// );

// Route untuk public, tidak perlu login bisa akses route ini
const PublicRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			if (sessionStorage.getItem('access_token')) {
				return <Component {...props} {...rest} />;
			}
			return (
				<Redirect
					to={{
						pathname: dashboardMenu.dashboard.path,
						state: { from: PropTypes.location },
					}}
				/>
			);
		}}
	/>
);

const LoginRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			if (sessionStorage.getItem('access_token')) {
				return (
					<Redirect
						to={{
							pathname: dashboardMenu.dashboard.path,
							state: { from: PropTypes.location },
						}}
					/>
				);
			}
			return <Component {...props} {...rest} />;
		}}
	/>
);

const Root = () => {
	return (
		<BrowserRouter basename='/'>
			<ScrollContext>
				<Switch>
					<LoginRoute exact path={`${process.env.PUBLIC_URL}/login`} component={Login} />
					<Route exact path={`${process.env.PUBLIC_URL}/`} component={DashboardUmum} />
					<Route exact path={`${process.env.PUBLIC_URL}/pengajuan-mahasiswa`} component={FormSuratMahasiswa} />
					<Route exact path={`${process.env.PUBLIC_URL}/detail-pengajuan-mahasiswa`} component={DetailSuratMahasiswa} />
					<Route path={`${process.env.PUBLIC_URL}/tracking-surat-mahasiswa`} component={TrackingSuratMahasiswa} />
					<Route exact path={`${process.env.PUBLIC_URL}/tracking-surat-mahasiswa/:kode_pengajuan`} component={TrackingSuratMahasiswa} />
					<Route path={`${process.env.PUBLIC_URL}/download`} component={DownloadSurat} />
					<Route exact path={`${process.env.PUBLIC_URL}/download/:id_surat`} component={DownloadSurat} />
					<Route exact path={`${process.env.PUBLIC_URL}/verifikasi/:jenis/:id`} component={BarcodeResult} />
					<React.StrictMode>
						<ThemeContextProvider>
							<App>
								{/* <PublicRoute
									exact
									path={`${process.env.PUBLIC_URL}/admin/pegawai`}
									component={Pegawai}
								/> */}
								<PublicRoute
									exact
									path={`${process.env.PUBLIC_URL}/admin/pengguna`}
									component={Pengguna}
								/>
								<PublicRoute
									exact
									path={`${process.env.PUBLIC_URL}/admin/instansi`}
									component={Instansi}
								/>
								<PublicRoute
									exact
									path={`${process.env.PUBLIC_URL}/admin/jam`}
									component={Jam}
								/>
								{/* <PrivateRoute
									path={`${process.env.PUBLIC_URL}/admin/dashboard`}
									component={Dashboard}
								/> */}
							</App>
						</ThemeContextProvider>
					</React.StrictMode>
				</Switch>
			</ScrollContext>
		</BrowserRouter>
	);
};
// export class Root extends React.Component {
// 	render() {
// 		return (
// 			<Router>
// 				<React.StrictMode>
// 					<ThemeContextProvider>
// 						<App />
// 					</ThemeContextProvider>
// 				</React.StrictMode>
// 			</Router>
// 			// <Provider store={store}>

// 			// </Provider>
// 		);
// 	}
// }

export default Root;
ReactDOM.render(<Root />, document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
