import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardBody } from '../bootstrap/Card';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Button from '../bootstrap/Button';
import Logo from '../Logo';
import AppLogo from '../../assets/img/Pamungkas.png'
import showNotification from '../extras/showNotification';
import Icon from '../icon/Icon';
import GlobalServices from './Services/GlobalServices';
// Modal
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';

// eslint-disable-next-line react/prop-types

const Login = (props) => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [isNewUser, setIsNewUser] = useState();

	const history = useHistory();

	// Modal
	const [state, setState] = useState(false);
	const [centeredStatus, setCenteredStatus] = useState(false);
	const initialStatus = () => {
		setCenteredStatus(false);
	};
	// End Modal
	
	const initFormData = {
		username: '',
		password: '',
	}
	const [formData, setFormData] = useState(initFormData);
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = () => {
		if (
			formData.username.trim() !== '' &&
			formData.password.trim() !== ''
		) {
			const data = {
				username: formData.username.trim(),
				password: formData.password.trim(),
			};
			
			GlobalServices.create(data, 'auth/login')
				.then((response) => {
					if (response.status === 200) {
						if (response.data.results.access_token) {
							sessionStorage.setItem('access_token', response.data.results.access_token);
							const setToken = GlobalServices.DataToken(response.data.results.access_token)
							console.log('set tkoen apa')
							console.log(setToken)
							localStorage.setItem('access_token', response.data.results.access_token);
							localStorage.setItem('uuid_user', setToken.sub.uuid);
							localStorage.setItem('role_user', setToken.sub.role);
							if(setToken.sub.role === 'Admin'){
								localStorage.setItem('uuid_pengguna', setToken.sub.uuid);
								localStorage.setItem('uuid_instansi', setToken.sub.data_karyawan.uuid_instansi);
								localStorage.setItem('jenis_instansi', setToken.sub.data_karyawan.data_instansi.jenis);
								localStorage.setItem('uuid_jabatan_struktural', setToken.sub.data_karyawan.uuid_jabatan_struktural);
							}
							else if(setToken.sub.role === 'Super Operator'){
								localStorage.setItem('uuid_pengguna', setToken.sub.uuid);
								localStorage.setItem('uuid_instansi', setToken.sub.uuid_instansi);
								localStorage.setItem('jenis_instansi', setToken.sub.data_instansi.jenis);
							}
							else{
								localStorage.removeItem('uuid_instansi');
								localStorage.removeItem('uuid_pengguna');
								localStorage.setItem('jenis_instansi', 'Non Pemerintahan');
							}
							props.history.push('/dashboard'); 
						} else {
							setFormData({ ...formData, msg: 'Gagal Login', detailMsg: 'Terjadi kesalahan' });
						}
					} else {
						setFormData({ ...formData, msg: 'Gagal Login', detailMsg: 'Username atau Password Tidak Ditemukan!' });
					}
				})
				.catch((e) => {
					setFormData({ ...formData, msg: 'Gagal Login', detailMsg: e.response.data.message });
					setState(true)
				});
		} else {
			// setIsOpen(false);
			setState(true)
			setFormData({ ...formData, msg: 'Gagal Login', detailMsg: 'Username atau Password Tidak Boleh Kosong!' });
		}
	};

	return (
		<PageWrapper
			title='Login'
			className={classNames({ 'bg-warning': !isNewUser, 'bg-info': !!isNewUser })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark'>
							<CardBody>
								<div className='text-center  my-5'>
									<Link
										to='/'
										className='text-decoration-none text-dark fw-bold display-2'>
										{/* <Logo width={200} /> */}
										{/* Pamungkas <font color='gold'><i>FST</i></font> */}
										<img src={AppLogo} alt="logo-aplikasi" style={{ width: '100%' }} />
									</Link>
								</div>

								<div className='text-center h1 fw-bold mt-5'>Selamat Datang,</div>
								<div className='text-center h4 text-muted mb-5'>Silahkan Login!</div>

								<form className='row g-4' onSubmit={(e) => {
									e.preventDefault()
									handleSubmit()
								}}>
									<div className='col-12'>
										<FormGroup
											id='username'
											isFloating
											label='Username'>
											<Input 
												type='text' 
												autoComplete={false}
												value={formData.username}
												onChange={handleInputChange}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup
											id='password'
											isFloating
											label='Password'>
											<Input
												type='password'
												autoComplete='password'
												value={formData.password}
												onChange={handleInputChange}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button
											color='info'
											type='submit'
											className='w-100 py-3'
											onClick={handleSubmit}>
											Login
										</Button>
									</div>
									<div className='col-12'>
										<Button
											color='success'
											type='button'
											className='w-100 py-3'
											onClick={() => {
												props.history.push('/'); 
											}}>
											Dashboard Pelayanan
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Copyright © 2021 Pamungkas FASTE
							</a>
							<a
								target='_blank'
								href='https://solusidatamadani.co.id/web/'
								rel='noreferrer'
								className={classNames('link-light text-decoration-none', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								<b>PT. Solusi Data Madani</b>
							</a>
						</div>
					</div>
				</div>
			</Page>
			<Modal
				isOpen={state}
				setIsOpen={setState}
				titleId='exampleModalLabel'
				isCentered={centeredStatus}
				size='md'
			>
				<ModalHeader>&nbsp;</ModalHeader>
				<ModalBody>
					<p align='center'>
						<h2>{formData.msg}</h2> <br />
						{formData.detailMsg}
					</p>
					<div align='center'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => {
								setState(false);
								setFormData(initFormData);
							}}
						>
							Tutup
						</Button>
					</div>
				</ModalBody>
				{/* <ModalFooter> &nbsp; </ModalFooter> */}
			</Modal>
		</PageWrapper>
	);
};
// Login.propTypes = {
// 	isSignUp: PropTypes.bool,
// };
// Login.defaultProps = {
// 	isSignUp: false,
// };

export default Login;
