const jenisInstansi = [
	{ value: 'Pemerintahan', label: 'Pemerintahan' },
	{ value: 'Non Pemerintahan', label: 'Non Pemerintahan' },
];

const statusPegawai = [
	{ value: 'PNS', label: 'PNS' },
	{ value: 'NON PNS', label: 'NON PNS' },
];

const jenisKaryawan = [
	{ value: 'Pegawai', label: 'Pegawai' },
	{ value: 'Dosen', label: 'Dosen' },
];

const jenisKelamin = [
	{ value: 'Laki-Laki', label: 'Laki-Laki' },
	{ value: 'Perempuan', label: 'Perempuan' },
];

const golonganDarah = [
	{ value: 'A', label: 'A' },
	{ value: 'B', label: 'B' },
	{ value: 'AB', label: 'AB' },
	{ value: 'O', label: 'O' },
];

const agama = [
	{ value: 'Islam', label: 'Islam' },
	{ value: 'Kristen', label: 'Kristen' },
	{ value: 'Katolik', label: 'Katolik' },
	{ value: 'Hindu', label: 'Hindu' },
	{ value: 'Buddha', label: 'Buddha' },
	{ value: 'Konghucu', label: 'Konghucu' },
];

const jenisPenggunaSuperadmin = [
	{ value: 'Super Operator', label: 'Super Operator' },
	{ value: 'Admin', label: 'Admin' },
];

const jenisDokumen = [
	{ value: 'Dosen', label: 'Dosen' },
	{ value: 'Mahasiswa', label: 'Mahasiswa' },
];

const statusDisposisi = [
	{ value: '1', label: 'Utama' },
	{ value: '0', label: 'Tidak Utama' },
];

const programStudi = [
	{ value: 'Teknik Elektro', label: 'Teknik Elektro' },
	{ value: 'Teknik Informatika', label: 'Teknik Informatika' },
	{ value: 'Teknik Industri', label: 'Teknik Industri' },
	{ value: 'Matematika', label: 'Matematika' },
	{ value: 'Sistem Informasi', label: 'Sistem Informasi' },
];

const sortData = [
	{ value: 'Terbaru', label: 'Terbaru' },
	{ value: 'Terlama', label: 'Terlama' },
];

const statusTTD = [
	{ value: 'a.n.', label: 'a.n.' },
	{ value: 'Plt.', label: 'Plt.' },
	{ value: 'Plh.', label: 'Plh.' },
];

const RolePengguna = [
	{ value: 'Pegawai', label: 'Pegawai' },
	{ value: 'Admin', label: 'Admin' },
];

const StatusPerkawinan = [
	{ value: 'Belum kawin', label: 'Belum kawin' },
	{ value: 'Kawin', label: 'Kawin' },
	{ value: 'Cerai hidup', label: 'Cerai hidup' },
	{ value: 'Cerai mati', label: 'Cerai mati' },
];

const pendidikanTerakhir = [
	{ value: 'DIPLOMA IV/ STRATA I', label: 'DIPLOMA IV/ STRATA I' },
	{ value: 'STRATA II', label: 'STRATA II' },
	{ value: 'STRATA III', label: 'STRATA III' },
];

const jabatanTTD = [
	{ value: 'Dekan', label: 'Dekan' },
	{ value: 'Wakil Dekan I', label: 'Wakil Dekan I' },
	{ value: 'Wakil Dekan II', label: 'Wakil Dekan II' },
	{ value: 'Wakil Dekan III', label: 'Wakil Dekan III' },
	{ value: 'Kepala Bagian Tata Usaha', label: 'Kepala Bagian Tata Usaha' },
];

const statusPenggantiTTD = [
	{ value: 'Plt.', label: 'Plt.' },
	{ value: 'Plh.', label: 'Plh.' },
	{ value: 'Pj.', label: 'Pj.' },
	{ value: 'Pjs.', label: 'Pjs.' },
];

export default {
	jenisInstansi,
	statusPegawai,
	jenisKaryawan,
	jenisKelamin,
	golonganDarah,
	agama,
	jenisPenggunaSuperadmin,
	jenisDokumen,
	statusDisposisi,
	programStudi,
	sortData,
	statusTTD,
	RolePengguna,
	StatusPerkawinan,
	pendidikanTerakhir,
	jabatanTTD,
	statusPenggantiTTD,
};
