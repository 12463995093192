import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CKEditors from "react-ckeditor-component";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Textarea from '../../bootstrap/forms/Textarea';
import Button from '../../bootstrap/Button';
import GlobalServices from '../Services/GlobalServices';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';

const ModalData = ({ id, isOpen, setIsOpen, statusRefresh, detailData, title }) => {
	const token = sessionStorage.getItem('access_token');
	const formData = {
		uuid: '',
		uuid_instansi: '',
		judul: '',
		isi: '',
		gambar: '',
		keterangan: '',
	};

	const [dataValues, setDataValues] = useState(formData);
	const [dataValuesIsi, setDataValuesIsi] = useState('');
	const [dokumenSelected, setDokumenSelected] = useState(false);
	const [isComplete, setIsComplete] = useState(true);

	useEffect(() => {
		if (detailData) {
			setDataValues(detailData)
			setDataValuesIsi(detailData.isi)
		}
	}, [detailData]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setDataValues({ ...dataValues, [name]: value });
	};

	const handleSubmit = () => {
		let data = {}
		if (title === 'Tambah') {
			data = {
				uuid_instansi: id,
				judul: dataValues.judul,
				isi: dataValuesIsi,
				gambar: dataValues.gambar,
			}
		} else {
			data = {
				uuid: dataValues.uuid,
				uuid_instansi: id,
				judul: dataValues.judul,
				isi: dataValuesIsi,
				gambar: dataValues.gambar,
			}
		}
		
		console.log(data);

		let validated = true

		// Validasi isi parameter root
		Object.keys(data).map((i, key) => {
			let stat = false
			console.log(typeof data[i]);
			if (typeof data[i] === 'string') {
				if (data[i] !== '') {
					stat = true
				}
				// just receive string value
				validated = validated && stat
			} 
			// else if (data[i].length > 0) {
			// 	stat = true
			// }
			// validated = validated && stat
			return ''
		})
		
		console.log(validated);

		if (validated) {
			if (title === 'Tambah') {
				data = ({...data, keterangan: dataValues.keterangan})
				console.log('datasimpan info')
				console.log(data)
				GlobalServices.create(data, `info-instansi`, token)
					.then((response) => {
						console.log(response);
	
						if (response.data.code === 201) {
							setIsOpen(false);
							// setSelectedInstansi(null);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil {title}</span>
								</span>,
								`Berhasil ${title} Data Info`,
							);
							tutupForm();
							statusRefresh();
						} else {
							// setIsOpen(false);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal {title}</span>
								</span>,
								`Gagal ${title} Data Info`,
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				data = ({...data, keterangan: dataValues.keterangan})
				GlobalServices.update(data, `info-instansi`, token)
					.then((response) => {
						console.log(response);
	
						if (response.data.code === 201) {
							setIsOpen(false);
							// setSelectedInstansi(null);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil {title}</span>
								</span>,
								`Berhasil ${title} Data Info`,
							);
							tutupForm();
							statusRefresh();
						} else {
							// setIsOpen(false);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal {title}</span>
								</span>,
								`Gagal ${title} Data Info`,
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			}
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal {title}</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	const uploadFile = (file) => {
		if (file) {
			const type = file.type.split('/');
			if (
				type[1] === 'jpg' || 
				type[1] === 'jpeg' || 
				type[1] === 'png' || 
				type[1] === 'bmp' || 
				type[1] === 'tiff' || 
				type[1] === 'webp'
			) {
				const body = new FormData()
				body.append('file', file)
				console.log(file);
				console.log(body);
	
				GlobalServices.create(body, 'upload-file/info-instansi', token)
					.then((response) => {
						console.log(response);
	
						if (response.data.code === 200) {
							setDokumenSelected(true)
							setIsComplete(true)
	
							setDataValues({
								...dataValues, gambar : response.data.results
							})
							
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Upload Gambar</span>
								</span>,
								'Berhasil Upload Gambar',
							);
						} else {
							setDokumenSelected(false)
							setIsComplete(false)
							
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Upload Gambar</span>
								</span>,
								'Gagal Upload Gambar',
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
	
			}
		}
	}

	const handleChangeFile = (event) => {
		console.log(event.target.files[0]);
		let data = event.target.files[0]
		setIsComplete(false)
		uploadFile(data)
	}

	
	const onChange = (evt) => {
		console.log('Hasil Ketik')
		console.log(evt)
		let newContent = evt.editor.getData();
		// setDataValues({ ...dataValues, [name]: value });
		setDataValuesIsi(newContent);
	}

	const tutupForm = () => {
		if(title==='Tambah'){
			setDataValues(formData);
			setDataValuesIsi('');
		}
	};
	
	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='xl' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
					<ModalHeader setIsOpen={setIsOpen} className='p-4'>
						<ModalTitle id={id}>{title} Info</ModalTitle>
					</ModalHeader>
					<ModalBody className='px-4'>
						<div className='row g-4'>
							<FormGroup id='judul' label='Judul' className='col-md-12'>
								<Input
									type='text'
									name='judul'
									value={dataValues.judul}
									onChange={handleInputChange}
									required
								/>
							</FormGroup>
							<FormGroup id='dokumen' label='Gambar Headline' className='col-md-12'>
								<Input
									id='dokumen'
									type='file'
									accept='application/image'
									onChange={handleChangeFile}
									required={title === 'Tambah'}
									// value={dataValues.dokumen}
								/>
							</FormGroup>
							<FormGroup id='isi' label='Isi' className='col-md-12'>
								{/* <Textarea
									type='text'
									name='isi'
									value={dataValues.isi}
									onChange={handleInputChange}
									required
								/> */}
								<CKEditors
									activeClass="p10"
									content={dataValuesIsi}
									config={{'removePlugins':'elementspath'}}
									events={{
										// blur: this.onBlur,
										// afterPaste: this.afterPaste,
										change: onChange
									}}
								/>
							</FormGroup>
							<FormGroup id='keterangan' label='Keterangan' className='col-md-12'>
								<Input
									type='text'
									name='keterangan'
									value={dataValues.keterangan}
									onChange={handleInputChange}
								/>
							</FormGroup>
						</div>
					</ModalBody>
					<ModalFooter className='px-4 pb-4'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => {
								setIsOpen(false);
								setDataValues(formData);
								tutupForm();
								// setSelectedInstansi(null);
							}}>
							Tutup
						</Button>
						{/* <Button color='info' onClick={formik.handleSubmit}> */}
						<Button color='info' type='submit' onClick={handleSubmit} disabled={!isComplete}>
							Simpan
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalData.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	// statusRefresh: PropTypes.func.isRequired,
	// listJabatanStruktural: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ModalData;