import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMaster } from '../../menu';
import Card, { CardBody } from '../bootstrap/Card';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Breadcrumb from '../bootstrap/Breadcrumb';
import PAYMENTS from '../../common/data/enumPaymentMethod';
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';

// Modal
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import ModalEdit from './Instansi/ModalEditInstansi';

// Alert
import showNotification from '../extras/showNotification';
import GlobalServices from './Services/GlobalServices';
import ModalData from './DokumenSyarat/ModalData';

const DokumenSyarat = (props) => {
	const location = useLocation()
	const setDataProp = location.state?.dataProp
	const token = sessionStorage.getItem('access_token');
	// const history = useHistory();

	const { title } = props

	const [uuidSuratInstansi, setUuidSuratInstansi] = useState('');

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const [pagination, setPagination] = useState({
		current_page: 1,
		from: 1,
		last_page: 1,
		first_page_url: '',
		last_page_url: '',
		next_page_url: '',
		prev_page_url: 10,
		path: '',
		per_page: 10,
		to: 10,
		total: 0,
	});
	
	const [loading, setLoading] = useState(true);
	const [list, setList] = useState([]);
	const [listDokumen, setListDokumen] = useState([]);

	const initialDokumenState = {
		uuid: '',
		uuid_surat_instansi: '',
		uuid_dokumen: '',
	};
	
	const [detailDokumen, setDetailDokumen] = useState(initialDokumenState)

	const formik = useFormik({
		initialValues: {
			searchInput: '',
			payment: Object.keys(PAYMENTS).map((i) => PAYMENTS[i].name),
			minPrice: '',
			maxPrice: '',
		},
		// eslint-disable-next-line no-unused-vars
		onSubmit: (values) => {
			setState(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Hapus Data</span>
				</span>,
				'Data Dokumen Berhasil Dihapus',
			);
		},
	});

	const getDataList = (page) => {
		let extra = ''

		if (page) {
			extra = `?page=${page}&q=${cariValue.nama}`
		}

		GlobalServices.getAll(`administrasi/dokumen-syarat-surat/surat-instansi/${setDataProp.uuid}${extra}`, token)
			.then((response) => {
				console.log(response);
				setList(response.results.data);
				setPagination(response.results)
				setCurrentPage(response.results.current_page)
				setPerPage(response.results.per_page)
				setLoading(false)
			})
			.catch((e) => {
				setLoading(false)
				console.log(e);
			});
	};
	
	const getListDokumenInstansi = () => {
		GlobalServices.getAll(`administrasi/dokumen/instansi/${setDataProp.uuid_instansi}/list`, token)
			.then((response) => {
				console.log("data list dokumen");
				console.log(response.results);
				if(response.results){
					let frmSelect = [];
					response.results.forEach(dt => {
						frmSelect.push({ value: dt.uuid, label: dt.nama });
					});
					setListDokumen(frmSelect);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};


	const refreshList = (page = '') => {
		getDataList(page);
	};

	useEffect(() => {
		if (setDataProp.uuid) {
			// console.log(props.match.params);
			setUuidSuratInstansi(setDataProp.uuid)
			getListDokumenInstansi();
			getDataList();
			// getTujuanSuratList();
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const [tambahModalStatus, setTambahModalStatus] = useState(false);
	const [editModalStatus, setEditModalStatus] = useState(false);

	const [state, setState] = useState(false);

	const [centeredStatus, setCenteredStatus] = useState(false);

	const initialStatus = () => {
		setCenteredStatus(false);
	};

	const detailData = (data) => {
		if (data) {
			setDetailDokumen({
				uuid: data.uuid,
				uuid_surat_instansi: data.uuid_surat_instansi,
				nama: data.nama,
			});
		}
	};

	const onDelete = (getUuid) => {
		setDetailDokumen({ ...detailDokumen, uuid: getUuid });
	};

	const hapusData = () => {
		const data = {
			uuid: detailDokumen.uuid,
		};

		GlobalServices.remove(data, `administrasi/dokumen-syarat-surat`, token).catch((e) => {
			setState(false);

			if (e === 204) {
				refreshList();
				setState(false);
				setDetailDokumen(initialDokumenState);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Berhasil Hapus Data</span>
					</span>,
					'Berhasil Hapus Data Dokumen Syarat',
				);
			} else {
				setState(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Hapus Data</span>
					</span>,
					'Gagal Hapus Data Dokumen Syarat',
				);
			}
		});
	};

	// Fitur Pencarian
	const dataCari = {
		nama: '',
	};

	const [cariValue, setcariValue] = useState(dataCari);
	const handleInputSearch = (event) => {
		const { name, value } = event.target;
		setcariValue({ ...cariValue, [name]: value });
	};

	const handleSearch = () => {
		let extra = `?page=${1}`

		GlobalServices.getSearch(`administrasi/dokumen-syarat-surat/surat-instansi/${setDataProp.uuid}${extra}`, cariValue.nama, token)
			.then((response) => {
				setList(response.data.results.data);
				setPagination(response.data.results)
				setCurrentPage(response.data.results.current_page)
				setPerPage(response.data.results.per_page)
				
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const handleSearchEnter = (event) => {
		if (event.key === 'Enter') {
			const extra = `?page=${1}`

			GlobalServices.getSearch(`administrasi/dokumen-syarat-surat/surat-instansi/${setDataProp.uuid}${extra}`, cariValue.nama, token)
				.then((response) => {
					console.log(response);
					setList(response.data.results.data);
					setPagination(response.data.results)
					setCurrentPage(response.data.results.current_page)
					setPerPage(response.data.results.per_page)
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};
	// End Fitur Pencarian

	return (
		<PageWrapper title={menuMaster.dokumen.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{ title: menuMaster.dokumen.text, to: menuMaster.dokumen.path },
							// { title: 'Dokumen Syarat', to: '#' },
							// { title: setDataProp.nama, to: menuMaster.dokumen.path }
						]}
					/>
				</SubHeaderLeft>
				<SubHeaderRight>
					<Button
						icon='ArrowLeft'
						color='primary'
						// isLight
						onClick={() => props.history.goBack()}>
						Kembali
					</Button>
				</SubHeaderRight>
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
						{
							loading
							?
							<>
							<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
								<br/><br/><br/><br/><br/><br/><br/><br/>
								<div className='row g-3'>
								{Object.keys(COLORS).map((color) => (
									<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
										<Spinner color={COLORS[color]} isSmall isGrow />
									</div>
								))}
								</div>
								<br/><br/><br/><br/>
							</div>
							</>
						:
							<>
							<CardBody isScrollable className='table-responsive'>
								{/* <h1>{list}</h1> */}
								<div className='row'>
									<div className='col-3' align='left'>
										<Button
											// icon='Add'
											color='primary'
											// isLight
											onClick={() => setTambahModalStatus(true)}>
											Tambah Data
										</Button>
									</div>
									<div className='col-9'>
										&nbsp;
										{/* <div className='row'>
											<div className='col-11'>
												<Input
													type='text'
													name='nama'
													value={cariValue.nama}
													onChange={handleInputSearch}
													isRequired
													onKeyDown={handleSearchEnter}
												/>
											</div>
											<div className='col-1' align='right'>
												<Button
													icon='Search'
													color='primary'
													// isLight
													onClick={handleSearch}>
												</Button>
											</div>
										</div> */}
									</div>
								</div>
								<br />
								<table className='table table-modern table-hover'>
									<thead>
										<tr>
											<th style={{ width: '50px', textAlign: 'center' }}>No.</th>
											<th>Nama Dokumen</th>
											<th style={{ width: '150px', textAlign: 'center' }}>Aksi</th>
										</tr>
									</thead>
									<tbody>
										{list.map(
											(i, key) => (
												<tr key={i.id}>
													<td style={{ textAlign: 'center' }}>{key + 1}</td>
													<td>{i.data_dokumen.nama}</td>
													<td style={{ textAlign: 'center' }}>
														<Button
															icon='Delete'
															color='danger'
															onClick={() => {
																initialStatus();
																setCenteredStatus(true);
																setState(true);
																onDelete(i.uuid);
															}} />
													</td>
												</tr>
											),
										)}
									</tbody>
								</table>
							</CardBody>
							<PaginationButtons
								data={list}
								label='Dokumen'
								setCurrentPage={getDataList}
								setPerPage={setPerPage}
								currentPage={currentPage}
								perPage={perPage}
								paginationDetail={pagination}
								setpaginationDetail={setPagination}
							/>
							</>
						}
						</Card>
					</div>
				</div>
			</Page>
			<ModalData
				setIsOpen={setTambahModalStatus}
				isOpen={tambahModalStatus}
				id={uuidSuratInstansi}
				statusRefresh={refreshList}
				listDokumen={listDokumen}
				title='Tambah Data Dokumen Syarat'
				action='Tambah'
			/>
			<Modal
				isOpen={state}
				setIsOpen={setState}
				titleId='exampleModalLabel'
				isCentered={centeredStatus}>
				<ModalHeader>&nbsp;</ModalHeader>
				<ModalBody>
					<p align='center'>
						<h2>Yakin Ingin Hapus Data?</h2> <br />
						Proses ini tidak dapat dibatalkan!
					</p>
					<br />
					<div align='center'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						&nbsp;&nbsp;
						<Button color='danger' icon='Delete' onClick={hapusData}>
							Hapus
						</Button>
					</div>
				</ModalBody>
				<ModalFooter>
					&nbsp;
					{/* <div align='center'>
						<Button
							color='success'
							// isOutline
							className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						<Button color='danger' icon='Delete'>
							Hapus
						</Button>
					</div> */}
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};

export default DokumenSyarat;
