import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
} from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import Button from '../bootstrap/Button';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../bootstrap/Card';
import Avatar from '../Avatar';
import ImgDummy from '../../assets/img/used/user-dummy.png';
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';

import showNotification from '../extras/showNotification';
import Icon from '../icon/Icon';

import GlobalServices from './Services/GlobalServices';

// Modal
import ModalDetail from './Karyawan/ModalVerifikasi';
import ModalDataDokumen from './Karyawan/ModalDataDokumen';

const Customer = (props) => {
	const roleUser = localStorage.getItem('role_user');
	const uuidInstansi = localStorage.getItem('uuid_instansi');
	const token = sessionStorage.getItem('access_token');

	const location = useLocation()
  	const setDataProp = location.state?.dataProp
	// const history = useHistory();
	console.log('hasil data prop detail karyawan');
	console.log(setDataProp);

	// Fitur Modal
	const [loading, setLoading] = useState(true);
	const [detailModalStatus, setDetailModalStatus] = useState(false);
	const [tambahModalStatus, setTambahModalStatus] = useState(false);
	const [listDokumenInstansi, setListDokumenInstansi] = useState([]);
	// End Fitur Modal

	const getDataDok = () => {
		GlobalServices.getListBy('administrasi/dokumen-karyawan', 'karyawan', setDataProp.uuid, token).then((hasil) => {
			console.log('Data Dokumen Karyawan')
			console.log(hasil)
			if(hasil.data.results){
				setDataDok(hasil.data.results);
			}
		});
	};

	
	const refreshList = () => {
		getDataDok();
	};

	const [dataDok, setDataDok] = useState([]);
	useEffect(() => {
		if (setDataProp) {
			getDataDok();
			setLoading(false)
		}

		GlobalServices.getAll(`administrasi/dokumen/instansi/${setDataProp.uuid_instansi}/list?filter=Dosen`, token)
			.then((response) => {
				console.log('List Dokumen Instansi');
				console.log(response.results);
				let frmSelect = [];
				response.results.forEach(dt => {
					frmSelect.push({ value: dt.uuid, label: dt.nama });
				});
				setListDokumenInstansi(frmSelect)
			})
			.catch((e) => {
				console.log(e);
			});
			
	}, [setDataProp]) // eslint-disable-line react-hooks/exhaustive-deps

	// Fitur Detail Dokumen
	
	const [detailDokKaryawan, setDetailDokKaryawan] = useState({
		uuid:'',
		uuid_dokumen:'',
		uuid_karyawan:'',
		link:'',
		nama:'',
		status_dokumen:''
	});
	const detailDokumen = (data) => {
		if (data) {
			console.log('show detail dko');
			console.log(data);
			const datanya = data;
			const dataDetail = {
				uuid: datanya.uuid,
				uuid_dokumen: datanya.uuid_dokumen,
				nama_dokumen: datanya.data_dokumen.nama,
				uuid_karyawan: datanya.uuid_karyawan,
				link: datanya.link,
				status: datanya.status,
			}
			setDetailDokKaryawan(dataDetail);
		}
	};
	// End Fitur Detail Data

	const statusDok = (item) => {
		switch (item) {
			case 'Aktif':
				return (
					<small><font color='green'><Button color='success' isOutline size='sm'>{item}</Button></font></small> 
				)
				// break;
			
			case 'Diajukan':
				return (
					<small><font color='yellow'><Button color='warning' isOutline size='sm'>{item}</Button></font></small> 
				)
				// break;
			case 'Tidak Aktif':
				return (
					<small><font color='red'><Button color='danger' isOutline size='sm'>{item}</Button></font></small>
				)
				// break;
			default:
				return ''
				// break;
		}
	}

	return (
		<PageWrapper title='Detail Profil Kepegawaian'>
			<SubHeader>
				<SubHeaderLeft>
					<Button
						icon='ArrowLeft'
						color='primary'
						// isLight
						onClick={() => props.history.goBack()}>
						Kembali
					</Button>
					{/* <SubheaderSeparator /> */}
					{/* <span className='text-muted fst-italic me-2'>Last update:</span>
					<span className='fw-bold'>13 hours ago</span> */}
				</SubHeaderLeft>
				<SubHeaderRight>
					{/* <Button icon='Edit' color='primary' isLight onClick={handleClickEdit}>
						Edit
					</Button> */}
				</SubHeaderRight>
			</SubHeader>
			<Page>
				{/* <div className='pt-3 pb-5 d-flex align-items-center'> */}
					{/* <span className='display-4 fw-bold me-3'>{setDataProp.nama}</span> */}
					{/* <span className='border border-success border-2 text-success fw-bold px-3 py-2 rounded'>
						{setDataProp.jenis_karyawan}
					</span> */}
				{/* </div> */}
				{
					loading
					?
					<>
					<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
						<br/><br/><br/><br/><br/><br/><br/><br/><br/>
						<div className='row g-3'>
						{Object.keys(COLORS).map((color) => (
							<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
								<Spinner color={COLORS[color]} isSmall isGrow />
							</div>
						))}
						</div>
						<br/><br/><br/><br/>
					</div>
					</>
				:
					<>
					<div className='row'>
						<div className='col-lg-4'>
							<Card className='shadow-3d-primary'>
								<CardBody>
									<div className='row g-5 py-3'>
										<div className='col-12 d-flex justify-content-center'>
											<Avatar
												src={ImgDummy}
												// srcSet={item.srcSet}
												// color={getColorNameWithIndex(item.id)}
												// isOnline={item.isOnline}
											/>
										</div>
										<div className='col-12'>
											<div className='row g-3'>
												<div className='col-12'>
													<div className='d-flex align-items-center' align='center'>
														<div className='flex-shrink-0'>
															&nbsp;
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-5 mb-0'>
																{setDataProp.nama}
															</div>
															<div className='text-muted'>
																Nama
															</div>
														</div>
													</div>
												</div>
												<div className='col-6'>
													<div className='d-flex align-items-center' align='left'>
														<div className='flex-shrink-0'>
															&nbsp;
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-5 mb-0'>
																{setDataProp.tempat_lahir?setDataProp.tempat_lahir:'Data Kosong'}
															</div>
															<div className='text-muted'>
																Tempat Lahir
															</div>
														</div>
													</div>
												</div>
												<div className='col-6'>
													<div className='d-flex align-items-center' align='left'>
														<div className='flex-shrink-0'>
															&nbsp;
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-5 mb-0'>
																{setDataProp.tanggal_lahir?setDataProp.tanggal_lahir:'Data Kosong'}
															</div>
															<div className='text-muted'>
																Tanggal Lahir
															</div>
														</div>
													</div>
												</div>
												<div className='col-6'>
													<div className='d-flex align-items-center' align='left'>
														<div className='flex-shrink-0'>
															&nbsp;
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-5 mb-0'>
																{setDataProp.jenis_kelamin?setDataProp.jenis_kelamin:'Data Kosong'}
															</div>
															<div className='text-muted'>
																Jenis Kelamin
															</div>
														</div>
													</div>
												</div>
												<div className='col-6'>
													<div className='d-flex align-items-center' align='left'>
														<div className='flex-shrink-0'>
															&nbsp;
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-5 mb-0'>
																{setDataProp.agama?setDataProp.agama:'Data Kosong'}
															</div>
															<div className='text-muted'>
																Agama
															</div>
														</div>
													</div>
												</div>
												<div className='col-6'>
													<div className='d-flex align-items-center' align='left'>
														<div className='flex-shrink-0'>
															&nbsp;
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-5 mb-0'>
																{setDataProp.golongan_darah?setDataProp.golongan_darah:'Data Kosong'}
															</div>
															<div className='text-muted'>
																Golongan Darah
															</div>
														</div>
													</div>
												</div>
												<div className='col-6'>
													<div className='d-flex align-items-center' align='left'>
														<div className='flex-shrink-0'>
															&nbsp;
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-5 mb-0'>
																{setDataProp.status_aktif?setDataProp.status_aktif:'Data Kosong'}
															</div>
															<div className='text-muted'>
																Status Aktif
															</div>
														</div>
													</div>
												</div>
												<div className='col-12'>
													<div className='d-flex align-items-center' align='center'>
														<div className='flex-shrink-0'>
															&nbsp;
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-5 mb-0'>
																{setDataProp.alamat?setDataProp.alamat:'Data Kosong'}
															</div>
															<div className='text-muted'>
																Alamat
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
						<div className='col-lg-8'>
							<Card>
								<CardHeader>
									<CardLabel icon='MapsHomeWork'>
										<CardTitle>Detail Kepegawaian</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='row'>
										<div className='col-6'>
											<div className='d-flex align-items-center'>
												<div className='flex-shrink-0'>
													&nbsp;
												</div>
												<div className='flex-grow-1 ms-3'>
													<div className='fw-bold fs-5 mb-0'>
														{setDataProp.nip?setDataProp.nip:'Data Kosong'}
													</div>
													<div className='text-muted'>
														{setDataProp.data_instansi.jenis === 'Pemerintahan'?'NIP' : 'No. Karyawan'}
													</div>
												</div>
											</div>
										</div>
										<div className='col-6'>
											<div className='d-flex align-items-center'>
												<div className='flex-shrink-0'>
													&nbsp;
												</div>
												<div className='flex-grow-1 ms-3'>
													<div className='fw-bold fs-5 mb-0'>
														{setDataProp.data_tempat_tugas?setDataProp.data_tempat_tugas.nama:'Data Kosong'}
													</div>
													<div className='text-muted'>
														Tempat Tugas
													</div>
												</div>
											</div>
										</div>

										<div className='col-12'>&nbsp;</div>

										<div className='col-6'>
											<div className='d-flex align-items-center'>
												<div className='flex-shrink-0'>
													&nbsp;
												</div>
												<div className='flex-grow-1 ms-3'>
													<div className='fw-bold fs-5 mb-0'>
														{setDataProp.data_jabatan_struktural?setDataProp.data_jabatan_struktural.nama:'Data Kosong'}
													</div>
													<div className='text-muted'>
														Jabatan Struktural
													</div>
												</div>
											</div>
										</div>
										<div className='col-6'>
											<div className='d-flex align-items-center'>
												<div className='flex-shrink-0'>
													&nbsp;
												</div>
												<div className='flex-grow-1 ms-3'>
													<div className='fw-bold fs-5 mb-0'>
														{setDataProp.jenis_karyawan?setDataProp.jenis_karyawan:'-'}
													</div>
													<div className='text-muted'>
														Jenis Karyawan
													</div>
												</div>
											</div>
										</div>

										{
											setDataProp.data_instansi && setDataProp.data_instansi.jenis === 'Pemerintahan'
											?
											<>
											<div className='col-12'>&nbsp;</div>
											<div className='col-6'>
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														&nbsp;
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='fw-bold fs-5 mb-0'>
															{setDataProp.data_jabatan_fungsional?setDataProp.data_jabatan_fungsional.nama:'Data Kosong'}
														</div>
														<div className='text-muted'>
															Jabatan Fungsional
														</div>
													</div>
												</div>
											</div>
											<div className='col-6'>
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														&nbsp;
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='fw-bold fs-5 mb-0'>
															{setDataProp.status_pegawai?setDataProp.status_pegawai:'Data Kosong'}
														</div>
														<div className='text-muted'>
															Status Pegawai
														</div>
													</div>
												</div>
											</div>
											<div className='col-12'>&nbsp;</div>
											{
												setDataProp.status_pegawai === 'PNS'
												?
												<>
													<div className='col-6'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																&nbsp;
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-5 mb-0'>
																	{setDataProp.data_pangkat_golongan?`${setDataProp.data_pangkat_golongan.golongan}${setDataProp.data_pangkat_golongan.ruang}-${setDataProp.data_pangkat_golongan.pangkat}`:'Data Kosong'}
																</div>
																<div className='text-muted'>
																	Pangkat Golongan
																</div>
															</div>
														</div>
													</div>
													<div className='col-6'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																&nbsp;
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-5 mb-0'>
																	{setDataProp.tmt_cpns?setDataProp.tmt_cpns:'Data Kosong'}
																</div>
																<div className='text-muted'>
																	TMT CPNS
																</div>
															</div>
														</div>
													</div>
													<div className='col-12'>&nbsp;</div>
													<div className='col-6'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																&nbsp;
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-5 mb-0'>
																	{setDataProp.tmt_pns?setDataProp.tmt_pns:'Data Kosong'}
																</div>
																<div className='text-muted'>
																	TMT PNS
																</div>
															</div>
														</div>
													</div>
													<div className='col-6'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																&nbsp;
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-5 mb-0'>
																	{setDataProp.tmt_golongan?setDataProp.tmt_golongan:'Data Kosong'}
																</div>
																<div className='text-muted'>
																	TMT Golongan
																</div>
															</div>
														</div>
													</div>
													<div className='col-12'>&nbsp;</div>
												</>
												:
												<>
												</>
											}
											<div className='col-6'>
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														&nbsp;
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='fw-bold fs-5 mb-0'>
															{setDataProp.prodi?setDataProp.prodi:'Data Kosong'}
														</div>
														<div className='text-muted'>
															Prodi
														</div>
													</div>
												</div>
											</div>
											
											<div className='col-6'>
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														&nbsp;
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='fw-bold fs-5 mb-0'>
															{setDataProp.fakultas?setDataProp.fakultas:'Data Kosong'}
														</div>
														<div className='text-muted'>
															Fakultas
														</div>
													</div>
												</div>
											</div>
											<div className='col-12'>&nbsp;</div>
											<div className='col-6'>
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														&nbsp;
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='fw-bold fs-5 mb-0'>
															{setDataProp.universitas?setDataProp.universitas:'Data Kosong'}
														</div>
														<div className='text-muted'>
															Universitas
														</div>
													</div>
												</div>
											</div>
											<div className='col-6'>
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														&nbsp;
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='fw-bold fs-5 mb-0'>
															{setDataProp.unit_kerja?setDataProp.unit_kerja:'Data Kosong'}
														</div>
														<div className='text-muted'>
															Unit Kerja
														</div>
													</div>
												</div>
											</div>
											</>
											:
											<>
											</>
										}
									</div>
								</CardBody>
							</Card>
							<Card>
								<CardHeader>
									<CardLabel icon='Description'>
										<CardTitle>Dokumen</CardTitle>
									</CardLabel>
									<CardLabel>
										<Button
											// icon='Add'
											color='success'
											title='Tambah Dokumen Pegawai'
											onClick={() => setTambahModalStatus(true)}>
											Tambah Data Dokumen
										</Button>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='row'>
										<div className='col-12'>
											<div className='d-flex align-items-center'>
												<div className='flex-shrink-0'>
													&nbsp;
												</div>
												<div className='flex-grow-1 ms-3'>
													{dataDok.map((item) => (
														<>
														<div className='fw-bold fs-5 mb-0'>
															{statusDok(item.status)} {' '} &nbsp;
															{item.data_dokumen.nama}
															<br/><br/>
														</div>
														<div>
															<Button
																icon='Eye'
																color='primary'
																onClick={() => {
																	setDetailModalStatus(true);
																	detailDokumen(item);
																}}
															>
																Lihat Dokumen
															</Button>
														</div>
														<div className='fw-bold fs-5 mb-0'>
															&nbsp;
														</div>
														</>
													))}
												</div>
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
					</>
				}
			</Page>
			<ModalDetail
				setIsOpen={setDetailModalStatus}
				isOpen={detailModalStatus}
				id={0}
				setDetail={detailDokKaryawan}
				statusRefresh={refreshList}
				jenisVerifikasi='Dokumen'
			/>
			
			<ModalDataDokumen
				setIsOpen={setTambahModalStatus}
				isOpen={tambahModalStatus}
				id={setDataProp.uuid}
				statusRefresh={refreshList}
				listDokumenInstansi={listDokumenInstansi}
				title='Tambah'
			/>

		</PageWrapper>
	);
};

export default Customer;
