import React, { useState, useEffect } from 'react';
import Select from "react-select";
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import GlobalServices from '../Services/GlobalServices';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import SelectData from '../Services/SelectData';

const ModalTambah = ({ id, isOpen, setIsOpen, statusRefresh, listInstansi, listKaryawan, uuidInstansi }) => {
	const token = sessionStorage.getItem('access_token');
	const formData = {
		title: 'Tambah Data Tanda Tangan',
		id: null,
		nama: '',
		salam_penutup:'',
		nip_ttd: '',
		nama_ttd:'',
		nip_ttd_pengganti: '',
		nama_ttd_pengganti:'',
		dokumen: '',
	};
	
	const [dataValues, setdataValues] = useState(formData);
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setdataValues({ ...dataValues, [name]: value });
	};

	// useEffect(() => {
	// 	setListKaryawan(listKaryawan)
	// 	setListKaryawanPengganti(listKaryawan)
	// }, [listKaryawan]); // eslint-disable-line react-hooks/exhaustive-deps

	const [listJabatanTTD, setListJabatanTTD] = useState(SelectData.jabatanTTD);
	const [listJabatanTTDPengganti, setListJabatanTTDPengganti] = useState(SelectData.jabatanTTD);
	const [listStatusTTD, setListStatusTTD] = useState(SelectData.statusTTD);
	const [statusPengganti, setstatusPengganti] = useState(false);
	const [selectedJabatanTTD, setselectedJabatanTTD] = useState(null);
	const [selectedJabatanTTDPengganti, setselectedJabatanTTDPengganti] = useState(null);
	const [selectedStatusTTD, setselectedStatusTTD] = useState(null);

	const [dokumenSelected, setDokumenSelected] = useState(false);
	const [isComplete, setIsComplete] = useState(true);
	
	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "status") {
				let newArray = listStatusTTD;	
				newArray = newArray.filter(day => day !== e);
				setListStatusTTD(newArray)
			}
			else if (jenis === "jabatan_ttd") {
				let newArray = listJabatanTTD;	
				newArray = newArray.filter(day => day !== e);
				setListJabatanTTD(newArray)
			}
			else if (jenis === "jabatan_ttd_pengganti") {
				let newArray = listJabatanTTDPengganti;	
				newArray = newArray.filter(day => day !== e);
				setListJabatanTTDPengganti(newArray)
			}
		}
	};

	const handleSelectChange = (event, param) => {
		if (event) {
			switch (param) {
				case 'status':
					setselectedStatusTTD(event);
					break;
				case 'jabatan_ttd':
					setselectedJabatanTTD(event);
					break;
				case 'jabatan_ttd_pengganti':
					setselectedJabatanTTDPengganti(event);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'status':
					setselectedStatusTTD(null);
					break;
				case 'jabatan_ttd':
					setselectedJabatanTTD(null);
					break;
				case 'jabatan_ttd_pengganti':
					setselectedJabatanTTDPengganti(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const [instansi, setInstansi] = useState(formData);

	const handleSubmit = () => {
		let cekStatusOpsi = false;
		let getStatusPenggantiTTD = '';
		let nip_ttd_pengganti = '';
		let nama_ttd_pengganti = '';
		let jabatan_pengganti = '';
		if(statusPengganti){
			if(
				selectedStatusTTD &&
				selectedJabatanTTDPengganti &&
				dataValues.nip_ttd_pengganti &&
				dataValues.nama_ttd_pengganti
			){
				cekStatusOpsi = true;
				getStatusPenggantiTTD = selectedStatusTTD.value
				nip_ttd_pengganti = `NIP. ${dataValues.nip_ttd_pengganti}`
				nama_ttd_pengganti = dataValues.nama_ttd_pengganti
				jabatan_pengganti = selectedJabatanTTDPengganti.value
			}
		}
		else{
			cekStatusOpsi = true;
		}

		if(
			dataValues.salam_penutup &&
			dataValues.nip_ttd &&
			dataValues.nama_ttd &&
			dataValues.dokumen &&
			selectedJabatanTTD &&
			cekStatusOpsi
		){
			const isiTTD = [
				{key: 'salam_penutup', value: dataValues.salam_penutup, type: 'text'},
				{key: 'status_ttd', value: getStatusPenggantiTTD, type: 'text'},
				{key: 'jabatan', value: selectedJabatanTTD.value, type: 'text'},
				{key: 'jabatan_pengganti', value: jabatan_pengganti, type: 'text'},
				{key: 'nama_ttd', value: dataValues.nama_ttd, type: 'text'},
				{key: 'nip_ttd', value: `NIP. ${dataValues.nip_ttd}`, type: 'text'},
				{key: 'nama_ttd_pengganti', value: nama_ttd_pengganti, type: 'text'},
				{key: 'nip_ttd_pengganti', value: nip_ttd_pengganti, type: 'text'},
				{key: 'gambar_ttd', value: dataValues.dokumen, type: 'image'},
			]

			const dataConfigTTD = {
				komponen: 'tanda_tangan',
				isi: isiTTD,
			}
			const data = {
				uuid_instansi: uuidInstansi,
				config_ttd: JSON.stringify(dataConfigTTD),
			};
	
			// console.log('Data Simpannya')
			// console.log(isiTTD)
			// console.log(dataConfigTTD)
			// console.log(data)

			GlobalServices.create(data, `configurasi-tanda-tangan`, token)
				.then((response) => {
					if (response.data.code === 201) {
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Berhasil Tambah Data</span>
							</span>,
							'Berhasil Tambah Data Tanda Tangan',
						);
						statusRefresh();
						tutupForm();
					} else {
						// setIsOpen(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Tambah Data</span>
							</span>,
							'Gagal Tambah Data Tanda Tangan',
						);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		} 
		else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Tambah Data</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	const tutupForm = () => {
		setselectedJabatanTTD(null);
		setselectedJabatanTTDPengganti(null);
		setselectedStatusTTD(null);
		setIsOpen(false);
		setstatusPengganti(false);
		setdataValues(formData);
	};

	const uploadFile = (file) => {
		if (file) {
			const type = file.type.split('/');
			if (
				type[1] === 'jpg' || 
				type[1] === 'jpeg' || 
				type[1] === 'png'
			) {
				const body = new FormData()
				body.append('file', file)
				console.log(file);
				console.log(body);
	
				GlobalServices.create(body, 'upload-file/tanda-tangan', token)
					.then((response) => {
						console.log(response);
	
						if (response.data.code === 200) {
							setDokumenSelected(true)
							setIsComplete(true)
	
							setdataValues({
								...dataValues, dokumen : response.data.results
							})
							
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Upload Dokumen</span>
								</span>,
								'Berhasil Upload Dokumen',
							);
						} else {
							setDokumenSelected(false)
							setIsComplete(false)
							
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Upload Dokumen</span>
								</span>,
								'Gagal Upload Dokumen',
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
	
			}
		}
	}

	const handleChangeFile = (event) => {
		console.log(event.target.files[0]);
		let data = event.target.files[0]
		setIsComplete(false)
		uploadFile(data)
	}
	
	console.log('statusPengganti')
	console.log(statusPengganti)

	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id} key='modal-tambah-tandatangan'>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={id}>{instansi.title}</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row g-4'>
						<FormGroup id='salam_penutup' label='Salam Penutup' className='col-md-12'>
							<Input
								type='text'
								name='salam_penutup'
								value={dataValues.salam_penutup}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='nip_ttd' label='NIP Penandatangan' className='col-md-12'>
							<Input
								type='text'
								name='nip_ttd'
								value={dataValues.nip_ttd}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='nama_ttd' label='Nama Penandatangan' className='col-md-12'>
							<Input
								type='text'
								name='nama_ttd'
								value={dataValues.nama_ttd}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='jabatan_ttd' label='Jabatan Penandatangan' className='col-md-12'>
							<Select
								inputId="jabatan_ttd"
								classNamePrefix="select"
								name="jabatan_ttd"
								placeholder="Pilih Jabatan Penandatangan"
								isClearable
								required
								defaultValue={selectedJabatanTTD}
								value={selectedJabatanTTD}
								options={listJabatanTTD}
								onInputChange={e => handleInputSelect(e, "jabatan_ttd")}
								onChange={e => {
									handleSelectChange(e, "jabatan_ttd");
								}}
							/>
						</FormGroup>
						<div className='col-12'>
							<Button
								color={statusPengganti?'danger':'primary'}
								// isOutline
								// className='border-0'
								style={{width:'100px'}}
								onClick={() => {
									setstatusPengganti(!statusPengganti);
									setselectedStatusTTD(null);
									setselectedJabatanTTDPengganti(null);
									setdataValues({ ...dataValues, nip_ttd_pengganti: '', nama_ttd_pengganti:'' });
								}}>
								Pengganti
							</Button>
						</div>
						{
							statusPengganti
							?
							<>
								<FormGroup id='status' label='Status Pengganti' className='col-md-12'>
									<Select
										inputId="status"
										classNamePrefix="select"
										name="status"
										placeholder="Pilih Status Pengganti"
										isClearable
										required
										defaultValue={selectedStatusTTD}
										value={selectedStatusTTD}
										options={listStatusTTD}
										onInputChange={e => handleInputSelect(e, "status")}
										onChange={e => {
											handleSelectChange(e, "status");
										}}
									/>
								</FormGroup>
								<FormGroup id='nip_ttd_pengganti' label='NIP Penandatangan Pengganti' className='col-md-12'>
									<Input
										type='text'
										name='nip_ttd_pengganti'
										value={dataValues.nip_ttd_pengganti}
										onChange={handleInputChange}
										required
									/>
								</FormGroup>
								<FormGroup id='nama_ttd_pengganti' label='Nama Penandatangan Pengganti' className='col-md-12'>
									<Input
										type='text'
										name='nama_ttd_pengganti'
										value={dataValues.nama_ttd_pengganti}
										onChange={handleInputChange}
										required
									/>
								</FormGroup>
								<FormGroup id='jabatan_ttd_pengganti' label='Jabatan Penandatangan Pengganti' className='col-md-12'>
									<Select
										inputId="jabatan_ttd_pengganti"
										classNamePrefix="select"
										name="jabatan_ttd_pengganti"
										placeholder="Pilih Jabatan Penandatangan Pengganti"
										isClearable
										required
										defaultValue={selectedJabatanTTDPengganti}
										value={selectedJabatanTTDPengganti}
										options={listJabatanTTD}
										onInputChange={e => handleInputSelect(e, "jabatan_ttd_pengganti")}
										onChange={e => {
											handleSelectChange(e, "jabatan_ttd_pengganti");
										}}
									/>
								</FormGroup>
							</>
							:
							<>
							</>
						}
						<FormGroup id='dokumen' label={statusPengganti?'Gambar Tandatangan Pengganti':'Gambar Tandatangan'} className='col-md-12'>
							<Input
								id='dokumen'
								type='file'
								accept='application/image'
								onChange={handleChangeFile}
								required
								// value={dataBanner.dokumen}
							/>
						</FormGroup>
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button
						color='danger'
						// isOutline
						// className='border-0'
						onClick={() => {
							tutupForm();
						}}>
						Batal
					</Button>
					{/* <Button color='info' onClick={formik.handleSubmit}> */}
					<Button color='success' type='submit' onClick={handleSubmit} disabled={!isComplete}>
						Simpan
					</Button>
				</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalTambah.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	listInstansi: PropTypes.func.isRequired,
	listKaryawan: PropTypes.func.isRequired,
	uuidInstansi: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
};

export default ModalTambah;
