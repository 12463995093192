const COLORS = {
	DARK: 'dark',
	PRIMARY: 'primary',
	// SECONDARY: 'secondary',
	INFO: 'info',
	// WARNING: 'warning',
	// DANGER: 'danger',
	SUCCESS: 'success',
	// LIGHT: 'light',
};
export default COLORS;
