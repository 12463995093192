import React, { useEffect, useState } from 'react';
import Select from "react-select";
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
// import data from './DataInstansi';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
// import Select from '../../bootstrap/forms/Select';
import Option from '../../bootstrap/Option';
import GlobalServices from '../Services/GlobalServices';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';

const ModalData = ({ id, isOpen, setIsOpen, statusRefresh, detailSurat, title, listDisposisi }) => {
	const token = sessionStorage.getItem('access_token');
	const formData = {
		uuid: '',
		uuid_surat_instansi: '',
		uuid_disposisi_instansi: '',
	};
	
	// Fitur Modal
	const [state, setState] = useState(false);

	const [centeredStatus, setCenteredStatus] = useState(false);

	const initialStatus = () => {
		setCenteredStatus(false);
	};
	// End Fitur Modal

	const [dataListDisposisi, setDataListDisposisi] = useState(listDisposisi);
	const [dataJenisSurat, setDataJenisSurat] = useState(formData);

	useEffect(() => {
		if (detailSurat) {
			if(detailSurat.data_surat_instansi_disposisi){
				setDataJenisSurat({
					jenis_form: 'Edit',
					uuid: detailSurat.data_surat_instansi_disposisi.uuid,
					uuid_surat_instansi: detailSurat.uuid,
					uuid_disposisi_instansi: detailSurat.data_surat_instansi_disposisi.uuid_disposisi_instansi
				})
				const getSelectDisposisi = {value: detailSurat.data_surat_instansi_disposisi.uuid_disposisi_instansi, label: detailSurat.data_surat_instansi_disposisi.data_disposisi_instansi.nama_disposisi};
				setSelectedDisposisi(getSelectDisposisi);
			}
			else{
				setDataJenisSurat({
					jenis_form: 'Tambah',
					uuid_surat_instansi: detailSurat.uuid,
				})
			}
		}

		const dataDisposisi = listDisposisi;
		setDataListDisposisi(dataDisposisi)
	}, [detailSurat, listDisposisi])

	const handleSubmit = () => {
		let data = {}
		if (dataJenisSurat.jenis_form === 'Tambah') {
			data = {
				uuid: null,
				uuid_surat_instansi: dataJenisSurat.uuid_surat_instansi,
				uuid_disposisi_instansi: selectedDisposisi.value,
			}
		}
		else{
			data = {
				uuid: dataJenisSurat.uuid,
				uuid_surat_instansi: dataJenisSurat.uuid_surat_instansi,
				uuid_disposisi_instansi: selectedDisposisi.value,
			}
		}

		if(selectedDisposisi){
			if (dataJenisSurat.jenis_form === 'Tambah') {
				GlobalServices.create(data, `administrasi/surat-instansi/disposisi`, token)
					.then((response) => {
	
						if (response.data.code === 201) {
							setIsOpen(false);
							// setSelectedInstansi(null);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil {dataJenisSurat.jenis_form}</span>
								</span>,
								`Berhasil ${dataJenisSurat.jenis_form} Data Jenis Surat`,
							);
							tutupForm();
							statusRefresh();
						} else {
							// setIsOpen(false);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal {dataJenisSurat.jenis_form}</span>
								</span>,
								`Gagal ${dataJenisSurat.jenis_form} Data Disposisi Jenis Surat`,
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				GlobalServices.update(data, `administrasi/surat-instansi/disposisi`, token)
					.then((response) => {
	
						if (response.data.code === 201) {
							setIsOpen(false);
							// setSelectedInstansi(null);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil {dataJenisSurat.jenis_form}</span>
								</span>,
								`Berhasil ${dataJenisSurat.jenis_form} Data Disposisi Jenis Surat`,
							);
							tutupForm();
							statusRefresh();
						} else {
							// setIsOpen(false);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal {dataJenisSurat.jenis_form}</span>
								</span>,
								`Gagal ${dataJenisSurat.jenis_form} Data Disposisi Jenis Surat`,
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			}
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal {title}</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};


	const [selectedDisposisi, setSelectedDisposisi] = useState(null);

	const handleSelectChange = (event, param) => {
		if (event) {
			switch (param) {
				case 'disposisi':
					setSelectedDisposisi(event);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'disposisi':
					setSelectedDisposisi(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "disposisi") {
				let newArray = dataListDisposisi;	
				newArray = newArray.filter(day => day !== e);
				setDataListDisposisi(newArray)
			}
		}
	};

	// Fitur Hapus Data
	const hapusData = () => {
		const data = {
			uuid: dataJenisSurat.uuid,
		};

		GlobalServices.remove(data, 'administrasi/surat-instansi/disposisi', token).catch((e) => {
			setState(false);

			if (e === 204) {
				setIsOpen(false);
				setDataJenisSurat(formData);
				setSelectedDisposisi(null);
				statusRefresh();
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Berhasil Hapus Data</span>
					</span>,
					'Berhasil Hapus Data Disposisi Jenis Surat',
				);
			} else {
				setIsOpen(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Hapus Data</span>
					</span>,
					'Gagal Hapus Data Disposisi Jenis Surat',
				);
			}
		});
	};
	// End Fitur Hapus Data

	const tutupForm = () => {
		setSelectedDisposisi(null);
		setDataJenisSurat(formData);
	};

	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={id}>{title} Jenis Surat</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row g-4'>
						<FormGroup id='uuid_disposisi' label='Disposisi' className='col-md-12'>
							<Select
								inputId="uuid_disposisi"
								classNamePrefix="select"
								name="uuid_disposisi"
								placeholder="Pilih Disposisi"
								isClearable
								required
								defaultValue={selectedDisposisi}
								value={selectedDisposisi}
								options={dataListDisposisi}
								onInputChange={e => handleInputSelect(e, "disposisi")}
								onChange={e => {
									handleSelectChange(e, "disposisi");
								}}
							/>
						</FormGroup>
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					{
						dataJenisSurat.jenis_form === 'Edit' 
						?
							<Button
								// icon='Delete'
								color='danger'
								onClick={() => {
									initialStatus();
									setCenteredStatus(true);
									setState(true);
									hapusData();
								}}
							>
								Hapus
							</Button>
						:
						<>
						</>
					}
					<Button
						color='success'
						// isOutline
						// className='border-0'
						onClick={() => {
							setIsOpen(false);
							setDataJenisSurat(formData);
							// setSelectedInstansi(null);
						}}>
						Batal
					</Button>
					{/* <Button color='info' onClick={formik.handleSubmit}> */}
					<Button color='info' type='submit' onClick={handleSubmit}>
						Simpan
					</Button>
				</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalData.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
	listDisposisi: PropTypes.func.isRequired,
};

export default ModalData;
