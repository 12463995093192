import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMaster } from '../../menu';
import Card, { CardBody } from '../bootstrap/Card';
import RiwayatDataService from './Services/RiwayatServices';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Input from '../bootstrap/forms/Input';
import Breadcrumb from '../bootstrap/Breadcrumb';
import Select from '../bootstrap/forms/Select';
import Option from '../bootstrap/Option';
import InstansiDataService from './Services/InstansiServices';
import GlobalServices from './Services/GlobalServices';

// Modal
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';

// import Icon from '../icon/Icon';
// import { useFormik } from 'formik';
// import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
// import PAYMENTS from '../../common/data/enumPaymentMethod';
// import useSortableData from '../../hooks/useSortableData';

// Alert
// import showNotification from '../extras/showNotification';

const RiwayatList = (props) => {
	const location = useLocation()
  	const setDataProp = location.state?.dataProp
  	const setUuidInstansi = location.state?.uuid_instansi
	const token = sessionStorage.getItem('access_token');
	// const history = useHistory();
	// console.log('hasil data prop detail Riwayat');
	// console.log(setDataProp);

	// Fitur Pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	// End Fitur Pagination

	// Fetching Data
	const [list, setList] = useState(setDataProp.absensi);
	const [listTipe, setListTipe] = useState([]);

	useEffect(() => {
		setListTipe(setDataProp.absensi)
		GlobalServices.getAll(`absensi/jam/instansi/${setUuidInstansi}`, token).then((hasil) => {
			setListTipe(hasil.results.data);
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// End Fetching Data

	const handleSearch = () => {
		// setStatCari(true);
		GlobalServices.getAll(`absensi/riwayat/${setUuidInstansi}?start=${setDataProp.tanggal}&end=${setDataProp.tanggal}&tipe_jam=${selectedTipe}`, token)
			.then((response) => {
				console.log('Hasil Cari')
				console.log(response)
				console.log(response.results.data[0].absensi)
				// if(response.results.data !== []){
					setList(response.results.data[0].absensi);
				// }
				// else{
				// 	setList([])
				// }
			})
			.catch((e) => {
				console.log(e);
			});
	};
	// End Fitur Pencarian

	function timerConvert(num){ 
		const hours = Math.floor(num / 60);  
		const minutes = num % 60;
		let hasil = '';
		if(hours === 0){
			// hasil = `${minutes} Menit`;
			hasil = `${hours} Jam ${minutes} Menit`;
		}
		else{
			hasil = `${hours} Jam ${minutes} Menit`;
		}
		return hasil;
	}

	function dateConvert(tgl){ 
		const today = new Date(tgl); 
		let dd = today.getDate(); 
		let mm = today.getMonth()+1; 
		const yyyy = today.getFullYear(); 
			if(dd<10){
				dd=`0${dd}`;
			} 
			if(mm<10){
				mm=`0${mm}`;
			} 
		const getDate = `${dd}-${mm}-${yyyy}`;
		return getDate;
	}
	
	// Fitur Modal
	const [state, setState] = useState(false);

	const [centeredStatus, setCenteredStatus] = useState(false);

	const initialStatus = () => {
		setCenteredStatus(false);
	};
	// End Fitur Modal

	// Fitur Detail Data
	
	const [detail, setDetailData] = useState({});
	const detailData = (data) => {
		if (data) {
			console.log('show detail data riwayat karyawan');
			console.log(data);
			const datanya = data;
			const dataDetail = {
				tgl_absensi: datanya.created_at,
				jenis_absensi: datanya.data_jam.jenis,
				waktu_absensi: datanya.data_jam.jam_absen,
				toleransi_terlambat: datanya.data_jam.keterlambatan,
				waktu_checkin: new Date(datanya.created_at).toTimeString().split(' ')[0],
				keterlambatan: timerConvert(datanya.keterlambatan),
				nama_karyawan: datanya.data_user.data_karyawan.nama,
				nip: datanya.data_user.data_karyawan.nip,
				alamat: datanya.data_user.data_karyawan.alamat,
				no_hp: datanya.data_user.no_hp,
			}
			setDetailData(dataDetail);
		}
	};
	// End Fitur Detail Data
	
	const [selectedTipe, setSelectedTipe] = useState(null);
	const handleSelectChange = (event, param) => {
		if (event) {
			switch (param) {
				case 'tipe':
					setSelectedTipe(event.target.value);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'tipe':
					setSelectedTipe(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	return (
		<PageWrapper title={menuMaster.riwayat.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Button
						// icon='Add'
						color='success'
						// isLight
						onClick={() => props.history.goBack()}>
						Kembali
					</Button>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
							<CardBody isScrollable className='table-responsive'>
								<div className='row'>
									<div className='col-4' align='left'>
										<Select
											id='largeSelect'
											ariaLabel='Default select example'
											placeholder='Pilih Tipe Jam'
											onChange={(e) => {
												handleSelectChange(e, 'tipe');
											}}
											value={selectedTipe}
											size='md'>
											{listTipe.map((item) => (
												<Option value={item.uuid}>{item.tipe} - {item.jenis}</Option>
											))}
										</Select>
									</div>
									<div className='col-4' align='left'>
										<Button
											icon='Search'
											color='primary'
											// isLight
											onClick={handleSearch}
										>
											Lihat Riwayat
										</Button>
									</div>
								</div>
								<br />
								<table className='table table-modern table-hover'>
									<thead>
										<tr>
											<th>No.</th>
											<th>Nama Karyawan</th>
											<th>Jenis Absen</th>
											<th>Jam Absen</th>
											<th>Keterlambatan</th>
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{list.map(
											(i, key) => (
												<tr key={i.id}>
													<td>{key + 1}</td>
													<td>{i.data_user.data_karyawan.nama}</td>
													<td>{i.data_jam.jenis}</td>
													<td>{new Date(i.created_at).toTimeString().split(' ')[0]}</td>
													<td>{timerConvert(i.keterlambatan)}</td>
													<td>
														<Button
															icon='Eye'
															color='success'
															size='sm'
															onClick={() => {
																initialStatus();
																setCenteredStatus(true);
																setState(true);
																detailData(i);
															}}
														/>
													</td>
												</tr>
											),
										)}
									</tbody>
								</table>
							</CardBody>
							{/* <PaginationButtons
								data={list}
								label='customers'
								// setCurrentPage={setCurrentPage}
								// currentPage={currentPage}
								// perPage={perPage}
								// setPerPage={setPerPage}
							/> */}
						</Card>
					</div>
				</div>
			</Page>
			<Modal
				isOpen={state}
				setIsOpen={setState}
				size='lg'
				titleId={0}
			>
				<ModalHeader setIsOpen={setState} className='p-4'>
					<ModalTitle id={0}>
						Detail Riwayat Absensi Karyawan
					</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row'>
						<div className='col-6'>
							<b>Tanggal Absensi</b><br />
							{detail.tgl_absensi}
						</div>
						<div className='col-6'>
							<b>Jenis Absensi</b><br />
							{detail.jenis_absensi}
						</div>
						<div className='col-12'>&nbsp;</div>

						<div className='col-6'>
							<b>Waktu Absensi</b><br />
							{detail.waktu_absensi}
						</div>
						<div className='col-6'>
							<b>Toleransi Keterlambatan</b><br />
							{detail.toleransi_terlambat} menit
						</div>
						<div className='col-12'>&nbsp;</div>
						<div className='col-6'>
							<b>Waktu Check In</b><br />
							{detail.waktu_checkin}
						</div>
						<div className='col-6'>
							<b>Keterlambatan</b><br />
							{detail.keterlambatan}
						</div>
						<div className='col-12'>&nbsp;</div>
						<div className='col-6'>
							<b>Nama Karyawan</b><br />
							{detail.nama_karyawan}
						</div>
						<div className='col-6'>
							<b>NIP</b><br />
							{detail.nip}
						</div>
						<div className='col-12'>&nbsp;</div>
						<div className='col-6'>
							<b>No. HP</b><br />
							{detail.no_hp}
						</div>
						<div className='col-6'>
							<b>Alamat</b><br />
							{detail.alamat}
						</div>
						<div className='col-12'>&nbsp;</div>
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button
						color='success'
						// isOutline
						// className='border-0'
						onClick={() => {
							setState(false);
						}}>
						Tutup
					</Button>
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};

export default RiwayatList;
