import React, { useState, useEffect } from 'react';
import Select from "react-select";
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Button from '../../bootstrap/Button';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import Input from '../../bootstrap/forms/Input';
import FormGroup from '../../bootstrap/forms/FormGroup';
import GlobalServices from '../Services/GlobalServices';
import SelectData from '../Services/SelectData';

const ModalEdit = ({ id, isOpen, setIsOpen, statusRefresh, setDetail }) => {
	const token = sessionStorage.getItem('access_token');
	const roleUser = localStorage.getItem('role_user');
	const uuidInstansi = localStorage.getItem('uuid_instansi');
	const jenisInstansi = localStorage.getItem('jenis_instansi');

	console.log('detail modal child')
	console.log(setDetail)
	const formData = {
		title: 'Edit Data Pengguna',
		id: null,
		username: '',
		nip: '',
		password: '',
		no_hp: '',
		email: '',
		role: ''
	};

	const [dataEdit, setDataEdit] = useState(formData);
	const [selectedRole, setSelectedRole] = useState(dataEdit.selectRole);
	const [listRole, setListRole] = useState(SelectData.RolePengguna);

	useEffect(() => {
		if (setDetail) {
			setDataEdit({
				title: 'Edit Data Pengguna',
				id: setDetail.uuid,
				username: setDetail.username,
				nip: setDetail.nip,
				password: setDetail.password,
				no_hp: setDetail.no_hp,
				email: setDetail.email,
				role: setDetail.role,
				selectRole: setDetail.selectRole,
			});

			const getSelectedRole = setDetail.selectRole;
			setSelectedRole(getSelectedRole);
		}
	}, [setDetail]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setDataEdit({ ...dataEdit, [name]: value });
	};

	const handleSubmit = () => {

		if (
				dataEdit.username !== '' && 
				dataEdit.no_hp !== '' && 
				dataEdit.email !== '' &&
				selectedRole 
			) 
		{
			
			let data = {};
			if(dataEdit.password === ''){
				data = {
					uuid: setDetail.uuid,
					username: dataEdit.username,
					no_hp: dataEdit.no_hp,
					email: dataEdit.email,
					role: selectedRole.value,
				};
			}
			else{
				data = {
					uuid: setDetail.uuid,
					username: dataEdit.username,
					password: dataEdit.password,
					no_hp: dataEdit.no_hp,
					email: dataEdit.email,
					role: selectedRole.value,
				};
			}

			GlobalServices.update(data, 'pengguna', token)
				.then((response) => {
					if (response.data.code === 201) {
						setIsOpen(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Berhasil Ubah Data</span>
							</span>,
							'Berhasil Ubah Data Pengguna',
							);
						statusRefresh();
						tutupForm();
					} else {
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Ubah Data</span>
							</span>,
							'Gagal Ubah Data Pengguna',
						);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Ubah Data</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	const handleSelectChange = (event, param) => {
		
		if (event) {
			switch (param) {
				case 'role':
					setSelectedRole(event);
					break;
				
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'role':
					setSelectedRole(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "role") {
				let newArray = listRole;	
				newArray = newArray.filter(day => day !== e);
				setListRole(newArray)
			}
		}
	};

	const tutupForm = () => {
		setDataEdit(formData);
		setSelectedRole(null);
	};

	if (id || id === 0) {
		return (
			<Modal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				setDetail={setDetail}
				isStaticBackdrop
				size='md'
				titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={id}>
						{dataEdit.title}
					</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
				<div className='row g-4'>
					<FormGroup id='username' label='Username' className='col-md-6'>
						<Input
							type='text'
							name='username'
							value={dataEdit.username}
							onChange={handleInputChange}
							required
						/>
					</FormGroup>

					<FormGroup id='password' label='Password' className='col-md-6'>
						<Input
							type='password'
							name='password'
							value={dataEdit.password}
							onChange={handleInputChange}
						/>
					</FormGroup>
					
					<FormGroup id='no_hp' label='No. HP' className='col-md-6'>
						<Input
							type='text'
							name='no_hp'
							value={dataEdit.no_hp}
							onChange={handleInputChange}
							required
						/>
					</FormGroup>
					
					<FormGroup id='email' label='Email' className='col-md-6'>
						<Input
							type='email'
							name='email'
							value={dataEdit.email}
							onChange={handleInputChange}
							required
						/>
					</FormGroup>
					
					<FormGroup id='role' label='Role Pengguna' className='col-md-6'>
						<Select
							inputId="role"
							classNamePrefix="select"
							name="role"
							placeholder="Pilih Role Pengguna"
							isClearable
							defaultValue={selectedRole}
							value={selectedRole}
							options={listRole}
							onInputChange={e => handleInputSelect(e, "role")}
							onChange={e => {
								handleSelectChange(e, "role");
							}}
						/>
					</FormGroup>
				</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button
						color='success'
						// isOutline
						// className='border-0'
						onClick={() => {
							setIsOpen(false);
							setDataEdit(formData);
							tutupForm();
						}}>
						Batal
					</Button>
					<Button color='info' type='submit' onClick={handleSubmit}>
						Simpan
					</Button>
				</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalEdit.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
	setDetail: PropTypes.func.isRequired,
};

export default ModalEdit;