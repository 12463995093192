import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMaster } from '../../menu';
import Card, { CardBody } from '../bootstrap/Card';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import Breadcrumb from '../bootstrap/Breadcrumb';
import GlobalServices from './Services/GlobalServices';
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';

// Modal
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import ModalTambah from './Pengguna/ModalTambahPengguna';
import ModalEdit from './Pengguna/ModalEditPengguna';

// Alert
import showNotification from '../extras/showNotification';

const Pengguna = () => {
	const roleUser = localStorage.getItem('role_user');
	const uuidInstansi = localStorage.getItem('uuid_instansi');
	const token = sessionStorage.getItem('access_token');
	const formData = {
		id: '',
		username: '',
		nip: '',
		password: '',
		no_hp: '',
		email: '',
		role: '',
		selectRole: {},
	};

	const [dataValues, setDataValues] = useState(formData);

	// Fitur Pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);

	const [pagination, setPagination] = useState({
		current_page: 1,
		from: 1,
		last_page: 1,
		first_page_url: '',
		last_page_url: '',
		next_page_url: '',
		prev_page_url: 10,
		path: '',
		per_page: 10,
		to: 10,
		total: 0,
	});
	// End Fitur Pagination

	// Fetching Data
	const [loading, setLoading] = useState(true);
	const [list, setList] = useState([]);

	const getDataList = (page) => {
		let extra = ''

		if (page) {
			extra = `?page=${page}&q=${cariValue.nama}`
		}

		let link = ''
		if(roleUser === 'Admin' || roleUser === 'Super Operator'){
			link=`pengguna/instansi/${uuidInstansi}${extra}`;
		}
		else{
			link=`pengguna${extra}`;
		}

		GlobalServices.getAll(link, token)
			.then((response) => {
				setList(response.results.data);
				setPagination(response.results)
				setCurrentPage(response.results.current_page)
				setPerPage(response.results.per_page)
				setLoading(false)
			})
			.catch((e) => {
				setLoading(false)
				console.log(e);
			});
	};

	const refreshList = (page = '') => {
		getDataList(page);
	};

	useEffect(() => {
		getDataList();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// End Fetching Data

	// Fitur Modal
	const [tambahModalStatus, setTambahModalStatus] = useState(false);
	const [editModalStatus, setEditModalStatus] = useState(false);

	const [state, setState] = useState(false);

	const [centeredStatus, setCenteredStatus] = useState(false);

	const initialStatus = () => {
		setCenteredStatus(false);
	};
	// End Fitur Modal

	// Fitur Detail Data
	const [detailData, setdetailData] = useState({});

	const detail = (data) => {
		console.log('DataDetail Paranet')
		console.log(data)
		if (data) {
			const datanya = data;
			const dataDetail = {
				username: datanya.username,
				nip: datanya.data_karyawan ? datanya.data_karyawan.nip : '',
				password: datanya.password,
				no_hp: datanya.no_hp,
				email: datanya.email,
				role: datanya.role,
				uuid: datanya.uuid,
				selectRole: {value: datanya.role, label: datanya.role},
			};
			setdetailData(dataDetail);
		}
	};
	// End Fitur Detail Data

	// Fitur Hapus Data
	const onDelete = (getId) => {
		setDataValues({ ...dataValues, id: getId });
	};

	const hapusData = () => {
		const data = {
			uuid: dataValues.id,
		};

		GlobalServices.remove(data, 'pengguna', token).catch((e) => {
			setState(false);

			if (e === 204) {
				refreshList();
				setState(false);
				setDataValues(formData);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Berhasil Hapus Data</span>
					</span>,
					'Berhasil Hapus Data Pegawai',
				);
			} else {
				setState(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Hapus Data</span>
					</span>,
					'Gagal Hapus Data Pegawai',
				);
			}
		});
	};
	// End Fitur Hapus Data

	// Fitur Pencarian
	const dataCari = {
		nama: '',
	};

	const [cariValue, setcariValue] = useState(dataCari);
	const handleInputSearch = (event) => {
		const { name, value } = event.target;
		setcariValue({ ...cariValue, [name]: value });
	};

	const handleSearch = () => {
		let extra = `?page=${1}`

		let link = ''
		if(roleUser === 'Admin' || roleUser === 'Super Operator'){
			link=`pengguna/instansi/${uuidInstansi}${extra}`;
		}
		else{
			link=`pengguna${extra}`;
		}

		GlobalServices.getSearch(link, cariValue.nama, token)
			.then((response) => {
				setList(response.data.results.data);
				setPagination(response.data.results)
				setCurrentPage(response.data.results.current_page)
				setPerPage(response.data.results.per_page)
				
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const handleSearchEnter = (event) => {
		if (event.key === 'Enter') {
			const extra = `?page=${1}`

			let link = ''
			if(roleUser === 'Admin' || roleUser === 'Super Operator'){
				link=`pengguna/instansi/${uuidInstansi}${extra}`;
			}
			else{
				link=`pengguna${extra}`;
			}

			GlobalServices.getSearch(link, cariValue.nama, token)
				.then((response) => {
					setList(response.data.results.data);
					setPagination(response.data.results)
					setCurrentPage(response.data.results.current_page)
					setPerPage(response.data.results.per_page)
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};
	// End Fitur Pencarian

	return (
		<PageWrapper title={menuMaster.pengguna.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{
								title: menuMaster.pengguna.text,
								to: menuMaster.pengguna.path,
							},
						]}
					/>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
						{
							loading
							?
							<>
							<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
								<br/><br/><br/><br/><br/><br/><br/><br/><br/>
								<div className='row g-3'>
								{Object.keys(COLORS).map((color) => (
									<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
										<Spinner color={COLORS[color]} isSmall isGrow />
									</div>
								))}
								</div>
								<br/><br/><br/><br/>
							</div>
							</>
						:
							<>
							<CardBody isScrollable className='table-responsive'>
								<div className='row'>
									<div className='col-3' align='left'>
										<Button
											// icon='Add'
											color='success'
											// isLight
											onClick={() => setTambahModalStatus(true)}>
											Tambah Data
										</Button>
									</div>
									<div className='col-9'>
										<div className='row'>
											<div className='col-11'>
												<Input
													type='text'
													name='nama'
													value={cariValue.nama}
													onChange={handleInputSearch}
													isRequired
													onKeyDown={handleSearchEnter}
												/>
											</div>
											<div className='col-1' align='right'>
												<Button
													icon='Search'
													color='primary'
													// isLight
													onClick={handleSearch}>
													{/* Cari Data */}
												</Button>
											</div>
										</div>
									</div>
								</div>
								<br />
								<table className='table table-modern table-hover'>
									<thead>
										<tr>
											{/* <th>No.</th> */}
											<th>Nama Karyawan</th>
											<th>Username</th>
											<th>No. HP</th>
											<th>Email</th>
											{
												roleUser === 'Admin' || roleUser === 'Super Operator'
												?
												<></>
												:
												<th>Role</th>
											}
											<th style={{ width: '150px', textAlign: 'center' }}>Aksi</th>
										</tr>
									</thead>
									<tbody>
										{list.map(
											(i, key) => (
												<tr key={i.id}>
													{/* <td>{key + 1}</td> */}
													<td>{i.data_karyawan ? i.data_karyawan.nama : ''}</td>
													<td>{i.username}</td>
													<td>{i.no_hp}</td>
													<td>{i.email}</td>
													{
														roleUser === 'Admin'|| roleUser === 'Super Operator'
														?
														<></>
														:
														<td>{i.role}</td>
													}
													<td style={{ textAlign: 'center' }}>
														<Link
															to={{
																pathname: "/superoperator/hak-akses-pengguna",
																state: { dataProp: i },
															}}
															style={{ padding: 0 }}
														>
															<Button
																icon='Eye'
																color='info'
																title='Hak Akses'
																/>{' '}
														</Link>
														<Button
															icon='Pencil'
															color='primary'
															onClick={() => {
																setEditModalStatus(true);
																detail(i);
															}}>
															{/* Edit */}
														</Button>{' '}
														<Button
															icon='Delete'
															color='danger'
															onClick={() => {
																initialStatus();
																setCenteredStatus(true);
																setState(true);
																onDelete(i.uuid);
															}}>
															{/* Hapus */}
														</Button>
													</td>
												</tr>
											),
										)}
									</tbody>
								</table>
							</CardBody>
							<PaginationButtons
								data={list}
								label='Pengguna'
								setCurrentPage={getDataList}
								setPerPage={setPerPage}
								currentPage={currentPage}
								perPage={perPage}
								paginationDetail={pagination}
								setpaginationDetail={setPagination}
							/>
							</>
						}
						</Card>
					</div>
				</div>
			</Page>
			<ModalTambah
				setIsOpen={setTambahModalStatus}
				isOpen={tambahModalStatus}
				id={0}
				statusRefresh={refreshList}
			/>
			<ModalEdit
				setIsOpen={setEditModalStatus}
				isOpen={editModalStatus}
				id={0}
				setDetail={detailData}
				statusRefresh={refreshList}
			/>
			<Modal
				isOpen={state}
				setIsOpen={setState}
				titleId='exampleModalLabel'
				isCentered={centeredStatus}>
				<ModalHeader>&nbsp;</ModalHeader>
				<ModalBody>
					<p align='center'>
						<h2>Yakin Ingin Hapus Data?</h2> <br />
						Proses ini tidak dapat dibatalkan!
					</p>
					<br />
					<div align='center'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						&nbsp;&nbsp;
						<Button color='danger' icon='Delete' onClick={hapusData}>
							Hapus
						</Button>
					</div>
				</ModalBody>
				<ModalFooter>
					&nbsp;
					{/* <div align='center'>
						<Button
							color='success'
							// isOutline
							className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						<Button color='danger' icon='Delete'>
							Hapus
						</Button>
					</div> */}
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};

export default Pengguna;