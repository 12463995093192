import React, { useState } from 'react';
import Select from "react-select";
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import GlobalServices from '../Services/GlobalServices';
import SelectData from '../Services/SelectData';

const ModalTambah = ({ id, isOpen, setIsOpen, statusRefresh }) => {
	const token = sessionStorage.getItem('access_token');
	const initialInstansiState = {
		title: 'Tambah Data Instansi',
		id: null,
		nama: '',
		alamat: '',
		no_telp: '',
		lat: '',
		long: '',
		jam_istirahat: '',
		pengurangan_jam_kerja: '',
	};

	const [listJenisInstansi, setListJenisInstansi] = useState(SelectData.jenisInstansi);
	const [selectedJenis, setSelectedJenis] = useState(null);
	
	const handleSelectChange = (event, param) => {
		if (event) {
			switch (param) {
				case 'jenis_instansi':
					setSelectedJenis(event);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'jenis_instansi':
					setSelectedJenis(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "jenis_instansi") {
				let newArray = listJenisInstansi;	
				newArray = newArray.filter(day => day !== e);
				setListJenisInstansi(newArray)
			}
		}
	};

	const [instansi, setInstansi] = useState(initialInstansiState);
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setInstansi({ ...instansi, [name]: value });
	};

	const handleSubmit = () => {
		if (
			instansi.nama !== '' &&
			instansi.alamat !== '' &&
			instansi.no_telp !== '' &&
			instansi.lat !== '' &&
			instansi.long !== '' &&
			instansi.jam_istirahat !== '' &&
			instansi.pengurangan_jam_kerja !== '' &&
			selectedJenis
		) {
			const data = {
				nama: instansi.nama,
				jenis: selectedJenis.value,
				alamat: instansi.alamat,
				no_telp: instansi.no_telp,
				lat: instansi.lat,
				long: instansi.long,
				jam_istirahat: instansi.jam_istirahat,
				pengurangan_jam_kerja: instansi.pengurangan_jam_kerja,
			};

			GlobalServices.create(data, 'instansi', token)
				.then((response) => {
					if (response.data.code === 201) {
						setIsOpen(false);
						setSelectedJenis(null);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Berhasil Tambah Data</span>
							</span>,
							'Berhasil Tambah Data Instansi',
						);
						setInstansi(initialInstansiState);
						statusRefresh();
					} else {
						// setIsOpen(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Tambah Data</span>
							</span>,
							'Gagal Tambah Data Instansi',
						);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Tambah Data</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={id}>{instansi.title}</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row g-4'>
						<FormGroup id='nama' label='Nama Instansi' className='col-md-6'>
							<Input
								type='text'
								name='nama'
								// onChange={formik.handleChange}
								value={instansi.nama}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='jenis' label='Jenis Instansi' className='col-md-6'>
							<Select
								inputId="jenis"
								classNamePrefix="select"
								name="jenis"
								placeholder="Pilih Jenis Instansi"
								isClearable
								required
								defaultValue={selectedJenis}
								value={selectedJenis}
								options={listJenisInstansi}
								onInputChange={e => handleInputSelect(e, "jenis_instansi")}
								onChange={e => {
									handleSelectChange(e, "jenis_instansi");
								}}
							/>
						</FormGroup>
						<FormGroup id='no_telp' label='No Telp' className='col-md-6'>
							<Input
								type='text'
								name='no_telp'
								// onChange={formik.handleChange}
								value={instansi.no_telp}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='alamat' label='Alamat Instansi' className='col-md-6'>
							<Input
								type='text'
								name='alamat'
								// onChange={formik.handleChange}
								value={instansi.alamat}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='lat' label='Lat' className='col-md-6'>
							<Input
								type='text'
								name='lat'
								// onChange={formik.handleChange}
								value={instansi.lat}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='long' label='Long' className='col-md-6'>
							<Input
								type='text'
								name='long'
								// onChange={formik.handleChange}
								value={instansi.long}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='jam_istirahat' label='Jam Istirahat' className='col-md-6'>
							<Input
								type='time'
								name='jam_istirahat'
								value={instansi.jam_istirahat}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='pengurangan_jam_kerja' label='Pengurangan Jam Kerja' className='col-md-6'>
							<Input
								type='number'
								name='pengurangan_jam_kerja'
								value={instansi.pengurangan_jam_kerja}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button
						color='success'
						// isOutline
						// className='border-0'
						onClick={() => {
							setIsOpen(false);
							setInstansi(initialInstansiState);
							setSelectedJenis(null);
						}}>
						Batal
					</Button>
					{/* <Button color='info' onClick={formik.handleSubmit}> */}
					<Button color='info' type='submit' onClick={handleSubmit}>
						Simpan
					</Button>
				</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalTambah.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
};

export default ModalTambah;
