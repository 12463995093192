import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMaster } from '../../menu';
import Card, { CardBody } from '../bootstrap/Card';
import InstansiDataService from './Services/InstansiServices';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import Breadcrumb from '../bootstrap/Breadcrumb';
import PAYMENTS from '../../common/data/enumPaymentMethod';
import useSortableData from '../../hooks/useSortableData';

// Modal
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import ModalEdit from './Instansi/ModalEditInstansi';

// Alert
import showNotification from '../extras/showNotification';
import GlobalServices from './Services/GlobalServices';
import ModalData from './DisposisiInstansi/ModalData';

const DisposisiInstansi = (props) => {
	const location = useLocation()
	const dataInstansi = location.state?.dataInstansi
	const token = sessionStorage.getItem('access_token');
	const history = useHistory();

	const [uuidInstansi, setUuidInstansi] = useState('');

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const [pagination, setPagination] = useState({
		current_page: 1,
		from: 1,
		last_page: 1,
		first_page_url: '',
		last_page_url: '',
		next_page_url: '',
		prev_page_url: 10,
		path: '',
		per_page: 10,
		to: 10,
		total: 0,
	});
	const [list, setList] = useState([]);
	const [listTujuanSurat, setListTujuanSurat] = useState([]);

	const formData = {
		uuid: '',
		uuid_instansi: '',
		nama_disposisi: '',
		status: {},
	};
	
	const [setDetail, setDetailData] = useState(formData)

	const formik = useFormik({
		initialValues: {
			searchInput: '',
			payment: Object.keys(PAYMENTS).map((i) => PAYMENTS[i].name),
			minPrice: '',
			maxPrice: '',
		},
		// eslint-disable-next-line no-unused-vars
		onSubmit: (values) => {
			setState(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Hapus Data</span>
				</span>,
				'Data Dokumen Berhasil Dihapus',
			);
		},
	});

	const getDataList = (page) => {
		let extra = ''

		if (page) {
			extra = `?page=${page}`
		}

		GlobalServices.getAll(`disposisi-instansi/instansi/${dataInstansi.uuid}${extra}`, token)
			.then((response) => {
				setList(response.results.data);
				setPagination(response.results)
				setCurrentPage(response.results.current_page)
				setPerPage(response.results.per_page)
			})
			.catch((e) => {
				console.log(e);
			});
	};


	const refreshList = (page = '') => {
		getDataList(page);
	};

	const [listJabatanStruktural, setListJabatanStruktural] = useState([]);

	useEffect(() => {
		if (dataInstansi.uuid) {
			setUuidInstansi(dataInstansi.uuid)
			getDataList();
			GlobalServices.getListBy('jabatan-struktural', 'instansi', dataInstansi.uuid, token).then((hasil) => {
				if(hasil.data.results){
					let frmSelect = [];
					hasil.data.results.forEach(dt => {
						frmSelect.push({ value: dt.uuid, label: dt.nama });
					});
					setListJabatanStruktural(frmSelect)
				}
			});
			// getTujuanSuratList();
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const [tambahModalStatus, setTambahModalStatus] = useState(false);
	const [editModalStatus, setEditModalStatus] = useState(false);

	const [state, setState] = useState(false);

	const [centeredStatus, setCenteredStatus] = useState(false);

	const initialStatus = () => {
		setCenteredStatus(false);
	};

	const detailData = (data) => {
		if (data) {
			setDetailData({
				uuid: data.uuid,
				uuid_instansi: data.uuid,
				nama_disposisi: data.nama_disposisi,
				nama_instansi: data.data_instansi.nama,
				status: {
					value: data.status, 
					label: data.status===1?'Utama':'Tidak Utama'
				},
			});
		}
	};

	const onDelete = (getUuid) => {
		setDetailData({ ...setDetail, uuid: getUuid });
	};

	const hapusData = () => {
		const data = {
			uuid: setDetail.uuid,
		};

		GlobalServices.remove(data, `disposisi-instansi`, token).catch((e) => {
			setState(false);

			if (e === 204) {
				refreshList();
				setState(false);
				setDetailData(formData);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Berhasil Hapus Data</span>
					</span>,
					'Berhasil Hapus Data Dokumen',
				);
			} else {
				setState(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Hapus Data</span>
					</span>,
					'Gagal Hapus Data Dokumen',
				);
			}
		});
	};

	return (
		<PageWrapper title={menuMaster.konfigurasi.subMenu.disposisiInstansi.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{ title: menuMaster.konfigurasi.subMenu.disposisiInstansi.text, to: menuMaster.konfigurasi.subMenu.disposisiInstansi.path },
							{ title: dataInstansi.nama, to: '/'},
						]}
					/>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
							<CardBody isScrollable className='table-responsive'>
								{/* <h1>{list}</h1> */}
								<div className='row'>
									<div className='col-6' align='left'>
										<Button
											// icon='Add'
											color='success'
											onClick={() => history.goBack()}
											>
											Kembali
										</Button>
										&nbsp;
										&nbsp;
										&nbsp;
										<Button
											// icon='Add'
											color='primary'
											// isLight
											onClick={() => setTambahModalStatus(true)}>
											Tambah Data
										</Button>
									</div>
									<div className='col-6'>
										<div className='row'>
											<div className='col-1' align='right'>
												<label
													className='border-0 bg-transparent cursor-pointer me-0'
													htmlFor='searchInput'>
													<Icon icon='Search' size='2x' color='primary' />
												</label>
											</div>
											<div className='col-11'>
												<Input
													id='searchInput'
													type='search'
													// className='border-0 shadow-none bg-transparent'
													placeholder='Cari...'
													onChange={formik.handleChange}
													value={formik.values.searchInput}
												/>
											</div>
										</div>
									</div>
								</div>
								<br />
								<table className='table table-modern table-hover'>
									<thead>
										<tr>
											<th style={{ width: '50px', textAlign: 'center' }}>No.</th>
											<th>Nama Disposisi</th>
											<th style={{ width: '150px', textAlign: 'center' }}>Aksi</th>
										</tr>
									</thead>
									<tbody>
										{list.map(
											(i, key) => (
												<tr key={i.id}>
													<td style={{ textAlign: 'center' }}>{key + 1}</td>
													<td>{i.nama_disposisi}</td>
													<td style={{ textAlign: 'center' }}>
														<Link 
															// to={{ pathname: '/admin/detail-disposisi-instansi', query: { the: 'query' } }}
															to={{
																pathname: "/admin/detail-disposisi-instansi",
																state: { 
																	dataDisposisiInstansi: i,
																	dataListJabatanStruktural: listJabatanStruktural,
																 },
															  }}
															// to='/admin/detail-disposisi-instansi'
															style={{ padding: 0 }}
														>
															<Button
																icon='Eye'
																color='primary'
																title='Lihat Detail Disposisi'
																/>
														</Link>
														<Button
															icon='Pencil'
															color='success'
															onClick={() => {
																setEditModalStatus(
																	true,
																);
																detailData(i);
															}} />
														{' '}
														<Button
															icon='Delete'
															color='danger'
															onClick={() => {
																initialStatus();
																setCenteredStatus(true);
																setState(true);
																onDelete(i.uuid);
															}} />
													</td>
												</tr>
											),
										)}
									</tbody>
								</table>
							</CardBody>
							<PaginationButtons
								data={list}
								label='Modal Instansi'
								setCurrentPage={getDataList}
								setPerPage={setPerPage}
								currentPage={currentPage}
								perPage={perPage}
								paginationDetail={pagination}
								setpaginationDetail={setPagination}
							/>
						</Card>
					</div>
				</div>
			</Page>
			<ModalData
				setIsOpen={setTambahModalStatus}
				isOpen={tambahModalStatus}
				id={uuidInstansi}
				statusRefresh={refreshList}
				listTujuanSurat={listTujuanSurat}
				title='Tambah Data Modal Instansi'
				action='Tambah'
			/>
			<ModalData
				setIsOpen={setEditModalStatus}
				isOpen={editModalStatus}
				id={uuidInstansi}
				setDetail={setDetail}
				listTujuanSurat={listTujuanSurat}
				statusRefresh={refreshList}
				title='Edit Data Modal Instansi'
				action='Edit'
			/>
			<Modal
				isOpen={state}
				setIsOpen={setState}
				titleId='exampleModalLabel'
				isCentered={centeredStatus}>
				<ModalHeader>&nbsp;</ModalHeader>
				<ModalBody>
					<p align='center'>
						<h2>Yakin Ingin Hapus Data?</h2> <br />
						Proses ini tidak dapat dibatalkan!
					</p>
					<br />
					<div align='center'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						&nbsp;&nbsp;
						<Button color='danger' icon='Delete' onClick={hapusData}>
							Hapus
						</Button>
					</div>
				</ModalBody>
				<ModalFooter>
					&nbsp;
					{/* <div align='center'>
						<Button
							color='success'
							// isOutline
							className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						<Button color='danger' icon='Delete'>
							Hapus
						</Button>
					</div> */}
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};

export default DisposisiInstansi;
