import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMaster } from '../../menu';
import Card, { CardBody } from '../bootstrap/Card';
import InstansiDataService from './Services/InstansiServices';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import Breadcrumb from '../bootstrap/Breadcrumb';
import PAYMENTS from '../../common/data/enumPaymentMethod';
import useSortableData from '../../hooks/useSortableData';

// Modal
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import ModalEdit from './Instansi/ModalEditInstansi';

// Alert
import showNotification from '../extras/showNotification';
import GlobalServices from './Services/GlobalServices';
import ModalData from './DetailDisposisiInstansi/ModalData';

const DisposisiInstansi = (props) => {
	const location = useLocation()
	const dataDisposisi = location.state?.dataDisposisiInstansi
	// const listJabatanStruktural = location.state?.dataListJabatanStruktural
	const [listJabatanStruktural, setListJabatanStruktural] = useState([]) 
	const token = sessionStorage.getItem('access_token');
	const history = useHistory();

	const formData = {
		uuid: '',
		urutan: '',
		uuid_jabatan_struktural: '',
		uuid_disposisi_instansi: '',
	};
	
	const [setDetail, setDetailData] = useState(formData)
	const [dataDisposisiDetail, setdataDisposisiDetail] = useState(dataDisposisi.data_disposisi_instansi_detail)

	const getDataList = (page) => {
		let extra = ''

		if (page) {
			extra = `?page=${page}`
		}

		GlobalServices.getAll(`disposisi-instansi/find/${dataDisposisi.uuid}${extra}`, token)
			.then((response) => {
				console.log('hasil getdatalist');
				console.log(response);
				setdataDisposisiDetail(response.results.data_disposisi_instansi_detail);
				// setPagination(response.results)
				// setCurrentPage(response.results.current_page)
				// setPerPage(response.results.per_page)
			})
			.catch((e) => {
				console.log(e);
			});
	};


	const refreshList = (page = '') => {
		getDataList(page);
	};

	useEffect(() => {
		if (dataDisposisi) {
			getDataList();
			setListJabatanStruktural(location.state?.dataListJabatanStruktural)
			console.log(location.state);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const [tambahModalStatus, setTambahModalStatus] = useState(false);
	const [editModalStatus, setEditModalStatus] = useState(false);

	const [state, setState] = useState(false);

	const [centeredStatus, setCenteredStatus] = useState(false);

	const initialStatus = () => {
		setCenteredStatus(false);
	};

	const detailData = (data) => {
		console.log('detail data');
		console.log(data);
		if (data) {
			setDetailData({
				uuid: data.uuid,
				urutan: data.urutan,
				uuid_jabatan_struktural: data.uuid_jabatan_struktural,
				uuid_disposisi_instansi: data.uuid_disposisi_instansi,
				selectJabatanStruktural:  { value: data.uuid_jabatan_struktural, label:data.data_jabatan_struktural.nama},
			});
		}
	};

	const onDelete = (getUuid) => {
		setDetailData({ ...setDetail, uuid: getUuid });
	};

	const hapusData = () => {
		const data = {
			uuid: setDetail.uuid,
		};

		GlobalServices.remove(data, `disposisi-instansi/detail`, token).catch((e) => {
			setState(false);

			if (e === 204) {
				refreshList();
				setState(false);
				setDetailData(formData);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Berhasil Hapus Data</span>
					</span>,
					'Berhasil Hapus Data Detail Disposisi Instansi',
				);
			} else {
				setState(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Hapus Data</span>
					</span>,
					'Gagal Hapus Data Detail Disposisi Instansi',
				);
			}
		});
	};

	return (
		<PageWrapper title={menuMaster.konfigurasi.subMenu.disposisiInstansi.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{ title: menuMaster.konfigurasi.subMenu.disposisiInstansi.text, to: menuMaster.konfigurasi.subMenu.disposisiInstansi.path },
							{ title: dataDisposisi.data_instansi.nama, to: '/'},
							{ title: dataDisposisi.nama_disposisi, to: '/'},
						]}
					/>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
							<CardBody isScrollable className='table-responsive'>
								{/* <h1>{list}</h1> */}
								<div className='row'>
									<div className='col-6' align='left'>
										<Button
											// icon='Add'
											color='success'
											onClick={() => history.goBack()}
										>
											Kembali
										</Button>
										&nbsp;
										&nbsp;
										&nbsp;
										<Button
											// icon='Add'
											color='primary'
											// isLight
											onClick={() => setTambahModalStatus(true)}>
											Tambah Data
										</Button>
									</div>
									<div className='col-6'>
										{/* <div className='row'>
											<div className='col-1' align='right'>
												<label
													className='border-0 bg-transparent cursor-pointer me-0'
													htmlFor='searchInput'>
													<Icon icon='Search' size='2x' color='primary' />
												</label>
											</div>
											<div className='col-11'>
												<Input
													id='searchInput'
													type='search'
													// className='border-0 shadow-none bg-transparent'
													placeholder='Cari...'
													onChange={formik.handleChange}
													value={formik.values.searchInput}
												/>
											</div>
										</div> */}
									</div>
								</div>
								<br />
								<table className='table table-modern table-hover'>
									<thead>
										<tr>
											{/* <th style={{ width: '50px', textAlign: 'center' }}>No.</th> */}
											<th>Urutan</th>
											<th>Jabatan Struktural</th>
											<th style={{ width: '150px', textAlign: 'center' }}>Aksi</th>
										</tr>
									</thead>
									<tbody>
										{dataDisposisiDetail.map(
											(i, key) => (
												<tr key={i.id}>
													{/* <td style={{ textAlign: 'center' }}>{key + 1}</td> */}
													<td>{i.urutan}</td>
													<td>{i.data_jabatan_struktural?i.data_jabatan_struktural.nama:''}</td>
													<td style={{ textAlign: 'center' }}>
														<Button
															icon='Pencil'
															color='success'
															onClick={() => {
																setEditModalStatus(
																	true,
																);
																detailData(i);
															}} />
														{' '}
														<Button
															icon='Delete'
															color='danger'
															onClick={() => {
																initialStatus();
																setCenteredStatus(true);
																setState(true);
																onDelete(i.uuid);
															}} />
													</td>
												</tr>
											),
										)}
									</tbody>
								</table>
							</CardBody>
							{/* <PaginationButtons
								data={list}
								label='Modal Instansi'
								setCurrentPage={getDataList}
								setPerPage={setPerPage}
								currentPage={currentPage}
								perPage={perPage}
								paginationDetail={pagination}
								setpaginationDetail={setPagination}
							/> */}
						</Card>
					</div>
				</div>
			</Page>
			<ModalData
				setIsOpen={setTambahModalStatus}
				isOpen={tambahModalStatus}
				id={0}
				listJabatanStruktural={listJabatanStruktural}
				statusRefresh={refreshList}
				dataDisposisi={dataDisposisi}
				title='Tambah Data Detail Disposisi Instansi'
				action='Tambah'
			/>
			{/* <ModalData
				setIsOpen={setTambahModalStatus}
				isOpen={tambahModalStatus}
				statusRefresh={refreshList}
				// listTujuanSurat={listTujuanSurat}
			/> */}
			<ModalData
				setIsOpen={setEditModalStatus}
				isOpen={editModalStatus}
				setDetail={setDetail}
				id={0}
				listJabatanStruktural={listJabatanStruktural}
				statusRefresh={refreshList}
				title='Edit Data Detail Disposisi Instansi'
				action='Edit'
			/>
			<Modal
				isOpen={state}
				setIsOpen={setState}
				titleId='exampleModalLabel'
				isCentered={centeredStatus}>
				<ModalHeader>&nbsp;</ModalHeader>
				<ModalBody>
					<p align='center'>
						<h2>Yakin Ingin Hapus Data?</h2> <br />
						Proses ini tidak dapat dibatalkan!
					</p>
					<br />
					<div align='center'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						&nbsp;&nbsp;
						<Button color='danger' icon='Delete' onClick={hapusData}>
							Hapus
						</Button>
					</div>
				</ModalBody>
				<ModalFooter>
					&nbsp;
					{/* <div align='center'>
						<Button
							color='success'
							// isOutline
							className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						<Button color='danger' icon='Delete'>
							Hapus
						</Button>
					</div> */}
				</ModalFooter>
			</Modal>
		</PageWrapper>
	);
};

export default DisposisiInstansi;
