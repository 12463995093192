import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import PaginationButtons, { dataPagination, PER_COUNT } from '../PaginationButtons';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';

// Modal
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import ModalEdit from './Instansi/ModalEditInstansi';

// Alert
import showNotification from '../extras/showNotification';
import GlobalServices from './Services/GlobalServices';
import ModalData from './JenisSurat/ModalData';
import ModalDataDisposisi from './JenisSurat/ModalDataDisposisi';

const JenisSurat = ({ setDataInstansi, linkParam, setReset }) => {
	const linkTo = linkParam;
	const roleUser = localStorage.getItem('role_user');
	const token = sessionStorage.getItem('access_token');

	let uuidInstansi = ""
	if(roleUser==='Super Operator'){
		uuidInstansi = setDataInstansi;
	}
	else{
		uuidInstansi = setDataInstansi.uuid;
	}

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const [pagination, setPagination] = useState({
		current_page: 1,
		from: 1,
		last_page: 1,
		first_page_url: '',
		last_page_url: '',
		next_page_url: '',
		prev_page_url: 10,
		path: '',
		per_page: 10,
		to: 10,
		total: 0,
	});

	const [loading, setLoading] = useState(true);
	const [list, setList] = useState([]);
	const [listTujuanSurat, setListTujuanSurat] = useState([]);
	const [detailSurat, setDetailSurat] = useState({
		uuid_instansi: '',
		nama: '',
		format_nomor_surat: '',
		nama_alias: '',
		tujuan_surat: '',
		gambar: '',
	})

	const getDataList = (page) => {

		let extra = ''

		if (page) {
			extra = `?page=${page}&q=${cariValue.nama}`
		}

		GlobalServices.getAll(`administrasi/surat-instansi/instansi/${uuidInstansi}${extra}`, token)
			.then((response) => {
				setList(response.results.data);
				setPagination(response.results)
				setCurrentPage(response.results.current_page)
				setPerPage(response.results.per_page)
				setLoading(false)
			})
			.catch((e) => {
				setLoading(false)
				console.log(e);
			});
	};
	
	const getTujuanSuratList = () => {
		GlobalServices.getAll(`select-entity/select-enum/surat_instansi/tujuan_surat`, token)
			.then((response) => {
				let frmSelect = [];
				response.forEach(dt => {
					frmSelect.push({ value: dt, label: dt });
				});
				setListTujuanSurat(frmSelect)
			})
			.catch((e) => {
				console.log(e);
			});
	};
	
	
	const [listDisposisi, setListDisposisi] = useState([]);
	const getListDisposisi = () => {
			GlobalServices.getListBy('disposisi-instansi', 'instansi', uuidInstansi, token).then((hasil) => {
				if(hasil.data.results){
					let frmSelect = [];
					hasil.data.results.forEach(dt => {
						frmSelect.push({ value: dt.uuid, label: dt.nama_disposisi });
					});
					setListDisposisi(frmSelect)
				}
			});
	};


	const refreshList = (page = '') => {
		getDataList(page);
	};

	useEffect(() => {
		if (uuidInstansi) {
			getDataList();
			getTujuanSuratList();
			getListDisposisi();
		}
	}, [ setReset ]); // eslint-disable-line react-hooks/exhaustive-deps

	const [tambahModalStatus, setTambahModalStatus] = useState(false);
	const [editModalStatus, setEditModalStatus] = useState(false);
	const [disposisiModalStatus, setDisposisiModalStatus] = useState(false);

	const [state, setState] = useState(false);

	const [centeredStatus, setCenteredStatus] = useState(false);

	const initialStatus = () => {
		setCenteredStatus(false);
	};

	const initialSuratState = {
		id: '',
		nama: '',
		alamat: '',
		no_telp: '',
	};

	const [surat, setSurat] = useState(initialSuratState);
	const [selectTujuanSurat, setSelectTujuanSurat] = useState({});
	const [detailInstansi, setDetailInstansi] = useState({});

	const detailData = (data) => {
		console.log('Data Detail')
		console.log(data)
		if (data) {
			setDetailSurat(data);
			setSelectTujuanSurat({ value: data.tujuan_surat, label:data.tujuan_surat})
		}
	};

	const onDelete = (getId) => {
		setSurat({ ...surat, id: getId });
	};

	const hapusData = () => {
		const data = {
			uuid: surat.id,
		};

		GlobalServices.remove(data, `administrasi/surat-instansi`, token).catch((e) => {
			setState(false);

			if (e === 204) {
				refreshList();
				setState(false);
				setSurat(initialSuratState);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Berhasil Hapus Data</span>
					</span>,
					'Berhasil Hapus Data Jenis Surat',
				);
			} else {
				setState(false);
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Warning' size='lg' className='me-1' />
						<span>Gagal Hapus Data</span>
					</span>,
					'Gagal Hapus Data Jenis Surat',
				);
			}
		});
	};

	const tombolAksi = (i) => {
		if (linkTo) {
			switch (linkTo) {
				case 'Dokumen Syarat':
					return (
						<Link 
							// to={`/admin/syarat-dokumen-instansi/${i.uuid_instansi}/${i.uuid}`}
							to={{
								pathname: "/admin/detail-syarat-dokumen-instansi",
								state: { 
									dataProp: i,
								 },
							  }}
							style={{ padding: 0 }}
						>
							<Button
								icon='Eye'
								color='primary'
								title='Lihat Daftar Dokumen'
								/>
						</Link>
					)
					// break;
			
				default:
					return ''
					// break;
			}
		} else { // render khusus menu jenis surat
			return (
				<>
					<Button
							icon='Near Me'
							color='secondary'
							title='Disposisi Jenis Surat'
							onClick={() => {
								setDisposisiModalStatus(true);
								detailData(i);
							}}/>{' '}
					<Button
						icon='Pencil'
						color='primary'
						title='Edit'
						onClick={() => {
							setEditModalStatus(true);
							detailData(i);
						}}/>{' '}
					<Button
						icon='Delete'
						color='danger'
						title='Hapus'
						onClick={() => {
							initialStatus();
							setCenteredStatus(true);
							setState(true);
							onDelete(i.uuid);
						}}/>
				</>
			)
		}
	}

	// let titlePage = menuMaster.suratKeluar.text
	// if (title) {
	// 	titlePage = title
	// }

	// Fitur Pencarian
	const dataCari = {
		nama: '',
	};

	const [cariValue, setcariValue] = useState(dataCari);
	const handleInputSearch = (event) => {
		const { name, value } = event.target;
		setcariValue({ ...cariValue, [name]: value });
	};

	const handleSearch = () => {
		let extra = `?page=${1}`

		GlobalServices.getSearch(`administrasi/surat-instansi/instansi/${uuidInstansi}${extra}`, cariValue.nama, token)
			.then((response) => {
				setList(response.data.results.data);
				setPagination(response.data.results)
				setCurrentPage(response.data.results.current_page)
				setPerPage(response.data.results.per_page)
				
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const handleSearchEnter = (event) => {
		if (event.key === 'Enter') {
			const extra = `?page=${1}`

			GlobalServices.getSearch(`administrasi/surat-instansi/instansi/${uuidInstansi}${extra}`, cariValue.nama, token)
				.then((response) => {
					console.log(response);
					setList(response.data.results.data);
					setPagination(response.data.results)
					setCurrentPage(response.data.results.current_page)
					setPerPage(response.data.results.per_page)
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};
	// End Fitur Pencarian

	return (
		<>
			{
				loading
				?
				<>
				<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
					<br/><br/><br/><br/><br/><br/><br/><br/><br/>
					<div className='row g-3'>
					{Object.keys(COLORS).map((color) => (
						<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
							<Spinner color={COLORS[color]} isSmall isGrow />
						</div>
					))}
					</div>
					<br/><br/><br/><br/>
				</div>
				</>
			:
				<>
					<div className='row'>
						<div className='col-3' align='left'>
							{linkTo ? '' : (
								<Button
									// icon='Add'
									color='success'
									// isLight
									onClick={() => setTambahModalStatus(true)}>
									Tambah Data
								</Button>
							)}
						</div>
						<div className='col-9'>
							<div className='row'>
								<div className='col-11'>
									<Input
										type='text'
										name='nama'
										value={cariValue.nama}
										onChange={handleInputSearch}
										isRequired
										onKeyDown={handleSearchEnter}
									/>
								</div>
								<div className='col-1' align='right'>
									<Button
										icon='Search'
										color='primary'
										// isLight
										onClick={handleSearch}>
										{/* Cari Data */}
									</Button>
								</div>
							</div>
						</div>
					</div>
					<br />
					<table className='table table-modern table-hover'>
						<thead>
							<tr>
								{/* <th>No.</th> */}
								<th>Nama Surat</th>
								<th>Nama Alias</th>
								{linkTo ? '' : (
									<>
										<th>Format Nomor Surat</th>
										<th>Tujuan Surat</th>
										<th>Disposisi</th>
									</>
								)}
								<th style={{ width: '150px', textAlign: 'center' }}>Aksi</th>
							</tr>
						</thead>
						<tbody>
							{list.map(
								(i, key) => (
									<tr key={i.id}>
										{/* <td>{key + 1}</td> */}
										<td>{i.nama}</td>
										<td>{i.nama_alias}</td>
										{linkTo ? '' : (
											<>
												<td>{i.format_nomor_surat}</td>
												<td>{i.tujuan_surat}</td>
												<td>
													{i.data_surat_instansi_disposisi? i.data_surat_instansi_disposisi.data_disposisi_instansi.nama_disposisi : <font color='red'>Disposisi Belum Disetting</font>}
												</td>
											</>
										)}
										<td style={{ textAlign: 'center' }}>
											{tombolAksi(i)}
										</td>
									</tr>
								),
							)}
						</tbody>
					</table>

					<PaginationButtons
						data={list}
						label='Surat'
						setCurrentPage={getDataList}
						setPerPage={setPerPage}
						currentPage={currentPage}
						perPage={perPage}
						paginationDetail={pagination}
						setpaginationDetail={setPagination}
					/>
				</>
			}

			<ModalData
				setIsOpen={setTambahModalStatus}
				isOpen={tambahModalStatus}
				id={uuidInstansi}
				statusRefresh={refreshList}
				listTujuanSurat={listTujuanSurat}
				title='Tambah'
			/>
			<ModalData
				setIsOpen={setEditModalStatus}
				isOpen={editModalStatus}
				id={uuidInstansi}
				detailSurat={detailSurat}
				selectTujuanSurat={selectTujuanSurat}
				listTujuanSurat={listTujuanSurat}
				statusRefresh={refreshList}
				title='Edit'
			/>
			<ModalDataDisposisi
				setIsOpen={setDisposisiModalStatus}
				isOpen={disposisiModalStatus}
				id={uuidInstansi}
				detailSurat={detailSurat}
				listDisposisi={listDisposisi}
				statusRefresh={refreshList}
				title='Disposisi Jenis Surat'
			/>
			<Modal
				isOpen={state}
				setIsOpen={setState}
				titleId='exampleModalLabel'
				isCentered={centeredStatus}>
				<ModalHeader>&nbsp;</ModalHeader>
				<ModalBody>
					<p align='center'>
						<h2>Yakin Ingin Hapus Data?</h2> <br />
						Proses ini tidak dapat dibatalkan!
					</p>
					<br />
					<div align='center'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						&nbsp;&nbsp;
						<Button color='danger' icon='Delete' onClick={hapusData}>
							Hapus
						</Button>
					</div>
				</ModalBody>
				<ModalFooter>
					&nbsp;
					{/* <div align='center'>
						<Button
							color='success'
							// isOutline
							className='border-0'
							onClick={() => setState(false)}>
							Batal
						</Button>
						<Button color='danger' icon='Delete'>
							Hapus
						</Button>
					</div> */}
				</ModalFooter>
			</Modal>
		</>
	);
};

export default JenisSurat;
