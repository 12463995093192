import jwtDecode from "jwt-decode";
import http from './http-common';

const token_akses = localStorage.getItem('access_token');
// console.log('TOKEN LOGIN')
// console.log(token_akses)
// console.log(localStorage.getItem('access_token'))

const getAll = (table, token = '') => {
	return http
		// .get('/jabatan-fungsional')
		.get(`/${table}`, {
			headers: {'Authorization': `Bearer ${token === '' ? token_akses : token}` }
		})
		.then((response) => response.data)
		.catch((error) => {
			throw error.response;
		});
};

const get = (id, table, token = '') => {
	// return http.get(`/jabatan-fungsional/${id}`);
	return http.get(`/${table}/find/${id}`, {
		headers: {'Authorization': `Bearer ${token === '' ? token_akses : token}` }
	});
};

const getSearch = (table, param, token = '') => {
	// return http.get(`/jabatan-fungsional/${id}`);
	return http.get(`/${table}&q=${param}`, {
		headers: {'Authorization': `Bearer ${token === '' ? token_akses : token}` }
	});
};

const getList = (table, token = '') => {
	// return http.get(`/jabatan-fungsional/${id}`);
	return http.get(`/${table}/list`, {
		headers: {'Authorization': `Bearer ${token === '' ? token_akses : token}` }
	});
};

const getListBy = (tableChild, tableParent, id, token = '') => {
	// return http.get(`/jabatan-fungsional/${id}`);
	return http.get(`/${tableChild}/${tableParent}/${id}/list`, {
		headers: {'Authorization': `Bearer ${token === '' ? token_akses : token}` }
	});
};

const create = (data, table, token = '') => {
	return http.post(`/${table}`, data, {
		headers: {'Authorization': `Bearer ${token === '' ? token_akses : token}` }
	});
};

const update = (data, table, token = '') => {
	return http.patch(`/${table}`, data, {
		headers: {'Authorization': `Bearer ${token === '' ? token_akses : token}` }
	});
};

const remove = (data, table, token = '') => {
	return http
		.delete(`/${table}`, { data, headers: {'Authorization': `Bearer ${token === '' ? token_akses : token}` } }, null)
		.then(function (response) {
			throw response.status;
		})
		.catch((error) => {
			throw error;
		});
};

const removeAll = (table) => {
	return http.delete(`/${table}`, {
		headers: {'Authorization': `Bearer ${token_akses}` }
	});
};

const findByTitle = (title, table) => {
	return http.get(`/${table}?title=${title}`, {
		headers: {'Authorization': `Bearer ${token_akses}` }
	});
};

const DataToken = (token) => {
	// return http.get(`/jabatan-fungsional/${id}`);
	return jwtDecode(token);
};

const cetakLaporan = (table, token = '') => {
	return http
		.get(`/${table}`, {
			responseType: 'blob',
			headers: {'Authorization': `Bearer ${token === '' ? token_akses : token}` }
		})
		.then((response) => response.data)
		.catch((error) => {
			throw error.response;
		});
};

export default {
	getAll,
	cetakLaporan,
	get,
	getSearch,
	getList,
	getListBy,
	create,
	update,
	remove,
	removeAll,
	findByTitle,
	DataToken
};
