import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Button from '../../bootstrap/Button';

const HtmlToReactParser = require("html-to-react").Parser;

const ModalData = ({ id, isOpen, setIsOpen, detailData, title }) => {
	const [dataValues, setDataValues] = useState(detailData);

	useEffect(() => {
		if (detailData) {
			setDataValues(detailData)
		}
	}, [detailData]) // eslint-disable-line react-hooks/exhaustive-deps
	
    const htmlToReactParser = new HtmlToReactParser();

	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
					<ModalHeader setIsOpen={setIsOpen} className='p-4'>
						<ModalTitle id={id}>{title} Info</ModalTitle>
					</ModalHeader>
					<ModalBody className='px-4'>
						<div className='row g-4'>
							<div className='row'>
								<div className='col-12'>
									<b>Judul</b>
								</div>
								<div className='col-12'>
									{dataValues.judul}
								</div>
								<div className='col-12'>
									&nbsp;
								</div>
								<div className='col-12'>
									<b>Isi</b>
								</div>
								<div className='col-12'>
									<div className="container">
										<div className="row">
											<div className="span6">
												{htmlToReactParser.parse(dataValues.isi)}
											</div>
										</div>
									</div>
								</div>
								<div className='col-12'>
									&nbsp;
								</div>
								<div className='col-12'>
									<b>Gambar</b>
								</div>
								<div className='col-12'>
									<img src={dataValues.gambar} style={{width: '100%', height: 'auto'}} alt=''/>
								</div>
								<div className='col-12'>
									&nbsp;
								</div>
								<div className='col-12'>
									<b>Keterangan</b>
								</div>
								<div className='col-12'>
									{dataValues.keterangan}
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter className='px-4 pb-4'>
						<Button
							color='success'
							// isOutline
							// className='border-0'
							onClick={() => {
								setIsOpen(false);
							}}>
							Tutup
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalData.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};

export default ModalData;