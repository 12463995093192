import React, { useEffect, useState } from 'react';
import Select from "react-select";
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import GlobalServices from '../Services/GlobalServices';
import SelectData from '../Services/SelectData';

const ModalData = ({ id, isOpen, setIsOpen, statusRefresh, detailSurat, selectTujuanSurat, listTujuanSurat, title }) => {
	const token = sessionStorage.getItem('access_token');
	const formData = {
		uuid_instansi: '',
		nama: '',
		format_nomor_surat: '',
		nama_alias: '',
		gambar: '',
	};

	// const [selectedInstansi, setSelectedInstansi] = useState(null);
	const [dataTujuanSurat, setDataJenisTujuanSurat] = useState(listTujuanSurat);
	const [selectedTujuanSurat, setSelectedTujuanSurat] = useState(null);
	const [surat, setSurat] = useState(formData);
	const [gambarSelected, setGambarSelected] = useState(false);

	const handleSelect = (event, param) => {
		if (event) {
			switch (param) {
				case 'tujuan_surat':
					setSelectedTujuanSurat(event);
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (param) {
				case 'tujuan_surat':
					setSelectedTujuanSurat(null);
					break;
				default:
					// no default
					break;
			}
		}
	};

	const handleInputSelect = (e, jenis) => {
		if (e) {
			if (jenis === "jenis_instansi") {
				let newArray = dataTujuanSurat;	
				newArray = newArray.filter(day => day !== e);
				setDataJenisTujuanSurat(newArray)
			}
		}
	};

	useEffect(() => {
		if (detailSurat) {
			setSurat(detailSurat)
			
			const getTujuanSurat = selectTujuanSurat;
			setSelectedTujuanSurat(getTujuanSurat);
		}

		const setTujuanList = listTujuanSurat;
		setDataJenisTujuanSurat(setTujuanList);
	}, [detailSurat, listTujuanSurat, selectTujuanSurat])
	
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setSurat({ ...surat, [name]: value });
	};

	const handleSubmit = () => {
		const data = {
			...surat, 
			tujuan_surat: selectedTujuanSurat.value,
			uuid_instansi: id
		}

		let validated = true

		if (title === 'Tambah') {
			Object.keys(data).map((i, key) => {
				let stat = false
				if (typeof data[i] === 'string') {
					if (data[i] !== '') {
						stat = true
					}
				// } else if (data[i].length > 0) {
				// 	stat = true
				}
				
				validated = validated && stat
	
				return ''
			})
		}

		if (validated) {
			if (title === 'Tambah') {
				GlobalServices.create(data, `administrasi/surat-instansi`, token)
					.then((response) => {
	
						if (response.data.code === 201) {
							setIsOpen(false);
							// setSelectedInstansi(null);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil {title}</span>
								</span>,
								`Berhasil ${title} Data Jenis Surat`,
							);
							tutupForm();
							statusRefresh();
						} else {
							// setIsOpen(false);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal {title}</span>
								</span>,
								`Gagal ${title} Data Jenis Surat`,
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				GlobalServices.update(data, `administrasi/surat-instansi`, token)
					.then((response) => {
	
						if (response.data.code === 201) {
							setIsOpen(false);
							// setSelectedInstansi(null);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil {title}</span>
								</span>,
								`Berhasil ${title} Data Jenis Surat`,
							);
							tutupForm();
							statusRefresh();
						} else {
							// setIsOpen(false);
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal {title}</span>
								</span>,
								`Gagal ${title} Data Jenis Surat`,
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			}
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal {title}</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	const uploadFile = (file) => {
		if (file) {
			const type = file.type.split('/');
			if (
				type[1] === 'jpg' || 
				type[1] === 'jpeg' || 
				type[1] === 'png' || 
				type[1] === 'bmp' || 
				type[1] === 'tiff' || 
				type[1] === 'webp'
			) {
				const body = new FormData()
				body.append('file', file)
				// console.log(file);
				// console.log(body);
	
				GlobalServices.create(body, 'upload-file/surat-keluar', token)
					.then((response) => {
						// console.log(response);
	
						if (response.data.code === 200) {
							setGambarSelected(true)
	
							setSurat({
								...surat, gambar : response.data.results
							})
							
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Info' size='lg' className='me-1' />
									<span>Berhasil Upload Gambar</span>
								</span>,
								'Berhasil Upload Gambar',
							);
						} else {
							setGambarSelected(false)
							
							showNotification(
								<span className='d-flex align-items-center'>
									<Icon icon='Warning' size='lg' className='me-1' />
									<span>Gagal Upload Gambar</span>
								</span>,
								'Gagal Upload Gambar',
							);
						}
					})
					.catch((e) => {
						console.log(e);
					});
	
			}
		}
	}

	const handleChangeFile = (event) => {
		// console.log(event.target.files[0]);
		let data = event.target.files[0]
		// setSurat({
		// 	...surat, gambar : data
		// })
		uploadFile(data)
	}

	const tutupForm = () => {
		setSelectedTujuanSurat(null);
		setSurat(formData);
	};
	

	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={id}>{title} Jenis Surat</ModalTitle>
				</ModalHeader>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalBody className='px-4'>
					<div className='row g-4'>
						<FormGroup id='nama' label='Nama Surat' className='col-md-12'>
							<Input
								type='text'
								name='nama'
								// onChange={formik.handleChange}
								value={surat.nama}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='format_nomor_surat' label='Format Nomor Surat' className='col-md-12'>
							<Input
								type='text'
								name='format_nomor_surat'
								// onChange={formik.handleChange}
								value={surat.format_nomor_surat}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='nama_alias' label='Nama Alias' className='col-md-12'>
							<Input
								type='text'
								name='nama_alias'
								// onChange={formik.handleChange}
								value={surat.nama_alias}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='tujuan_surat' label='Tujuan Surat' className='col-md-12'>
							<Select
								inputId="tujuan_surat"
								classNamePrefix="select"
								name="tujuan_surat"
								placeholder="Pilih Tujuan Surat"
								isClearable
								required
								defaultValue={selectedTujuanSurat}
								value={selectedTujuanSurat}
								options={dataTujuanSurat}
								onInputChange={e => handleInputSelect(e, "tujuan_surat")}
								onChange={e => {
									handleSelect(e, "tujuan_surat");
								}}
							/>
						</FormGroup>
						<FormGroup id='gambar' label='Gambar' className='col-md-12'>
							<Input
								id='gambar'
								type='file'
								onChange={handleChangeFile}
								// value={surat.gambar}
							/>
						</FormGroup>
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button
						color='success'
						// isOutline
						// className='border-0'
						onClick={() => {
							setIsOpen(false);
							setSurat(formData);
							// setSelectedInstansi(null);
						}}>
						Batal
					</Button>
					{/* <Button color='info' onClick={formik.handleSubmit}> */}
					<Button color='info' type='submit' onClick={handleSubmit}>
						Simpan
					</Button>
				</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalData.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
	listTujuanSurat: PropTypes.arrayOf(PropTypes.object).isRequired,
	selectTujuanSurat: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ModalData;
