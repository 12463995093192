import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMasterAdmin, menuMaster } from '../../menu';
import Card, { CardBody } from '../bootstrap/Card';
import Button from '../bootstrap/Button';
import Breadcrumb from '../bootstrap/Breadcrumb';
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';


const HtmlToReactParser = require("html-to-react").Parser;

const Info = ({ props }) => {
	const history = useHistory();
	const roleUser = localStorage.getItem('role_user');
	const location = useLocation()
	const setDataInstansi = location.state?.dataProp
	const token = sessionStorage.getItem('access_token');

	const setDataProp = location.state?.dataProp
	console.log('hasil data prop detail karyawan');
	console.log(setDataProp);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false)
		}, 2000);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

    const htmlToReactParser = new HtmlToReactParser();

	return (
		<PageWrapper title='Detail Info'>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{
								title: 'Detail Info',
								to: roleUser === 'Super Admin' ? menuMaster.info.path :menuMasterAdmin.info.path,
							},
						]}
					/>
				</SubHeaderLeft>
				<SubHeaderRight>
					<Button
						icon='ArrowLeft'
						color='primary'
						// isLight
						onClick={() => history.goBack()}>
						Kembali
					</Button>
				</SubHeaderRight>
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
						{
							loading
							?
							<>
							<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
								<br/><br/><br/><br/><br/><br/><br/><br/><br/>
								<div className='row g-3'>
								{Object.keys(COLORS).map((color) => (
									<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
										<Spinner color={COLORS[color]} isSmall isGrow />
									</div>
								))}
								</div>
								<br/><br/><br/><br/>
							</div>
							</>
						:
							<>
							<CardBody isScrollable className='table-responsive'>
								<div className='container'>
									<div className='row'>
										<div className='col-12' align='center'>
											<h3>{setDataProp.judul}</h3>
										</div>
										<div className='col-12' align='center'>
											&nbsp;
										</div>
										<div className='col-12' align='center'>
											<img src={setDataProp.gambar} style={{width: '100%', height: 'auto'}} alt=''/>
										</div>
										<div className='col-12' align='center'>
											&nbsp;
										</div>
										<div className='col-12' align='center'>
											&nbsp;
										</div>
										<div className='col-12'>
											{htmlToReactParser.parse(setDataProp.isi)}
										</div>
									</div>
								</div>
							</CardBody>
							</>
						}
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Info;
