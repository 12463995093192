import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Button from '../bootstrap/Button';
import Company1 from '../../assets/logos/company1.png';
import CommonGridProductItem from '../common/CommonGridProductItem';
import tableData from '../../common/data/dummyProductData';
import GlobalServices from './Services/GlobalServices';
import Quadrilateral from '../../assets/img/abstract/message-open.png';
import '../../assets/css/site.css'
import AppLogo from '../../assets/img/Pamungkas.png'
import COLORS from './Services/Spinner';
import Spinner from '../bootstrap/Spinner';

// eslint-disable-next-line react/prop-types

const validate = (values) => {
	const errors = {};

	if (!values.name) {
		errors.name = 'Required';
	} else if (values.name.length < 3) {
		errors.name = 'Must be 3 characters or more';
	} else if (values.name.length > 20) {
		errors.name = 'Must be 20 characters or less';
	}

	if (!values.price) {
		errors.price = 'Required';
	} else if (values.price < 0) {
		errors.price = 'Price should not be 0';
	}

	if (!values.stock) {
		errors.stock = 'Required';
	}

	if (!values.category) {
		errors.category = 'Required';
	} else if (values.category.length < 3) {
		errors.category = 'Must be 3 characters or more';
	} else if (values.category.length > 20) {
		errors.category = 'Must be 20 characters or less';
	}

	return errors;
};

const DashboardUmum = (props) => {
	const history = useHistory()
	// useMinimizeAside();
	const [data, setData] = useState(tableData);
	const [list, setList] = useState([]);
	const [editItem, setEditItem] = useState(null);
	const [editPanel, setEditPanel] = useState(false);
	const [loading, setLoading] = useState(true);

	function handleRemove(id) {
		const newData = data.filter((item) => item.id !== id);
		setData(newData);
	}

	function handleEdit(id) {
		const newData = data.filter((item) => item.id === id);
		setEditItem(newData[0]);
	}

	const formik = useFormik({
		initialValues: {
			name: '',
			price: '',
			stock: '',
			category: '',
		},
		validate,
		// eslint-disable-next-line no-unused-vars
		onSubmit: (values) => {
			setEditPanel(false);
		},
	});

	const getDataList = () => {
		GlobalServices.getAll(`administrasi/surat-instansi/instansi/1450e8a0-6887-45fe-a0da-f56e6e496e0f/mahasiswa/list`)
			.then((response) => {
				console.log(response);
				setList(response.results);
				setLoading(false)
			})
			.catch((e) => {
				setLoading(false)
				console.log(e);
			});
	};

	useEffect(() => {
		getDataList()
		if (editItem) {
			formik.setValues({
				name: editItem.name,
				price: editItem.price,
				stock: editItem.stock,
				category: editItem.category,
			});
		}
		return () => {
			formik.setValues({
				name: '',
				price: '',
				stock: '',
				category: '',
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editItem]);

	return (
		// <PageWrapper title={demoPages.gridPages.subMenu.gridBoxed.text}>
		<PageWrapper title='Pelayanan'>
			<SubHeader>
				<SubHeaderLeft>
					{/* <img src={Company1} alt='Company' height={24} /> */}
					<h1 className='brand-title' style={{ color: 'black' }}>
						{/* <font color='gold'><i>p</i></font>amungkas */}
						<img src={AppLogo} alt="logo-aplikasi" style={{ width: '100%', maxWidth: '157px' }} />
					</h1>
					<div style={{ display: 'flex' }}>
						<span style={{ marginRight: '10px' }}>Mahasiswa</span>
						{/* <SubheaderSeparator style={{ marginRight: '10px' }} /> */}
						<span className='text-muted'>{list.length} surat</span>
					</div>
				</SubHeaderLeft>
				<SubHeaderRight>
					<Button
						color='info'
						
						icon='Search'
						tag='a'
						to={{
							pathname: "/tracking-surat-mahasiswa",
							state: {
								title: "Tracking Surat Mahasiswa"
							}
						}}>
						Tracking Surat
					</Button>
				</SubHeaderRight>
			</SubHeader>
			<Page style={{ paddingTop: '0.5rem' }}>
				<div className='display-4 fw-bold py-3' style={{ paddingTop: '0px !important', fontSize: '3rem', textAlign: 'center' }}>Pengajuan Surat Mahasiswa</div>
				{
					loading
					?
					<>
					<div className='col-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
						<br/><br/><br/><br/><br/><br/><br/><br/><br/>
						<div className='row g-3'>
						{Object.keys(COLORS).map((color) => (
							<div key={COLORS[color]} style={{ align:'center'}} className='col-auto'>
								<Spinner color={COLORS[color]} isSmall isGrow />
							</div>
						))}
						</div>
						<br/><br/><br/><br/>
					</div>
					</>
				:
					<>
						<div className='row'>
							{list.map((item) => (
								<div className='col-xxl-3 col-xl-4 col-md-6'
									style={{ marginBottom: '21px' }}
									onClick={()=> {
										history.push({
											pathname: "/pengajuan-mahasiswa",
											state: { 
												dataProp: item,
											},
										})
									}}>
									<CommonGridProductItem
										id={item.uuid}
										name={item.nama_alias}
										category={item.nama}
										img={item.gambar}
										detail={item}
										color='blue'
										// editAction={() => {
										// 	setEditPanel(true);
										// 	handleEdit(item.uuid);
										// }}
										// deleteAction={() => handleRemove(item.uuid)}
									/>
								</div>
							))}
						</div>
					</>
				}
			</Page>

		</PageWrapper>
	);
};
// Login.propTypes = {
// 	isSignUp: PropTypes.bool,
// };
// Login.defaultProps = {
// 	isSignUp: false,
// };

export default DashboardUmum;
