import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
// import data from './DataInstansi';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import Select from '../../bootstrap/forms/Select';
import Option from '../../bootstrap/Option';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';
import GlobalServices from '../Services/GlobalServices';

const ModalTambah = ({ id, isOpen, setIsOpen, statusRefresh, listInstansi }) => {

	const token = sessionStorage.getItem('access_token');
	const initialJamState = {
		title: 'Tambah Data Jam Absen',
		id: null,
		jam_absen: '',
		keterlambatan: '',
		active: '',
		urutan: '',
	};

	const [jam, setJam] = useState(initialJamState);
	// const [setSubmitted] = useState(false);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setJam({ ...jam, [name]: value });
	};

	const [selectedJenis, setSelectedJenis] = useState(null);
	const handleSelectChange = (event) => {
		setSelectedJenis(event.target.value);
	};

	const [selectedInstansi, setSelectedInstansi] = useState(null);
	const handleSelectChangeInstansi = (event) => {
		setSelectedInstansi(event.target.value);
	};

	const [selectedTipe, setSelectedTipe] = useState(null);
	const handleSelectChangeTipe = (event) => {
		setSelectedTipe(event.target.value);
	};

	const handleSubmit = () => {
		const data = {
			uuid_instansi: selectedInstansi,
			jenis: selectedJenis,
			tipe: selectedTipe,
			jam_absen: jam.jam_absen,
			keterlambatan: jam.keterlambatan,
			active: '1',
			urutan: jam.urutan,
		};

		if (
			jam.jam_absen !== '' &&
			jam.keterlambatan !== '' &&
			jam.urutan !== '' &&
			selectedInstansi &&
			selectedJenis &&
			selectedTipe
		) {
			GlobalServices.create(data, 'absensi/jam', token)
				.then((response) => {
					setJam({
						id: response.data.id,
						uuid_instansi: response.data.uuid_instansi,
						jenis: response.data.jenis,
						jam_absen: response.data.jam_absen,
						keterlambatan: response.data.keterlambatan,
						active: response.data.active,
						urutan: response.data.urutan,
					});
					// setSubmitted(true);
					console.log('lihat hasil simpan');
					console.log(response.data);
					if (response.data.code === 201) {
						setIsOpen(false);
						setSelectedJenis(null);
						setSelectedInstansi(null);
						setSelectedTipe(null);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Berhasil Tambah Data</span>
							</span>,
							'Berhasil Tambah Data Jam',
						);
						statusRefresh();
					} else {
						// setIsOpen(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Tambah Data</span>
							</span>,
							'Gagal Tambah Data Jam',
						);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Tambah Data</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};


	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id}>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={id}>{jam.title}</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row g-4'>
						<FormGroup id='uuid_instansi' label='Nama Instansi' className='col-md-6'>
							<Select
								id='largeSelect'
								ariaLabel='Default select example'
								placeholder='Pilih Instansi'
								required
								onChange={handleSelectChangeInstansi}
								value={selectedInstansi}
								size='md'>
								{listInstansi.map((item) => (
									<Option value={item.uuid}>{item.nama}</Option>
								))}
							</Select>
						</FormGroup>
						<FormGroup id='jenis' label='Jenis' className='col-md-6'>
							<Select
								id='mediumSelect'
								ariaLabel='Default select example'
								placeholder='Pilih Jenis Jadwal'
								required
								onChange={handleSelectChange}
								value={selectedJenis}
								size='md'>
								<Option value='Pagi'>Pagi</Option>
								<Option value='Sore'>Sore</Option>
								<Option value='Istirahat'>Istirahat</Option>
							</Select>
						</FormGroup>
						<FormGroup id='tipe' label='Tipe Absen' className='col-md-6'>
							<Select
								id='mediumSelect'
								ariaLabel='Default select example'
								placeholder='Pilih Tipe Absen'
								required
								onChange={handleSelectChangeTipe}
								value={selectedTipe}
								size='md'>
								<Option value='Masuk'>Masuk</Option>
								<Option value='Keluar'>Keluar</Option>
							</Select>
						</FormGroup>
						<FormGroup id='urutan' label='Urutan' className='col-md-6'>
							<Input
								type='number'
								maxLength='1'
								name='urutan'
								value={jam.urutan}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
						<FormGroup id='jam_absen' label='Jam Absen' className='col-md-6'>
							<Input
								placeholder='Jam Absen'
								onChange={handleInputChange}
								value={jam.jam_absen}
								type='time'
								required
							/>
						</FormGroup>
						<FormGroup id='keterlambatan' label='Keterlambatan' className='col-md-6'>
							<Input
								type='number'
								name='keterlambatan'
								value={jam.keterlambatan}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button
						color='success'
						// isOutline
						// className='border-0'
						onClick={() => {
							setIsOpen(false);
							setJam(initialJamState);
							setSelectedJenis(null);
							setSelectedInstansi(null);
							setSelectedTipe(null);
						}}>
						Batal
					</Button>
					{/* <Button color='info' onClick={formik.handleSubmit}> */}
					<Button color='info' type='submit' onClick={handleSubmit}>
						Simpan
					</Button>
				</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalTambah.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	listInstansi: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
};

export default ModalTambah;
