import React, { useEffect, useState } from 'react';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Breadcrumb from '../bootstrap/Breadcrumb';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Card, {
	CardActions,
	CardBody,
	CardCodeView,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle
} from '../bootstrap/Card';
import Humans from '../../assets/img/scene4.png';
import HumansWebp from '../../assets/img/scene4.webp';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Button from '../bootstrap/Button';
import GlobalServices from './Services/GlobalServices';
import Option from '../bootstrap/Option';
import Icon from '../icon/Icon';
import Alert from '../bootstrap/Alert';
import AppLogo from '../../assets/img/Pamungkas.png'

const DownloadSurat = (props) => {
	// useMinimizeAside();

	const [ idSurat, setIdSurat ] = useState(null);

	useEffect(
		() => {
			// getDataList();
			console.log(props);
			let query = new URLSearchParams(props.location.search)
			let id_surat = query.get('id_surat')

			setIdSurat(id_surat)
			
			if (id_surat) {
				window.location = `${process.env.REACT_APP_API_URL}api/v1/web/administrasi/pengajuan-surat-mahasiswa/cetak/${id_surat}`
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	if (idSurat) {
		return (
			// <PageWrapper title={demoPages.page404.text}>
			<PageWrapper title='Download Surat'>
				<Page>
					<div className='row d-flex align-items-center h-100'>
						<div className='col-12 d-flex flex-column justify-content-center align-items-center'>
							<div
								className='text-primary fw-bold'
								style={{ fontSize: 'calc(3rem + 3vw)' }}>
								{/* Pamungkas FST */}
								<img src={AppLogo} alt="logo-aplikasi" style={{ width: '100%' }} />
							</div>
							<div
								className='text-dark text-center fw-bold'
								style={{ fontSize: 'calc(1.5rem + 1.5vw)' }}>
								Sedang mempersipkan surat anda...
							</div>
							<h5 className='text-center'>Jika surat anda tidak terunduh otomatis, ketik tombol muat ulang dibawah ini</h5>
						</div>
						<div className='col-12 d-flex align-items-baseline justify-content-center'>
							<img
								srcSet={HumansWebp}
								src={Humans}
								alt='Humans'
								style={{ height: '50vh' }}
							/>
						</div>
						<div className='col-12 d-flex flex-column justify-content-center align-items-center' style={{ paddingBottom: '15px' }}>
							<Button
								className='px-5 py-3'
								color='info'
								// isLight
								icon='HolidayVillage'
								tag='a'
								href='/'>
								Muat ulang
							</Button>
						</div>
					</div>
				</Page>
			</PageWrapper>
		);
	}

	return (
		// <PageWrapper title={demoPages.page404.text}>
		<PageWrapper title='Page 404'>
			<Page>
				<div className='row d-flex align-items-center h-100'>
					<div className='col-12 d-flex flex-column justify-content-center align-items-center'>
						<div
							className='text-primary fw-bold'
							style={{ fontSize: 'calc(3rem + 3vw)' }}>
							404
						</div>
						<div
							className='text-dark fw-bold'
							style={{ fontSize: 'calc(1.5rem + 1.5vw)' }}>
							Page not found
						</div>
					</div>
					<div className='col-12 d-flex align-items-baseline justify-content-center'>
						<img
							srcSet={HumansWebp}
							src={Humans}
							alt='Humans'
							style={{ height: '50vh' }}
						/>
					</div>
					{/* <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
						<Button
							className='px-5 py-3'
							color='primary'
							isLight
							icon='HolidayVillage'
							tag='a'
							href='/'>
							Homepage
						</Button>
					</div> */}
				</div>
			</Page>
		</PageWrapper>
	); 
	

};
// Login.propTypes = {
// 	isSignUp: PropTypes.bool,
// };
// Login.defaultProps = {
// 	isSignUp: false,
// };

export default DownloadSurat;
