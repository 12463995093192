import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Form, FormGroup, Label, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import Page from '../../layout/Page/Page';
import { menuMaster, menuMasterAdmin } from '../../menu';
import Card, { CardBody } from '../bootstrap/Card';
import Breadcrumb from '../bootstrap/Breadcrumb';

// Page Tab Pane
import SuratMahasiswaProses from './SuratMahasiswaProses';
import SuratMahasiswaVerifikasi from './SuratMahasiswaVerifikasi';
import SuratMahasiswaSelesai from './SuratMahasiswaSelesai';
import SuratMahasiswaCetakLaporan from './SuratMahasiswaCetakLaporan';

const SuratMahasiswa = (props) => {
	const roleUser = localStorage.getItem('role_user');
	const uuidInstansiRole = localStorage.getItem('uuid_instansi');

	const location = useLocation()
  	const setDataProp = location.state?.dataProp
	// const history = useHistory();

	// Fitur Tab Pane
	const [resetList, setResetList] = useState(true);
	const [activeTabIcon, setActiveTabIcon] = useState('1');
	const [styleTab, setStyleTab] = useState({ backgroundColor: '#6a5cd0' });
	const [styleFontTab, setStyleFontTab] = useState('white');
	const toggleTab = (tab) => {
		if (activeTabIcon !== tab) {
			setActiveTabIcon(tab)
			setStyleTab({ backgroundColor: '#6a5cd0' })
			setStyleFontTab('white')
		}
	}
	// ENd Fitur Tab Pane

	const { linkTo, title } = props
	let titlePage = menuMasterAdmin.suratMahasiswa.text
	if (title) {
		titlePage = title
	}

	return (
		<PageWrapper title={menuMasterAdmin.suratMahasiswa.text}>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{ 
								title: titlePage, 
								to: menuMasterAdmin.suratMahasiswa.path 
							}
						]}
					/>
				</SubHeaderLeft>
			</SubHeader>
			<Page>
				<div className='row h-100'>
					<div className='col-12'>
						<Card stretch>
							<CardBody isScrollable className='table-responsive'>
							<Nav tabs className="tabs-color">
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '1' })}
											onClick={() => { setResetList(!resetList); toggleTab('1'); }} style={ activeTabIcon==='1'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-truck-alt" /><font color={activeTabIcon==='1'?styleFontTab:'#6a5cd0'}>Proses</font>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '2' })}
											onClick={() => { setResetList(!resetList); toggleTab('2'); }} style={ activeTabIcon==='2'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-truck-alt" /><font color={activeTabIcon==='2'?styleFontTab:'#6a5cd0'}>Menunggu Verifikasi</font>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '3' })}
											onClick={() => { setResetList(!resetList); toggleTab('3'); }} style={ activeTabIcon==='3'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-truck-alt" /><font color={activeTabIcon==='3'?styleFontTab:'#6a5cd0'}>Selesai</font>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink 
											className={classnames({ active: activeTabIcon === '4' })}
											onClick={() => { setResetList(!resetList); toggleTab('4'); }} style={ activeTabIcon==='4'? styleTab : {backgroundColor:'white'}}
										>
											<i className="icofont icofont-truck-alt" /><font color={activeTabIcon==='4'?styleFontTab:'#6a5cd0'}>Cetak Laporan</font>
										</NavLink>
									</NavItem>
								</Nav>
								<br />
								<TabContent activeTab={activeTabIcon}>
									<TabPane tabId="1">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<SuratMahasiswaProses setReset={resetList} statusTab={activeTabIcon === '1' ? 1:0}/>
												</Col>
											</Row>

										</div>
									</TabPane>

									{/* =========================================================================== */}
									<TabPane tabId="2">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<SuratMahasiswaVerifikasi setReset={resetList} statusTab={activeTabIcon === '2' ? 1:0}/>
												</Col>
											</Row>

										</div>
									</TabPane>

									{/* =========================================================================== */}
									<TabPane tabId="3">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<SuratMahasiswaSelesai setReset={resetList} statusTab={activeTabIcon === '3' ? 1:0}/>
												</Col>
											</Row>

										</div>
									</TabPane>

									{/* =========================================================================== */}
									<TabPane tabId="4">
										<div style={{ marginTop: 15 }}>
											<Row>
												<Col sm="12">
													<SuratMahasiswaCetakLaporan setReset={resetList} statusTab={activeTabIcon === '4' ? 1:0}/>
												</Col>
											</Row>

										</div>
									</TabPane>
									{/* =========================================================================== */}
								</TabContent>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default SuratMahasiswa;
