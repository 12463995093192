import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button from '../../bootstrap/Button';
import Select from '../../bootstrap/forms/Select';
import Option from '../../bootstrap/Option';
import GlobalServices from '../Services/GlobalServices';
import showNotification from '../../extras/showNotification';
import Icon from '../../icon/Icon';

const ModalTambah = ({ id, isOpen, setIsOpen, statusRefresh, listInstansi, uuidInstansi }) => {
	const token = sessionStorage.getItem('access_token');
	const formData = {
		title: 'Tambah Data Jabatan Fungsional',
		id: null,
		nama: '',
	};

	const [selectedInstansi, setSelectedInstansi] = useState(null);
	const handleSelectChangeInstansi = (event) => {
		setSelectedInstansi(event.target.value);
	};

	const [instansi, setInstansi] = useState(formData);
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setInstansi({ ...instansi, [name]: value });
	};

	const handleSubmit = () => {
		const data = {
			uuid_instansi: uuidInstansi,
			nama: instansi.nama,
		};

		if (instansi.nama !== '' && uuidInstansi) {
			GlobalServices.create(data, 'jabatan-fungsional', token)
				.then((response) => {
					if (response.data.code === 201) {
						setIsOpen(false);
						setSelectedInstansi(null);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Info' size='lg' className='me-1' />
								<span>Berhasil Tambah Data</span>
							</span>,
							'Berhasil Tambah Data Jabatan Fungsional',
						);
						setInstansi(formData);
						statusRefresh();
					} else {
						// setIsOpen(false);
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Warning' size='lg' className='me-1' />
								<span>Gagal Tambah Data</span>
							</span>,
							'Gagal Tambah Data Jabatan Fungsional',
						);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			// setIsOpen(false);
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Warning' size='lg' className='me-1' />
					<span>Gagal Tambah Data</span>
				</span>,
				'Data Tidak Lengkap!',
			);
		}
	};

	if (id || id === 0) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} isStaticBackdrop size='md' titleId={id} key='modal-tambah-jabatanfungsional'>
				<form onSubmit={(e) => e.preventDefault()}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle id={id}>{instansi.title}</ModalTitle>
				</ModalHeader>
				<ModalBody className='px-4'>
					<div className='row g-4'>
						<FormGroup id='nama' label='Jabatan Fungsional' className='col-md-12'>
							<Input
								type='text'
								name='nama'
								value={instansi.nama}
								onChange={handleInputChange}
								required
							/>
						</FormGroup>
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button
						color='danger'
						// isOutline
						// className='border-0'
						onClick={() => {
							setIsOpen(false);
							setInstansi(formData);
							setSelectedInstansi(null);
						}}>
						Batal
					</Button>
					{/* <Button color='info' onClick={formik.handleSubmit}> */}
					<Button color='success' type='submit' onClick={handleSubmit}>
						Simpan
					</Button>
				</ModalFooter>
				</form>
			</Modal>
		);
	}
	return null;
};
ModalTambah.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	listInstansi: PropTypes.func.isRequired,
	uuidInstansi: PropTypes.func.isRequired,
	statusRefresh: PropTypes.func.isRequired,
};

export default ModalTambah;
